import { memo } from 'react';

function Hardware() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1065_23075)">
        <path
          d="M15.7087 7.00001L11.4925 2.75501C10.3664 1.63121 8.84055 1.00006 7.24967 1.00006C5.6588 1.00006 4.1329 1.63121 3.00686 2.75501L3.00124 2.76063L2.14061 3.65001C2.05146 3.74582 2.00342 3.87276 2.00682 4.00359C2.01021 4.13441 2.06477 4.2587 2.15877 4.34975C2.25277 4.44081 2.37872 4.49138 2.50959 4.49061C2.64046 4.48984 2.76581 4.43779 2.85874 4.34563L3.71686 3.45938C4.0621 3.11421 4.45608 2.82151 4.88624 2.59063L7.79249 5.50001L1.79249 11.5C1.6996 11.5929 1.62592 11.7031 1.57565 11.8245C1.52539 11.9458 1.49951 12.0759 1.49951 12.2072C1.49951 12.3385 1.52539 12.4686 1.57565 12.5899C1.62592 12.7113 1.6996 12.8215 1.79249 12.9144L3.08561 14.2075C3.17847 14.3004 3.28872 14.3741 3.41006 14.4243C3.5314 14.4746 3.66146 14.5005 3.7928 14.5005C3.92414 14.5005 4.05419 14.4746 4.17553 14.4243C4.29687 14.3741 4.40712 14.3004 4.49999 14.2075L10.5 8.20751L11.3962 9.10376L12.5 10.2063C12.5928 10.2991 12.7031 10.3728 12.8244 10.4231C12.9458 10.4734 13.0758 10.4992 13.2072 10.4992C13.3385 10.4992 13.4686 10.4734 13.5899 10.4231C13.7113 10.3728 13.8215 10.2991 13.9144 10.2063L15.7075 8.41313C15.8949 8.22581 16.0002 7.97178 16.0005 7.70683C16.0007 7.44188 15.8958 7.18767 15.7087 7.00001ZM3.79249 13.5L2.49999 12.2069L6.74999 7.95688L8.04249 9.25001L3.79249 13.5ZM10.1462 7.14501L8.74999 8.54188L7.45686 7.25001L8.85374 5.85313C8.90022 5.8067 8.9371 5.75155 8.96227 5.69085C8.98743 5.63015 9.00038 5.56509 9.00038 5.49938C9.00038 5.43368 8.98743 5.36861 8.96227 5.30791C8.9371 5.24721 8.90022 5.19207 8.85374 5.14563L5.89499 2.18751C6.74982 1.94676 7.65335 1.93803 8.51267 2.16222C9.372 2.38642 10.1561 2.83545 10.7844 3.46313L13.5481 6.24501L11.75 8.04126L10.8537 7.14501C10.8073 7.09852 10.7522 7.06164 10.6915 7.03648C10.6308 7.01132 10.5657 6.99837 10.5 6.99837C10.4343 6.99837 10.3692 7.01132 10.3085 7.03648C10.2478 7.06164 10.1927 7.09852 10.1462 7.14501ZM13.2087 9.49876L12.4587 8.74876L14.25 6.95313L15 7.70313L13.2087 9.49876Z"
          fill="#1B1D2E"
        />
      </g>
      <defs>
        <clipPath id="clip0_1065_23075">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default memo(Hardware);
