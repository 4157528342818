import React, { createContext, useState, useContext } from "react";

interface CategoryContextProps {
  selectedCategory: number | null;
  selectCategory: (category: number | null) => void;
}

export const CategoriesContext = createContext<
  CategoryContextProps | undefined
>(undefined);

export const CategoriesProvider = ({ children }: { children: any }) => {
  const [selectedCategory, setSelectedCategory] = useState<number | null>(null);

  const selectCategory = (category: number | null) => {
    setSelectedCategory(category);
  };

  return (
    <CategoriesContext.Provider value={{ selectedCategory, selectCategory }}>
      {children}
    </CategoriesContext.Provider>
  );
};

export const useCategoryContext = (): CategoryContextProps => {
  const context = useContext(CategoriesContext);
  if (!context) {
    throw new Error(
      "useCategoryContext must be used within a CategoryProvider"
    );
  }
  return context;
};
