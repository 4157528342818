import styled, { css } from "styled-components";
import {
  IconButton,
  TextField,
  TextFieldProps,
  IconButtonProps,
  Popper,
  PopperProps,
  TypographyProps,
  Typography,
} from "@mui/material";
import { CloseRounded } from "../../svgs";

export const SearchContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const SearchButton = styled((props: TypographyProps & { searchButtonColor?: string }) => (
  <Typography {...props} variant="interH5" color="gray.White">
    Search
  </Typography>
))`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.7;

  padding: ${({ theme }) => theme.spacing(2, 3)};
  background-color: ${({  theme: { palette } }) => palette.primary.PurpleBase};
  border-radius: 0 6px 6px 0;
  transition: background-color 0.2s;
  &:hover {
    background-color: ${({ theme: { palette }, searchButtonColor }) =>
            searchButtonColor || palette.primary.PurpleDark02};
  }
`;

export const SearchInput = styled(
  ({
    isFocused,
    borderRadius,
    ...props
  }: TextFieldProps & {
    isFocused: boolean;
    borderRadius: string;
  }) => <TextField {...props} />
)<{
  isFocused: boolean;
  borderRadius: string;
}>`
  && {
    .MuiOutlinedInput-root {
      background-color: ${({ theme: { palette } }) =>
        palette.primary.PurpleLight03};
      border: 1px solid
        ${({ theme: { palette } }) => palette.primary.PurpleLight02};

      &.Mui-focused {
        border-color: ${({ theme: { palette } }) => palette.primary.PurpleBase};
      }

      color: ${({ theme: { palette } }) => palette.gray.GrayBase};
      border-radius: ${({ borderRadius }) => borderRadius || "6px"};
      height: 56px;
      padding: 0 12px;

      input {
        ${(props) => props.theme.inter.sh5};
        color: ${({ theme: { palette } }) => palette.gray.GrayDark03};
      }

      fieldset {
        border: none;
      }

      &:hover,
      &.Mui-focused {
        fieldset {
          border: none;
        }
      }
    }
  }
`;

export const StyledClearIcon = styled((props: IconButtonProps) => (
  <IconButton {...props} disableRipple>
    <CloseRounded />
  </IconButton>
))`
  cursor: pointer;
  padding: 0;
  margin-right: ${({ theme }) => theme.spacing(1)};
`;

export const StyledPopper = styled(({ ...props }: PopperProps) => (
  <Popper
    modifiers={[
      {
        name: "offset",
        options: {
          offset: [0, 4], // Margin top: 4px
        },
      },
    ]}
    {...props}
  />
))`
  && {
    height: 320px;
    padding: ${({ theme }) => theme.spacing(1, 2, 1.5)};
    border-radius: 6px;
    background-color: ${({ theme }) => theme.palette.gray.White};
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
    overflow-y: auto;
  }
`;

export const Toggle = styled(
  ({ active, ...props }: TypographyProps & { active: boolean }) => (
    <Typography {...props} />
  )
)`
  && {
    cursor: pointer;
    position: relative;
    padding: 8px 12px 0;
    ${({ active, theme }) =>
      active
        ? {
            ...theme.inter.h6,
            color: theme.palette.primary.PurpleDark02,
          }
        : {
            ...theme.inter.sh6,
            color: theme.palette.gray.GrayDark02,
          }}

    transition: color 0.1s;

    &:before {
      ${({ active, theme }) =>
        active &&
        css`
          position: absolute;
          content: "";
          bottom: 0;
          left: 12px;
          right: 12px;
          height: 3px;
          background-color: ${theme.palette.primary.PurpleDark02};
          border-radius: 4px 4px 0 0;
        `}
    }

    &:hover {
      ${({ active, theme }) =>
        !active &&
        css`
          color: ${theme.palette.gray.GrayDark03};
          background-color: ${theme.palette.primary.PurpleLight03};
          border-radius: 6px 6px 0 0;
        `}
    }
  }
`;

export const Results = styled.div`
  display: flex;
  padding: 12px 0 0;
`;
