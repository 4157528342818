export const ForwardIcon = (props: any) => {
  return (
    <svg width="11" height="10" viewBox="0 0 11 10" fill="none" {...props}>
      <path
        d="M0.702637 1.00098C0.702637 3.94548 3.08964 6.33298 6.03464 6.33298H9.96464M7.29814 3.66548L9.96464 6.33248L7.29814 8.99898"
        stroke="#1B1D2E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
