import { memo } from 'react';

function OfficeSupplies() {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.6799 4.15068L5.2606 9.57001C4.73994 10.0907 4.73994 10.9347 5.2606 11.4553C5.78127 11.976 6.62527 11.976 7.14594 11.4553L12.5646 6.03668C13.6059 4.99534 13.6059 3.30668 12.5646 2.26534C11.5233 1.22401 9.8346 1.22401 8.79327 2.26534L3.3766 7.68201C1.81327 9.24534 1.81327 11.78 3.3766 13.3433C4.93993 14.9067 7.4746 14.9067 9.03793 13.3433L13.8259 8.55534"
        stroke="#1B1D2E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default memo(OfficeSupplies);
