import { useState, ChangeEvent, useEffect } from "react";
import {
  Results,
  SearchButton,
  SearchContainer,
  SearchInput,
  StyledClearIcon,
  StyledPopper,
} from "./index.syled";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import {
  Autocomplete,
  CircularProgress,
  ClickAwayListener,
} from "@mui/material";
import { ExternalSearchRetailersList } from "../retailersList";
import { useSearchAPI } from "../../hooks/useSearchAPI";
import {  CategoriesList } from "../categoriesList";
import { sendEvent } from "../../utils/tracking";
import { RETAILER_URL } from "../../path";

type Category = {
  id: number;
  name: string;
  selected?: boolean;
  disabled?: boolean;
  subCategories?: {
    name: string;
    selected?: boolean;
    disabled?: boolean;
  }[];
};

enum SearchType {
  retailers = "Retailers",
  categories = "Product categories",
}

interface Props {
  searchButtonColor?: string;
}

export const Search = ({ searchButtonColor }: Props) => {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [retailers, setRetailers] = useState<string[]>([]);
  const recentRetailers: string[] = [
    'apple.com',
    'amazon.com',
    'nike.com',
    'adidas.com',
    'ikea.com',
    'walmart.com',
  ];
  const [searchType] = useState(SearchType.retailers);
  const [showPopup, setShowPopup] = useState(false);
  const [categories, setCategories] = useState<Category[] | null>(null);

  const { data, isLoading } = useSearchAPI({
    search,
    type: searchType === SearchType.categories ? "category" : "domain",
    limit: 10,
    offset: 0,
  });

  const notFoundMessage =
    search.length > 2 && !isLoading && retailers && !retailers.length
      ? `There are no retailers matching for ‘${search}’. Try a different search phrase.`
      : `Please enter at least 3 characters to search for retailers.`;

  const initialMessage =
    "Start by entering an e-commerce domain in the search bar above";

  const onSelect = (value: string) => {};

  const onRetailerSelect = (value: string) => {
    window.location.href = RETAILER_URL(value);

    sendEvent("search", {
      location: "site_search",
      type: "retailer",
      query: value,
    });
  };

  useEffect(() => {
    if (data?.type === "categories") {
      setCategories(data.data as Category[]);
      return;
    }
    if (data?.type === "domains") {
      setRetailers(data.data as string[]);
    }
  }, [data, searchType]);

  useEffect(() => {
    setRetailers([]);
    if (search.length < 3) {
      setCategories(null);
    }
  }, [search]);

  return (
    <>
      <ClickAwayListener onClickAway={() => setShowPopup(false)}>
        <SearchContainer>
          <Autocomplete
            freeSolo
            disableClearable
            fullWidth
            options={[]}
            open={open}
            inputValue={search ? search : ""}
            renderInput={(params) => (
              <SearchInput
                {...params}
                fullWidth
                isFocused={open}
                onFocus={() => {
                  setOpen(true);
                  setShowPopup(true);
                }}
                borderRadius="6px 0 0 6px"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <SearchOutlinedIcon
                      sx={{
                        mr: 1.5,
                        color: "gray.GrayDark01",
                        fontSize: "1.7rem",
                      }}
                    />
                  ),
                  endAdornment: (
                    <>
                      {isLoading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {!isLoading && search.length ? (
                        <StyledClearIcon
                          onClick={() => {
                            setShowPopup(false);
                            setSearch("");
                          }}
                        />
                      ) : null}
                    </>
                  ),
                }}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  if (!open) setOpen(true);
                  setSearch(e.target.value);
                }}
                onKeyPress={(e: any) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    onSelect(search);
                  }
                }}
                placeholder={`Enter retailer`}
              />
            )}
            PopperComponent={({ children, ...popperProps }) =>
              showPopup ? (
                <StyledPopper {...popperProps}>
                  <Results>
                    {searchType === SearchType.retailers ? (
                      <ExternalSearchRetailersList
                        initialMessage={initialMessage}
                        notFoundMessage={notFoundMessage}
                        showRecentRetailers={!search}
                        recentRetailers={recentRetailers}
                        retailers={retailers}
                        onRetailerSelect={onRetailerSelect}
                      />
                    ) : (
                      <CategoriesList
                        onClearSearch={() => setSearch("")}
                        numberOfColumns={
                          categories && categories.length ? 1 : 3
                        }
                        notFoundMessage={notFoundMessage}
                        findedCategories={categories}
                        recentCategories={[]}
                      />
                    )}
                  </Results>
                </StyledPopper>
              ) : (
                <></>
              )
            }
          />
          <SearchButton
            onClick={() => onSelect(search)}
            searchButtonColor={searchButtonColor}
          />
        </SearchContainer>
      </ClickAwayListener>
    </>
  );
};
