import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import styled, { ThemeProvider as StyledProvider } from "styled-components";
import { theme } from "./utils/theme";
import { CategoriesProvider } from "./components/categoriesList/categoriesContext";
import { Search } from "./components/search";

const Container = styled.div<{ height: number; width: number }>`
  height: ${({ height }) => height}rem;
  width: ${({ width }) => width}rem;
  display: flex;
  justify-content: center;
`;

interface AppProps {
  width: number;
  height: number;
  searchButtonColor?: string;
}

const App: React.FC<AppProps> = ({ width, height, searchButtonColor }) => {
  return (
    <StyledProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <CategoriesProvider>
          <Container id="Container" height={height} width={width} >
            <Search searchButtonColor={searchButtonColor} />
          </Container>
        </CategoriesProvider>
      </ThemeProvider>
    </StyledProvider>
  );
};

export default App;
