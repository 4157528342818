import { memo } from 'react';

function SportingGoods() {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.10066 1.77866V8.00066M8.10066 1.77866C6.46107 1.77866 4.9696 2.41285 3.85817 3.4493M8.10066 1.77866C9.74026 1.77866 11.2317 2.41284 12.3432 3.4493M8.10066 8.00066V14.2227M8.10066 8.00066H5.4353M8.10066 8.00066H10.7704M8.10066 14.2227C6.46107 14.2227 4.9696 13.5885 3.85817 12.552M8.10066 14.2227C9.74026 14.2227 11.2317 13.5885 12.3432 12.552M1.87866 8.00066H5.4353M1.87866 8.00066C1.87866 6.20394 2.64023 4.58509 3.85817 3.4493M1.87866 8.00066C1.87866 9.79738 2.64023 11.4162 3.85817 12.552M5.4353 8.00066C5.4353 5.34666 4.18121 4.09132 3.85817 3.4493M5.4353 8.00066C5.4353 10.6546 4.18121 11.9289 3.85817 12.552M10.7704 8.00066H14.3227M10.7704 8.00066C10.7704 5.34666 11.8189 4.08176 12.3432 3.4493M10.7704 8.00066C10.7704 10.6546 11.8189 11.93 12.3432 12.552M14.3227 8.00066C14.3227 6.20394 13.5611 4.58509 12.3432 3.4493M14.3227 8.00066C14.3227 9.79738 13.5611 11.4162 12.3432 12.552"
        stroke="#1B1D2E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default memo(SportingGoods);
