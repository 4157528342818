export const PrimaryColors = {
  PurpleDark03: "#2B0E5D",
  PurpleDark02: "#6222CC",
  PurpleDark01: "#803CF1",
  PurpleBase: "#A670FF",
  PurpleLight01: "#DAC7FA",
  PurpleLight02: "#F0E7FF",
  PurpleLight03: "#F9F5FF",
  MintDark03: "#002428",
  MintDark02: "#004A44",
  MintDark01: "#009374",
  MintBase: "#00CA94",
  MintLight01: "#A2FFDE",
  MintLight02: "#CEFFED",
  MintLight03: "#EEFFF9",
};
export const SecondaryColors = {
  BlueDark03: "#011845",
  BlueDark02: "#1359E1",
  BlueDark01: "#2C8DFF",
  BlueBase: "#3EC5FF",
  BlueLight01: "#B2E8FF",
  BlueLight02: "#CFF0FF",
  BlueLight03: "#ECF9FF",
  RedDark03: "#590B08",
  RedDark02: "#D14141",
  RedDark01: "#C34642",
  RedBase: "#FF7C77",
  RedLight01: "#FFA19D",
  RedLight02: "#FFCFD8",
  RedLight03: "#FFF4F6",
  YellowDark03: "#5B410C",
  YellowDark02: "#B78D23",
  YellowDark01: "#DBB033",
  YellowBase: "#FFD447",
  YellowLight01: "#FFEA90",
  YellowLight02: "#FFF3B5",
  YellowLight03: "#FFF9DA",
};
export const GrayscaleColors = {
  GrayDark03: "#1B1D2E",
  GrayDark02: "#494A58",
  GrayDark01: "#767782",
  GrayBase: "#A4A5AB",
  GrayLight01: "#D1D2D5",
  GrayLight02: "#F3F3F4",
  GrayLight03: "#FBFBFB",
  White: "#FFFFFF",
};
export const NeutralColors = {
  NeutralDark03: "#180027",
  NeutralDark02: "#2C2045",
  NeutralDark01: "#726B97",
  NeutralLight01: "#B1A9CB",
  NeutralLight02: "#DCCEEF",
  NeutralLight03: "#FAF8FF",
};
