import React, { isValidElement, useState } from "react";

import styled from "styled-components";
import { Box, BoxProps } from "@mui/system";
import { Typography, TypographyProps, useTheme } from "@mui/material";
import {
  Category,
  formatMainCategoryTitle,
  getCategoriesByIds,
  raw_categories,
} from "../../commons/categories";
import { ForwardIcon } from "../../svgs/forwardIcon";
import { useCategoryContext } from "./categoriesContext";
import CatsalBreadcrumbs from "./catsalBreadcrumbs";
import { sendEvent } from "../../utils/tracking";

type Props = {
  onClearSearch: () => void;
  recentCategories: Category[];
  findedCategories: Category[] | null;
  numberOfColumns?: number;
  notFoundMessage?: string | null;
};

const CategoryText = styled((props: any) => (
  <Typography variant="interSH6" {...props} />
))`
  width: 75%;
  color: ${({ theme }) => theme.palette.gray.GrayDark03};
`;

const ForwardButton = styled.div`
  background-color: ${({ theme }) => theme.palette.gray.GrayLight03};
  width: 40px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.PurpleLight03};
  }
`;

const LeftSideButton = styled.div`
  height: 100%;
  display: flex;
  flex: 1;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.gray.GrayLight03};
  margin-right: 2px;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.PurpleLight03};
  }
`;

const AllProductButton = styled((props: TypographyProps) => (
  <Typography variant="interSH6" {...props} style={props.style} />
))`
  &.MuiTypography-root {
    cursor: pointer;
    color: ${({ theme }) => theme.palette.primary.PurpleDark01};
  }
`;

const IconWrapper = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CategoryComponentWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: ${({ theme }) => theme.spacing(0.25)};
`;

export const API_URL = "https://app.gripsintelligence.com/";

const CategoryComponent = (props: {
  category: Category;
  onCategoryClick: (categoryId: number) => void;
  onForwardClick: (categoryId: number) => void;
}) => {
  const {
    category: { id, name, children, icon },
    onCategoryClick,
    onForwardClick,
  } = props;

  const isIconValid = isValidElement(icon);
  return (
    <CategoryComponentWrapper>
      <LeftSideButton onClick={() => onCategoryClick(id)}>
        {isIconValid && <IconWrapper>{icon}</IconWrapper>}
        <CategoryText style={{ marginLeft: !isIconValid ? 12 : 0 }}>
          {formatMainCategoryTitle(name)}
        </CategoryText>
      </LeftSideButton>
      {children && (
        <ForwardButton onClick={() => onForwardClick(id)}>
          <ForwardIcon />
        </ForwardButton>
      )}
    </CategoryComponentWrapper>
  );
};

const Grid = styled(({ numberOfColumns, ...props }: any) => <Box {...props} />)`
  display: grid;
  grid-template-columns: repeat(
    ${({ numberOfColumns }) => numberOfColumns},
    auto
  );
  row-gap: ${({ theme }) => theme.spacing(3.6)};
  column-gap: ${({ theme }) => theme.spacing(2.5)};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

const BottomListTextContainer = styled.div`
  width: 100%;
  height: 42px;
  display: flex;
  align-items: center;
  margin-top: 16px;
  margin-left: -16px;
  margin-right: -16px;
  padding: 0 16px;
  border-top: 1px solid ${({ theme }) => theme.palette.gray.GrayLight02};
  gap: 4px;
`;

export const CategoriesList = styled(
  ({
    onClearSearch,
    findedCategories,
    recentCategories,
    numberOfColumns,
    notFoundMessage,
    ...props
  }: BoxProps & Props) => {
    const theme = useTheme();
    numberOfColumns = numberOfColumns || 3;

    const { selectedCategory, selectCategory } = useCategoryContext();

    const onClickAllCategories = () => {
      onClearSearch();
      setCurrentCategory(0);
    };

    const onCategoryClick = (category: number) => {
      window.location.href = `${API_URL}?category=${category}`;

      sendEvent("search", {
        location: "site_search",
        type: "category",
        query: category,
      });
    };

    const onForwardClick = (category: number) => {
      setCurrentCategory(category);
      selectCategory(category);
    };

    const [currentCategory, setCurrentCategory] = useState<number>(
      selectedCategory || 0
    );
    const selectableCategories = getCategoriesByIds(
      raw_categories[currentCategory].children as number[]
    );

    const displayedCategories = findedCategories?.length
      ? findedCategories
      : selectableCategories;

    return (
      <Container>
        {currentCategory !== 0 ? (
          <CatsalBreadcrumbs
            onClickBack={(previousCategory) =>
              setCurrentCategory(previousCategory)
            }
            onCategorySelect={(category) => setCurrentCategory(category)}
            category={currentCategory}
          />
        ) : (
          <Typography
            sx={{
              padding: 1,
              marginBottom: 1.5,
              color: theme.palette.gray.GrayDark01,
            }}
            variant="h7"
          >
            ALL PRODUCT CATEGORIES
          </Typography>
        )}

        <Grid
          style={{ width: "100%", rowGap: 8, columnGap: 16 }}
          numberOfColumns={numberOfColumns}
        >
          {displayedCategories.map((category, idx) => (
            <CategoryComponent
              key={idx}
              category={category}
              onCategoryClick={(cat) => onCategoryClick(cat)}
              onForwardClick={(cat) => onForwardClick(cat)}
            />
          ))}
        </Grid>
        {findedCategories && (
          <BottomListTextContainer>
            <Typography
              style={{
                color: theme.palette.gray.GrayDark01,
              }}
              variant="interSH6"
            >
              Didn’t find a good match? Try changing the search phrase or
            </Typography>
            <AllProductButton onClick={onClickAllCategories}>
              browse all product categories.
            </AllProductButton>
          </BottomListTextContainer>
        )}
      </Container>
    );
  }
)``;
