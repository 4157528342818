import { memo } from 'react';

function HomeGarden() {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.1447 7.11133V12.2213C13.1447 13.326 12.2494 14.2213 11.1447 14.2213H6.25536C5.15069 14.2213 4.25536 13.326 4.25536 12.2213V7.11133M2.47803 8.88933L8.70003 2.66733L14.922 8.88933M7.30003 12.4433V8.88933H10.1V12.4433"
        stroke="#1B1D2E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default memo(HomeGarden);
