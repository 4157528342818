import { createTheme, ThemeOptions } from "@mui/material/styles";
import { Colors } from "../utils/colors";
import {
  NeutralColors,
  PrimaryColors,
  GrayscaleColors,
  SecondaryColors,
} from "../utils/spcolors";
import * as React from "react";
import { TypographyOptions } from "@mui/material/styles/createTypography";

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    xxl: true;
    mobile: true;
    tablet: true;
    desktop: true;
    desktopL: true;
  }
}

declare module "@mui/material/styles" {
  interface TypographyVariants {
    header1: React.CSSProperties;
    header2: React.CSSProperties;
    header3: React.CSSProperties;
    header4: React.CSSProperties;
    header5: React.CSSProperties;
    header6: React.CSSProperties;
    header7: React.CSSProperties;
    header8: React.CSSProperties;
    subHeader1: React.CSSProperties;
    subHeader2: React.CSSProperties;
    subHeader3: React.CSSProperties;
    subHeader4: React.CSSProperties;
    subHeader5: React.CSSProperties;
    subHeader6: React.CSSProperties;
    subHeader7: React.CSSProperties;
    subHeader8: React.CSSProperties;
    body1: React.CSSProperties;
    body2: React.CSSProperties;
    body3: React.CSSProperties;
    body4: React.CSSProperties;
    body5: React.CSSProperties;
    body6: React.CSSProperties;
    body7: React.CSSProperties;
    body8: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    h1: React.CSSProperties;
    h2: React.CSSProperties;
    h3: React.CSSProperties;
    h4: React.CSSProperties;
    h5: React.CSSProperties;
    h6: React.CSSProperties;
    h7: React.CSSProperties;
    h8: React.CSSProperties;
    sh1: React.CSSProperties;
    sh2: React.CSSProperties;
    sh3: React.CSSProperties;
    sh4: React.CSSProperties;
    sh5: React.CSSProperties;
    sh6: React.CSSProperties;
    sh7: React.CSSProperties;
    sh8: React.CSSProperties;
    b1: React.CSSProperties;
    b2: React.CSSProperties;
    b3: React.CSSProperties;
    b4: React.CSSProperties;
    b5: React.CSSProperties;
    b6: React.CSSProperties;
    b7: React.CSSProperties;
    b8: React.CSSProperties;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    h1: true;
    h2: true;
    h3: true;
    h4: true;
    h5: true;
    h6: true;
    h7: true;
    h8: true;
    sh1: true;
    sh2: true;
    sh3: true;
    sh4: true;
    sh5: true;
    sh6: true;
    sh7: true;
    sh8: true;
    b1: true;
    b2: true;
    b3: true;
    b4: true;
    b5: true;
    b6: true;
    b7: true;
    b8: true;
    interH1: true;
    interH2: true;
    interH3: true;
    interH4: true;
    interH5: true;
    interH6: true;
    interH7: true;
    interH8: true;
    interSH1: true;
    interSH2: true;
    interSH3: true;
    interSH4: true;
    interSH5: true;
    interSH6: true;
    interSH7: true;
    interSH8: true;
    interB1: true;
    interB2: true;
    interB3: true;
    interB4: true;
    interB5: true;
    interB6: true;
    interB7: true;
    interB8: true;
    gintoH1: true;
    gintoH2: true;
    gintoH3: true;
    gintoH4: true;
    gintoH5: true;
    gintoH6: true;
    gintoH7: true;
    gintoSH1: true;
    gintoSH2: true;
    gintoSH3: true;
    gintoSH4: true;
    gintoSH5: true;
    gintoSH6: true;
    gintoSH7: true;
  }
}

interface ExtendedTypographyOptions extends TypographyOptions {
  interH1: React.CSSProperties;
  interH2: React.CSSProperties;
  interH3: React.CSSProperties;
  interH4: React.CSSProperties;
  interH5: React.CSSProperties;
  interH6: React.CSSProperties;
  interH7: React.CSSProperties;
  interH8: React.CSSProperties;
  interSH1: React.CSSProperties;
  interSH2: React.CSSProperties;
  interSH3: React.CSSProperties;
  interSH4: React.CSSProperties;
  interSH5: React.CSSProperties;
  interSH6: React.CSSProperties;
  interSH7: React.CSSProperties;
  interSH8: React.CSSProperties;
  interB1: React.CSSProperties;
  interB2: React.CSSProperties;
  interB3: React.CSSProperties;
  interB4: React.CSSProperties;
  interB5: React.CSSProperties;
  interB6: React.CSSProperties;
  interB7: React.CSSProperties;
  gintoH1: React.CSSProperties;
  gintoH2: React.CSSProperties;
  gintoH3: React.CSSProperties;
  gintoH4: React.CSSProperties;
  gintoH5: React.CSSProperties;
  gintoH6: React.CSSProperties;
  gintoH7: React.CSSProperties;
  gintoSH1: React.CSSProperties;
  gintoSH2: React.CSSProperties;
  gintoSH3: React.CSSProperties;
  gintoSH4: React.CSSProperties;
  gintoSH5: React.CSSProperties;
  gintoSH6: React.CSSProperties;
  gintoSH7: React.CSSProperties;
}

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    dark: true;
  }
}

declare module "@mui/material/styles" {
  interface PaletteColor {
    80: string;
    60: string;
    40: string;
    30: string;
    20: string;
    5: string;
    2: string;
    GrayDark03: string;
    GrayDark02: string;
    GrayDark01: string;
    GrayBase: string;
    GrayLight01: string;
    GrayLight02: string;
    GrayLight03: string;
    White: string;
    PurpleDark03: string;
    PurpleDark02: string;
    PurpleDark01: string;
    PurpleBase: string;
    PurpleLight01: string;
    PurpleLight02: string;
    PurpleLight03: string;
    MintDark03: string;
    MintDark02: string;
    MintDark01: string;
    MintBase: string;
    MintLight01: string;
    MintLight02: string;
    MintLight03: string;
    BlueDark03: string;
    BlueDark02: string;
    BlueDark01: string;
    BlueBase: string;
    BlueLight01: string;
    BlueLight02: string;
    BlueLight03: string;
    RedDark03: string;
    RedDark02: string;
    RedDark01: string;
    RedBase: string;
    RedLight01: string;
    RedLight02: string;
    RedLight03: string;
    YellowDark03: string;
    YellowDark02: string;
    YellowDark01: string;
    YellowBase: string;
    YellowLight01: string;
    YellowLight02: string;
    YellowLight03: string;
    NeutralDark03: string;
    NeutralDark02: string;
    NeutralDark01: string;
    NeutralLight01: string;
    NeutralLight02: string;
    NeutralLight03: string;
  }
  interface SimplePaletteColorOptions {
    80: string;
    60: string;
    40: string;
    30: string;
    20: string;
    5: string;
    2: string;
  }

  interface Palette {
    tertiary: Palette["primary"];
    gray: Palette["primary"];
  }
  interface PaletteOptions {
    tertiary: PaletteOptions["primary"];
  }
}

export const theme = createTheme({
  inter: {
    fontFamily: "'Inter'",
    h1: { fontSize: 36, fontWeight: 700, lineHeight: 1.5 },
    h2: { fontSize: 28, fontWeight: 700, lineHeight: 1.5 },
    h3: { fontSize: 20, fontWeight: 700, lineHeight: 1.5 },
    h4: { fontSize: 16, fontWeight: 700, lineHeight: 1.5 },
    h5: { fontSize: 14, fontWeight: 700, lineHeight: 1.5 },
    h6: { fontSize: 12, fontWeight: 700, lineHeight: 1.5 },
    h7: { fontSize: 10, fontWeight: 700, lineHeight: 1.5 },
    sh1: { fontSize: 24, fontWeight: 500, lineHeight: 1.5 },
    sh2: { fontSize: 20, fontWeight: 500, lineHeight: 1.5 },
    sh3: { fontSize: 18, fontWeight: 500, lineHeight: 1.5 },
    sh4: { fontSize: 16, fontWeight: 500, lineHeight: 1.5 },
    sh5: { fontSize: 14, fontWeight: 500, lineHeight: 1.5 },
    sh6: { fontSize: 12, fontWeight: 500, lineHeight: 1.5 },
    sh7: { fontSize: 10, fontWeight: 500, lineHeight: 1.5 },
    sh8: { fontSize: 8, fontWeight: 500, lineHeight: 1.5 },
    b1: { fontSize: 24, fontWeight: 400, lineHeight: 1.5 },
    b2: { fontSize: 20, fontWeight: 400, lineHeight: 1.5 },
    b3: { fontSize: 18, fontWeight: 400, lineHeight: 1.5 },
    b4: { fontSize: 16, fontWeight: 400, lineHeight: 1.5 },
    b5: { fontSize: 14, fontWeight: 400, lineHeight: 1.5 },
    b6: { fontSize: 12, fontWeight: 400, lineHeight: 1.5 },
    b7: { fontSize: 10, fontWeight: 400, lineHeight: 1.5 },
  },
  ginto: {
    fontFamily: "'GintoNord'",
    h1: { fontSize: 40, fontWeight: 700, lineHeight: 1.5 },
    h2: { fontSize: 32, fontWeight: 700, lineHeight: 1.5 },
    h3: { fontSize: 24, fontWeight: 700, lineHeight: 1.5 },
    h4: { fontSize: 18, fontWeight: 700, lineHeight: 1.5 },
    h5: { fontSize: 14, fontWeight: 700, lineHeight: 1.5 },
    h6: { fontSize: 12, fontWeight: 700, lineHeight: 1.5 },
    h7: { fontSize: 10, fontWeight: 700, lineHeight: 1.5 },
    sh1: { fontSize: 40, fontWeight: 500, lineHeight: 1.5 },
    sh2: { fontSize: 32, fontWeight: 500, lineHeight: 1.5 },
    sh3: { fontSize: 24, fontWeight: 500, lineHeight: 1.5 },
    sh4: { fontSize: 18, fontWeight: 500, lineHeight: 1.5 },
    sh5: { fontSize: 14, fontWeight: 500, lineHeight: 1.5 },
    sh6: { fontSize: 12, fontWeight: 500, lineHeight: 1.5 },
    sh7: { fontSize: 10, fontWeight: 500, lineHeight: 1.5 },
  },
  typography: {
    fontFamily: "'Inter'",
    h1: { fontSize: 36, fontWeight: 700, lineHeight: 1.5 },
    h2: { fontSize: 28, fontWeight: 700, lineHeight: 1.5 },
    h3: { fontSize: 20, fontWeight: 700, lineHeight: 1.5 },
    h4: { fontSize: 16, fontWeight: 700, lineHeight: 1.5 },
    h5: { fontSize: 14, fontWeight: 700, lineHeight: 1.5 },
    h6: { fontSize: 12, fontWeight: 700, lineHeight: 1.5 },
    h7: { fontSize: 10, fontWeight: 700, lineHeight: 1.5 },
    h8: { fontSize: 8, fontWeight: 700, lineHeight: 1.5 },
    sh1: { fontSize: 24, fontWeight: 500, lineHeight: 1.5 },
    sh2: { fontSize: 20, fontWeight: 500, lineHeight: 1.5 },
    sh3: { fontSize: 18, fontWeight: 500, lineHeight: 1.5 },
    sh4: { fontSize: 16, fontWeight: 500, lineHeight: 1.5 },
    sh5: { fontSize: 14, fontWeight: 500, lineHeight: 1.5 },
    sh6: { fontSize: 12, fontWeight: 500, lineHeight: 1.5 },
    sh7: { fontSize: 10, fontWeight: 500, lineHeight: 1.5 },
    sh8: { fontSize: 8, fontWeight: 500, lineHeight: 1.5 },
    b1: { fontSize: 24, fontWeight: 400, lineHeight: 1.5 },
    b2: { fontSize: 20, fontWeight: 400, lineHeight: 1.5 },
    b3: { fontSize: 18, fontWeight: 400, lineHeight: 1.5 },
    b4: { fontSize: 16, fontWeight: 400, lineHeight: 1.5 },
    b5: { fontSize: 14, fontWeight: 400, lineHeight: 1.5 },
    b6: { fontSize: 12, fontWeight: 400, lineHeight: 1.5 },
    b7: { fontSize: 10, fontWeight: 400, lineHeight: 1.5 },
    b8: { fontSize: 8, fontWeight: 400, lineHeight: 1.5 },
    interH1: {
      fontFamily: "'Inter', sans-serif",
      fontWeight: 700,
      fontSize: 36,
    },
    interH2: {
      fontFamily: "'Inter', sans-serif",
      fontWeight: 700,
      fontSize: 28,
    },
    interH3: {
      fontFamily: "'Inter', sans-serif",
      fontWeight: 700,
      fontSize: 20,
    },
    interH4: {
      fontFamily: "'Inter', sans-serif",
      fontWeight: 700,
      fontSize: 16,
    },
    interH5: {
      fontFamily: "'Inter', sans-serif",
      fontWeight: 700,
      fontSize: 14,
    },
    interH6: {
      fontFamily: "'Inter', sans-serif",
      fontWeight: 700,
      fontSize: 12,
    },
    interH7: {
      fontFamily: "'Inter', sans-serif",
      fontWeight: 700,
      fontSize: 10,
    },
    interH8: {
      fontFamily: "'Inter', sans-serif",
      fontWeight: 700,
      fontSize: 8,
    },
    interSH1: {
      fontFamily: "'Inter', sans-serif",
      fontWeight: 500,
      fontSize: 24,
    },
    interSH2: {
      fontFamily: "'Inter', sans-serif",
      fontWeight: 500,
      fontSize: 20,
    },
    interSH3: {
      fontFamily: "'Inter', sans-serif",
      fontWeight: 500,
      fontSize: 18,
    },
    interSH4: {
      fontFamily: "'Inter', sans-serif",
      fontWeight: 500,
      fontSize: 16,
    },
    interSH5: {
      fontFamily: "'Inter', sans-serif",
      fontWeight: 500,
      fontSize: 14,
    },
    interSH6: {
      fontFamily: "'Inter', sans-serif",
      fontWeight: 500,
      fontSize: 12,
    },
    interSH7: {
      fontFamily: "'Inter', sans-serif",
      fontWeight: 500,
      fontSize: 10,
    },
    interSH8: {
      fontFamily: "'Inter', sans-serif",
      fontWeight: 500,
      fontSize: 8,
    },
    interB1: {
      fontSize: 24,
      fontFamily: "'Inter', sans-serif",
      fontWeight: 400,
    },
    interB2: {
      fontSize: 20,
      fontFamily: "'Inter', sans-serif",
      fontWeight: 400,
    },
    interB3: {
      fontSize: 18,
      fontFamily: "'Inter', sans-serif",
      fontWeight: 400,
    },
    interB4: {
      fontSize: 16,
      fontFamily: "'Inter', sans-serif",
      fontWeight: 400,
    },
    interB5: {
      fontSize: 14,
      fontFamily: "'Inter', sans-serif",
      fontWeight: 400,
    },
    interB6: {
      fontSize: 12,
      fontFamily: "'Inter', sans-serif",
      fontWeight: 400,
    },
    interB7: {
      fontSize: 10,
      fontFamily: "'Inter', sans-serif",
      fontWeight: 400,
    },
    gintoH1: {
      fontSize: 40,
      fontFamily: "'GintoNord', sans-serif",
      fontWeight: 700,
    },
    gintoH2: {
      fontSize: 32,
      fontFamily: "'GintoNord', sans-serif",
      fontWeight: 700,
    },
    gintoH3: {
      fontSize: 24,
      fontFamily: "'GintoNord', sans-serif",
      fontWeight: 700,
    },
    gintoH4: {
      fontSize: 18,
      fontFamily: "'GintoNord', sans-serif",
      fontWeight: 700,
    },
    gintoH5: {
      fontSize: 14,
      fontFamily: "'GintoNord', sans-serif",
      fontWeight: 700,
    },
    gintoH6: {
      fontSize: 12,
      fontFamily: "'GintoNord', sans-serif",
      fontWeight: 700,
    },
    gintoH7: {
      fontSize: 10,
      fontFamily: "'GintoNord', sans-serif",
      fontWeight: 700,
    },
    gintoSH1: {
      fontSize: 40,
      fontFamily: "'GintoNord', sans-serif",
      fontWeight: 500,
    },
    gintoSH2: {
      fontSize: 32,
      fontFamily: "'GintoNord', sans-serif",
      fontWeight: 500,
    },
    gintoSH3: {
      fontSize: 24,
      fontFamily: "'GintoNord', sans-serif",
      fontWeight: 500,
    },
    gintoSH4: {
      fontSize: 18,
      fontFamily: "'GintoNord', sans-serif",
      fontWeight: 500,
    },
    gintoSH5: {
      fontSize: 14,
      fontFamily: "'GintoNord', sans-serif",
      fontWeight: 500,
    },
    gintoSH6: {
      fontSize: 12,
      fontFamily: "'GintoNord', sans-serif",
      fontWeight: 500,
    },
    gintoSH7: {
      fontSize: 10,
      fontFamily: "'GintoNord', sans-serif",
      fontWeight: 500,
    },
  } as ExtendedTypographyOptions,
  MuiTypography: {
    styleOverrides: {
      root: {
        color: GrayscaleColors.GrayDark03,
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1440,
      xxl: 1920,
      mobile: 0,
      tablet: 600,
      desktop: 1000,
      desktopL: 1200,
    },
  },
  components: {
    MuiInputBase: {
      defaultProps: {
        disableInjectingGlobalStyles: true,
      },
    },
    MuiDivider: {
      styleOverrides: {
        light: {
          borderColor: GrayscaleColors.GrayLight02,
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          color: GrayscaleColors.GrayDark03,
          border: `1px solid ${GrayscaleColors.GrayDark03}`,
          "&.Mui-selected, &.Mui-selected:hover": {
            color: GrayscaleColors.White,
            background: GrayscaleColors.GrayDark03,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontSize: 12,
          fontWeight: 700,
          lineHeight: 1.5,
          padding: "8px 24px",
        },
        containedPrimary: {
          color: GrayscaleColors.GrayDark03,
          "&.Mui-disabled": {
            backgroundColor: GrayscaleColors.GrayBase,
          },
        },
        outlinedPrimary: {
          color: GrayscaleColors.GrayDark03,
          borderColor: GrayscaleColors.GrayDark03,
          "&:hover": {
            borderColor: GrayscaleColors.GrayDark02,
          },
        },
        outlinedSecondary: {
          background: GrayscaleColors.White,
          borderColor: PrimaryColors.PurpleDark03,
          "&:hover": {
            background: Colors.lightBlue5,
          },
        },
      },
      variants: [
        {
          props: { variant: "dark" },
          style: {
            color: GrayscaleColors.White,
            background: GrayscaleColors.GrayDark03,
            "&:hover": {
              background: GrayscaleColors.GrayDark02,
            },
          },
        },
      ],
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          color: GrayscaleColors.GrayDark03,
        },
        rail: {
          background: GrayscaleColors.GrayLight01,
        },
        track: {
          background: GrayscaleColors.GrayDark03,
        },
        thumb: {
          "&.Mui-focusVisible, &:hover": {
            boxShadow: "0px 0px 0px 8px rgba(0, 0, 0, 0.16)",
          },
          "&.MuiSlider-active": {
            boxShadow: "0px 0px 0px 14px rgba(0, 0, 0, 0.16)",
          },
        },
        valueLabel: {
          background: GrayscaleColors.GrayDark03,
          color: "#fff",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.06)",
        },
      },
    },
  },
  palette: {
    error: { main: Colors.error },
    primary: {
      ...PrimaryColors,
      main: PrimaryColors.PurpleBase,
    },
    secondary: {
      ...SecondaryColors,
      main: SecondaryColors.BlueDark03,
      40: Colors.darkBlue40,
      20: Colors.darkBlue20,
      5: Colors.darkBlue5,
      3: Colors.darkBlue3,
    },
    gray: GrayscaleColors,
    neutral: NeutralColors,
    tertiary: {
      main: Colors.lightBlue100,
      40: Colors.lightBlue40,
      20: Colors.lightBlue20,
    },
  },
} as ThemeOptions);

type PeekdTheme = typeof theme;
declare module "@mui/system" {
  interface DefaultTheme extends PeekdTheme {}
}
