import { memo } from 'react';

function Media() {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.05542 7.07534C4.05542 9.53001 6.04542 11.52 8.50009 11.52M8.50009 11.52C10.9548 11.52 12.9448 9.53001 12.9448 7.07534M8.50009 11.52V14.186M8.50009 8.85334C7.51809 8.85334 6.72209 8.05734 6.72209 7.07534V3.52068C6.72209 2.53868 7.51809 1.74268 8.50009 1.74268C9.48209 1.74268 10.2781 2.53868 10.2781 3.52068V7.07534C10.2781 8.05734 9.48209 8.85334 8.50009 8.85334Z"
        stroke="#1B1D2E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default memo(Media);
