import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./fonts.css";
import App from "./App";
import * as Sentry from "@sentry/react";

Sentry.init({
    dsn: "https://785006bf83de4b0f8c5c9b468038a3ac@o75181.ingest.sentry.io/4505273290194944",
    tracesSampleRate: 1.0,
});

[...document.getElementsByClassName("grips-search")].forEach((gripsSearchElement) => {
    const width = parseInt(gripsSearchElement?.getAttribute("data-width"));
    const height = parseInt(gripsSearchElement?.getAttribute("data-height"));
    const searchButtonColor = gripsSearchElement?.getAttribute("data-search-button-color");
    const root = ReactDOM.createRoot(gripsSearchElement);

    root.render(
      <React.StrictMode>
        <App width={width} height={height} searchButtonColor={searchButtonColor} />
      </React.StrictMode>
    );
})

