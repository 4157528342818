export const PopupBg = (props: any) => {
  return (
    <svg
      width="557"
      height="64"
      viewBox="0 0 557 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M556.117 301C556.117 466.696 431.531 601 277.824 601C-91.3275 585.189 -91.2216 16.7534 277.824 1C431.531 1 556.117 135.304 556.117 301Z"
        stroke="#A670FF"
        strokeWidth="0.808511"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeDasharray="0.4 8.09"
      />
      <path
        d="M548.171 301C548.171 461.959 427.133 592.496 277.767 592.496C-80.9005 577.142 -80.7946 24.8584 277.767 9.56154C427.133 9.50446 548.171 140.041 548.171 301Z"
        stroke="#A670FF"
        strokeWidth="1.26031"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeDasharray="0.4 8.09"
      />
      <path
        d="M540.286 301C540.286 457.279 422.795 583.934 277.824 583.934C-70.3069 569.037 -70.201 32.9066 277.824 18.0664C422.795 18.0664 540.286 144.722 540.286 301Z"
        stroke="#A670FF"
        strokeWidth="1.7121"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeDasharray="0.4 8.09"
      />
      <path
        d="M532.342 301.001C532.342 452.542 418.399 575.373 277.823 575.373C-59.8246 560.932 -59.7187 41.0119 277.823 26.5713C418.399 26.5713 532.342 149.459 532.342 301.001Z"
        stroke="#A670FF"
        strokeWidth="2.16398"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeDasharray="0.4 8.09"
      />
      <path
        d="M524.452 301C524.452 447.804 414.056 566.868 277.822 566.868C-49.3424 552.827 -49.2365 49.0597 277.822 35.1328C414.003 35.1328 524.452 154.14 524.452 301Z"
        stroke="#A670FF"
        strokeWidth="2.61577"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeDasharray="0.4 8.09"
      />
      <path
        d="M516.511 300.943C516.511 443.066 409.662 558.249 277.823 558.249C-38.8044 544.721 -38.6985 57.107 277.823 43.5796C409.61 43.6367 516.511 158.876 516.511 300.943Z"
        stroke="#A670FF"
        strokeWidth="3.06757"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeDasharray="0.4 8.09"
      />
      <path
        d="M508.569 300.942C508.569 438.328 405.268 549.744 277.77 549.744C-28.3735 536.616 -28.2676 65.2119 277.77 52.1982C405.268 52.1982 508.569 163.557 508.569 300.942Z"
        stroke="#A670FF"
        strokeWidth="3.51937"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeDasharray="0.4 8.09"
      />
      <path
        d="M500.677 300.943C500.677 433.648 400.924 541.183 277.821 541.183C-17.8395 528.569 -17.7336 73.3173 277.821 60.7031C400.871 60.7031 500.677 168.294 500.677 300.943Z"
        stroke="#A670FF"
        strokeWidth="3.97124"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeDasharray="0.4 8.09"
      />
    </svg>
  );
};
