import React from "react";
import { Typography } from "@mui/material";
import East from "@mui/icons-material/East";

import styled from "styled-components";
import { Box, BoxProps } from "@mui/system";
import { PopupBg } from "../../svgs/popupBg";

type Props = {
  initialMessage?: string;
  recentRetailers: string[];
  retailers: string[];
  showRecentRetailers: boolean;
  notFoundMessage?: string | null;
  onRetailerSelect: (retailer: string) => void;
};

const FaviconFrame = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  background: ${({ theme }) => theme.palette.gray.White};
  border: 1px solid ${({ theme }) => theme.palette.gray.GrayLight02};
  border-radius: 4px;
  justify-content: center;
  align-items: center;
`;

const StyledArrowRightAltIcon = styled(East)`
  font-size: 14px;
`;

const RowEndAdorment = styled((props: any) => (
  <div {...props}>
    <Typography variant="interSH6">View</Typography>
    <StyledArrowRightAltIcon />
  </div>
))`
  margin-left: auto;
  align-items: center;
  gap: 12px;
`;

const StyledRetailerRow = styled(Box)`
  display: flex;
  padding: ${({ theme }) => theme.spacing(1, 0.75)};
  gap: ${({ theme }) => theme.spacing(2)};
  align-items: center;
  cursor: pointer;

  border-radius: 6px;

  ${RowEndAdorment} {
    display: none;
  }

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.PurpleLight03};

    ${RowEndAdorment} {
      display: flex;
    }
  }
`;

const InitialTextContainer = styled.div`
  height: 266px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5;
  position: relative;
  > {
    &.MuiTypography-root {
      color: ${({ theme }) => theme.palette.gray.GrayDark01};
    }
  }
`;

const RetailerItem = ({
  retailer,
  onRetailerSelect,
  isFreeSample,
}: {
  retailer: string;
  isFreeSample?: boolean;
  onRetailerSelect: (r: string) => void;
}) => {
  return (
    <StyledRetailerRow onClick={() => onRetailerSelect(retailer)}>
      <FaviconFrame>
        <img
          src={`https://www.google.com/s2/favicons?sz=256&domain=${retailer}`}
          height="18"
          width="18"
          alt={retailer}
        />
      </FaviconFrame>
      <Typography variant="interB6">{retailer}</Typography>
      {isFreeSample && (
        <Box
          sx={{
            display: "flex",
            py: 0.5,
            px: 1,
            ml: 2,
            borderRadius: "4px",
            backgroundColor: "gray.GrayLight02",
          }}
        >
          <Typography variant="interH7" color="gray.GrayBase">
            FREE SAMPLE
          </Typography>
        </Box>
      )}
      <RowEndAdorment />
    </StyledRetailerRow>
  );
};

export const ExternalSearchRetailersList = styled(
  ({
    initialMessage,
    retailers,
    recentRetailers,
    showRecentRetailers,
    onRetailerSelect,
    notFoundMessage,
    ...props
  }: BoxProps & Props) => {
    if (initialMessage && !(showRecentRetailers && recentRetailers.length) && !retailers.length) {
      return (
        <InitialTextContainer>
          <PopupBg style={{ position: "absolute", bottom: -35 }} />
          <Typography variant="interSH6" textAlign="center" sx={{ width: 340 }}>
            {initialMessage}
          </Typography>
        </InitialTextContainer>
      );
    }

    return (
      <Box
        {...props}
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: 0.5,
          ...props?.sx,
        }}
      >
        {showRecentRetailers ? (
          <>
            {recentRetailers.map((retailer, index) => (
              <RetailerItem
                key={index}
                onRetailerSelect={onRetailerSelect}
                retailer={retailer}
              />
            ))}
          </>
        ) : (
          retailers.map((retailer, index) => (
            <RetailerItem
              key={index}
              onRetailerSelect={onRetailerSelect}
              retailer={retailer}
            />
          ))
        )}

        {notFoundMessage && !showRecentRetailers && retailers.length === 0 && (
          <Typography variant="interB6" sx={{ color: "gray.GrayDark01" }}>
            {notFoundMessage}
          </Typography>
        )}
      </Box>
    );
  }
)``;
