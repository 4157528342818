import { memo } from 'react';

function Electronics() {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.60002 5.02603L8.60122 5.02407M3.26668 3.11197H13.744V9.6293H3.26668V3.11197ZM3.26733 9.6302L2.37866 12.8889H14.8227L13.7447 9.6302H3.26733Z"
        stroke="#1B1D2E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default memo(Electronics);
