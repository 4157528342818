import axios from "axios";
import { useState, useEffect } from "react";
import debounce from "lodash.debounce";

const baseURL: string = "https://stage.app.gripsintelligence.com";

export interface ResponseCategory {
  id: number;
  name: string;
  children: number[];
  parent: number;
  level: number;
}
/*

export interface SearchParams {
  q: string;
  parent?: number;
  limit?: number;
  offset?: number;
}
*/

export type SearchAPIResponse = ResponseCategory[] | string[];

export const useSearchAPI = ({
  type,
  search,
  limit,
  offset,
  parent,
}: {
  type: "domain" | "category";
  search: string;
  limit?: number;
  offset?: number;
  parent?: number;
}) => {
  const [data, setData] = useState<{
    type: "domains" | "categories";
    data: SearchAPIResponse;
  } | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (type === "domain" && search.length < 3) {
        setData(null);
        setIsLoading(false);
        return;
      }

      if (type === "category" && search.length < 3) {
        setData(null);
        setIsLoading(false);
        return null;
      }

      try {
        const params = { q: search, limit, offset, parent };
        const { data } = await axios.get<SearchAPIResponse>(
          `${baseURL}/api/external_search_${type}`,
          { params }
        );
        if (type === "domain") {
          setData({ data, type: "domains" });
        } else {
          setData({ data, type: "categories" });
        }
        setIsLoading(false);
      } catch (error) {
        console.error("Error", error);
        setIsLoading(false);
      }
    };
    setIsLoading(true);
    const delayedFetchData = debounce(fetchData, 300);
    delayedFetchData();
    return () => {
      delayedFetchData.cancel();
    };
  }, [type, search, limit, offset, parent]);

  return { data, isLoading };
};
