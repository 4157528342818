import { ButtonProps, IconButton } from "@mui/material";
import styled, { css } from "styled-components";

export const SquareButton = styled(
  (
    props: ButtonProps & {
      width?: number;
      reverse?: boolean;
      darkened?: boolean;
      borderRadius?: number;
    }
  ) => <IconButton {...props} />
)`
  && {
    border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : 8)}px;
    background-color: ${({ theme }) => theme.palette.gray.GrayLight03};
    border: 1px solid ${({ theme }) => theme.palette.gray.GrayLight02};
    ${({ reverse }) =>
      reverse &&
      css`
        svg {
          transform: rotate(180deg);
        }
      `}
    ${({ width }) =>
      width &&
      css`
        width: ${width}px;
        height: ${width}px;
      `}
      svg {
      path {
        fill: ${({ theme: { palette } }) => palette.gray.GrayDark03};
        ${({ disabled }) =>
          disabled &&
          css`
            fill: ${({ theme: { palette } }) => palette.gray.GrayLight01};
          `}
      }
    }
  }
`;
