import { memo } from 'react';

function VehiclesParts() {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.1573 8.164V8.82933M4.44265 8.758V8.164M3.47865 10.5413V12.368C3.47865 12.9007 3.91065 13.332 4.44265 13.332C4.97532 13.332 5.40665 12.9 5.40665 12.368V10.908M11.1926 10.908V12.368C11.1926 12.9007 11.6246 13.332 12.1566 13.332C12.6893 13.332 13.1206 12.9 13.1206 12.368V10.5413M9.87798 8.46133H6.72198M12.634 4.45999C12.2646 3.52599 11.73 2.66733 11.1926 2.66733H5.40731C4.86998 2.66733 4.33532 3.52599 3.96598 4.45999M14.0853 8.25599V8.73733C14.0853 9.93533 13.114 10.9067 11.916 10.9067H4.68398C3.48598 10.9067 2.51465 9.93533 2.51465 8.73733V8.25599C2.51465 7.05799 3.48598 6.08666 4.68398 6.08666H11.916C13.114 6.08666 14.0853 7.05799 14.0853 8.25599Z"
        stroke="#1B1D2E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default memo(VehiclesParts);
