import { isServer } from "../utils/isBrowser";

declare global {
  interface Window {
    dataLayer: any;
  }
}

export const sendEvent = (name: string, value?: string | object) => {
  if (isServer()) return;
  if (!window.dataLayer) return;
  if (!!value && typeof value !== "string")
    return window.dataLayer.push({ event: name, ...value });
  if (!!value) return window.dataLayer.push({ event: name, [name]: value });
  window.dataLayer.push({ event: name });
};
