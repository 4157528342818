import { memo } from 'react';

function CamerasOptics() {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.97559 2.66666H6.62092M10.1774 8.40299C10.1774 9.38499 9.38142 10.181 8.39942 10.181C7.41742 10.181 6.62142 9.38499 6.62142 8.40299C6.62142 7.42099 7.41742 6.62499 8.39942 6.62499M12.5641 6.45572L12.5655 6.45657M2.17871 10.377V6.42968C2.17871 5.32501 3.07404 4.42968 4.17871 4.42968H12.6214C13.726 4.42968 14.6214 5.32501 14.6214 6.42968V10.377C14.6214 11.4817 13.726 12.377 12.6214 12.377H4.17871C3.07404 12.377 2.17871 11.4817 2.17871 10.377Z"
        stroke="#1B1D2E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default memo(CamerasOptics);
