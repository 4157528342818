import AnimalsPetSupplies from "../assets/icons/categories/Animals & pet supplies";
import ApparelAccessories from "../assets/icons/categories/Apparel & Accessories";
import ArtsEntertainment from "../assets/icons/categories/Arts & Entertainment";
import BabyToddler from "../assets/icons/categories/Baby & Toddler";
import BusinessIndustrial from "../assets/icons/categories/Business & Industrial";
import CamerasOptics from "../assets/icons/categories/Cameras & Optics";
import Electronics from "../assets/icons/categories/Electronics";
import FoodBeveragesTobacco from "../assets/icons/categories/FoodBeverages&Tobacco";
import Furniture from "../assets/icons/categories/Furniture";
import Hardware from "../assets/icons/categories/Hardware";
import HealthBeauty from "../assets/icons/categories/Health&Beauty";
import HomeGarden from "../assets/icons/categories/Home&Garden";
import LuggageBags from "../assets/icons/categories/Luggage & Bags";
import Mature from "../assets/icons/categories/Mature";
import Media from "../assets/icons/categories/Media";
import OfficeSupplies from "../assets/icons/categories/Office Supplies";
import ReligiousCeremonial from "../assets/icons/categories/Religious & Ceremonial";
import Software from "../assets/icons/categories/Software";
import SportingGoods from "../assets/icons/categories/Sporting Goods";
import ToysGames from "../assets/icons/categories/Toys & Games";
import VehiclesParts from "../assets/icons/categories/Vehicles & Parts";

export function formatCategoryTitle(
  category: string,
  { withSubcategory = true }: { withSubcategory?: boolean } = {}
) {
  const [mainCategory, subCategory] = category
    ? category.replace(/_/g, " ").split("/")
    : [];
  if (withSubcategory) {
    return mainCategory ? (subCategory || mainCategory) + ` ` : "";
  }
  return mainCategory ? mainCategory + " " : "";
}

export function formatMainCategoryTitle(c: string) {
  return formatCategoryTitle(c, { withSubcategory: false });
}

export interface Category {
  id: number;
  name: string;
  children?: (keyof NormalizedCategories)[];
  parent?: number;
  level?: number;
  icon?: JSX.Element;
}

export interface NormalizedCategories {
  [key: number]: Category;
}

export const raw_categories: NormalizedCategories = {
  0: {
    id: 0,
    name: "All categories",
    children: [
      1, 166, 8, 537, 111, 141, 222, 412, 436, 632, 469, 536, 5181, 772, 783,
      922, 5605, 2092, 988, 1239, 888,
    ],
  },
  1: {
    icon: <AnimalsPetSupplies />,
    id: 1,
    name: "Animals & Pet Supplies",
    children: [3237, 2],
    parent: 0,
    level: 1,
  },
  2: {
    id: 2,
    name: "Pet Supplies",
    children: [
      3, 4, 5, 6, 6983, 6811, 500084, 5092, 6978, 7143, 8513, 6252, 500026,
      6251, 6250, 6321, 505811, 4497, 8050, 8068, 6248, 5162, 5163, 6383,
      500110, 499743, 5093, 6253, 6249, 5145, 6861, 5086, 5144, 7144, 5087,
      6973, 6276, 7396, 505314, 5081, 502982, 8070, 505297, 7, 5013, 8474,
    ],
    parent: 1,
    level: 3,
  },
  3: {
    id: 3,
    name: "Bird Supplies",
    children: [7385, 4989, 4990, 7398, 4991, 4992, 4993],
    parent: 2,
    level: 4,
  },
  4: {
    id: 4,
    name: "Cat Supplies",
    children: [
      5082, 4433, 3367, 4997, 500059, 4999, 8069, 7142, 5000, 5001, 5002,
    ],
    parent: 2,
    level: 4,
  },
  5: {
    id: 5,
    name: "Dog Supplies",
    children: [
      5004, 4434, 7372, 499900, 3530, 5094, 7428, 7274, 5010, 8123, 5011,
    ],
    parent: 2,
    level: 4,
  },
  6: {
    id: 6,
    name: "Fish Supplies",
    children: [
      505303, 505307, 500038, 5019, 5020, 505306, 5021, 5079, 6951, 5023,
      500062, 5161, 3238, 6085, 6403, 5024,
    ],
    parent: 2,
    level: 4,
  },
  7: {
    id: 7,
    name: "Reptile & Amphibian Supplies",
    children: [5026, 5027, 5028, 5029, 5030],
    parent: 2,
    level: 4,
  },
  8: {
    icon: <ArtsEntertainment />,
    id: 8,
    name: "Arts & Entertainment",
    children: [499969, 5710, 5709],
    parent: 0,
    level: 1,
  },
  9: {
    id: 9,
    name: "Artwork",
    children: [500045, 500044, 11],
    parent: 696,
    level: 4,
  },
  11: { id: 11, name: "Sculptures & Statues", parent: 9, level: 5 },
  16: {
    id: 16,
    name: "Arts & Crafts",
    children: [505370, 505372, 504643, 504639, 505369, 505371],
    parent: 5710,
    level: 4,
  },
  18: { id: 18, name: "Art Brushes", parent: 505392, level: 7 },
  19: { id: 19, name: "Painting Canvas", parent: 505397, level: 8 },
  24: {
    id: 24,
    name: "Art & Craft Paper",
    children: [505399, 2532, 8168, 505400, 2967, 6110, 2741],
    parent: 505372,
    level: 6,
  },
  32: { id: 32, name: "Beads", parent: 505379, level: 7 },
  33: { id: 33, name: "Juggling", parent: 5710, level: 4 },
  35: { id: 35, name: "Magic & Novelties", parent: 5710, level: 4 },
  36: { id: 36, name: "Craft Magnets", parent: 505376, level: 7 },
  37: { id: 37, name: "Scale Models", parent: 216, level: 5 },
  39: {
    id: 39,
    name: "Photography",
    children: [41, 42, 503735, 4368],
    parent: 141,
    level: 3,
  },
  40: { id: 40, name: "Photo Albums", parent: 4360, level: 6 },
  41: {
    id: 41,
    name: "Darkroom",
    children: [2234, 2520, 1622, 2804, 2600],
    parent: 39,
    level: 4,
  },
  42: {
    id: 42,
    name: "Lighting & Studio",
    children: [5499, 1548, 1611, 503018, 2475, 2926, 503017, 2007],
    parent: 39,
    level: 4,
  },
  44: {
    id: 44,
    name: "Pottery & Sculpting Materials",
    children: [3692, 505401, 505804, 505402],
    parent: 505372,
    level: 6,
  },
  47: { id: 47, name: "Fabric", parent: 505384, level: 7 },
  49: { id: 49, name: "Thread & Floss", parent: 505382, level: 7 },
  53: { id: 53, name: "Gift Cards & Certificates", parent: 2559, level: 5 },
  54: {
    id: 54,
    name: "Musical Instruments",
    children: [4983, 4984, 63, 6001, 75, 76, 77, 87],
    parent: 5710,
    level: 4,
  },
  55: {
    id: 55,
    name: "Musical Instrument & Orchestra Accessories",
    children: [
      57, 505288, 3270, 505365, 505328, 500001, 7277, 4142, 8072, 56, 60, 3465,
      61, 62,
    ],
    parent: 5710,
    level: 4,
  },
  56: { id: 56, name: "Musical Instrument Amplifiers", parent: 55, level: 5 },
  57: {
    id: 57,
    name: "Brass Instrument Accessories",
    children: [4797, 505310, 505308, 505768, 4798, 505309],
    parent: 55,
    level: 5,
  },
  59: { id: 59, name: "Drum Sticks & Brushes", parent: 3465, level: 6 },
  60: {
    id: 60,
    name: "Musical Keyboard Accessories",
    children: [7357, 3588, 3324],
    parent: 55,
    level: 5,
  },
  61: {
    id: 61,
    name: "String Instrument Accessories",
    children: [3502, 503033, 4806],
    parent: 55,
    level: 5,
  },
  62: {
    id: 62,
    name: "Woodwind Instrument Accessories",
    children: [
      4790, 4791, 4792, 4955, 4793, 503747, 4794, 4866, 4867, 4957, 4939,
    ],
    parent: 55,
    level: 5,
  },
  63: {
    id: 63,
    name: "Brass Instruments",
    children: [505769, 65, 67, 70, 505770, 72],
    parent: 54,
    level: 5,
  },
  65: { id: 65, name: "Euphoniums", parent: 63, level: 6 },
  67: { id: 67, name: "French Horns", parent: 63, level: 6 },
  70: { id: 70, name: "Trombones", parent: 63, level: 6 },
  72: { id: 72, name: "Tubas", parent: 63, level: 6 },
  74: { id: 74, name: "Musical Keyboards", parent: 6001, level: 6 },
  75: {
    id: 75,
    name: "Percussion",
    children: [
      2917, 3043, 2518, 2856, 7431, 6098, 7285, 3015, 7232, 2797, 3005,
    ],
    parent: 54,
    level: 5,
  },
  76: { id: 76, name: "Pianos", parent: 54, level: 5 },
  77: {
    id: 77,
    name: "String Instruments",
    children: [79, 80, 84, 78, 85, 86],
    parent: 54,
    level: 5,
  },
  78: { id: 78, name: "Upright Basses", parent: 77, level: 6 },
  79: { id: 79, name: "Cellos", parent: 77, level: 6 },
  80: { id: 80, name: "Guitars", parent: 77, level: 6 },
  84: { id: 84, name: "Harps", parent: 77, level: 6 },
  85: { id: 85, name: "Violas", parent: 77, level: 6 },
  86: { id: 86, name: "Violins", parent: 77, level: 6 },
  87: {
    id: 87,
    name: "Woodwinds",
    children: [
      4540, 88, 89, 7188, 4743, 4744, 5481, 7250, 4541, 7249, 90, 91, 6721,
      6728,
    ],
    parent: 54,
    level: 5,
  },
  88: { id: 88, name: "Clarinets", parent: 87, level: 6 },
  89: { id: 89, name: "Flutes", parent: 87, level: 6 },
  90: { id: 90, name: "Recorders", parent: 87, level: 6 },
  91: { id: 91, name: "Saxophones", parent: 87, level: 6 },
  93: { id: 93, name: "Bookmarks", parent: 6174, level: 4 },
  94: {
    id: 94,
    name: "Gift Wrapping",
    children: [5838, 5091, 8213, 6712, 2816],
    parent: 2559,
    level: 5,
  },
  95: { id: 95, name: "Greeting & Note Cards", parent: 2559, level: 5 },
  96: {
    id: 96,
    name: "Party Supplies",
    children: [
      328061, 6311, 2587, 2531, 4730, 505763, 7007, 2781, 8216, 3735, 5043,
      1484, 8038, 4914, 8110, 1371, 2783, 5452, 7160, 6906, 502981, 502972,
      3994, 5472, 2104, 1887, 4915, 7097, 4351,
    ],
    parent: 5709,
    level: 4,
  },
  97: {
    id: 97,
    name: "Religious Items",
    children: [3923, 328060, 7120, 1949, 499711],
    parent: 5605,
    level: 3,
  },
  100: { id: 100, name: "Backpacks", parent: 5181, level: 3 },
  101: { id: 101, name: "Briefcases", parent: 5181, level: 3 },
  103: { id: 103, name: "Duffel Bags", parent: 5181, level: 3 },
  104: { id: 104, name: "Fanny Packs", parent: 5181, level: 3 },
  105: { id: 105, name: "Garment Bags", parent: 5181, level: 3 },
  106: { id: 106, name: "Messenger Bags", parent: 5181, level: 3 },
  107: { id: 107, name: "Suitcases", parent: 5181, level: 3 },
  108: { id: 108, name: "Cosmetic & Toiletry Bags", parent: 5181, level: 3 },
  110: {
    id: 110,
    name: "Luggage Accessories",
    children: [503014, 7521, 499691, 5652, 5651, 5620, 6919, 5650],
    parent: 5181,
    level: 3,
  },
  111: {
    icon: <BusinessIndustrial />,
    id: 111,
    name: "Business & Industrial",
    children: [
      5863, 112, 7261, 114, 7497, 2155, 1813, 135, 1827, 7240, 1795, 1475, 5830,
      8025, 500086, 1556, 1470, 6987, 2496, 2187, 4285, 138, 1624, 976, 2047,
    ],
    parent: 0,
    level: 1,
  },
  112: {
    id: 112,
    name: "Agriculture",
    children: [6991],
    parent: 111,
    level: 3,
  },
  113: { id: 113, name: "Fertilizers", parent: 2962, level: 5 },
  114: {
    id: 114,
    name: "Construction",
    children: [134, 8278],
    parent: 111,
    level: 3,
  },
  115: {
    id: 115,
    name: "Building Materials",
    children: [
      2729, 6343, 119, 503776, 2826, 120, 499949, 2030, 122, 125, 7112, 503777,
      123, 6943, 503775, 7439, 7004, 7136, 7053, 505300, 499772, 124,
    ],
    parent: 632,
    level: 3,
  },
  119: {
    id: 119,
    name: "Doors",
    children: [4468, 4634],
    parent: 115,
    level: 4,
  },
  120: { id: 120, name: "Glass", parent: 115, level: 4 },
  121: { id: 121, name: "Gutters", parent: 123, level: 5 },
  122: { id: 122, name: "Insulation", parent: 115, level: 4 },
  123: {
    id: 123,
    name: "Roofing",
    children: [4544, 121, 2008, 8270],
    parent: 115,
    level: 4,
  },
  124: { id: 124, name: "Windows", parent: 115, level: 4 },
  125: { id: 125, name: "Lumber & Sheet Stock", parent: 115, level: 4 },
  126: { id: 126, name: "Dollies & Hand Trucks", parent: 1167, level: 4 },
  127: {
    id: 127,
    name: "Power & Electrical Supplies",
    children: [
      500049, 7183, 499893, 6807, 499768, 7275, 2006, 5627, 6459, 2345, 6375,
      4789, 4709, 1218, 2413, 2028, 5533, 499966, 5142, 1869, 4715, 4714,
      505318, 2377, 6833, 2274, 503729,
    ],
    parent: 632,
    level: 3,
  },
  128: {
    id: 128,
    name: "Fencing & Barriers",
    children: [502983, 502973, 1352, 1919, 502986, 1788, 502984, 499958],
    parent: 632,
    level: 3,
  },
  130: {
    id: 130,
    name: "Ladders & Scaffolding",
    children: [2416, 6928, 1866, 635, 1809],
    parent: 1167,
    level: 4,
  },
  131: {
    id: 131,
    name: "Lifts & Hoists",
    children: [503768, 503771, 503767, 503769, 503772],
    parent: 6987,
    level: 4,
  },
  133: {
    id: 133,
    name: "Plumbing",
    children: [1810, 504635, 1673, 2570, 2216, 2203, 2273, 2243, 6832, 1723],
    parent: 632,
    level: 3,
  },
  134: { id: 134, name: "Surveying", parent: 114, level: 4 },
  135: {
    id: 135,
    name: "Food Service",
    children: [
      7303, 4217, 8532, 5102, 8059, 7088, 4632, 4096, 4742, 6786, 6517, 7353,
      5104, 8533, 5097, 7553, 137,
    ],
    parent: 111,
    level: 3,
  },
  136: { id: 136, name: "Food Gift Baskets", parent: 422, level: 4 },
  137: { id: 137, name: "Vending Machines", parent: 135, level: 4 },
  138: {
    id: 138,
    name: "Retail",
    children: [4244, 3803, 7128, 4181, 1837, 4127, 4160, 499897],
    parent: 111,
    level: 3,
  },
  139: { id: 139, name: "Barcode Scanners", parent: 1928, level: 6 },
  141: {
    icon: <CamerasOptics />,
    id: 141,
    name: "Cameras & Optics",
    children: [2096, 142, 156, 39],
    parent: 0,
    level: 1,
  },
  142: {
    id: 142,
    name: "Cameras",
    children: [499976, 152, 4024, 154, 362, 5402, 155, 312],
    parent: 141,
    level: 3,
  },
  143: {
    id: 143,
    name: "Camera Parts & Accessories",
    children: [
      8174, 6308, 296246, 298420, 153, 5479, 148, 500104, 461567, 500037,
      296248, 461568, 5532, 296247, 296249, 503020, 499998, 5429, 503019, 2987,
      500107, 5937, 8535, 6307, 2394,
    ],
    parent: 2096,
    level: 4,
  },
  146: { id: 146, name: "Memory Card Adapters", parent: 258, level: 5 },
  147: { id: 147, name: "Lens Filters", parent: 2911, level: 5 },
  148: { id: 148, name: "Camera Flashes", parent: 143, level: 5 },
  149: {
    id: 149,
    name: "Camera & Video Camera Lenses",
    children: [4432, 5346, 5280],
    parent: 2096,
    level: 4,
  },
  150: { id: 150, name: "Tripods & Monopods", parent: 2096, level: 4 },
  152: { id: 152, name: "Digital Cameras", parent: 142, level: 4 },
  153: { id: 153, name: "Camera Film", parent: 143, level: 5 },
  154: { id: 154, name: "Film Cameras", parent: 142, level: 4 },
  155: { id: 155, name: "Video Cameras", parent: 142, level: 4 },
  156: {
    id: 156,
    name: "Optics",
    children: [157, 4164, 161, 4040],
    parent: 141,
    level: 3,
  },
  157: { id: 157, name: "Binoculars", parent: 156, level: 4 },
  158: { id: 158, name: "Microscopes", parent: 4335, level: 5 },
  160: {
    id: 160,
    name: "Optic Accessories",
    children: [5282, 5545, 5283, 5542, 5284, 4274, 5543],
    parent: 2096,
    level: 4,
  },
  161: { id: 161, name: "Rangefinders", parent: 156, level: 4 },
  165: { id: 165, name: "Telescopes", parent: 4040, level: 5 },
  166: {
    id: 166,
    icon: <ApparelAccessories />,
    name: "Apparel & Accessories",
    children: [1604, 167, 184, 6552, 6551, 188, 1933, 187],
    parent: 0,
    level: 1,
  },
  167: {
    id: 167,
    name: "Clothing Accessories",
    children: [
      5942, 5422, 1786, 168, 3913, 169, 5443, 6985, 6984, 193, 5114, 6238, 170,
      171, 7133, 5207, 173, 2020, 5941, 6268, 502987, 7230, 176, 4179, 499972,
      177, 178, 179, 180, 5390, 1893,
    ],
    parent: 166,
    level: 3,
  },
  168: {
    id: 168,
    name: "Bandanas & Headties",
    children: [543586, 543587],
    parent: 167,
    level: 4,
  },
  169: { id: 169, name: "Belts", parent: 167, level: 4 },
  170: { id: 170, name: "Gloves & Mittens", parent: 167, level: 4 },
  171: {
    id: 171,
    name: "Hair Accessories",
    children: [
      8451, 2477, 4057, 1948, 6183, 502988, 5915, 1662, 1483, 5914, 7305, 181,
    ],
    parent: 167,
    level: 4,
  },
  173: { id: 173, name: "Hats", parent: 167, level: 4 },
  175: { id: 175, name: "Keychains", parent: 6552, level: 4 },
  176: { id: 176, name: "Neckties", parent: 167, level: 4 },
  177: {
    id: 177,
    name: "Scarves & Shawls",
    children: [543673, 543674],
    parent: 167,
    level: 4,
  },
  178: { id: 178, name: "Sunglasses", parent: 167, level: 4 },
  179: { id: 179, name: "Suspenders", parent: 167, level: 4 },
  180: { id: 180, name: "Tie Clips", parent: 167, level: 4 },
  181: { id: 181, name: "Wigs", parent: 171, level: 5 },
  182: {
    id: 182,
    name: "Baby & Toddler Clothing",
    children: [
      5408, 5549, 5424, 5425, 5622, 5412, 5423, 5409, 5410, 5411, 5621,
    ],
    parent: 1604,
    level: 4,
  },
  184: {
    id: 184,
    name: "Costumes & Accessories",
    children: [5192, 5387, 5193, 5194],
    parent: 166,
    level: 3,
  },
  187: { id: 187, name: "Shoes", parent: 166, level: 3 },
  188: {
    id: 188,
    name: "Jewelry",
    children: [189, 190, 191, 197, 192, 194, 6463, 196, 200, 5122, 201],
    parent: 166,
    level: 3,
  },
  189: { id: 189, name: "Anklets", parent: 188, level: 4 },
  190: { id: 190, name: "Body Jewelry", parent: 188, level: 4 },
  191: { id: 191, name: "Bracelets", parent: 188, level: 4 },
  192: { id: 192, name: "Charms & Pendants", parent: 188, level: 4 },
  193: { id: 193, name: "Cufflinks", parent: 167, level: 4 },
  194: { id: 194, name: "Earrings", parent: 188, level: 4 },
  196: { id: 196, name: "Necklaces", parent: 188, level: 4 },
  197: { id: 197, name: "Brooches & Lapel Pins", parent: 188, level: 4 },
  198: { id: 198, name: "Loose Stones", parent: 505379, level: 7 },
  200: { id: 200, name: "Rings", parent: 188, level: 4 },
  201: { id: 201, name: "Watches", parent: 188, level: 4 },
  203: {
    id: 203,
    name: "Outerwear",
    children: [5506, 5598, 5514, 3066, 5909, 1831],
    parent: 1604,
    level: 4,
  },
  204: { id: 204, name: "Pants", parent: 1604, level: 4 },
  206: { id: 206, name: "School Uniforms", parent: 2306, level: 5 },
  207: { id: 207, name: "Shorts", parent: 1604, level: 4 },
  208: {
    id: 208,
    name: "Sleepwear & Loungewear",
    children: [5713, 5513, 2580, 2302],
    parent: 1604,
    level: 4,
  },
  209: { id: 209, name: "Socks", parent: 213, level: 5 },
  211: { id: 211, name: "Swimwear", parent: 1604, level: 4 },
  212: { id: 212, name: "Shirts & Tops", parent: 1604, level: 4 },
  213: {
    id: 213,
    name: "Underwear & Socks",
    children: [
      7207, 214, 215, 5327, 1772, 2563, 1807, 2963, 1578, 209, 2745, 2562, 5834,
    ],
    parent: 1604,
    level: 4,
  },
  214: { id: 214, name: "Bras", parent: 213, level: 5 },
  215: { id: 215, name: "Hosiery", parent: 213, level: 5 },
  216: {
    id: 216,
    name: "Collectibles",
    children: [3599, 217, 6997, 220, 219, 218, 6000, 37, 1312, 3865, 3893],
    parent: 5710,
    level: 4,
  },
  217: {
    id: 217,
    name: "Collectible Coins & Currency",
    children: [543607, 543606],
    parent: 216,
    level: 5,
  },
  218: { id: 218, name: "Rocks & Fossils", parent: 216, level: 5 },
  219: { id: 219, name: "Postage Stamps", parent: 216, level: 5 },
  220: {
    id: 220,
    name: "Collectible Weapons",
    children: [499953, 5311, 221, 1340],
    parent: 216,
    level: 5,
  },
  221: { id: 221, name: "Collectible Swords", parent: 220, level: 6 },
  222: {
    icon: <Electronics />,
    id: 222,
    name: "Electronics",
    children: [
      3356, 223, 3702, 262, 1801, 278, 2082, 3895, 339, 6544, 340, 342, 345,
      912, 500091, 4488, 386, 1270, 1294,
    ],
    parent: 0,
    level: 1,
  },
  223: {
    id: 223,
    name: "Audio",
    children: [1420, 2165, 242, 8159, 4921, 2154, 3727],
    parent: 222,
    level: 3,
  },
  224: {
    id: 224,
    name: "Audio Amplifiers",
    children: [4493, 5381],
    parent: 2165,
    level: 5,
  },
  225: { id: 225, name: "Boomboxes", parent: 242, level: 5 },
  226: { id: 226, name: "CD Players & Recorders", parent: 242, level: 5 },
  230: { id: 230, name: "Karaoke Systems", parent: 242, level: 5 },
  232: {
    id: 232,
    name: "MP3 Player Accessories",
    children: [7566, 3055],
    parent: 1420,
    level: 5,
  },
  233: { id: 233, name: "MP3 Players", parent: 242, level: 5 },
  234: { id: 234, name: "Microphones", parent: 2165, level: 5 },
  235: { id: 235, name: "MiniDisc Players & Recorders", parent: 242, level: 5 },
  236: { id: 236, name: "Audio Mixers", parent: 2165, level: 5 },
  239: { id: 239, name: "Satellite Radio Accessories", parent: 1420, level: 5 },
  241: { id: 241, name: "Audio & Video Receivers", parent: 2165, level: 5 },
  242: {
    id: 242,
    name: "Audio Players & Recorders",
    children: [
      225, 226, 243, 252, 4652, 230, 233, 235, 5434, 6886, 8271, 251, 256, 244,
    ],
    parent: 223,
    level: 4,
  },
  243: { id: 243, name: "Cassette Players & Recorders", parent: 242, level: 5 },
  244: { id: 244, name: "Voice Recorders", parent: 242, level: 5 },
  245: { id: 245, name: "Audio Samplers", parent: 6001, level: 6 },
  246: {
    id: 246,
    name: "Signal Processors",
    children: [5435, 247, 248, 5597, 3945, 5596, 5369],
    parent: 2165,
    level: 5,
  },
  247: { id: 247, name: "Effects Processors", parent: 246, level: 6 },
  248: { id: 248, name: "Equalizers", parent: 246, level: 6 },
  249: { id: 249, name: "Speakers", parent: 2165, level: 5 },
  251: { id: 251, name: "Stereo Systems", parent: 242, level: 5 },
  252: { id: 252, name: "Home Theater Systems", parent: 242, level: 5 },
  256: { id: 256, name: "Turntables & Record Players", parent: 242, level: 5 },
  258: {
    id: 258,
    name: "Adapters",
    children: [4463, 146, 7182],
    parent: 2082,
    level: 4,
  },
  259: {
    id: 259,
    name: "Cables",
    children: [1867, 3461, 1480, 500035, 1763, 3541],
    parent: 2082,
    level: 4,
  },
  262: {
    id: 262,
    name: "Communications",
    children: [266, 5275, 263, 2471, 5404, 360, 268, 270, 274],
    parent: 222,
    level: 3,
  },
  263: {
    id: 263,
    name: "Communication Radio Accessories",
    parent: 262,
    level: 4,
  },
  264: {
    id: 264,
    name: "Mobile Phone Accessories",
    children: [8111, 2353, 4550, 6030, 7347, 5566, 499916],
    parent: 270,
    level: 5,
  },
  265: {
    id: 265,
    name: "Telephone Accessories",
    children: [269],
    parent: 270,
    level: 5,
  },
  266: { id: 266, name: "Answering Machines", parent: 262, level: 4 },
  267: {
    id: 267,
    name: "Mobile Phones",
    children: [543513, 543512, 543514],
    parent: 270,
    level: 5,
  },
  268: { id: 268, name: "Pagers", parent: 262, level: 4 },
  269: { id: 269, name: "Phone Cards", parent: 265, level: 6 },
  270: {
    id: 270,
    name: "Telephony",
    children: [4666, 271, 272, 264, 267, 1924, 265],
    parent: 262,
    level: 4,
  },
  271: { id: 271, name: "Corded Phones", parent: 270, level: 5 },
  272: { id: 272, name: "Cordless Phones", parent: 270, level: 5 },
  273: { id: 273, name: "Two-Way Radios", parent: 2471, level: 5 },
  274: { id: 274, name: "Video Conferencing", parent: 262, level: 4 },
  275: {
    id: 275,
    name: "Power",
    children: [
      276, 7166, 2978, 6933, 505295, 6790, 3160, 5274, 5380, 7135, 1348, 1375,
    ],
    parent: 2082,
    level: 4,
  },
  276: {
    id: 276,
    name: "Batteries",
    children: [
      1722, 1880, 7551, 4928, 1564, 499810, 1745, 5133, 7438, 6289, 2222,
      500117,
    ],
    parent: 275,
    level: 5,
  },
  278: {
    id: 278,
    name: "Computers",
    children: [5254, 331, 325, 298, 5255, 328, 500002, 4745, 8539, 502995],
    parent: 222,
    level: 3,
  },
  279: {
    id: 279,
    name: "Computer Accessories",
    children: [
      500040, 7530, 5489, 280, 6291, 6979, 300, 1993, 5669, 5308, 499956,
    ],
    parent: 2082,
    level: 4,
  },
  280: {
    id: 280,
    name: "Handheld Device Accessories",
    children: [4736, 4737],
    parent: 279,
    level: 5,
  },
  283: {
    id: 283,
    name: "Computer Monitor Accessories",
    children: [5516],
    parent: 2027,
    level: 5,
  },
  284: { id: 284, name: "Scanner Accessories", parent: 345, level: 4 },
  285: {
    id: 285,
    name: "Computer Components",
    children: [
      6932, 8158, 291, 292, 293, 294, 295, 296, 8162, 287, 6475, 1928, 4224,
      2414, 7349, 311,
    ],
    parent: 2082,
    level: 4,
  },
  286: { id: 286, name: "Audio Cards & Adapters", parent: 287, level: 6 },
  287: {
    id: 287,
    name: "I/O Cards & Adapters",
    children: [286, 505299, 503755, 1487, 297],
    parent: 285,
    level: 5,
  },
  288: {
    id: 288,
    name: "Memory",
    children: [1665, 384, 1733, 2130, 1767],
    parent: 2082,
    level: 4,
  },
  289: { id: 289, name: "Motherboards", parent: 499898, level: 6 },
  290: { id: 290, name: "Network Cards & Adapters", parent: 342, level: 4 },
  291: { id: 291, name: "Computer Power Supplies", parent: 285, level: 5 },
  292: { id: 292, name: "Computer Processors", parent: 285, level: 5 },
  293: { id: 293, name: "Computer Racks & Mounts", parent: 285, level: 5 },
  294: { id: 294, name: "Computer Starter Kits", parent: 285, level: 5 },
  295: {
    id: 295,
    name: "Computer System Cooling Parts",
    parent: 285,
    level: 5,
  },
  296: {
    id: 296,
    name: "Desktop Computer & Server Cases",
    parent: 285,
    level: 5,
  },
  297: { id: 297, name: "Video Cards & Adapters", parent: 287, level: 6 },
  298: {
    id: 298,
    name: "Handheld Devices",
    children: [5256, 3539, 3769],
    parent: 278,
    level: 4,
  },
  300: { id: 300, name: "Laptop Docking Stations", parent: 279, level: 5 },
  301: {
    id: 301,
    name: "Game Controllers",
    children: [543591, 543590, 543589, 543588, 543593],
    parent: 1928,
    level: 6,
  },
  302: { id: 302, name: "Graphics Tablets", parent: 1928, level: 6 },
  303: { id: 303, name: "Keyboards", parent: 1928, level: 6 },
  304: { id: 304, name: "Mice & Trackballs", parent: 1928, level: 6 },
  305: { id: 305, name: "Computer Monitors", parent: 386, level: 4 },
  306: { id: 306, name: "Scanners", parent: 345, level: 4 },
  308: { id: 308, name: "Touchpads", parent: 1928, level: 6 },
  311: { id: 311, name: "USB & FireWire Hubs", parent: 285, level: 5 },
  312: { id: 312, name: "Webcams", parent: 142, level: 4 },
  313: {
    id: 313,
    name: "Computer Software",
    children: [
      5299, 5300, 315, 5301, 5127, 317, 5304, 3283, 318, 319, 5302, 5303, 321,
      7225,
    ],
    parent: 2092,
    level: 3,
  },
  315: {
    id: 315,
    name: "Compilers & Programming Tools",
    parent: 313,
    level: 4,
  },
  317: { id: 317, name: "Educational Software", parent: 313, level: 4 },
  318: { id: 318, name: "Handheld & PDA Software", parent: 313, level: 4 },
  319: {
    id: 319,
    name: "Multimedia & Design Software",
    children: [6027, 4950, 4951, 6029, 4949, 6028, 5096, 4952, 4953, 4954],
    parent: 313,
    level: 4,
  },
  321: { id: 321, name: "Operating Systems", parent: 313, level: 4 },
  325: { id: 325, name: "Desktop Computers", parent: 278, level: 4 },
  328: { id: 328, name: "Laptops", parent: 278, level: 4 },
  331: { id: 331, name: "Computer Servers", parent: 278, level: 4 },
  333: {
    id: 333,
    name: "Calculators",
    children: [543518, 543521, 543519, 543517, 543520],
    parent: 950,
    level: 4,
  },
  337: {
    id: 337,
    name: "Electronic Dictionaries & Translators",
    parent: 950,
    level: 4,
  },
  338: {
    id: 338,
    name: "Flashlights & Headlamps",
    children: [543689, 2454],
    parent: 1167,
    level: 4,
  },
  339: { id: 339, name: "GPS Navigation Systems", parent: 222, level: 3 },
  340: {
    id: 340,
    name: "Marine Electronics",
    children: [1550, 8134, 2178, 1552, 4450, 8473],
    parent: 222,
    level: 3,
  },
  341: { id: 341, name: "Remote Controls", parent: 2082, level: 4 },
  342: {
    id: 342,
    name: "Networking",
    children: [1350, 2479, 2455, 5576, 343, 290, 3742, 6508, 3425, 2121],
    parent: 222,
    level: 3,
  },
  343: { id: 343, name: "Modems", parent: 342, level: 4 },
  345: {
    id: 345,
    name: "Print, Copy, Scan & Fax",
    children: [499682, 6865, 502990, 500106, 284, 306],
    parent: 222,
    level: 3,
  },
  356: { id: 356, name: "Toner & Inkjet Cartridges", parent: 5258, level: 6 },
  359: {
    id: 359,
    name: "Business & Home Security",
    children: [5491, 3873, 2161, 500025, 363, 364, 499865, 3819, 365],
    parent: 536,
    level: 3,
  },
  360: { id: 360, name: "Intercoms", parent: 262, level: 4 },
  361: { id: 361, name: "Metal Detectors", parent: 1556, level: 4 },
  362: { id: 362, name: "Surveillance Cameras", parent: 142, level: 4 },
  363: { id: 363, name: "Security Lights", parent: 359, level: 4 },
  364: {
    id: 364,
    name: "Security Monitors & Recorders",
    parent: 359,
    level: 4,
  },
  365: { id: 365, name: "Security System Sensors", parent: 359, level: 4 },
  367: { id: 367, name: "Blank Media", parent: 2082, level: 4 },
  376: { id: 376, name: "CD/DVD Duplicators", parent: 5268, level: 7 },
  377: { id: 377, name: "Optical Drives", parent: 2414, level: 6 },
  380: { id: 380, name: "Hard Drives", parent: 2414, level: 6 },
  381: { id: 381, name: "Hard Drive Carrying Cases", parent: 1623, level: 7 },
  384: {
    id: 384,
    name: "Flash Memory",
    children: [3387],
    parent: 288,
    level: 5,
  },
  385: { id: 385, name: "Tape Drives", parent: 2414, level: 6 },
  386: {
    id: 386,
    name: "Video",
    children: [305, 396, 5561, 404, 2027, 1368, 1634, 387, 5278, 5450],
    parent: 222,
    level: 3,
  },
  387: {
    id: 387,
    name: "Video Players & Recorders",
    children: [388, 389, 390, 5276, 391],
    parent: 386,
    level: 4,
  },
  388: { id: 388, name: "DVD & Blu-ray Players", parent: 387, level: 5 },
  389: { id: 389, name: "DVD Recorders", parent: 387, level: 5 },
  390: { id: 390, name: "Digital Video Recorders", parent: 387, level: 5 },
  391: { id: 391, name: "VCRs", parent: 387, level: 5 },
  393: {
    id: 393,
    name: "Projector Accessories",
    children: [5599, 4570, 395, 5257, 394],
    parent: 2027,
    level: 5,
  },
  394: { id: 394, name: "Projector Replacement Lamps", parent: 393, level: 6 },
  395: { id: 395, name: "Projection Screens", parent: 393, level: 6 },
  396: {
    id: 396,
    name: "Projectors",
    children: [397, 398, 399],
    parent: 386,
    level: 4,
  },
  397: { id: 397, name: "Multimedia Projectors", parent: 396, level: 5 },
  398: { id: 398, name: "Overhead Projectors", parent: 396, level: 5 },
  399: { id: 399, name: "Slide Projectors", parent: 396, level: 5 },
  401: { id: 401, name: "Satellite Receivers", parent: 5561, level: 5 },
  403: {
    id: 403,
    name: "Television Parts & Accessories",
    children: [4458, 5503, 5471, 43616],
    parent: 2027,
    level: 5,
  },
  404: { id: 404, name: "Televisions", parent: 386, level: 4 },
  408: {
    id: 408,
    name: "Special Effects",
    children: [5711, 409, 5967, 503028, 410],
    parent: 5709,
    level: 4,
  },
  409: { id: 409, name: "Fog Machines", parent: 408, level: 5 },
  410: { id: 410, name: "Special Effects Lighting", parent: 408, level: 5 },
  412: {
    icon: <FoodBeveragesTobacco />,
    id: 412,
    name: "Food, Beverages & Tobacco",
    children: [413, 422, 435],
    parent: 0,
    level: 1,
  },
  413: {
    id: 413,
    name: "Beverages",
    children: [
      499676, 6797, 1868, 8030, 8036, 415, 2887, 418, 5724, 6848, 2628, 5723,
      2073, 7528, 420,
    ],
    parent: 412,
    level: 3,
  },
  414: { id: 414, name: "Beer", parent: 499676, level: 5 },
  415: { id: 415, name: "Hot Chocolate", parent: 413, level: 4 },
  417: {
    id: 417,
    name: "Liquor & Spirits",
    children: [505761, 2364, 1671, 2933, 2605, 502976, 2220, 2107, 1926],
    parent: 499676,
    level: 5,
  },
  418: { id: 418, name: "Milk", parent: 413, level: 4 },
  420: {
    id: 420,
    name: "Water",
    children: [543531, 543530, 543533, 543532],
    parent: 413,
    level: 4,
  },
  421: { id: 421, name: "Wine", parent: 499676, level: 5 },
  422: {
    id: 422,
    name: "Food Items",
    children: [
      1876, 6219, 4748, 427, 2660, 428, 5740, 136, 5788, 430, 431, 432, 433,
      434, 5814, 4608, 423, 2423, 5807,
    ],
    parent: 412,
    level: 3,
  },
  423: {
    id: 423,
    name: "Snack Foods",
    children: [
      7159, 5747, 6192, 2392, 1445, 5746, 5744, 3284, 1534, 6194, 3446, 5743,
      2432, 6847, 7427, 6785, 7327, 5745,
    ],
    parent: 422,
    level: 4,
  },
  424: { id: 424, name: "Breads & Buns", parent: 1876, level: 5 },
  427: {
    id: 427,
    name: "Condiments & Sauces",
    children: [
      6772, 6905, 6845, 5763, 5762, 4947, 6782, 4614, 2018, 500074, 1568, 1387,
      5760, 5759, 500076, 6203, 500075, 1969, 4615, 4616, 500089, 4943, 4692,
      6783, 500105, 6246,
    ],
    parent: 422,
    level: 4,
  },
  428: {
    id: 428,
    name: "Dairy Products",
    children: [5827, 429, 4418, 1855, 5786, 5787, 6821, 1954],
    parent: 422,
    level: 4,
  },
  429: { id: 429, name: "Cheese", parent: 428, level: 5 },
  430: {
    id: 430,
    name: "Fruits & Vegetables",
    children: [5799, 5798, 5797, 1755, 7387, 5796, 5795, 5793, 5794],
    parent: 422,
    level: 4,
  },
  431: {
    id: 431,
    name: "Grains, Rice & Cereal",
    children: [
      4683, 4687, 4684, 4689, 7196, 4686, 4690, 6259, 4682, 7374, 4688,
    ],
    parent: 422,
    level: 4,
  },
  432: {
    id: 432,
    name: "Meat, Seafood & Eggs",
    children: [4627, 4628, 4629],
    parent: 422,
    level: 4,
  },
  433: { id: 433, name: "Nuts & Seeds", parent: 422, level: 4 },
  434: { id: 434, name: "Pasta & Noodles", parent: 422, level: 4 },
  435: {
    id: 435,
    name: "Tobacco Products",
    children: [3916, 3151, 3682, 3741, 499963, 4091],
    parent: 412,
    level: 3,
  },
  436: {
    icon: <Furniture />,
    id: 436,
    name: "Furniture",
    children: [
      554, 6433, 441, 6356, 442, 7248, 443, 457, 6345, 6860, 2786, 450, 6362,
      503765, 458, 4299, 6963, 6915, 4163, 464, 8023, 7212, 460, 6913, 6392,
    ],
    parent: 0,
    level: 1,
  },
  438: { id: 438, name: "Bean Bag Chairs", parent: 443, level: 4 },
  441: {
    id: 441,
    name: "Benches",
    children: [6850, 6851, 4241],
    parent: 436,
    level: 3,
  },
  442: {
    id: 442,
    name: "Carts & Islands",
    children: [453, 6374],
    parent: 436,
    level: 3,
  },
  443: {
    id: 443,
    name: "Chairs",
    children: [
      6499, 438, 456, 2919, 500051, 3358, 6800, 7197, 5886, 2002, 6859, 1463,
    ],
    parent: 436,
    level: 3,
  },
  447: { id: 447, name: "Buffets & Sideboards", parent: 6356, level: 4 },
  448: { id: 448, name: "China Cabinets & Hutches", parent: 6356, level: 4 },
  450: { id: 450, name: "Futons", parent: 436, level: 3 },
  451: { id: 451, name: "Headboards & Footboards", parent: 6433, level: 4 },
  453: { id: 453, name: "Kitchen & Dining Carts", parent: 442, level: 4 },
  456: { id: 456, name: "Chaises", parent: 443, level: 4 },
  457: {
    id: 457,
    name: "Entertainment Centers & TV Stands",
    parent: 436,
    level: 3,
  },
  458: { id: 458, name: "Ottomans", parent: 436, level: 3 },
  460: { id: 460, name: "Sofas", parent: 436, level: 3 },
  462: { id: 462, name: "Nightstands", parent: 6392, level: 4 },
  463: { id: 463, name: "File Cabinets", parent: 6356, level: 4 },
  464: {
    id: 464,
    name: "Shelving",
    children: [465, 6372],
    parent: 436,
    level: 3,
  },
  465: { id: 465, name: "Bookcases & Standing Shelves", parent: 464, level: 4 },
  469: {
    icon: <HealthBeauty />,
    id: 469,
    name: "Health & Beauty",
    children: [491, 5573, 2915],
    parent: 0,
    level: 1,
  },
  471: {
    id: 471,
    name: "Massagers",
    children: [543596, 543597, 543595],
    parent: 5663,
    level: 5,
  },
  472: { id: 472, name: "Tanning Beds", parent: 2915, level: 4 },
  473: {
    id: 473,
    name: "Cosmetics",
    children: [474, 475, 6069, 6331, 2619, 477, 478, 479, 567],
    parent: 2915,
    level: 4,
  },
  474: {
    id: 474,
    name: "Bath & Body",
    children: [499913, 2503, 2522, 2876, 2875, 2747, 3691, 3208, 7417, 4049],
    parent: 473,
    level: 5,
  },
  475: { id: 475, name: "Bath & Body Gift Sets", parent: 473, level: 5 },
  476: { id: 476, name: "Face Mirrors", parent: 2548, level: 7 },
  477: {
    id: 477,
    name: "Makeup",
    children: [5978, 4779, 2779, 2571, 2645, 6072, 3509],
    parent: 473,
    level: 5,
  },
  478: {
    id: 478,
    name: "Nail Care",
    children: [3009, 4218, 6893, 5975, 233419, 2946, 7445, 2683],
    parent: 473,
    level: 5,
  },
  479: { id: 479, name: "Perfume & Cologne", parent: 473, level: 5 },
  481: { id: 481, name: "Acne Treatments & Kits", parent: 567, level: 6 },
  482: {
    id: 482,
    name: "Lip Balms & Treatments",
    children: [543573, 543574],
    parent: 567,
    level: 6,
  },
  484: {
    id: 484,
    name: "Deodorant & Anti-Perspirant",
    children: [543599, 543598],
    parent: 2915,
    level: 4,
  },
  485: {
    id: 485,
    name: "Feminine Sanitary Supplies",
    children: [6862, 5821, 2387, 8122, 2564],
    parent: 2915,
    level: 4,
  },
  486: {
    id: 486,
    name: "Hair Care",
    children: [
      8452, 2814, 6053, 5977, 6099, 4766, 6052, 3013, 6429, 1901, 6018, 6019,
      2441,
    ],
    parent: 2915,
    level: 4,
  },
  487: { id: 487, name: "Combs & Brushes", parent: 6019, level: 6 },
  488: { id: 488, name: "Hair Curlers", parent: 6019, level: 6 },
  489: { id: 489, name: "Curling Irons", parent: 6019, level: 6 },
  490: { id: 490, name: "Hair Dryers", parent: 6019, level: 6 },
  491: {
    id: 491,
    name: "Health Care",
    children: [
      5849, 7220, 5071, 494, 775, 505820, 7002, 508, 2890, 5690, 517, 500087,
      5966, 5965, 505293, 518, 519, 5870, 3777, 4551, 8082, 7186, 8105, 523,
      5923,
    ],
    parent: 469,
    level: 3,
  },
  493: { id: 493, name: "Back Care", children: [7404], parent: 2915, level: 4 },
  494: {
    id: 494,
    name: "Biometric Monitors",
    children: [500009, 2246, 495, 496, 500, 2633, 497, 505822, 501, 4767, 5551],
    parent: 491,
    level: 4,
  },
  495: { id: 495, name: "Blood Pressure Monitors", parent: 494, level: 5 },
  496: { id: 496, name: "Body Fat Analyzers", parent: 494, level: 5 },
  497: { id: 497, name: "Cholesterol Analyzers", parent: 494, level: 5 },
  500: { id: 500, name: "Body Weight Scales", parent: 494, level: 5 },
  501: { id: 501, name: "Medical Thermometers", parent: 494, level: 5 },
  506: {
    id: 506,
    name: "Ear Care",
    children: [5706, 6559, 6560, 500024, 6561, 6562, 7542, 2596],
    parent: 2915,
    level: 4,
  },
  508: {
    id: 508,
    name: "First Aid",
    children: [2954, 6206, 4527, 510, 516, 509],
    parent: 491,
    level: 4,
  },
  509: { id: 509, name: "Medical Tape & Bandages", parent: 508, level: 5 },
  510: { id: 510, name: "First Aid Kits", parent: 508, level: 5 },
  511: { id: 511, name: "Disposable Gloves", parent: 2907, level: 5 },
  512: { id: 512, name: "Household Insect Repellents", parent: 2865, level: 6 },
  513: { id: 513, name: "Medical Masks", parent: 503724, level: 5 },
  515: {
    id: 515,
    name: "Foot Care",
    children: [2992, 3022, 3049, 2801, 7495],
    parent: 2915,
    level: 4,
  },
  516: {
    id: 516,
    name: "Hot & Cold Therapies",
    children: [5848, 6205, 4753],
    parent: 508,
    level: 5,
  },
  517: { id: 517, name: "Incontinence Aids", parent: 491, level: 4 },
  518: { id: 518, name: "Medicine & Drugs", parent: 491, level: 4 },
  519: {
    id: 519,
    name: "Mobility & Accessibility",
    children: [520, 521, 5488, 6929, 5164],
    parent: 491,
    level: 4,
  },
  520: {
    id: 520,
    name: "Accessibility Equipment",
    children: [3512, 7138, 502969, 3364],
    parent: 519,
    level: 5,
  },
  521: {
    id: 521,
    name: "Accessibility Equipment Accessories",
    parent: 519,
    level: 5,
  },
  523: { id: 523, name: "Supports & Braces", parent: 491, level: 4 },
  524: { id: 524, name: "Eyeglasses", parent: 1380, level: 5 },
  525: { id: 525, name: "Vitamins & Supplements", parent: 2890, level: 5 },
  526: {
    id: 526,
    name: "Oral Care",
    children: [
      6189, 2620, 5823, 6455, 5295, 5155, 5824, 8543, 2527, 2769, 3040, 505367,
      6715, 3019, 6441, 4775, 527, 1360, 5154, 4316,
    ],
    parent: 2915,
    level: 4,
  },
  527: { id: 527, name: "Toothbrushes", parent: 526, level: 5 },
  528: {
    id: 528,
    name: "Shaving & Grooming",
    children: [
      529, 8214, 531, 532, 6842, 533, 4507, 534, 8531, 2681, 2971, 5111, 2508,
    ],
    parent: 2915,
    level: 4,
  },
  529: { id: 529, name: "Aftershave", parent: 528, level: 5 },
  531: { id: 531, name: "Electric Razor Accessories", parent: 528, level: 5 },
  532: { id: 532, name: "Electric Razors", parent: 528, level: 5 },
  533: { id: 533, name: "Hair Clippers & Trimmers", parent: 528, level: 5 },
  534: { id: 534, name: "Razors & Razor Blades", parent: 528, level: 5 },
  536: {
    icon: <HomeGarden />,
    id: 536,
    name: "Home & Garden",
    children: [
      574, 359, 696, 5835, 2862, 6792, 1679, 3348, 604, 630, 638, 689, 594,
      2956, 4171, 4358, 985, 729, 600, 6173, 2639,
    ],
    parent: 0,
    level: 1,
  },
  537: {
    icon: <BabyToddler />,
    id: 537,
    name: "Baby & Toddler",
    children: [4678, 5859, 5252, 540, 2847, 2764, 4386, 548, 561, 6952, 6899],
    parent: 0,
    level: 1,
  },
  538: { id: 538, name: "Baby Carriers", parent: 2764, level: 4 },
  539: { id: 539, name: "Play Yards", parent: 2847, level: 4 },
  540: {
    id: 540,
    name: "Baby Safety",
    children: [6869, 542, 541, 5049, 543, 544],
    parent: 537,
    level: 3,
  },
  541: { id: 541, name: "Baby Monitors", parent: 540, level: 4 },
  542: { id: 542, name: "Baby & Pet Gates", parent: 540, level: 4 },
  543: { id: 543, name: "Baby Safety Locks & Guards", parent: 540, level: 4 },
  544: { id: 544, name: "Baby Safety Rails", parent: 540, level: 4 },
  547: { id: 547, name: "Baby & Toddler Car Seats", parent: 2764, level: 4 },
  548: {
    id: 548,
    name: "Diapering",
    children: [
      7200, 553, 502999, 5628, 7014, 6949, 6883, 7001, 550, 2949, 6971, 551,
    ],
    parent: 537,
    level: 3,
  },
  549: { id: 549, name: "Diaper Bags", parent: 5181, level: 3 },
  550: { id: 550, name: "Diaper Pails", parent: 548, level: 4 },
  551: { id: 551, name: "Diapers", parent: 548, level: 4 },
  552: { id: 552, name: "Potty Seats", parent: 6952, level: 4 },
  553: { id: 553, name: "Baby Wipes", parent: 548, level: 4 },
  554: {
    id: 554,
    name: "Baby & Toddler Furniture",
    children: [6349, 7068, 6393, 558, 7070, 6394, 6969, 559],
    parent: 436,
    level: 3,
  },
  555: { id: 555, name: "Baby Bouncers & Rockers", parent: 2847, level: 4 },
  558: { id: 558, name: "Changing Tables", parent: 554, level: 4 },
  559: { id: 559, name: "High Chairs & Booster Seats", parent: 554, level: 4 },
  560: { id: 560, name: "Baby Jumpers & Swings", parent: 2847, level: 4 },
  561: {
    id: 561,
    name: "Nursing & Feeding",
    children: [
      562, 5630, 564, 4768, 2125, 5296, 7234, 505366, 565, 5629, 5843, 503762,
      8075, 5298, 6950,
    ],
    parent: 537,
    level: 3,
  },
  562: {
    id: 562,
    name: "Baby & Toddler Food",
    children: [5721, 5718, 5719, 563, 5720, 8436],
    parent: 561,
    level: 4,
  },
  563: { id: 563, name: "Baby Formula", parent: 562, level: 5 },
  564: { id: 564, name: "Baby Bottles", parent: 561, level: 4 },
  565: { id: 565, name: "Breast Pumps", parent: 561, level: 4 },
  566: { id: 566, name: "Pacifiers & Teethers", parent: 5252, level: 4 },
  567: {
    id: 567,
    name: "Skin Care",
    children: [
      481, 7429, 6104, 5980, 8029, 2526, 7467, 6791, 482, 2592, 6034, 6753,
      6262, 5820, 2844, 2740, 5976, 6863,
    ],
    parent: 473,
    level: 5,
  },
  568: { id: 568, name: "Baby Strollers", parent: 2764, level: 4 },
  569: {
    id: 569,
    name: "Bedding",
    children: [505803, 2314, 2974, 1985, 2541, 4452, 1599, 2927, 2700, 505287],
    parent: 4171,
    level: 4,
  },
  572: { id: 572, name: "Address Signs", parent: 696, level: 4 },
  573: { id: 573, name: "Baskets", parent: 696, level: 4 },
  574: {
    id: 574,
    name: "Bathroom Accessories",
    children: [
      575, 577, 4366, 7093, 6858, 579, 8016, 5141, 2418, 2034, 8114, 578, 580,
      1962, 4971, 582, 7509, 583, 584, 585, 586,
    ],
    parent: 536,
    level: 3,
  },
  575: { id: 575, name: "Bath Caddies", parent: 574, level: 4 },
  576: { id: 576, name: "Bath Towels & Washcloths", parent: 4077, level: 5 },
  577: { id: 577, name: "Bath Mats & Rugs", parent: 574, level: 4 },
  578: { id: 578, name: "Shower Curtain Rings", parent: 574, level: 4 },
  579: { id: 579, name: "Facial Tissue Holders", parent: 574, level: 4 },
  580: { id: 580, name: "Shower Curtains", parent: 574, level: 4 },
  581: { id: 581, name: "Shower Heads", parent: 2206, level: 6 },
  582: { id: 582, name: "Soap Dishes & Holders", parent: 574, level: 4 },
  583: { id: 583, name: "Toilet Brushes & Holders", parent: 574, level: 4 },
  584: { id: 584, name: "Toilet Paper Holders", parent: 574, level: 4 },
  585: { id: 585, name: "Toothbrush Holders", parent: 574, level: 4 },
  586: { id: 586, name: "Towel Racks & Holders", parent: 574, level: 4 },
  587: { id: 587, name: "Bookends", parent: 696, level: 4 },
  588: { id: 588, name: "Candles", parent: 592, level: 5 },
  592: {
    id: 592,
    name: "Home Fragrances",
    children: [3898, 588, 5847, 3686, 4740, 6767],
    parent: 696,
    level: 4,
  },
  594: {
    id: 594,
    name: "Lighting",
    children: [
      1436, 500003, 1546, 7401, 4636, 7400, 2425, 2608, 3006, 505826, 2370,
      7399, 6274,
    ],
    parent: 536,
    level: 3,
  },
  595: { id: 595, name: "Mirrors", parent: 696, level: 4 },
  596: {
    id: 596,
    name: "Seasonal & Holiday Decorations",
    children: [
      5359, 5504, 6603, 499805, 6532, 499804, 3144, 5990, 5991, 5930, 6531,
      505809,
    ],
    parent: 696,
    level: 4,
  },
  597: { id: 597, name: "Picture Frames", parent: 696, level: 4 },
  598: { id: 598, name: "Rugs", parent: 696, level: 4 },
  599: { id: 599, name: "Slipcovers", parent: 696, level: 4 },
  600: {
    id: 600,
    name: "Smoking Accessories",
    children: [4082, 6882, 6879, 6881, 500007, 6880, 6878],
    parent: 536,
    level: 3,
  },
  601: {
    id: 601,
    name: "Table Linens",
    children: [4203, 4343, 2547, 6325, 6322, 4143],
    parent: 4171,
    level: 4,
  },
  602: { id: 602, name: "Vases", parent: 696, level: 4 },
  603: {
    id: 603,
    name: "Window Treatments",
    children: [2882, 6492, 2885, 5989, 4375, 2621],
    parent: 696,
    level: 4,
  },
  604: {
    id: 604,
    name: "Household Appliances",
    children: [
      1626, 235920, 616, 5294, 4483, 6741, 609, 2706, 500081, 619, 7121, 621,
    ],
    parent: 536,
    level: 3,
  },
  605: { id: 605, name: "Air Conditioners", parent: 1626, level: 5 },
  606: { id: 606, name: "Air Purifiers", parent: 1626, level: 5 },
  607: { id: 607, name: "Dehumidifiers", parent: 1626, level: 5 },
  608: {
    id: 608,
    name: "Fans",
    children: [1700, 2535, 7527, 4485, 8090],
    parent: 1626,
    level: 5,
  },
  609: { id: 609, name: "Garage Door Openers", parent: 604, level: 4 },
  610: { id: 610, name: "Garbage Disposals", parent: 730, level: 5 },
  611: { id: 611, name: "Space Heaters", parent: 1626, level: 5 },
  613: { id: 613, name: "Humidifiers", parent: 1626, level: 5 },
  615: { id: 615, name: "Sewing Machines", parent: 505388, level: 7 },
  616: {
    id: 616,
    name: "Floor & Steam Cleaners",
    children: [543601, 543600, 543602, 543603],
    parent: 604,
    level: 4,
  },
  618: { id: 618, name: "Vacuum Accessories", parent: 3348, level: 4 },
  619: { id: 619, name: "Vacuums", parent: 604, level: 4 },
  621: { id: 621, name: "Water Heaters", parent: 604, level: 4 },
  623: {
    id: 623,
    name: "Household Cleaning Supplies",
    children: [
      4671, 499892, 2857, 6437, 4677, 5113, 6263, 2250, 4515, 6419, 4973, 6264,
      2713, 499767, 4670, 8071, 2796, 2610,
    ],
    parent: 630,
    level: 4,
  },
  624: { id: 624, name: "Facial Tissues", parent: 2530, level: 5 },
  625: { id: 625, name: "Firewood & Fuel", parent: 2862, level: 4 },
  627: {
    id: 627,
    name: "Laundry Supplies",
    children: [
      4982, 5704, 7320, 2677, 6240, 5705, 2794, 4657, 6387, 7457, 499937, 4656,
      499931, 633, 5084, 634, 2754, 5085, 3080, 7502,
    ],
    parent: 630,
    level: 4,
  },
  628: {
    id: 628,
    name: "Shoe Care & Tools",
    children: [
      5600, 2301, 1874, 8033, 2371, 5601, 8032, 1659, 8031, 5604, 2431,
    ],
    parent: 630,
    level: 4,
  },
  629: { id: 629, name: "Toilet Paper", parent: 2530, level: 5 },
  630: {
    id: 630,
    name: "Household Supplies",
    children: [
      7351, 499674, 7214, 8522, 2374, 623, 2530, 3355, 627, 7406, 728, 3307,
      628, 499885, 636, 5056, 4516, 6757,
    ],
    parent: 536,
    level: 3,
  },
  631: { id: 631, name: "Hangers", parent: 5558, level: 6 },
  632: {
    icon: <Hardware />,
    id: 632,
    name: "Hardware",
    children: [
      503739, 115, 128, 543575, 502975, 2878, 500096, 499873, 1974, 133, 127,
      499982, 1910, 3650, 1167,
    ],
    parent: 0,
    level: 1,
  },
  633: { id: 633, name: "Ironing Boards", parent: 627, level: 5 },
  634: { id: 634, name: "Laundry Baskets", parent: 627, level: 5 },
  635: { id: 635, name: "Step Stools", parent: 130, level: 5 },
  636: {
    id: 636,
    name: "Storage & Organization",
    children: [5558, 5128, 8058, 3561, 6986, 5631, 7255, 4360, 2446],
    parent: 630,
    level: 4,
  },
  637: { id: 637, name: "Trash Cans & Wastebaskets", parent: 4516, level: 5 },
  638: {
    id: 638,
    name: "Kitchen & Dining",
    children: [649, 6070, 2920, 2626, 6478, 2901, 730, 668, 8161, 672],
    parent: 536,
    level: 3,
  },
  639: { id: 639, name: "Aprons", parent: 668, level: 5 },
  640: {
    id: 640,
    name: "Bakeware",
    children: [4764, 641, 642, 6756, 643, 644, 645, 2843, 646, 647, 648],
    parent: 6070,
    level: 5,
  },
  641: { id: 641, name: "Baking & Cookie Sheets", parent: 640, level: 6 },
  642: { id: 642, name: "Bread Pans & Molds", parent: 640, level: 6 },
  643: { id: 643, name: "Cake Pans & Molds", parent: 640, level: 6 },
  644: { id: 644, name: "Muffin & Pastry Pans", parent: 640, level: 6 },
  645: { id: 645, name: "Pie & Quiche Pans", parent: 640, level: 6 },
  646: { id: 646, name: "Pizza Stones", parent: 640, level: 6 },
  647: { id: 647, name: "Ramekins & Souffle Dishes", parent: 640, level: 6 },
  648: { id: 648, name: "Roasting Pans", parent: 640, level: 6 },
  649: {
    id: 649,
    name: "Barware",
    children: [
      7075, 1817, 7569, 505806, 499990, 4562, 7238, 2363, 6957, 651, 2976, 650,
      7139, 4563, 8493, 7008,
    ],
    parent: 638,
    level: 4,
  },
  650: { id: 650, name: "Decanters", parent: 649, level: 5 },
  651: {
    id: 651,
    name: "Cocktail Shakers & Tools",
    children: [4222, 3427, 6956, 505327, 503757],
    parent: 649,
    level: 5,
  },
  652: { id: 652, name: "Coffee Servers & Tea Pots", parent: 672, level: 5 },
  653: { id: 653, name: "Colanders & Strainers", parent: 668, level: 5 },
  654: {
    id: 654,
    name: "Cookware",
    children: [
      655, 4721, 6838, 656, 657, 6518, 658, 5110, 4459, 660, 661, 4423, 662,
      663, 659, 5340, 664,
    ],
    parent: 6070,
    level: 5,
  },
  655: { id: 655, name: "Casserole Dishes", parent: 654, level: 6 },
  656: { id: 656, name: "Double Boilers", parent: 654, level: 6 },
  657: { id: 657, name: "Dutch Ovens", parent: 654, level: 6 },
  658: { id: 658, name: "Griddles & Grill Pans", parent: 654, level: 6 },
  659: { id: 659, name: "Stovetop Kettles", parent: 654, level: 6 },
  660: { id: 660, name: "Pressure Cookers & Canners", parent: 654, level: 6 },
  661: { id: 661, name: "Saucepans", parent: 654, level: 6 },
  662: { id: 662, name: "Skillets & Frying Pans", parent: 654, level: 6 },
  663: { id: 663, name: "Stock Pots", parent: 654, level: 6 },
  664: { id: 664, name: "Woks", parent: 654, level: 6 },
  665: { id: 665, name: "Kitchen Knives", parent: 668, level: 5 },
  666: { id: 666, name: "Cutting Boards", parent: 668, level: 5 },
  667: { id: 667, name: "Food Storage Containers", parent: 2626, level: 5 },
  668: {
    id: 668,
    name: "Kitchen Tools & Utensils",
    children: [
      639, 3768, 3347, 3430, 7149, 4630, 6408, 4247, 733, 5078, 6522, 653, 4777,
      3850, 6342, 7331, 3091, 3713, 5928, 3835, 666, 3268, 6723, 6411, 741,
      5370, 505316, 3381, 3723, 3156, 3521, 7329, 6554, 503005, 3385, 6787,
      4746, 7485, 665, 8006, 2948, 3256, 5251, 3206, 4765, 3620, 3294, 3475,
      3248, 4530, 3999, 6526, 4771, 670, 6749, 4332, 4708, 7365, 3421, 5109,
      4705, 3467, 6497, 3914, 3175, 6746, 5080, 6388, 3196, 4788, 4762, 4334,
      6974, 7247, 4559, 4005, 3597,
    ],
    parent: 638,
    level: 4,
  },
  669: { id: 669, name: "Lunch Boxes & Totes", parent: 2920, level: 5 },
  670: { id: 670, name: "Oven Mitts & Pot Holders", parent: 668, level: 5 },
  671: { id: 671, name: "Picnic Baskets", parent: 2920, level: 5 },
  672: {
    id: 672,
    name: "Tableware",
    children: [6740, 652, 673, 674, 675, 676, 4026, 6425, 8046, 677],
    parent: 638,
    level: 4,
  },
  673: {
    id: 673,
    name: "Dinnerware",
    children: [3498, 5537, 3553],
    parent: 672,
    level: 5,
  },
  674: {
    id: 674,
    name: "Drinkware",
    children: [7568, 6049, 6051, 6958, 2169, 2694, 2712, 2951],
    parent: 672,
    level: 5,
  },
  675: {
    id: 675,
    name: "Flatware",
    children: [6439, 3699, 5647, 4015, 3939, 3844],
    parent: 672,
    level: 5,
  },
  676: { id: 676, name: "Salt & Pepper Shakers", parent: 672, level: 5 },
  677: { id: 677, name: "Trivets", parent: 672, level: 5 },
  679: { id: 679, name: "Cooktops", parent: 730, level: 5 },
  680: { id: 680, name: "Dishwashers", parent: 730, level: 5 },
  681: { id: 681, name: "Freezers", parent: 730, level: 5 },
  683: { id: 683, name: "Ovens", parent: 730, level: 5 },
  684: { id: 684, name: "Range Hoods", parent: 730, level: 5 },
  685: { id: 685, name: "Ranges", parent: 730, level: 5 },
  686: { id: 686, name: "Refrigerators", parent: 730, level: 5 },
  687: { id: 687, name: "Food Smokers", parent: 730, level: 5 },
  688: { id: 688, name: "Trash Compactors", parent: 730, level: 5 },
  689: {
    id: 689,
    name: "Lawn & Garden",
    children: [2962, 2918, 3798, 4564, 5362, 3568],
    parent: 536,
    level: 3,
  },
  690: { id: 690, name: "Compost", parent: 4085, level: 6 },
  691: { id: 691, name: "Disease Control", parent: 2962, level: 5 },
  693: { id: 693, name: "Greenhouses", parent: 2962, level: 5 },
  694: {
    id: 694,
    name: "Lawn Mowers",
    children: [3311, 6788, 6258, 3730],
    parent: 3798,
    level: 5,
  },
  695: { id: 695, name: "Log Racks & Carriers", parent: 2862, level: 4 },
  696: {
    id: 696,
    name: "Decor",
    children: [
      572, 6265, 6266, 9, 4456, 573, 5521, 6993, 230911, 500078, 697, 587, 7380,
      4453, 505827, 3890, 5708, 7206, 6317, 6457, 7113, 708, 5875, 6456, 2675,
      7172, 6936, 6935, 5609, 7422, 7419, 701, 4770, 702, 704, 499693, 3221,
      500121, 592, 503000, 7382, 6547, 7436, 6333, 706, 595, 3473, 6324, 5885,
      6927, 597, 4295, 709, 710, 5876, 598, 596, 5922, 599, 6535, 7173, 711,
      4454, 4233, 6424, 602, 2334, 712, 714, 2839, 6530, 6254, 603, 3262, 6267,
    ],
    parent: 536,
    level: 3,
  },
  697: { id: 697, name: "Bird Baths", parent: 696, level: 4 },
  698: { id: 698, name: "Bird Feeders", parent: 6993, level: 5 },
  699: { id: 699, name: "Birdhouses", parent: 500078, level: 5 },
  700: { id: 700, name: "Garden Bridges", parent: 2613, level: 6 },
  701: { id: 701, name: "Flags & Windsocks", parent: 696, level: 4 },
  702: {
    id: 702,
    name: "Fountains & Ponds",
    children: [2921, 6763, 2763],
    parent: 696,
    level: 4,
  },
  703: {
    id: 703,
    name: "Garden Arches, Trellises, Arbors & Pergolas",
    parent: 2613,
    level: 6,
  },
  704: { id: 704, name: "Garden & Stepping Stones", parent: 696, level: 4 },
  706: { id: 706, name: "Mailboxes", parent: 696, level: 4 },
  708: { id: 708, name: "Decorative Plaques", parent: 696, level: 4 },
  709: { id: 709, name: "Rain Chains", parent: 696, level: 4 },
  710: { id: 710, name: "Rain Gauges", parent: 696, level: 4 },
  711: { id: 711, name: "Sundials", parent: 696, level: 4 },
  712: { id: 712, name: "Weather Vanes & Roof Decor", parent: 696, level: 4 },
  714: { id: 714, name: "Wind Chimes", parent: 696, level: 4 },
  716: { id: 716, name: "Canopies & Gazebos", parent: 2613, level: 6 },
  717: { id: 717, name: "Hammocks", parent: 2918, level: 5 },
  718: { id: 718, name: "Porch Swings", parent: 2918, level: 5 },
  719: {
    id: 719,
    name: "Outdoor Umbrellas & Sunshades",
    parent: 2918,
    level: 5,
  },
  720: { id: 720, name: "Sheds, Garages & Carports", parent: 2613, level: 6 },
  721: { id: 721, name: "Pots & Planters", parent: 2962, level: 5 },
  726: { id: 726, name: "Mace & Pepper Spray", parent: 780, level: 4 },
  727: { id: 727, name: "Stun Guns & Tasers", parent: 780, level: 4 },
  728: {
    id: 728,
    name: "Pest Control",
    children: [4220, 2631, 2869, 2865],
    parent: 630,
    level: 4,
  },
  729: {
    id: 729,
    name: "Pool & Spa",
    children: [2832, 543687, 3992, 2982, 2810],
    parent: 536,
    level: 3,
  },
  730: {
    id: 730,
    name: "Kitchen Appliances",
    children: [
      5287, 732, 5090, 736, 679, 3319, 738, 3181, 680, 7165, 751, 4421, 4720,
      4532, 743, 744, 505666, 687, 5103, 681, 5156, 610, 6524, 6543, 747, 748,
      749, 4161, 750, 752, 753, 3526, 4482, 2985, 683, 755, 756, 1015, 684, 685,
      686, 4495, 5577, 5057, 4528, 5289, 688, 763, 3293, 765, 4539, 766,
    ],
    parent: 638,
    level: 4,
  },
  732: { id: 732, name: "Breadmakers", parent: 730, level: 5 },
  733: { id: 733, name: "Can Openers", parent: 668, level: 5 },
  734: { id: 734, name: "Coffee Grinders", parent: 3988, level: 6 },
  736: {
    id: 736,
    name: "Coffee Makers & Espresso Machines",
    children: [1388, 1647, 2422, 1557, 2247, 5286],
    parent: 730,
    level: 5,
  },
  737: { id: 737, name: "Slow Cookers", parent: 4532, level: 6 },
  738: { id: 738, name: "Deep Fryers", parent: 730, level: 5 },
  739: { id: 739, name: "Egg Cookers", parent: 4532, level: 6 },
  741: { id: 741, name: "Electric Knives", parent: 668, level: 5 },
  743: { id: 743, name: "Food Dehydrators", parent: 730, level: 5 },
  744: { id: 744, name: "Food Grinders & Mills", parent: 730, level: 5 },
  747: { id: 747, name: "Hot Plates", parent: 730, level: 5 },
  748: { id: 748, name: "Ice Cream Makers", parent: 730, level: 5 },
  749: { id: 749, name: "Ice Crushers & Shavers", parent: 730, level: 5 },
  750: { id: 750, name: "Juicers", parent: 730, level: 5 },
  751: { id: 751, name: "Electric Kettles", parent: 730, level: 5 },
  752: { id: 752, name: "Knife Sharpeners", parent: 730, level: 5 },
  753: { id: 753, name: "Microwave Ovens", parent: 730, level: 5 },
  755: { id: 755, name: "Pasta Makers", parent: 730, level: 5 },
  756: { id: 756, name: "Popcorn Makers", parent: 730, level: 5 },
  757: { id: 757, name: "Rice Cookers", parent: 4532, level: 6 },
  759: { id: 759, name: "Sandwich Makers", parent: 5289, level: 6 },
  760: { id: 760, name: "Food Steamers", parent: 4532, level: 6 },
  761: { id: 761, name: "Countertop & Toaster Ovens", parent: 5289, level: 6 },
  762: { id: 762, name: "Toasters", parent: 5289, level: 6 },
  763: { id: 763, name: "Vacuum Sealers", parent: 730, level: 5 },
  764: { id: 764, name: "Waffle Irons", parent: 5289, level: 6 },
  765: { id: 765, name: "Water Filters", parent: 730, level: 5 },
  766: { id: 766, name: "Yogurt Makers", parent: 730, level: 5 },
  772: {
    icon: <Mature />,
    id: 772,
    name: "Mature",
    children: [773, 780],
    parent: 0,
    level: 1,
  },
  773: {
    id: 773,
    name: "Erotic",
    children: [779, 774, 776, 5055, 6040, 4060, 6536, 778],
    parent: 772,
    level: 3,
  },
  774: { id: 774, name: "Erotic Clothing", parent: 773, level: 4 },
  775: { id: 775, name: "Condoms", parent: 491, level: 4 },
  776: { id: 776, name: "Erotic DVDs & Videos", parent: 773, level: 4 },
  777: { id: 777, name: "Personal Lubricants", parent: 2915, level: 4 },
  778: { id: 778, name: "Sex Toys", parent: 773, level: 4 },
  779: { id: 779, name: "Erotic Books", parent: 773, level: 4 },
  780: {
    id: 780,
    name: "Weapons",
    children: [
      3833, 7567, 6109, 2214, 782, 726, 3092, 7175, 3924, 727, 3666, 3694, 3437,
    ],
    parent: 772,
    level: 3,
  },
  781: { id: 781, name: "Ammunition", parent: 2214, level: 5 },
  782: { id: 782, name: "Guns", parent: 780, level: 4 },
  783: {
    id: 783,
    name: "Media",
    children: [784, 499995, 839, 886, 855, 5037, 887],
    parent: 0,
    level: 1,
    icon: <Media />,
  },
  784: {
    id: 784,
    name: "Books",
    children: [543541, 543542, 543543],
    parent: 783,
    level: 3,
  },
  839: {
    id: 839,
    name: "DVDs & Videos",
    children: [543527, 543529, 543528],
    parent: 783,
    level: 3,
  },
  855: {
    id: 855,
    name: "Music & Sound Recordings",
    children: [543526, 543522, 543524, 543523, 543525],
    parent: 783,
    level: 3,
  },
  886: {
    id: 886,
    name: "Magazines & Newspapers",
    children: [543539, 543540],
    parent: 783,
    level: 3,
  },
  887: { id: 887, name: "Sheet Music", parent: 783, level: 3 },
  888: {
    icon: <VehiclesParts />,
    id: 888,
    name: "Vehicles & Parts",
    children: [5613, 5614],
    parent: 0,
    level: 1,
  },
  891: { id: 891, name: "Motor Vehicle Amplifiers", parent: 8526, level: 5 },
  894: {
    id: 894,
    name: "Motor Vehicle Equalizers & Crossovers",
    parent: 8526,
    level: 5,
  },
  895: { id: 895, name: "Motor Vehicle Speakers", parent: 8526, level: 5 },
  899: {
    id: 899,
    name: "Motor Vehicle Parts",
    children: [
      2977, 8232, 2805, 8235, 2550, 2820, 8137, 908, 8227, 2727, 8233, 3318,
      2642, 8231, 8238, 8234, 2935, 8228, 2641, 3020, 2534,
    ],
    parent: 5613,
    level: 4,
  },
  908: { id: 908, name: "Motor Vehicle Exhaust", parent: 899, level: 5 },
  911: {
    id: 911,
    name: "Motor Vehicle Tires",
    children: [6093, 6091, 7252],
    parent: 3020,
    level: 6,
  },
  912: { id: 912, name: "Radar Detectors", parent: 222, level: 3 },
  913: {
    id: 913,
    name: "Vehicle Maintenance, Care & Decor",
    children: [8534, 2895, 3436, 2495, 2788, 3812, 8236],
    parent: 5613,
    level: 4,
  },
  916: { id: 916, name: "Cars, Trucks & Vans", parent: 1267, level: 5 },
  919: { id: 919, name: "Motorcycles & Scooters", parent: 1267, level: 5 },
  920: { id: 920, name: "Recreational Vehicles", parent: 1267, level: 5 },
  922: {
    icon: <OfficeSupplies />,
    id: 922,
    name: "Office Supplies",
    children: [
      6174, 8078, 923, 932, 5829, 8499, 2435, 6519, 6373, 950, 2986, 2014, 964,
      2636,
    ],
    parent: 0,
    level: 1,
  },
  923: {
    id: 923,
    name: "Filing & Organization",
    children: [
      5997, 4312, 6190, 6171, 926, 927, 5531, 6177, 928, 939, 925, 930, 6884,
      5070, 6962, 3062, 6885, 6779,
    ],
    parent: 922,
    level: 3,
  },
  925: { id: 925, name: "File Boxes", parent: 923, level: 4 },
  926: { id: 926, name: "CD/DVD Cases & Organizers", parent: 923, level: 4 },
  927: {
    id: 927,
    name: "Calendars, Organizers & Planners",
    parent: 923,
    level: 4,
  },
  928: { id: 928, name: "Cash Boxes", parent: 923, level: 4 },
  930: { id: 930, name: "File Folders", parent: 923, level: 4 },
  932: {
    id: 932,
    name: "General Office Supplies",
    children: [
      6319, 2591, 938, 960, 8015, 505805, 934, 936, 956, 944, 948, 949,
    ],
    parent: 922,
    level: 3,
  },
  934: { id: 934, name: "Office Tape", parent: 932, level: 4 },
  935: { id: 935, name: "Clipboards", parent: 2986, level: 4 },
  936: {
    id: 936,
    name: "Paper Clips & Clamps",
    children: [543676, 543675],
    parent: 932,
    level: 4,
  },
  938: { id: 938, name: "Erasers", parent: 932, level: 4 },
  939: { id: 939, name: "Desk Organizers", parent: 923, level: 4 },
  941: { id: 941, name: "Magnifiers", parent: 2986, level: 4 },
  943: { id: 943, name: "Pencil Sharpeners", parent: 2986, level: 4 },
  944: { id: 944, name: "Rubber Bands", parent: 932, level: 4 },
  947: { id: 947, name: "Staplers", parent: 2986, level: 4 },
  948: { id: 948, name: "Staples", parent: 932, level: 4 },
  949: { id: 949, name: "Tacks & Pushpins", parent: 932, level: 4 },
  950: {
    id: 950,
    name: "Office Equipment",
    children: [499864, 333, 337, 952, 1625, 953, 1708, 6404, 954, 955],
    parent: 922,
    level: 3,
  },
  952: { id: 952, name: "Label Makers", parent: 950, level: 4 },
  953: { id: 953, name: "Office Shredders", parent: 950, level: 4 },
  954: {
    id: 954,
    name: "Transcribers & Dictation Systems",
    parent: 950,
    level: 4,
  },
  955: { id: 955, name: "Typewriters", parent: 950, level: 4 },
  956: {
    id: 956,
    name: "Paper Products",
    children: [
      2658, 5264, 957, 5918, 6930, 1513, 958, 959, 961, 3871, 962, 5919, 3457,
      2689,
    ],
    parent: 932,
    level: 4,
  },
  957: { id: 957, name: "Business Cards", parent: 956, level: 5 },
  958: { id: 958, name: "Envelopes", parent: 956, level: 5 },
  959: { id: 959, name: "Index Cards", parent: 956, level: 5 },
  960: {
    id: 960,
    name: "Labels & Tags",
    children: [4377, 4154, 4137, 5502, 4200, 4117],
    parent: 932,
    level: 4,
  },
  961: { id: 961, name: "Notebooks & Notepads", parent: 956, level: 5 },
  962: { id: 962, name: "Printer & Copier Paper", parent: 956, level: 5 },
  963: { id: 963, name: "Transparencies", parent: 964, level: 4 },
  964: {
    id: 964,
    name: "Presentation Supplies",
    children: [965, 966, 4492, 971, 967, 968, 969, 970, 963, 4465],
    parent: 922,
    level: 3,
  },
  965: { id: 965, name: "Chalkboards", parent: 964, level: 4 },
  966: {
    id: 966,
    name: "Display Boards",
    children: [7525, 2401, 2263, 1627, 2674],
    parent: 964,
    level: 4,
  },
  967: { id: 967, name: "Easel Pads", parent: 964, level: 4 },
  968: { id: 968, name: "Easels", parent: 964, level: 4 },
  969: { id: 969, name: "Laser Pointers", parent: 964, level: 4 },
  970: { id: 970, name: "Lecterns", parent: 964, level: 4 },
  971: { id: 971, name: "Dry-Erase Boards", parent: 964, level: 4 },
  973: { id: 973, name: "Moving & Shipping Boxes", parent: 2636, level: 4 },
  974: { id: 974, name: "Packing Materials", parent: 2636, level: 4 },
  975: { id: 975, name: "Packing Tape", parent: 2636, level: 4 },
  976: {
    id: 976,
    name: "Signage",
    children: [
      7322, 8155, 4297, 5894, 5897, 7323, 5896, 5900, 5898, 5895, 5892, 5893,
      5899,
    ],
    parent: 111,
    level: 3,
  },
  977: {
    id: 977,
    name: "Writing & Drawing Instruments",
    children: [2623, 978, 979, 980, 6067, 4752, 6065],
    parent: 2986,
    level: 4,
  },
  978: { id: 978, name: "Chalk", parent: 977, level: 5 },
  979: { id: 979, name: "Crayons", parent: 977, level: 5 },
  980: {
    id: 980,
    name: "Markers & Highlighters",
    children: [543609, 543608],
    parent: 977,
    level: 5,
  },
  981: {
    id: 981,
    name: "Writing Pencils",
    children: [543660, 543661],
    parent: 6068,
    level: 7,
  },
  982: { id: 982, name: "Pens", parent: 6065, level: 6 },
  984: { id: 984, name: "Flowers", parent: 985, level: 4 },
  985: {
    id: 985,
    name: "Plants",
    children: [5590, 984, 6762, 505285, 2802, 1684],
    parent: 536,
    level: 3,
  },
  988: {
    icon: <SportingGoods />,
    id: 988,
    name: "Sporting Goods",
    children: [499713, 990, 1001, 1011],
    parent: 0,
    level: 1,
  },
  989: { id: 989, name: "Dancing", children: [3269], parent: 499713, level: 4 },
  990: {
    id: 990,
    name: "Exercise & Fitness",
    children: [
      499797, 237166, 499796, 499792, 993, 5869, 499795, 4669, 499978, 8471,
      6337, 5319, 6867, 355576, 3938, 499912, 8215, 7174, 8062, 505302, 5693,
      499798, 8066, 499793, 6103, 999,
    ],
    parent: 988,
    level: 3,
  },
  992: { id: 992, name: "Elliptical Trainers", parent: 4589, level: 6 },
  993: { id: 993, name: "Exercise Balls", parent: 990, level: 4 },
  994: { id: 994, name: "Exercise Bikes", parent: 4589, level: 6 },
  995: { id: 995, name: "Rowing Machines", parent: 4589, level: 6 },
  996: {
    id: 996,
    name: "Stair Climbers & Steppers",
    children: [543610, 543611],
    parent: 4589,
    level: 6,
  },
  997: { id: 997, name: "Treadmills", parent: 4589, level: 6 },
  999: {
    id: 999,
    name: "Yoga & Pilates",
    children: [3810, 6750, 3640, 6743, 5107],
    parent: 990,
    level: 4,
  },
  1000: {
    id: 1000,
    name: "Gymnastics",
    children: [503763, 3808, 3774, 3123, 3182, 3779],
    parent: 499713,
    level: 4,
  },
  1001: {
    id: 1001,
    name: "Indoor Games",
    children: [1002, 1003, 1004, 1007, 7010, 1008, 1009, 1005],
    parent: 988,
    level: 3,
  },
  1002: {
    id: 1002,
    name: "Air Hockey",
    children: [505330, 3548, 3245],
    parent: 1001,
    level: 4,
  },
  1003: {
    id: 1003,
    name: "Billiards",
    children: [3059, 3135, 3222, 3910, 3755, 3469, 3183, 3139],
    parent: 1001,
    level: 4,
  },
  1004: {
    id: 1004,
    name: "Bowling",
    children: [3698, 3219, 3535, 3669, 3260],
    parent: 1001,
    level: 4,
  },
  1005: {
    id: 1005,
    name: "Throwing Darts",
    children: [3957, 3327, 3559, 3839],
    parent: 1001,
    level: 4,
  },
  1006: {
    id: 1006,
    name: "Fencing",
    children: [3261, 3622],
    parent: 499713,
    level: 4,
  },
  1007: {
    id: 1007,
    name: "Foosball",
    children: [3641, 3524, 3847],
    parent: 1001,
    level: 4,
  },
  1008: {
    id: 1008,
    name: "Ping Pong",
    children: [3964, 3788, 3900, 3375, 3132, 3546, 3345],
    parent: 1001,
    level: 4,
  },
  1009: {
    id: 1009,
    name: "Table Shuffleboard",
    children: [3148, 3996, 4021],
    parent: 1001,
    level: 4,
  },
  1011: {
    id: 1011,
    name: "Outdoor Recreation",
    children: [
      499811, 1013, 7059, 1025, 1031, 3334, 1043, 3789, 499824, 5998, 5635,
      499761, 7375, 499846, 5879, 3276, 499844,
    ],
    parent: 988,
    level: 3,
  },
  1013: {
    id: 1013,
    name: "Camping & Hiking",
    children: [
      1014, 1016, 1019, 3937, 3508, 5636, 7154, 3738, 3538, 4785, 502993, 1023,
      5881, 1020, 1021, 5655, 1022, 8079,
    ],
    parent: 1011,
    level: 4,
  },
  1014: {
    id: 1014,
    name: "Camp Furniture",
    children: [4451, 3695, 3089],
    parent: 1013,
    level: 5,
  },
  1015: { id: 1015, name: "Portable Cooking Stoves", parent: 730, level: 5 },
  1016: {
    id: 1016,
    name: "Camping Cookware & Dinnerware",
    parent: 1013,
    level: 5,
  },
  1017: { id: 1017, name: "Coolers", parent: 2920, level: 5 },
  1019: { id: 1019, name: "Camping Lights & Lanterns", parent: 1013, level: 5 },
  1020: { id: 1020, name: "Sleeping Bags", parent: 1013, level: 5 },
  1021: { id: 1021, name: "Sleeping Pads", parent: 1013, level: 5 },
  1022: { id: 1022, name: "Tents", parent: 1013, level: 5 },
  1023: {
    id: 1023,
    name: "Portable Water Filters & Purifiers",
    parent: 1013,
    level: 5,
  },
  1025: {
    id: 1025,
    name: "Cycling",
    children: [3214, 3618, 1026, 3982, 3634, 3531, 3070, 1030],
    parent: 1011,
    level: 4,
  },
  1026: { id: 1026, name: "Bicycles", parent: 1025, level: 5 },
  1027: { id: 1027, name: "Bicycle Locks", parent: 3214, level: 6 },
  1028: {
    id: 1028,
    name: "Bicycle Front & Rear Racks",
    parent: 3214,
    level: 6,
  },
  1029: { id: 1029, name: "Bicycle Helmets", parent: 3982, level: 6 },
  1030: { id: 1030, name: "Unicycles", parent: 1025, level: 5 },
  1031: {
    id: 1031,
    name: "Equestrian",
    children: [3257, 5593, 7215, 5594],
    parent: 1011,
    level: 4,
  },
  1033: {
    id: 1033,
    name: "Archery",
    children: [3773, 499833, 3883, 3291, 3533, 499826, 499825, 3757],
    parent: 499824,
    level: 5,
  },
  1034: { id: 1034, name: "Hunting Blinds & Screens", parent: 3136, level: 6 },
  1037: { id: 1037, name: "Fishing Lines & Leaders", parent: 3334, level: 5 },
  1041: { id: 1041, name: "Tackle Bags & Boxes", parent: 3334, level: 5 },
  1043: {
    id: 1043,
    name: "Golf",
    children: [
      8044, 7314, 4605, 1044, 6864, 1045, 3642, 1046, 3578, 4466, 3106, 4467,
      3772,
    ],
    parent: 1011,
    level: 4,
  },
  1044: { id: 1044, name: "Golf Bags", parent: 1043, level: 5 },
  1045: { id: 1045, name: "Golf Balls", parent: 1043, level: 5 },
  1046: { id: 1046, name: "Golf Clubs", parent: 1043, level: 5 },
  1047: {
    id: 1047,
    name: "Team Handball",
    children: [499785],
    parent: 499713,
    level: 4,
  },
  1049: {
    id: 1049,
    name: "Paintball",
    children: [6748, 3408, 3187, 3234, 6781, 3438],
    parent: 499834,
    level: 6,
  },
  1051: {
    id: 1051,
    name: "Auto Racing Fan Accessories",
    parent: 3515,
    level: 7,
  },
  1057: { id: 1057, name: "Ice Skates", parent: 499915, level: 5 },
  1058: { id: 1058, name: "Inline Skates", parent: 499761, level: 5 },
  1059: { id: 1059, name: "Skateboards", parent: 3276, level: 5 },
  1060: {
    id: 1060,
    name: "Track & Field",
    children: [
      3478, 3445, 3864, 3389, 3987, 3878, 3770, 3997, 3880, 3149, 499786, 4020,
    ],
    parent: 499713,
    level: 4,
  },
  1062: {
    id: 1062,
    name: "Badminton",
    children: [3107, 3950, 3907],
    parent: 499846,
    level: 5,
  },
  1065: {
    id: 1065,
    name: "Tennis",
    children: [3105, 3985, 3565, 3113, 3961, 3658, 3906],
    parent: 499713,
    level: 4,
  },
  1068: {
    id: 1068,
    name: "Wrestling",
    children: [3057],
    parent: 499713,
    level: 4,
  },
  1070: {
    id: 1070,
    name: "Baseball & Softball",
    children: [
      3544, 3747, 1076, 234671, 234670, 1078, 3790, 3783, 1077, 3679, 3671,
    ],
    parent: 499713,
    level: 4,
  },
  1074: {
    id: 1074,
    name: "Baseball & Softball Fan Accessories",
    parent: 3515,
    level: 7,
  },
  1076: {
    id: 1076,
    name: "Baseball & Softball Gloves & Mitts",
    parent: 1070,
    level: 5,
  },
  1077: { id: 1077, name: "Pitching Machines", parent: 1070, level: 5 },
  1078: {
    id: 1078,
    name: "Baseball & Softball Protective Gear",
    children: [3668, 499715, 499718, 499716, 499717],
    parent: 1070,
    level: 5,
  },
  1081: {
    id: 1081,
    name: "Basketball",
    children: [4676, 1082, 499751, 1083],
    parent: 499713,
    level: 4,
  },
  1082: { id: 1082, name: "Basketball Hoops", parent: 1081, level: 5 },
  1083: { id: 1083, name: "Basketballs", parent: 1081, level: 5 },
  1084: {
    id: 1084,
    name: "Basketball Fan Accessories",
    parent: 3515,
    level: 7,
  },
  1087: {
    id: 1087,
    name: "Cricket",
    children: [3870, 499737, 3815, 499735, 499736, 499734],
    parent: 499713,
    level: 4,
  },
  1089: { id: 1089, name: "Field Hockey Balls", parent: 499741, level: 5 },
  1092: { id: 1092, name: "Field Hockey Sticks", parent: 499741, level: 5 },
  1093: {
    id: 1093,
    name: "American Football",
    children: [3442, 3492, 3656, 1097, 3998, 1094],
    parent: 499713,
    level: 4,
  },
  1094: { id: 1094, name: "American Footballs", parent: 1093, level: 5 },
  1095: {
    id: 1095,
    name: "American Football Fan Accessories",
    parent: 3515,
    level: 7,
  },
  1097: {
    id: 1097,
    name: "American Football Protective Gear",
    children: [3510, 3060, 1098, 3497, 499778, 3621],
    parent: 1093,
    level: 5,
  },
  1098: { id: 1098, name: "American Football Helmets", parent: 1097, level: 6 },
  1105: {
    id: 1105,
    name: "Hockey Protective Gear",
    children: [499756, 6078, 499890, 6080, 3615, 499755, 499757, 499975],
    parent: 499915,
    level: 5,
  },
  1110: {
    id: 1110,
    name: "Rugby",
    children: [3761, 3487, 3881, 499782, 3983],
    parent: 499713,
    level: 4,
  },
  1111: {
    id: 1111,
    name: "Soccer",
    children: [1112, 3973, 3141, 6055, 1113, 499784],
    parent: 499713,
    level: 4,
  },
  1112: { id: 1112, name: "Soccer Balls", parent: 1111, level: 5 },
  1113: { id: 1113, name: "Soccer Goals", parent: 1111, level: 5 },
  1114: { id: 1114, name: "Soccer Shin Guards", parent: 499784, level: 6 },
  1115: {
    id: 1115,
    name: "Volleyball",
    children: [1117, 499788, 499787, 1116],
    parent: 499713,
    level: 4,
  },
  1116: { id: 1116, name: "Volleyballs", parent: 1115, level: 5 },
  1117: { id: 1117, name: "Volleyball Nets", parent: 1115, level: 5 },
  1120: {
    id: 1120,
    name: "Boating & Rafting",
    children: [7449, 6314, 1124, 6312, 1127, 499964, 1129, 6097, 3406, 3476],
    parent: 499811,
    level: 5,
  },
  1122: {
    id: 1122,
    name: "Watercraft Care",
    children: [3866, 3955],
    parent: 3391,
    level: 5,
  },
  1124: { id: 1124, name: "Canoes", parent: 1120, level: 6 },
  1125: {
    id: 1125,
    name: "Watercraft Engines & Motors",
    parent: 3391,
    level: 5,
  },
  1127: { id: 1127, name: "Kayaks", parent: 1120, level: 6 },
  1128: { id: 1128, name: "Life Jackets", parent: 499813, level: 6 },
  1129: { id: 1129, name: "Paddles & Oars", parent: 1120, level: 6 },
  1130: { id: 1130, name: "Personal Watercraft", parent: 3540, level: 5 },
  1132: { id: 1132, name: "Sailboat Parts", parent: 3391, level: 5 },
  1133: { id: 1133, name: "Boat Trailers", parent: 4027, level: 6 },
  1135: {
    id: 1135,
    name: "Diving & Snorkeling",
    children: [1136, 1137, 499867, 1139, 1140, 6514, 5312, 1141, 1142],
    parent: 499811,
    level: 5,
  },
  1136: { id: 1136, name: "Buoyancy Compensators", parent: 1135, level: 6 },
  1137: { id: 1137, name: "Dive Computers", parent: 1135, level: 6 },
  1138: { id: 1138, name: "Drysuits", parent: 499813, level: 6 },
  1139: { id: 1139, name: "Diving & Snorkeling Fins", parent: 1135, level: 6 },
  1140: { id: 1140, name: "Diving & Snorkeling Masks", parent: 1135, level: 6 },
  1141: { id: 1141, name: "Diving Regulators", parent: 1135, level: 6 },
  1142: { id: 1142, name: "Snorkels", parent: 1135, level: 6 },
  1143: {
    id: 1143,
    name: "Surfing",
    children: [6287, 6288, 6286, 3649, 3579, 3525, 3801, 3320, 7451, 3762],
    parent: 499811,
    level: 5,
  },
  1144: {
    id: 1144,
    name: "Swimming",
    children: [
      7104, 6473, 2966, 3595, 6513, 3807, 3304, 6330, 3360, 6550, 6511, 3596,
      6515,
    ],
    parent: 499811,
    level: 5,
  },
  1145: {
    id: 1145,
    name: "Water Polo",
    children: [3794, 3575, 3678],
    parent: 499713,
    level: 4,
  },
  1146: {
    id: 1146,
    name: "Water Skiing",
    children: [3289, 6302, 6296, 3350],
    parent: 3195,
    level: 6,
  },
  1147: { id: 1147, name: "Wetsuits", parent: 499813, level: 6 },
  1148: {
    id: 1148,
    name: "Windsurfing",
    children: [3624, 3894, 3413],
    parent: 499811,
    level: 5,
  },
  1157: { id: 1157, name: "Ski Poles", parent: 499845, level: 6 },
  1158: { id: 1158, name: "Downhill Skis", parent: 6064, level: 7 },
  1161: { id: 1161, name: "Ski & Snowboard Helmets", parent: 499845, level: 6 },
  1162: { id: 1162, name: "Snowboard Bindings", parent: 499845, level: 6 },
  1163: { id: 1163, name: "Snowboard Boots", parent: 499845, level: 6 },
  1164: { id: 1164, name: "Snowboards", parent: 499845, level: 6 },
  1166: {
    id: 1166,
    name: "Snowshoeing",
    children: [3073, 3064],
    parent: 499844,
    level: 5,
  },
  1167: {
    id: 1167,
    name: "Tools",
    children: [
      6938, 1169, 1171, 7271, 1174, 1215, 2792, 4325, 2015, 4672, 1180, 1391,
      126, 1217, 6461, 338, 7556, 1219, 1185, 1186, 499887, 5927, 1220, 1221,
      2456, 7416, 130, 1663, 1603, 503774, 7030, 5873, 1832, 1193, 3932, 1305,
      5077, 5587, 1194, 1206, 5828, 2077, 1196, 1667, 2053, 1862, 6868, 1187,
      1958, 1563, 1225, 3501, 1179, 505315, 1202, 1819, 7064, 1841, 1188, 4419,
      1201, 1235, 1203, 1923, 1644, 1195, 1236, 1787, 1184, 1584, 2835, 3745,
      1439, 2198, 4919, 1238, 1469, 5592, 1632,
    ],
    parent: 632,
    level: 3,
  },
  1169: { id: 1169, name: "Anvils", parent: 1167, level: 4 },
  1171: { id: 1171, name: "Axes", parent: 1167, level: 4 },
  1174: { id: 1174, name: "Carving Chisels & Gouges", parent: 1167, level: 4 },
  1179: { id: 1179, name: "Pry Bars", parent: 1167, level: 4 },
  1180: {
    id: 1180,
    name: "Cutters",
    children: [1181, 1182, 1454, 7562, 2080, 1824, 2726, 2411],
    parent: 1167,
    level: 4,
  },
  1181: { id: 1181, name: "Bolt Cutters", parent: 1180, level: 5 },
  1182: { id: 1182, name: "Glass Cutters", parent: 1180, level: 5 },
  1184: { id: 1184, name: "Taps & Dies", parent: 1167, level: 4 },
  1185: { id: 1185, name: "Grips", parent: 1167, level: 4 },
  1186: {
    id: 1186,
    name: "Hammers",
    children: [2208, 505364],
    parent: 1167,
    level: 4,
  },
  1187: { id: 1187, name: "Planes", parent: 1167, level: 4 },
  1188: { id: 1188, name: "Sanders", parent: 1167, level: 4 },
  1191: {
    id: 1191,
    name: "Levels",
    children: [4081, 4931, 4294],
    parent: 1305,
    level: 5,
  },
  1193: {
    id: 1193,
    name: "Masonry Tools",
    children: [1668, 2305, 1555, 2337, 7484, 1799, 1450, 2181, 4132],
    parent: 1167,
    level: 4,
  },
  1194: { id: 1194, name: "Nail Pullers", parent: 1167, level: 4 },
  1195: { id: 1195, name: "Socket Drivers", parent: 1167, level: 4 },
  1196: { id: 1196, name: "Pickup Tools", parent: 1167, level: 4 },
  1198: { id: 1198, name: "Protractors", parent: 1305, level: 5 },
  1201: { id: 1201, name: "Saw Horses", parent: 1167, level: 4 },
  1202: { id: 1202, name: "Putty Knives & Scrapers", parent: 1167, level: 4 },
  1203: { id: 1203, name: "Screwdrivers", parent: 1167, level: 4 },
  1205: { id: 1205, name: "Squares", parent: 1305, level: 5 },
  1206: { id: 1206, name: "Nailers & Staplers", parent: 1167, level: 4 },
  1207: { id: 1207, name: "Stud Sensors", parent: 1305, level: 5 },
  1215: { id: 1215, name: "Caulking Tools", parent: 1167, level: 4 },
  1216: { id: 1216, name: "Drill Presses", parent: 1217, level: 5 },
  1217: {
    id: 1217,
    name: "Drills",
    children: [1367, 1216, 2629, 1465, 1994],
    parent: 1167,
    level: 4,
  },
  1218: { id: 1218, name: "Generators", parent: 127, level: 4 },
  1219: { id: 1219, name: "Grinders", parent: 1167, level: 4 },
  1220: { id: 1220, name: "Heat Guns", parent: 1167, level: 4 },
  1221: { id: 1221, name: "Impact Wrenches & Drivers", parent: 1167, level: 4 },
  1223: { id: 1223, name: "Weed Trimmers", parent: 3798, level: 5 },
  1225: { id: 1225, name: "Polishers & Buffers", parent: 1167, level: 4 },
  1226: { id: 1226, name: "Pressure Washers", parent: 3798, level: 5 },
  1235: {
    id: 1235,
    name: "Saws",
    children: [
      3582, 3516, 3594, 3224, 3725, 7077, 3517, 499985, 3494, 4633, 3706,
    ],
    parent: 1167,
    level: 4,
  },
  1236: { id: 1236, name: "Soldering Irons", parent: 1167, level: 4 },
  1238: {
    id: 1238,
    name: "Welding Guns & Plasma Cutters",
    parent: 1167,
    level: 4,
  },
  1239: {
    icon: <ToysGames />,
    id: 1239,
    name: "Toys & Games",
    children: [4648, 3793, 1249, 3867, 1253],
    parent: 0,
    level: 1,
  },
  1241: {
    id: 1241,
    name: "Baby Walkers & Entertainers",
    parent: 2847,
    level: 4,
  },
  1242: { id: 1242, name: "Baby Mobiles", parent: 2847, level: 4 },
  1243: {
    id: 1243,
    name: "Play Mats & Gyms",
    children: [543613, 543612],
    parent: 2847,
    level: 4,
  },
  1244: { id: 1244, name: "Rattles", parent: 2847, level: 4 },
  1246: { id: 1246, name: "Board Games", parent: 3793, level: 4 },
  1247: { id: 1247, name: "Card Games", parent: 3793, level: 4 },
  1249: {
    id: 1249,
    name: "Outdoor Play Equipment",
    children: [
      7219, 6396, 6270, 6397, 1251, 1863, 2743, 6450, 2867, 3948, 6269, 6271,
      5524, 1738, 6464,
    ],
    parent: 1239,
    level: 3,
  },
  1251: { id: 1251, name: "Playhouses", parent: 1249, level: 4 },
  1253: {
    id: 1253,
    name: "Toys",
    children: [
      4352, 3731, 7311, 3207, 3911, 1268, 1254, 1255, 1262, 3074, 7366, 1261,
      1264, 5970, 2505, 3229, 2778, 2546, 7202, 2799, 3625, 8127, 1266, 499712,
      500005, 3627, 3562, 2953,
    ],
    parent: 1239,
    level: 3,
  },
  1254: {
    id: 1254,
    name: "Building Toys",
    children: [3805, 3172, 3287, 3163, 3617],
    parent: 1253,
    level: 4,
  },
  1255: {
    id: 1255,
    name: "Dolls, Playsets & Toy Figures",
    children: [
      6058, 7114, 3584, 2497, 2499, 1257, 8021, 6056, 6057, 1258, 1259, 3166,
    ],
    parent: 1253,
    level: 4,
  },
  1257: { id: 1257, name: "Dolls", parent: 1255, level: 5 },
  1258: { id: 1258, name: "Puppets & Marionettes", parent: 1255, level: 5 },
  1259: { id: 1259, name: "Stuffed Animals", parent: 1255, level: 5 },
  1261: {
    id: 1261,
    name: "Flying Toys",
    children: [3966, 3460, 3378, 3263],
    parent: 1253,
    level: 4,
  },
  1262: {
    id: 1262,
    name: "Educational Toys",
    children: [3088, 499938, 3928, 500015, 5529, 3500, 6466],
    parent: 1253,
    level: 4,
  },
  1264: {
    id: 1264,
    name: "Musical Toys",
    children: [3252],
    parent: 1253,
    level: 4,
  },
  1266: {
    id: 1266,
    name: "Sports Toys",
    children: [
      3371, 3776, 3552, 3675, 3665, 500113, 8529, 3199, 4167, 3909, 3226, 3943,
      499965, 505284,
    ],
    parent: 1253,
    level: 4,
  },
  1267: {
    id: 1267,
    name: "Motor Vehicles",
    children: [916, 3931, 919, 503031, 920, 3549],
    parent: 5614,
    level: 4,
  },
  1268: { id: 1268, name: "Beach & Sand Toys", parent: 1253, level: 4 },
  1270: {
    id: 1270,
    name: "Video Game Console Accessories",
    children: [1505, 2070],
    parent: 222,
    level: 3,
  },
  1279: { id: 1279, name: "Video Game Software", parent: 2092, level: 3 },
  1294: { id: 1294, name: "Video Game Consoles", parent: 222, level: 3 },
  1300: { id: 1300, name: "Paint Brushes", parent: 2077, level: 5 },
  1301: { id: 1301, name: "Floppy Drives", parent: 2414, level: 6 },
  1302: { id: 1302, name: "Sprinkler Controls", parent: 3780, level: 6 },
  1305: {
    id: 1305,
    name: "Measuring Tools & Sensors",
    children: [
      5515, 4022, 500058, 3602, 2192, 1533, 5487, 1850, 503737, 1640, 1991,
      1732, 5371, 4754, 4506, 2330, 1191, 1698, 1459, 4755, 1785, 1198, 1539,
      2021, 4756, 4757, 1205, 1413, 1207, 2481, 4340, 6799, 2093, 7394, 4758,
      4759, 1374, 4074,
    ],
    parent: 1167,
    level: 4,
  },
  1306: { id: 1306, name: "Water & Flood Detectors", parent: 1679, level: 4 },
  1312: { id: 1312, name: "Seal Stamps", parent: 216, level: 5 },
  1318: { id: 1318, name: "Coils", parent: 2878, level: 4 },
  1319: { id: 1319, name: "Plumbing Traps", parent: 504637, level: 6 },
  1334: {
    id: 1334,
    name: "Microwave Oven Accessories",
    parent: 2901,
    level: 5,
  },
  1337: { id: 1337, name: "Modulators", parent: 1801, level: 4 },
  1340: { id: 1340, name: "Sword Stands & Displays", parent: 220, level: 6 },
  1348: { id: 1348, name: "UPS", parent: 275, level: 5 },
  1350: {
    id: 1350,
    name: "Bridges & Routers",
    children: [5659, 2358, 5496, 5497],
    parent: 342,
    level: 4,
  },
  1352: { id: 1352, name: "Fence Pickets", parent: 128, level: 4 },
  1354: { id: 1354, name: "Water Chillers", parent: 2343, level: 6 },
  1356: { id: 1356, name: "Door Knobs & Handles", parent: 6343, level: 5 },
  1360: { id: 1360, name: "Toothpaste", parent: 526, level: 5 },
  1361: { id: 1361, name: "Paint", parent: 503740, level: 5 },
  1367: { id: 1367, name: "Augers", parent: 1217, level: 5 },
  1368: {
    id: 1368,
    name: "Video Editing Hardware & Production Equipment",
    parent: 386,
    level: 4,
  },
  1371: { id: 1371, name: "Invitations", parent: 96, level: 5 },
  1374: {
    id: 1374,
    name: "Weather Forecasters & Stations",
    parent: 1305,
    level: 5,
  },
  1375: { id: 1375, name: "UPS Accessories", parent: 275, level: 5 },
  1380: {
    id: 1380,
    name: "Vision Care",
    children: [3011, 2923, 2922, 2733, 524, 2521, 6977],
    parent: 2915,
    level: 4,
  },
  1387: { id: 1387, name: "Mustard", parent: 427, level: 5 },
  1388: { id: 1388, name: "Drip Coffee Makers", parent: 736, level: 6 },
  1390: { id: 1390, name: "Water Distillers", parent: 2273, level: 5 },
  1391: { id: 1391, name: "Deburrers", parent: 1167, level: 4 },
  1395: { id: 1395, name: "Coffee Tables", parent: 6369, level: 5 },
  1407: { id: 1407, name: "Drain Rods", parent: 504637, level: 6 },
  1413: { id: 1413, name: "Straight Edges", parent: 1305, level: 5 },
  1420: {
    id: 1420,
    name: "Audio Accessories",
    children: [503008, 505797, 5395, 232, 3306, 3912, 239, 7163, 2372],
    parent: 223,
    level: 4,
  },
  1425: { id: 1425, name: "Ballcocks & Flappers", parent: 2691, level: 6 },
  1434: { id: 1434, name: "Fire Extinguishers", parent: 1679, level: 4 },
  1436: { id: 1436, name: "Emergency Lighting", parent: 594, level: 4 },
  1439: { id: 1439, name: "Tool Keys", parent: 1167, level: 4 },
  1442: { id: 1442, name: "Massage Chairs", parent: 5663, level: 5 },
  1444: { id: 1444, name: "Flasks", parent: 2920, level: 5 },
  1445: { id: 1445, name: "Crackers", parent: 423, level: 5 },
  1450: { id: 1450, name: "Masonry Jointers", parent: 1193, level: 5 },
  1454: {
    id: 1454,
    name: "Handheld Metal Shears & Nibblers",
    parent: 1180,
    level: 5,
  },
  1458: { id: 1458, name: "Fixture Plates", parent: 504635, level: 5 },
  1459: { id: 1459, name: "Measuring Wheels", parent: 1305, level: 5 },
  1463: { id: 1463, name: "Table & Bar Stools", parent: 443, level: 4 },
  1465: { id: 1465, name: "Mortisers", parent: 1217, level: 5 },
  1469: { id: 1469, name: "Wire & Cable Hand Tools", parent: 1167, level: 4 },
  1470: { id: 1470, name: "Manufacturing", parent: 111, level: 3 },
  1471: { id: 1471, name: "Smoke Detectors", parent: 499673, level: 5 },
  1475: { id: 1475, name: "Hotel & Hospitality", parent: 111, level: 3 },
  1479: { id: 1479, name: "Chimney Cleaners", parent: 2277, level: 5 },
  1480: { id: 1480, name: "Network Cables", parent: 259, level: 5 },
  1483: { id: 1483, name: "Ponytail Holders", parent: 171, level: 5 },
  1484: { id: 1484, name: "Envelope Seals", parent: 96, level: 5 },
  1487: { id: 1487, name: "TV Tuner Cards & Adapters", parent: 287, level: 6 },
  1489: {
    id: 1489,
    name: "Faucet Accessories",
    children: [8115, 8116],
    parent: 504635,
    level: 5,
  },
  1491: { id: 1491, name: "Cinches", parent: 5593, level: 6 },
  1492: { id: 1492, name: "Chains", parent: 502977, level: 5 },
  1496: { id: 1496, name: "Foil", parent: 3110, level: 6 },
  1505: {
    id: 1505,
    name: "Home Game Console Accessories",
    parent: 1270,
    level: 4,
  },
  1508: { id: 1508, name: "Drywall Anchors", parent: 500054, level: 5 },
  1513: { id: 1513, name: "Cover Paper", parent: 956, level: 5 },
  1514: { id: 1514, name: "Drain Frames", parent: 504637, level: 6 },
  1516: { id: 1516, name: "Skirt Suits", parent: 1594, level: 5 },
  1519: {
    id: 1519,
    name: "HVAC Controls",
    children: [2238, 500043, 1897],
    parent: 499873,
    level: 4,
  },
  1529: { id: 1529, name: "Herbs & Spices", parent: 4608, level: 5 },
  1530: { id: 1530, name: "Fireplace Tools", parent: 2862, level: 4 },
  1533: { id: 1533, name: "Cruising Rods", parent: 1305, level: 5 },
  1534: { id: 1534, name: "Popcorn", parent: 423, level: 5 },
  1539: { id: 1539, name: "Rebar Locators", parent: 1305, level: 5 },
  1540: { id: 1540, name: "Drill & Screwdriver Bits", parent: 3944, level: 5 },
  1541: { id: 1541, name: "Snow Blowers", parent: 3798, level: 5 },
  1544: { id: 1544, name: "Splitters", parent: 1801, level: 4 },
  1546: { id: 1546, name: "Flood & Spot Lights", parent: 594, level: 4 },
  1548: { id: 1548, name: "Light Meters", parent: 42, level: 5 },
  1549: { id: 1549, name: "End Tables", parent: 6369, level: 5 },
  1550: { id: 1550, name: "Fish Finders", parent: 340, level: 4 },
  1552: { id: 1552, name: "Marine Radar", parent: 340, level: 4 },
  1555: { id: 1555, name: "Construction Lines", parent: 1193, level: 5 },
  1556: {
    id: 1556,
    name: "Law Enforcement",
    children: [1906, 361],
    parent: 111,
    level: 3,
  },
  1557: { id: 1557, name: "French Presses", parent: 736, level: 6 },
  1562: { id: 1562, name: "KVM Switches", parent: 1928, level: 6 },
  1563: { id: 1563, name: "Plungers", parent: 1167, level: 4 },
  1564: { id: 1564, name: "Laptop Batteries", parent: 276, level: 6 },
  1568: { id: 1568, name: "Mayonnaise", parent: 427, level: 5 },
  1573: { id: 1573, name: "Bagels", parent: 1876, level: 5 },
  1578: { id: 1578, name: "Shapewear", parent: 213, level: 5 },
  1580: { id: 1580, name: "Tuxedos", parent: 1594, level: 5 },
  1581: {
    id: 1581,
    name: "Skirts",
    children: [6229, 6228, 2331],
    parent: 1604,
    level: 4,
  },
  1584: { id: 1584, name: "Threading Machines", parent: 1167, level: 4 },
  1594: {
    id: 1594,
    name: "Suits",
    children: [5183, 1516, 1580],
    parent: 1604,
    level: 4,
  },
  1599: { id: 1599, name: "Nap Mats", parent: 569, level: 5 },
  1602: { id: 1602, name: "Sofa Tables", parent: 6369, level: 5 },
  1603: { id: 1603, name: "Light Bulb Changers", parent: 1167, level: 4 },
  1604: {
    id: 1604,
    name: "Clothing",
    children: [
      5322, 182, 2271, 5182, 203, 7313, 204, 212, 207, 1581, 5344, 208, 1594,
      211, 5388, 213, 2306, 5441,
    ],
    parent: 166,
    level: 3,
  },
  1611: { id: 1611, name: "Studio Backgrounds", parent: 42, level: 5 },
  1622: { id: 1622, name: "Photographic Chemicals", parent: 41, level: 5 },
  1623: {
    id: 1623,
    name: "Hard Drive Accessories",
    children: [381, 4417, 505767],
    parent: 2414,
    level: 6,
  },
  1624: {
    id: 1624,
    name: "Science & Laboratory",
    children: [6975, 7325, 3002, 4335, 8119, 4255],
    parent: 111,
    level: 3,
  },
  1625: { id: 1625, name: "Laminators", parent: 950, level: 4 },
  1626: {
    id: 1626,
    name: "Climate Control Appliances",
    children: [
      605, 606, 607, 7328, 6727, 608, 3082, 2060, 613, 6709, 2649, 611,
    ],
    parent: 604,
    level: 4,
  },
  1627: { id: 1627, name: "Mounting Boards", parent: 966, level: 5 },
  1632: { id: 1632, name: "Wrenches", parent: 1167, level: 4 },
  1634: { id: 1634, name: "Video Multiplexers", parent: 386, level: 4 },
  1636: { id: 1636, name: "Bathtubs", parent: 1673, level: 5 },
  1639: {
    id: 1639,
    name: "Fire Extinguisher & Equipment Storage",
    parent: 1679,
    level: 4,
  },
  1640: { id: 1640, name: "Flow Meters & Controllers", parent: 1305, level: 5 },
  1644: { id: 1644, name: "Sharpeners", parent: 1167, level: 4 },
  1645: { id: 1645, name: "Surgical Gowns", parent: 2496, level: 4 },
  1647: {
    id: 1647,
    name: "Electric & Stovetop Espresso Pots",
    parent: 736,
    level: 6,
  },
  1648: { id: 1648, name: "Stains", parent: 503740, level: 5 },
  1653: { id: 1653, name: "Palette Knives", parent: 505391, level: 7 },
  1659: { id: 1659, name: "Shoe Polishes & Waxes", parent: 628, level: 5 },
  1662: { id: 1662, name: "Headbands", parent: 171, level: 5 },
  1663: { id: 1663, name: "Lathes", parent: 1167, level: 4 },
  1665: { id: 1665, name: "Cache Memory", parent: 288, level: 5 },
  1667: { id: 1667, name: "Pipe & Bar Benders", parent: 1167, level: 4 },
  1668: { id: 1668, name: "Brick Tools", parent: 1193, level: 5 },
  1671: { id: 1671, name: "Gin", parent: 417, level: 6 },
  1673: {
    id: 1673,
    name: "Plumbing Fixtures",
    children: [499999, 1636, 2032, 7244, 1687, 2062],
    parent: 133,
    level: 4,
  },
  1675: { id: 1675, name: "Garters", parent: 2563, level: 6 },
  1679: {
    id: 1679,
    name: "Flood, Fire & Gas Safety",
    children: [7226, 1871, 1639, 1434, 1934, 7227, 499673, 1306],
    parent: 536,
    level: 3,
  },
  1680: { id: 1680, name: "Pregnancy Tests", parent: 505293, level: 5 },
  1683: { id: 1683, name: "Printer Memory", parent: 502990, level: 5 },
  1684: { id: 1684, name: "Trees", parent: 985, level: 4 },
  1687: {
    id: 1687,
    name: "Sinks",
    children: [2886, 2757],
    parent: 1673,
    level: 5,
  },
  1694: { id: 1694, name: "Plumbing Flanges", parent: 1810, level: 5 },
  1695: { id: 1695, name: "Weapon Scopes & Sights", parent: 4040, level: 5 },
  1698: { id: 1698, name: "Measuring Scales", parent: 1305, level: 5 },
  1699: { id: 1699, name: "Paint Sponges", parent: 2077, level: 5 },
  1700: { id: 1700, name: "Ceiling Fans", parent: 608, level: 6 },
  1702: { id: 1702, name: "Salsa", parent: 5740, level: 5 },
  1708: { id: 1708, name: "Postage Meters", parent: 950, level: 4 },
  1709: { id: 1709, name: "Water Heater Vents", parent: 2751, level: 5 },
  1718: { id: 1718, name: "Antennas", parent: 2082, level: 4 },
  1719: { id: 1719, name: "Palettes", parent: 505391, level: 7 },
  1722: { id: 1722, name: "Camera Batteries", parent: 276, level: 6 },
  1723: { id: 1723, name: "Well Supplies", parent: 133, level: 4 },
  1732: { id: 1732, name: "Gauges", parent: 1305, level: 5 },
  1733: { id: 1733, name: "RAM", parent: 288, level: 5 },
  1735: { id: 1735, name: "Acid Neutralizers", parent: 2277, level: 5 },
  1738: { id: 1738, name: "Trampolines", parent: 1249, level: 4 },
  1739: { id: 1739, name: "Nuts & Bolts", parent: 500054, level: 5 },
  1744: { id: 1744, name: "Water Heater Elements", parent: 2751, level: 5 },
  1745: { id: 1745, name: "Mobile Phone Batteries", parent: 276, level: 6 },
  1746: { id: 1746, name: "Urinals", parent: 2062, level: 6 },
  1749: { id: 1749, name: "Drain Cleaners", parent: 2277, level: 5 },
  1753: { id: 1753, name: "Lubricants", parent: 503739, level: 4 },
  1754: { id: 1754, name: "Reins", parent: 5593, level: 6 },
  1755: { id: 1755, name: "Dried Fruits", parent: 430, level: 5 },
  1763: { id: 1763, name: "System & Power Cables", parent: 259, level: 5 },
  1767: { id: 1767, name: "Video Memory", parent: 288, level: 5 },
  1771: { id: 1771, name: "Hinges", parent: 2878, level: 4 },
  1772: { id: 1772, name: "Lingerie", parent: 213, level: 5 },
  1774: { id: 1774, name: "Paint Rollers", parent: 2077, level: 5 },
  1779: { id: 1779, name: "Shower Doors & Enclosures", parent: 2206, level: 6 },
  1783: { id: 1783, name: "Gun Holsters", parent: 2214, level: 5 },
  1785: { id: 1785, name: "Probes & Finders", parent: 1305, level: 5 },
  1786: { id: 1786, name: "Balaclavas", parent: 167, level: 4 },
  1787: { id: 1787, name: "Tap Reseaters", parent: 1167, level: 4 },
  1788: { id: 1788, name: "Gates", parent: 128, level: 4 },
  1794: {
    id: 1794,
    name: "Sands & Soils",
    children: [543677, 543678],
    parent: 2962,
    level: 5,
  },
  1795: {
    id: 1795,
    name: "Heavy Machinery",
    children: [2072],
    parent: 111,
    level: 3,
  },
  1799: { id: 1799, name: "Masonry Edgers & Groovers", parent: 1193, level: 5 },
  1801: {
    id: 1801,
    name: "Components",
    children: [7395, 2182, 1977, 1337, 1544],
    parent: 222,
    level: 3,
  },
  1802: { id: 1802, name: "Automotive Alarm Systems", parent: 2768, level: 6 },
  1803: { id: 1803, name: "Paperweights", parent: 2014, level: 4 },
  1806: { id: 1806, name: "Gun Grips", parent: 2214, level: 5 },
  1807: { id: 1807, name: "Long Johns", parent: 213, level: 5 },
  1809: { id: 1809, name: "Work Platforms", parent: 130, level: 5 },
  1810: {
    id: 1810,
    name: "Plumbing Fittings & Supports",
    children: [6732, 499697, 2068, 2710, 2909, 2359, 1694, 2634, 2611, 2466],
    parent: 133,
    level: 4,
  },
  1813: {
    id: 1813,
    name: "Finance & Insurance",
    children: [7565],
    parent: 111,
    level: 3,
  },
  1816: { id: 1816, name: "Filters & Screens", parent: 2878, level: 4 },
  1817: { id: 1817, name: "Beer Dispensers & Taps", parent: 649, level: 5 },
  1819: { id: 1819, name: "Reamers", parent: 1167, level: 4 },
  1821: { id: 1821, name: "Drinking Fountains", parent: 2343, level: 6 },
  1822: { id: 1822, name: "Gun Rails", parent: 2214, level: 5 },
  1824: { id: 1824, name: "Rebar Cutters", parent: 1180, level: 5 },
  1827: { id: 1827, name: "Forestry & Logging", parent: 111, level: 3 },
  1831: { id: 1831, name: "Vests", parent: 203, level: 5 },
  1832: { id: 1832, name: "Marking Tools", parent: 1167, level: 4 },
  1835: { id: 1835, name: "Water Heater Pans", parent: 2751, level: 5 },
  1836: { id: 1836, name: "Paper Joggers", parent: 2014, level: 4 },
  1837: {
    id: 1837,
    name: "Paper & Plastic Shopping Bags",
    parent: 138,
    level: 4,
  },
  1841: { id: 1841, name: "Routing Tools", parent: 1167, level: 4 },
  1850: { id: 1850, name: "Dividers", parent: 1305, level: 5 },
  1855: { id: 1855, name: "Cottage Cheese", parent: 428, level: 5 },
  1856: { id: 1856, name: "Shoelaces", parent: 1933, level: 4 },
  1862: { id: 1862, name: "Pipe Brushes", parent: 1167, level: 4 },
  1863: { id: 1863, name: "Pogo Sticks", parent: 1249, level: 4 },
  1865: { id: 1865, name: "Toilet & Bidet Seats", parent: 2691, level: 6 },
  1866: { id: 1866, name: "Scaffolding", parent: 130, level: 5 },
  1867: { id: 1867, name: "Audio & Video Cables", parent: 259, level: 5 },
  1868: { id: 1868, name: "Coffee", parent: 413, level: 4 },
  1869: { id: 1869, name: "Power Outlets & Sockets", parent: 127, level: 4 },
  1870: { id: 1870, name: "Key Card Entry Systems", parent: 1974, level: 4 },
  1871: { id: 1871, name: "Fire Alarms", parent: 1679, level: 4 },
  1874: { id: 1874, name: "Shoe Brushes", parent: 628, level: 5 },
  1876: {
    id: 1876,
    name: "Bakery",
    children: [
      1573, 5904, 424, 2194, 6196, 2229, 6195, 5751, 5054, 5790, 1895, 5750,
      5749, 6891, 5748,
    ],
    parent: 422,
    level: 4,
  },
  1880: { id: 1880, name: "Cordless Phone Batteries", parent: 276, level: 6 },
  1887: { id: 1887, name: "Response Cards", parent: 96, level: 5 },
  1893: { id: 1893, name: "Wristbands", parent: 167, level: 4 },
  1895: { id: 1895, name: "Muffins", parent: 1876, level: 5 },
  1897: { id: 1897, name: "Thermostats", parent: 1519, level: 5 },
  1898: { id: 1898, name: "Hospital Gowns", parent: 2496, level: 4 },
  1901: { id: 1901, name: "Hair Styling Products", parent: 486, level: 5 },
  1906: { id: 1906, name: "Cuffs", parent: 1556, level: 4 },
  1910: { id: 1910, name: "Storage Tanks", parent: 632, level: 3 },
  1919: { id: 1919, name: "Fence Posts & Rails", parent: 128, level: 4 },
  1921: { id: 1921, name: "Toilets", parent: 2062, level: 6 },
  1922: { id: 1922, name: "Headdresses", parent: 2020, level: 5 },
  1923: { id: 1923, name: "Shapers", parent: 1167, level: 4 },
  1924: { id: 1924, name: "Satellite Phones", parent: 270, level: 5 },
  1926: { id: 1926, name: "Whiskey", parent: 417, level: 6 },
  1927: { id: 1927, name: "Sew-in Labels", parent: 505379, level: 7 },
  1928: {
    id: 1928,
    name: "Input Devices",
    children: [
      139, 5309, 505801, 5366, 301, 499950, 302, 1562, 303, 3580, 304, 4512,
      308,
    ],
    parent: 285,
    level: 5,
  },
  1932: { id: 1932, name: "Drain Openers", parent: 504637, level: 6 },
  1933: {
    id: 1933,
    name: "Shoe Accessories",
    children: [5567, 7078, 5385, 1856, 2427],
    parent: 166,
    level: 3,
  },
  1934: { id: 1934, name: "Fire Sprinklers", parent: 1679, level: 4 },
  1935: { id: 1935, name: "Light Switches", parent: 6459, level: 5 },
  1948: { id: 1948, name: "Hair Forks & Sticks", parent: 171, level: 5 },
  1949: { id: 1949, name: "Religious Veils", parent: 97, level: 4 },
  1952: { id: 1952, name: "Water Softeners", parent: 2273, level: 5 },
  1954: { id: 1954, name: "Yogurt", parent: 428, level: 5 },
  1958: { id: 1958, name: "Pliers", parent: 1167, level: 4 },
  1962: { id: 1962, name: "Shower Rods", parent: 574, level: 4 },
  1963: {
    id: 1963,
    name: "Sink Accessories",
    children: [2410],
    parent: 504635,
    level: 5,
  },
  1967: { id: 1967, name: "Lawn & Garden Sprayers", parent: 3173, level: 6 },
  1969: { id: 1969, name: "Salad Dressing", parent: 427, level: 5 },
  1974: {
    id: 1974,
    name: "Locks & Keys",
    children: [6488, 8067, 1870, 503730],
    parent: 632,
    level: 3,
  },
  1977: {
    id: 1977,
    name: "Electronics Component Connectors",
    parent: 1801,
    level: 4,
  },
  1979: { id: 1979, name: "Drop Cloths", parent: 2878, level: 4 },
  1985: { id: 1985, name: "Blankets", parent: 569, level: 5 },
  1991: { id: 1991, name: "Gas Detectors", parent: 1305, level: 5 },
  1993: { id: 1993, name: "Mouse Pads", parent: 279, level: 5 },
  1994: { id: 1994, name: "Pneumatic Drills", parent: 1217, level: 5 },
  1995: { id: 1995, name: "Solder & Flux", parent: 503739, level: 4 },
  1996: { id: 1996, name: "AV Carts", parent: 6373, level: 4 },
  2002: { id: 2002, name: "Rocking Chairs", parent: 443, level: 4 },
  2006: {
    id: 2006,
    name: "Electrical Mount Boxes & Brackets",
    parent: 127,
    level: 4,
  },
  2007: { id: 2007, name: "Studio Stands & Mounts", parent: 42, level: 5 },
  2008: { id: 2008, name: "Roof Flashings", parent: 123, level: 5 },
  2014: {
    id: 2014,
    name: "Paper Handling",
    children: [6486, 6467, 2207, 1836, 1803, 6178],
    parent: 922,
    level: 3,
  },
  2015: { id: 2015, name: "Compressors", parent: 1167, level: 4 },
  2018: { id: 2018, name: "Ketchup", parent: 427, level: 5 },
  2020: {
    id: 2020,
    name: "Headwear",
    children: [7054, 1922, 5939],
    parent: 167,
    level: 4,
  },
  2021: { id: 2021, name: "Rulers", parent: 1305, level: 5 },
  2027: {
    id: 2027,
    name: "Video Accessories",
    children: [4760, 283, 393, 2145, 403],
    parent: 386,
    level: 4,
  },
  2028: { id: 2028, name: "Phone & Data Jacks", parent: 127, level: 4 },
  2030: { id: 2030, name: "Hatches", parent: 115, level: 4 },
  2032: { id: 2032, name: "Faucets", parent: 1673, level: 5 },
  2034: { id: 2034, name: "Robe Hooks", parent: 574, level: 4 },
  2044: { id: 2044, name: "Bellows", parent: 2862, level: 4 },
  2045: { id: 2045, name: "Office Chairs", parent: 6362, level: 4 },
  2047: {
    id: 2047,
    name: "Work Safety Protective Gear",
    children: [
      2389, 8269, 2723, 2808, 6764, 2227, 503724, 5591, 499961, 499927, 499708,
      7085,
    ],
    parent: 111,
    level: 3,
  },
  2053: { id: 2053, name: "Pipe & Tube Cleaners", parent: 1167, level: 4 },
  2055: { id: 2055, name: "In-Line Water Filters", parent: 2273, level: 5 },
  2058: { id: 2058, name: "Primers", parent: 503740, level: 5 },
  2060: { id: 2060, name: "Heating Radiators", parent: 1626, level: 5 },
  2062: {
    id: 2062,
    name: "Toilets & Bidets",
    children: [2376, 1921, 1746],
    parent: 1673,
    level: 5,
  },
  2063: { id: 2063, name: "Water Filter Cartridges", parent: 2171, level: 6 },
  2065: {
    id: 2065,
    name: "Stock Photographs & Video Footage",
    parent: 5032,
    level: 4,
  },
  2068: { id: 2068, name: "Nozzles", parent: 1810, level: 5 },
  2070: {
    id: 2070,
    name: "Portable Game Console Accessories",
    parent: 1270,
    level: 4,
  },
  2072: { id: 2072, name: "Chippers", parent: 1795, level: 4 },
  2073: { id: 2073, name: "Tea & Infusions", parent: 413, level: 4 },
  2074: { id: 2074, name: "Massage Tables", parent: 5663, level: 5 },
  2077: {
    id: 2077,
    name: "Paint Tools",
    children: [2486, 1300, 6556, 1774, 499888, 1699, 2465, 505325, 6557],
    parent: 1167,
    level: 4,
  },
  2080: { id: 2080, name: "Pipe Cutters", parent: 1180, level: 5 },
  2081: { id: 2081, name: "Bathroom Vanities", parent: 4148, level: 5 },
  2082: {
    id: 2082,
    name: "Electronics Accessories",
    children: [
      258, 5476, 1718, 8156, 367, 3328, 259, 279, 285, 4617, 5466, 288, 3422,
      499878, 275, 341, 5473, 5695,
    ],
    parent: 222,
    level: 3,
  },
  2088: { id: 2088, name: "Shower Columns", parent: 2206, level: 6 },
  2092: {
    icon: <Software />,
    id: 2092,
    name: "Software",
    children: [313, 5032, 1279],
    parent: 0,
    level: 1,
  },
  2093: {
    id: 2093,
    name: "Thermocouples & Thermopiles",
    parent: 1305,
    level: 5,
  },
  2096: {
    id: 2096,
    name: "Camera & Optic Accessories",
    children: [463625, 149, 2911, 143, 160, 4638, 150],
    parent: 141,
    level: 3,
  },
  2104: { id: 2104, name: "Place Cards", parent: 96, level: 5 },
  2106: { id: 2106, name: "CB Radios", parent: 2471, level: 5 },
  2107: { id: 2107, name: "Vodka", parent: 417, level: 6 },
  2110: { id: 2110, name: "Motorcycle Helmets", parent: 5547, level: 6 },
  2121: { id: 2121, name: "Repeaters & Transceivers", parent: 342, level: 4 },
  2125: { id: 2125, name: "Bibs", parent: 561, level: 4 },
  2126: { id: 2126, name: "Cooking Oils", parent: 2660, level: 5 },
  2130: { id: 2130, name: "ROM", parent: 288, level: 5 },
  2139: { id: 2139, name: "Sheet Protectors", parent: 4086, level: 6 },
  2140: { id: 2140, name: "Vinegar", parent: 2660, level: 5 },
  2145: { id: 2145, name: "Rewinders", parent: 2027, level: 5 },
  2147: { id: 2147, name: "Shovels & Spades", parent: 3173, level: 6 },
  2154: { id: 2154, name: "Public Address Systems", parent: 223, level: 4 },
  2155: { id: 2155, name: "Film & Television", parent: 111, level: 3 },
  2160: { id: 2160, name: "Garter Belts", parent: 2563, level: 6 },
  2161: { id: 2161, name: "Motion Sensors", parent: 359, level: 4 },
  2164: {
    id: 2164,
    name: "Carbon Monoxide Detectors",
    parent: 499673,
    level: 5,
  },
  2165: {
    id: 2165,
    name: "Audio Components",
    children: [241, 224, 236, 5129, 6545, 6546, 505771, 234, 246, 249, 505298],
    parent: 223,
    level: 4,
  },
  2169: { id: 2169, name: "Mugs", parent: 674, level: 6 },
  2170: { id: 2170, name: "Plumbing Wastes", parent: 504637, level: 6 },
  2171: {
    id: 2171,
    name: "Water Filtration Accessories",
    children: [2063, 2406],
    parent: 2273,
    level: 5,
  },
  2174: {
    id: 2174,
    name: "Tool Blades",
    children: [505831, 2202],
    parent: 3650,
    level: 4,
  },
  2175: { id: 2175, name: "Hot Water Tanks", parent: 2751, level: 5 },
  2178: { id: 2178, name: "Marine Chartplotters & GPS", parent: 340, level: 4 },
  2181: { id: 2181, name: "Masonry Trowels", parent: 1193, level: 5 },
  2182: {
    id: 2182,
    name: "Converters",
    children: [503001, 2205],
    parent: 1801,
    level: 4,
  },
  2187: { id: 2187, name: "Mining & Quarrying", parent: 111, level: 3 },
  2188: { id: 2188, name: "Jams & Jellies", parent: 5740, level: 5 },
  2192: { id: 2192, name: "Calipers", parent: 1305, level: 5 },
  2194: { id: 2194, name: "Cakes & Dessert Bars", parent: 1876, level: 5 },
  2195: { id: 2195, name: "Washers", parent: 500054, level: 5 },
  2198: { id: 2198, name: "Tool Knives", parent: 1167, level: 4 },
  2202: { id: 2202, name: "Saw Blades", parent: 2174, level: 5 },
  2203: { id: 2203, name: "Plumbing Repair Kits", parent: 133, level: 4 },
  2204: {
    id: 2204,
    name: "Power Tillers & Cultivators",
    parent: 3798,
    level: 5,
  },
  2205: { id: 2205, name: "Scan Converters", parent: 2182, level: 5 },
  2206: {
    id: 2206,
    name: "Shower Parts",
    children: [8320, 8277, 504638, 4728, 2088, 1779, 581, 7130, 5048],
    parent: 504635,
    level: 5,
  },
  2207: { id: 2207, name: "Paper Folding Machines", parent: 2014, level: 4 },
  2208: { id: 2208, name: "Manual Hammers", parent: 1186, level: 5 },
  2210: { id: 2210, name: "Saddles", parent: 5593, level: 6 },
  2212: { id: 2212, name: "Hardware Tape", parent: 503739, level: 4 },
  2214: {
    id: 2214,
    name: "Gun Care & Accessories",
    children: [
      781, 505762, 500048, 503021, 1806, 1783, 5067, 1822, 499853, 503026,
    ],
    parent: 780,
    level: 4,
  },
  2216: { id: 2216, name: "Plumbing Pipes", parent: 133, level: 4 },
  2218: { id: 2218, name: "Grass Edgers", parent: 3798, level: 5 },
  2220: { id: 2220, name: "Tequila", parent: 417, level: 6 },
  2221: { id: 2221, name: "Water Heater Stacks", parent: 2751, level: 5 },
  2222: { id: 2222, name: "Video Camera Batteries", parent: 276, level: 6 },
  2227: { id: 2227, name: "Protective Eyewear", parent: 2047, level: 4 },
  2229: { id: 2229, name: "Cookies", parent: 1876, level: 5 },
  2230: { id: 2230, name: "Screw Posts", parent: 500054, level: 5 },
  2234: {
    id: 2234,
    name: "Developing & Processing Equipment",
    children: [2625, 2999, 2650, 2728, 2516],
    parent: 41,
    level: 5,
  },
  2238: { id: 2238, name: "Control Panels", parent: 1519, level: 5 },
  2242: { id: 2242, name: "Art & Drafting Tables", parent: 6363, level: 5 },
  2243: { id: 2243, name: "Water Levelers", parent: 133, level: 4 },
  2246: { id: 2246, name: "Blood Glucose Meters", parent: 494, level: 5 },
  2247: { id: 2247, name: "Percolators", parent: 736, level: 6 },
  2248: { id: 2248, name: "Vehicle License Plates", parent: 2495, level: 6 },
  2249: { id: 2249, name: "Chandeliers", parent: 3006, level: 5 },
  2250: { id: 2250, name: "Dusters", parent: 623, level: 5 },
  2251: { id: 2251, name: "Screws", parent: 500054, level: 5 },
  2257: { id: 2257, name: "Drain Liners", parent: 504637, level: 6 },
  2263: { id: 2263, name: "Foam Boards", parent: 966, level: 5 },
  2271: { id: 2271, name: "Dresses", parent: 1604, level: 4 },
  2273: {
    id: 2273,
    name: "Water Dispensing & Filtration",
    children: [2055, 2343, 1390, 2171, 5646, 1952],
    parent: 133,
    level: 4,
  },
  2274: { id: 2274, name: "Wire Caps & Nuts", parent: 127, level: 4 },
  2277: {
    id: 2277,
    name: "Chemicals",
    children: [1735, 6795, 1479, 7504, 6191, 7503, 1749, 505319, 500088, 7470],
    parent: 503739,
    level: 4,
  },
  2282: {
    id: 2282,
    name: "Cement, Mortar & Concrete Mixes",
    parent: 503743,
    level: 5,
  },
  2290: { id: 2290, name: "Vehicle Organizers", parent: 8237, level: 5 },
  2292: { id: 2292, name: "White Coats", parent: 2306, level: 5 },
  2301: { id: 2301, name: "Shoe Bags", parent: 628, level: 5 },
  2302: { id: 2302, name: "Robes", parent: 208, level: 5 },
  2305: { id: 2305, name: "Cement Mixers", parent: 1193, level: 5 },
  2306: {
    id: 2306,
    name: "Uniforms",
    children: [5484, 5878, 7235, 5949, 206, 3414, 3598, 2292],
    parent: 1604,
    level: 4,
  },
  2310: { id: 2310, name: "Anode Rods", parent: 2751, level: 5 },
  2313: { id: 2313, name: "Garden Hoses", parent: 3568, level: 5 },
  2314: { id: 2314, name: "Bed Sheets", parent: 569, level: 5 },
  2330: { id: 2330, name: "Knife Guides", parent: 1305, level: 5 },
  2331: { id: 2331, name: "Mini Skirts", parent: 1581, level: 5 },
  2334: { id: 2334, name: "Wallpaper", parent: 696, level: 4 },
  2337: { id: 2337, name: "Floats", parent: 1193, level: 5 },
  2343: {
    id: 2343,
    name: "Water Dispensers",
    children: [1821, 1354],
    parent: 2273,
    level: 5,
  },
  2344: { id: 2344, name: "Spice Organizers", parent: 2948, level: 6 },
  2345: { id: 2345, name: "Electrical Wires & Cable", parent: 127, level: 4 },
  2349: { id: 2349, name: "Fireman's Masks", parent: 503724, level: 5 },
  2353: { id: 2353, name: "Mobile Phone Cases", parent: 264, level: 6 },
  2358: { id: 2358, name: "VoIP Gateways & Routers", parent: 1350, level: 5 },
  2359: { id: 2359, name: "Pipe Connectors", parent: 1810, level: 5 },
  2363: { id: 2363, name: "Coasters", parent: 649, level: 5 },
  2364: { id: 2364, name: "Brandy", parent: 417, level: 6 },
  2365: { id: 2365, name: "Fireplace Screens", parent: 2862, level: 4 },
  2367: {
    id: 2367,
    name: "Air Conditioner Accessories",
    children: [5826, 3573],
    parent: 3348,
    level: 4,
  },
  2370: { id: 2370, name: "Picture Lights", parent: 594, level: 4 },
  2371: { id: 2371, name: "Shoe Dryers", parent: 628, level: 5 },
  2372: { id: 2372, name: "Turntable Accessories", parent: 1420, level: 5 },
  2374: { id: 2374, name: "Garbage Bags", parent: 630, level: 4 },
  2376: { id: 2376, name: "Bidets", parent: 2062, level: 6 },
  2377: { id: 2377, name: "Wall Plates & Covers", parent: 127, level: 4 },
  2380: { id: 2380, name: "Industrial Staples", parent: 3650, level: 4 },
  2387: { id: 2387, name: "Feminine Pads & Protectors", parent: 485, level: 5 },
  2389: { id: 2389, name: "Bullet Proof Vests", parent: 2047, level: 4 },
  2392: { id: 2392, name: "Chips", parent: 423, level: 5 },
  2394: { id: 2394, name: "Video Camera Lights", parent: 143, level: 5 },
  2396: { id: 2396, name: "Chef's Jackets", parent: 7235, level: 6 },
  2401: { id: 2401, name: "Bulletin Boards", parent: 966, level: 5 },
  2406: { id: 2406, name: "Water Filter Housings", parent: 2171, level: 6 },
  2408: { id: 2408, name: "Nails", parent: 500054, level: 5 },
  2410: { id: 2410, name: "Sink Legs", parent: 1963, level: 6 },
  2411: { id: 2411, name: "Utility Knives", parent: 1180, level: 5 },
  2413: { id: 2413, name: "Home Automation Kits", parent: 127, level: 4 },
  2414: {
    id: 2414,
    name: "Storage Devices",
    children: [5268, 1301, 1623, 5272, 380, 5269, 377, 385, 3712],
    parent: 285,
    level: 5,
  },
  2416: { id: 2416, name: "Ladder Carts", parent: 130, level: 5 },
  2418: { id: 2418, name: "Medicine Cabinets", parent: 574, level: 4 },
  2422: { id: 2422, name: "Espresso Machines", parent: 736, level: 6 },
  2423: { id: 2423, name: "Soups & Broths", parent: 422, level: 4 },
  2425: {
    id: 2425,
    name: "Light Bulbs",
    children: [2947, 2690, 2944, 3329],
    parent: 594,
    level: 4,
  },
  2427: { id: 2427, name: "Spurs", parent: 1933, level: 4 },
  2431: { id: 2431, name: "Shoe Trees & Shapers", parent: 628, level: 5 },
  2432: { id: 2432, name: "Puffed Rice Cakes", parent: 423, level: 5 },
  2435: { id: 2435, name: "Name Plates", parent: 922, level: 3 },
  2441: {
    id: 2441,
    name: "Shampoo & Conditioner",
    children: [543616, 543615, 543617],
    parent: 486,
    level: 5,
  },
  2443: {
    id: 2443,
    name: "Airsoft",
    children: [3116, 3093, 3925],
    parent: 499834,
    level: 6,
  },
  2446: {
    id: 2446,
    name: "Storage Hooks & Racks",
    children: [499930, 5494, 5707],
    parent: 636,
    level: 5,
  },
  2447: { id: 2447, name: "Flashlight Accessories", parent: 3650, level: 4 },
  2454: { id: 2454, name: "Headlamps", parent: 338, level: 5 },
  2455: { id: 2455, name: "Hubs & Switches", parent: 342, level: 4 },
  2456: { id: 2456, name: "Industrial Vibrators", parent: 1167, level: 4 },
  2463: { id: 2463, name: "Bathtub Spouts", parent: 2996, level: 6 },
  2465: { id: 2465, name: "Paint Sprayers", parent: 2077, level: 5 },
  2466: { id: 2466, name: "Plumbing Valves", parent: 1810, level: 5 },
  2471: {
    id: 2471,
    name: "Communication Radios",
    children: [2106, 4415, 273],
    parent: 262,
    level: 4,
  },
  2473: { id: 2473, name: "Gas Masks & Respirators", parent: 503724, level: 5 },
  2474: { id: 2474, name: "Paint Binders", parent: 503740, level: 5 },
  2475: {
    id: 2475,
    name: "Studio Lighting Controls",
    children: [3056, 5431, 2490, 5432],
    parent: 42,
    level: 5,
  },
  2477: { id: 2477, name: "Hair Combs", parent: 171, level: 5 },
  2478: { id: 2478, name: "Toilet Trim", parent: 2691, level: 6 },
  2479: {
    id: 2479,
    name: "Concentrators & Multiplexers",
    parent: 342,
    level: 4,
  },
  2481: { id: 2481, name: "Tape Measures", parent: 1305, level: 5 },
  2485: { id: 2485, name: "Tool & Equipment Belts", parent: 3974, level: 5 },
  2486: { id: 2486, name: "Airbrushes", parent: 2077, level: 5 },
  2490: { id: 2490, name: "Lighting Filters & Gobos", parent: 2475, level: 6 },
  2494: { id: 2494, name: "Vehicle Hardtops", parent: 3436, level: 6 },
  2495: {
    id: 2495,
    name: "Vehicle Decor",
    children: [
      2667, 2789, 2588, 2582, 2722, 8469, 2652, 5995, 8145, 7022, 5994, 8298,
      2248, 7532, 8478, 8463, 8142, 8464, 8202,
    ],
    parent: 913,
    level: 5,
  },
  2496: {
    id: 2496,
    name: "Medical",
    children: [
      6275, 1898, 6303, 3477, 5167, 230913, 2907, 6490, 5602, 2928, 1645,
    ],
    parent: 111,
    level: 3,
  },
  2497: { id: 2497, name: "Dollhouse Accessories", parent: 1255, level: 5 },
  2499: { id: 2499, name: "Dollhouses", parent: 1255, level: 5 },
  2503: { id: 2503, name: "Bar Soap", parent: 474, level: 6 },
  2505: {
    id: 2505,
    name: "Play Vehicles",
    children: [3444, 3792, 3551, 3506, 3590, 3474, 3589, 5152, 3296],
    parent: 1253,
    level: 4,
  },
  2507: { id: 2507, name: "Insulated Bags", parent: 2920, level: 5 },
  2508: { id: 2508, name: "Styptic Pencils", parent: 528, level: 5 },
  2511: { id: 2511, name: "Pumice Stones", parent: 2958, level: 7 },
  2512: { id: 2512, name: "Training Fins", parent: 6511, level: 7 },
  2513: {
    id: 2513,
    name: "Vehicle Power Steering Fluid",
    parent: 2788,
    level: 6,
  },
  2515: { id: 2515, name: "Tambourines", parent: 7285, level: 7 },
  2516: {
    id: 2516,
    name: "Retouching Equipment & Supplies",
    parent: 2234,
    level: 6,
  },
  2517: {
    id: 2517,
    name: "Vehicle Cooling System Additives",
    parent: 2788,
    level: 6,
  },
  2518: { id: 2518, name: "Drum Kits", parent: 75, level: 6 },
  2520: {
    id: 2520,
    name: "Enlarging Equipment",
    children: [2969, 2543, 3029, 2815, 2698],
    parent: 41,
    level: 5,
  },
  2521: {
    id: 2521,
    name: "Eyewear Accessories",
    children: [5507, 352853, 543538, 8204],
    parent: 1380,
    level: 5,
  },
  2522: { id: 2522, name: "Bath Additives", parent: 474, level: 6 },
  2524: { id: 2524, name: "Ceiling Light Fixtures", parent: 3006, level: 5 },
  2526: { id: 2526, name: "Facial Cleansers", parent: 567, level: 6 },
  2527: { id: 2527, name: "Dentures", parent: 526, level: 5 },
  2528: { id: 2528, name: "Go Karts & Dune Buggies", parent: 503031, level: 6 },
  2530: {
    id: 2530,
    name: "Household Paper Products",
    children: [624, 3846, 2742, 629],
    parent: 630,
    level: 4,
  },
  2531: { id: 2531, name: "Banners", parent: 96, level: 5 },
  2532: { id: 2532, name: "Construction Paper", parent: 24, level: 7 },
  2534: {
    id: 2534,
    name: "Motor Vehicle Window Parts & Accessories",
    parent: 899,
    level: 5,
  },
  2535: { id: 2535, name: "Desk & Pedestal Fans", parent: 608, level: 6 },
  2540: { id: 2540, name: "Pasta Maker Accessories", parent: 2901, level: 5 },
  2541: { id: 2541, name: "Duvet Covers", parent: 569, level: 5 },
  2543: { id: 2543, name: "Darkroom Timers", parent: 2520, level: 6 },
  2546: {
    id: 2546,
    name: "Remote Control Toys",
    children: [7090, 3532, 3601, 3554, 5968, 3677, 6059, 5969],
    parent: 1253,
    level: 4,
  },
  2547: { id: 2547, name: "Placemats", parent: 601, level: 5 },
  2548: {
    id: 2548,
    name: "Makeup Tools",
    children: [7356, 6555, 6282, 2780, 476, 4121, 502996, 3025, 4106, 499822],
    parent: 2619,
    level: 6,
  },
  2549: { id: 2549, name: "Washing Machines", parent: 2706, level: 5 },
  2550: {
    id: 2550,
    name: "Motor Vehicle Engine Oil Circulation",
    parent: 899,
    level: 5,
  },
  2552: { id: 2552, name: "Drug Tests", parent: 505293, level: 5 },
  2556: { id: 2556, name: "Motor Vehicle Wheel Parts", parent: 3020, level: 6 },
  2559: {
    id: 2559,
    name: "Gift Giving",
    children: [6100, 5916, 2899, 53, 94, 95],
    parent: 5709,
    level: 4,
  },
  2562: { id: 2562, name: "Underwear", parent: 213, level: 5 },
  2563: {
    id: 2563,
    name: "Lingerie Accessories",
    children: [2160, 1675],
    parent: 213,
    level: 5,
  },
  2564: { id: 2564, name: "Tampons", parent: 485, level: 5 },
  2570: {
    id: 2570,
    name: "Plumbing Hoses & Supply Lines",
    parent: 133,
    level: 4,
  },
  2571: {
    id: 2571,
    name: "Face Makeup",
    children: [6305, 2980, 8218, 2765, 6304],
    parent: 477,
    level: 6,
  },
  2572: { id: 2572, name: "Baking Mixes", parent: 2660, level: 5 },
  2579: { id: 2579, name: "Wine Making", parent: 3577, level: 5 },
  2580: { id: 2580, name: "Pajamas", parent: 208, level: 5 },
  2582: {
    id: 2582,
    name: "Vehicle Dashboard Accessories",
    parent: 2495,
    level: 6,
  },
  2587: { id: 2587, name: "Balloons", parent: 96, level: 5 },
  2588: { id: 2588, name: "Vehicle Antenna Balls", parent: 2495, level: 6 },
  2589: { id: 2589, name: "Lip Liner", parent: 2645, level: 7 },
  2590: { id: 2590, name: "Car Wash Solutions", parent: 2895, level: 6 },
  2591: {
    id: 2591,
    name: "Correction Fluids, Pens & Tapes",
    children: [543618, 543620, 543619],
    parent: 932,
    level: 4,
  },
  2592: { id: 2592, name: "Lotion & Moisturizer", parent: 567, level: 6 },
  2596: { id: 2596, name: "Earplugs", parent: 506, level: 5 },
  2600: { id: 2600, name: "Safelights", parent: 41, level: 5 },
  2605: { id: 2605, name: "Rum", parent: 417, level: 6 },
  2608: { id: 2608, name: "Light Ropes & Strings", parent: 594, level: 4 },
  2610: { id: 2610, name: "Squeegees", parent: 623, level: 5 },
  2611: { id: 2611, name: "Plumbing Regulators", parent: 1810, level: 5 },
  2612: { id: 2612, name: "Dryers", parent: 2706, level: 5 },
  2613: {
    id: 2613,
    name: "Outdoor Structures",
    children: [716, 6105, 703, 700, 720],
    parent: 2918,
    level: 5,
  },
  2614: { id: 2614, name: "Jump Ropes", parent: 499792, level: 5 },
  2618: { id: 2618, name: "Jigsaw Puzzles", parent: 3867, level: 4 },
  2619: {
    id: 2619,
    name: "Cosmetic Tools",
    children: [2548, 2975, 2958],
    parent: 473,
    level: 5,
  },
  2620: { id: 2620, name: "Dental Floss", parent: 526, level: 5 },
  2621: { id: 2621, name: "Window Valances & Cornices", parent: 603, level: 5 },
  2623: { id: 2623, name: "Art Charcoals", parent: 977, level: 5 },
  2625: { id: 2625, name: "Copystands", parent: 2234, level: 6 },
  2626: {
    id: 2626,
    name: "Food Storage",
    children: [3337, 6534, 2644, 6481, 3591, 667, 3110, 5134],
    parent: 638,
    level: 4,
  },
  2627: { id: 2627, name: "Lens Hoods", parent: 2911, level: 5 },
  2628: { id: 2628, name: "Soda", parent: 413, level: 4 },
  2629: { id: 2629, name: "Handheld Power Drills", parent: 1217, level: 5 },
  2631: { id: 2631, name: "Pest Control Traps", parent: 728, level: 5 },
  2633: { id: 2633, name: "Breathalyzers", parent: 494, level: 5 },
  2634: { id: 2634, name: "Plumbing Pipe Clamps", parent: 1810, level: 5 },
  2635: { id: 2635, name: "Vehicle Antifreeze", parent: 2788, level: 6 },
  2636: {
    id: 2636,
    name: "Shipping Supplies",
    children: [973, 974, 975],
    parent: 922,
    level: 3,
  },
  2639: { id: 2639, name: "Wood Stoves", parent: 536, level: 3 },
  2641: {
    id: 2641,
    name: "Motor Vehicle Transmission & Drivetrain Parts",
    parent: 899,
    level: 5,
  },
  2642: { id: 2642, name: "Motor Vehicle Mirrors", parent: 899, level: 5 },
  2643: {
    id: 2643,
    name: "Vehicle Waxes, Polishes & Protectants",
    parent: 2895,
    level: 6,
  },
  2644: { id: 2644, name: "Cookie Jars", parent: 2626, level: 5 },
  2645: {
    id: 2645,
    name: "Lip Makeup",
    children: [6306, 2858, 2589, 8217, 3021],
    parent: 477,
    level: 6,
  },
  2647: { id: 2647, name: "Windshield Repair Kits", parent: 8236, level: 6 },
  2649: { id: 2649, name: "Patio Heaters", parent: 1626, level: 5 },
  2650: { id: 2650, name: "Developing Tanks & Reels", parent: 2234, level: 6 },
  2652: { id: 2652, name: "Vehicle Display Flags", parent: 2495, level: 6 },
  2656: { id: 2656, name: "Tweezers", parent: 2915, level: 4 },
  2658: { id: 2658, name: "Binder Paper", parent: 956, level: 5 },
  2660: {
    id: 2660,
    name: "Cooking & Baking Ingredients",
    children: [
      6754, 5776, 5775, 2572, 2803, 5774, 6774, 4613, 5773, 500093, 7506, 2126,
      5771, 5777, 5770, 5752, 6775, 543549, 5105, 2775, 7127, 5769, 499986,
      5767, 8076, 5766, 5800, 5765, 7354, 503734, 499707, 6922, 5768, 2140,
      5778, 2905,
    ],
    parent: 422,
    level: 4,
  },
  2665: { id: 2665, name: "Door Stops", parent: 6343, level: 5 },
  2667: { id: 2667, name: "Bumper Stickers", parent: 2495, level: 6 },
  2668: { id: 2668, name: "Wallets & Money Clips", parent: 6551, level: 4 },
  2669: { id: 2669, name: "Yarn", parent: 505382, level: 7 },
  2671: { id: 2671, name: "Stencils & Die Cuts", parent: 505392, level: 7 },
  2672: { id: 2672, name: "Pool Toys", parent: 2832, level: 5 },
  2674: { id: 2674, name: "Poster Boards", parent: 966, level: 5 },
  2675: { id: 2675, name: "Door Mats", parent: 696, level: 4 },
  2677: { id: 2677, name: "Drying Racks & Hangers", parent: 627, level: 5 },
  2681: { id: 2681, name: "Shaving Brushes", parent: 528, level: 5 },
  2683: { id: 2683, name: "Nail Polishes", parent: 478, level: 6 },
  2684: { id: 2684, name: "Outdoor Tables", parent: 4299, level: 4 },
  2686: { id: 2686, name: "Eyebrow Enhancers", parent: 2779, level: 7 },
  2688: {
    id: 2688,
    name: "Vehicle Transmission Fluid",
    parent: 2788,
    level: 6,
  },
  2689: { id: 2689, name: "Sticky Notes", parent: 956, level: 5 },
  2690: { id: 2690, name: "Fluorescent Tubes", parent: 2425, level: 5 },
  2691: {
    id: 2691,
    name: "Toilet & Bidet Accessories",
    children: [1425, 504634, 1865, 7358, 7446, 5666, 2817, 5665, 2478],
    parent: 504635,
    level: 5,
  },
  2693: { id: 2693, name: "Poker & Game Tables", parent: 6392, level: 4 },
  2694: { id: 2694, name: "Shot Glasses", parent: 674, level: 6 },
  2696: { id: 2696, name: "Mattresses", parent: 6433, level: 4 },
  2698: { id: 2698, name: "Photographic Enlargers", parent: 2520, level: 6 },
  2699: {
    id: 2699,
    name: "Vehicle Remote Keyless Systems",
    parent: 2768,
    level: 6,
  },
  2700: { id: 2700, name: "Pillows", parent: 569, level: 5 },
  2704: {
    id: 2704,
    name: "Vehicle Carpet & Upholstery Cleaners",
    parent: 2895,
    level: 6,
  },
  2706: {
    id: 2706,
    name: "Laundry Appliances",
    children: [2612, 5138, 5139, 2849, 5140, 2549],
    parent: 604,
    level: 4,
  },
  2710: { id: 2710, name: "Pipe Adapters & Bushings", parent: 1810, level: 5 },
  2712: { id: 2712, name: "Stemware", parent: 674, level: 6 },
  2713: { id: 2713, name: "Mops", parent: 623, level: 5 },
  2719: {
    id: 2719,
    name: "Vehicle Fuel System Cleaners",
    parent: 2788,
    level: 6,
  },
  2720: { id: 2720, name: "Mattress Foundations", parent: 6433, level: 4 },
  2722: { id: 2722, name: "Vehicle Decals", parent: 2495, level: 6 },
  2723: { id: 2723, name: "Hardhats", parent: 2047, level: 4 },
  2724: {
    id: 2724,
    name: "Push & Pedal Riding Vehicles",
    parent: 2799,
    level: 5,
  },
  2726: { id: 2726, name: "Tile & Shingle Cutters", parent: 1180, level: 5 },
  2727: { id: 2727, name: "Motor Vehicle Fuel Systems", parent: 899, level: 5 },
  2728: {
    id: 2728,
    name: "Print Trays, Washers & Dryers",
    parent: 2234,
    level: 6,
  },
  2729: { id: 2729, name: "Countertops", parent: 115, level: 4 },
  2733: { id: 2733, name: "Eyeglass Lenses", parent: 1380, level: 5 },
  2734: { id: 2734, name: "Nail Files & Emery Boards", parent: 2975, level: 7 },
  2735: { id: 2735, name: "Vehicle Greases", parent: 2788, level: 6 },
  2739: { id: 2739, name: "Cuticle Pushers", parent: 2975, level: 7 },
  2740: {
    id: 2740,
    name: "Tanning Products",
    children: [5338, 5339],
    parent: 567,
    level: 6,
  },
  2741: { id: 2741, name: "Vellum Paper", parent: 24, level: 7 },
  2742: { id: 2742, name: "Paper Towels", parent: 2530, level: 5 },
  2743: { id: 2743, name: "Sandboxes", parent: 1249, level: 4 },
  2745: { id: 2745, name: "Undershirts", parent: 213, level: 5 },
  2747: { id: 2747, name: "Body Wash", parent: 474, level: 6 },
  2750: {
    id: 2750,
    name: "Vehicle Steering Wheel Locks",
    parent: 2768,
    level: 6,
  },
  2751: {
    id: 2751,
    name: "Water Heater Accessories",
    children: [2310, 2175, 1744, 500063, 1835, 2221, 1709],
    parent: 3348,
    level: 4,
  },
  2753: { id: 2753, name: "Electric Riding Vehicles", parent: 2799, level: 5 },
  2754: { id: 2754, name: "Laundry Detergent", parent: 627, level: 5 },
  2755: { id: 2755, name: "Pool Skimmers", parent: 2832, level: 5 },
  2756: { id: 2756, name: "Horse Harnesses", parent: 5593, level: 6 },
  2757: { id: 2757, name: "Kitchen & Utility Sinks", parent: 1687, level: 6 },
  2761: { id: 2761, name: "False Eyelashes", parent: 2779, level: 7 },
  2763: { id: 2763, name: "Ponds", parent: 702, level: 5 },
  2764: {
    id: 2764,
    name: "Baby Transport",
    children: [547, 538, 568],
    parent: 537,
    level: 3,
  },
  2765: { id: 2765, name: "Foundations & Concealers", parent: 2571, level: 7 },
  2766: { id: 2766, name: "Vents & Flues", parent: 499873, level: 4 },
  2768: {
    id: 2768,
    name: "Vehicle Alarms & Locks",
    children: [6084, 1802, 6083, 8302, 235921, 3024, 2699, 2750, 500077],
    parent: 8301,
    level: 5,
  },
  2769: { id: 2769, name: "Gum Stimulators", parent: 526, level: 5 },
  2770: {
    id: 2770,
    name: "Vehicle Performance Additives",
    parent: 2788,
    level: 6,
  },
  2775: { id: 2775, name: "Flour", parent: 2660, level: 5 },
  2778: {
    id: 2778,
    name: "Remote Control Toy Accessories",
    parent: 1253,
    level: 4,
  },
  2779: {
    id: 2779,
    name: "Eye Makeup",
    children: [8220, 2904, 2686, 2807, 2761, 6340, 2834, 8219],
    parent: 477,
    level: 6,
  },
  2780: { id: 2780, name: "Eyelash Curlers", parent: 2548, level: 7 },
  2781: { id: 2781, name: "Confetti", parent: 96, level: 5 },
  2783: { id: 2783, name: "Noisemakers & Party Blowers", parent: 96, level: 5 },
  2784: { id: 2784, name: "Candle Holders", parent: 500121, level: 5 },
  2786: { id: 2786, name: "Futon Pads", parent: 436, level: 3 },
  2788: {
    id: 2788,
    name: "Vehicle Fluids",
    children: [
      2635, 3051, 2517, 2881, 2719, 2735, 2916, 3044, 2770, 2513, 2688, 2943,
    ],
    parent: 913,
    level: 5,
  },
  2789: { id: 2789, name: "Vehicle Air Fresheners", parent: 2495, level: 6 },
  2792: { id: 2792, name: "Chimney Brushes", parent: 1167, level: 4 },
  2794: {
    id: 2794,
    name: "Fabric Softeners & Dryer Sheets",
    parent: 627,
    level: 5,
  },
  2795: { id: 2795, name: "Door Knockers", parent: 6343, level: 5 },
  2796: { id: 2796, name: "Sponges & Scouring Pads", parent: 623, level: 5 },
  2797: { id: 2797, name: "Snare Drums", parent: 75, level: 6 },
  2799: {
    id: 2799,
    name: "Riding Toys",
    children: [2753, 6407, 2724, 3441, 6379],
    parent: 1253,
    level: 4,
  },
  2801: { id: 2801, name: "Insoles & Inserts", parent: 515, level: 5 },
  2802: {
    id: 2802,
    name: "Seeds",
    children: [543561, 543562],
    parent: 985,
    level: 4,
  },
  2803: { id: 2803, name: "Baking Powder", parent: 2660, level: 5 },
  2804: { id: 2804, name: "Photographic Paper", parent: 41, level: 5 },
  2805: {
    id: 2805,
    name: "Motor Vehicle Climate Control",
    parent: 899,
    level: 5,
  },
  2807: { id: 2807, name: "Eyeliner", parent: 2779, level: 7 },
  2808: { id: 2808, name: "Hazardous Material Suits", parent: 2047, level: 4 },
  2809: { id: 2809, name: "Cabinet Light Fixtures", parent: 3006, level: 5 },
  2810: { id: 2810, name: "Swimming Pools", parent: 729, level: 4 },
  2814: { id: 2814, name: "Hair Color", parent: 486, level: 5 },
  2815: { id: 2815, name: "Photographic Analyzers", parent: 2520, level: 6 },
  2816: { id: 2816, name: "Wrapping Paper", parent: 94, level: 6 },
  2817: { id: 2817, name: "Toilet Tank Levers", parent: 2691, level: 6 },
  2820: { id: 2820, name: "Motor Vehicle Engine Parts", parent: 899, level: 5 },
  2826: { id: 2826, name: "Flooring & Carpet", parent: 115, level: 4 },
  2828: { id: 2828, name: "Nail Clippers", parent: 2975, level: 7 },
  2829: { id: 2829, name: "Lens Caps", parent: 2911, level: 5 },
  2832: {
    id: 2832,
    name: "Pool & Spa Accessories",
    children: [
      2939, 500042, 2981, 505815, 6996, 6771, 3017, 500050, 2994, 7496, 2860,
      5654, 6766, 503751, 2755, 2997, 2672, 5546,
    ],
    parent: 729,
    level: 4,
  },
  2833: { id: 2833, name: "Motor Vehicle Subwoofers", parent: 8526, level: 5 },
  2834: { id: 2834, name: "Mascara", parent: 2779, level: 7 },
  2835: { id: 2835, name: "Tool Clamps & Vises", parent: 1167, level: 4 },
  2836: { id: 2836, name: "Vehicle Bicycle Racks", parent: 3472, level: 6 },
  2837: { id: 2837, name: "Roller Skates", parent: 499761, level: 5 },
  2839: { id: 2839, name: "Wind Wheels & Spinners", parent: 696, level: 4 },
  2843: { id: 2843, name: "Pizza Pans", parent: 640, level: 6 },
  2844: { id: 2844, name: "Sunscreen", parent: 567, level: 6 },
  2846: { id: 2846, name: "Vehicle Glass Cleaners", parent: 2895, level: 6 },
  2847: {
    id: 2847,
    name: "Baby Toys & Activity Equipment",
    children: [
      3661, 7198, 555, 560, 7191, 1242, 7360, 1241, 1243, 539, 3459, 1244, 3860,
    ],
    parent: 537,
    level: 3,
  },
  2849: { id: 2849, name: "Laundry Combo Units", parent: 2706, level: 5 },
  2851: {
    id: 2851,
    name: "Drain Covers & Strainers",
    parent: 504637,
    level: 6,
  },
  2856: { id: 2856, name: "Electronic Drums", parent: 75, level: 6 },
  2857: { id: 2857, name: "Brooms", parent: 623, level: 5 },
  2858: { id: 2858, name: "Lip Gloss", parent: 2645, level: 7 },
  2860: { id: 2860, name: "Pool Floats & Loungers", parent: 2832, level: 5 },
  2862: {
    id: 2862,
    name: "Fireplace & Wood Stove Accessories",
    children: [2044, 6563, 7523, 7109, 2365, 1530, 625, 7091, 7029, 695, 4918],
    parent: 536,
    level: 3,
  },
  2865: {
    id: 2865,
    name: "Repellents",
    children: [7137, 512],
    parent: 728,
    level: 5,
  },
  2867: { id: 2867, name: "Slides", parent: 1249, level: 4 },
  2869: { id: 2869, name: "Pesticides", parent: 728, level: 5 },
  2875: { id: 2875, name: "Bath Sponges & Loofahs", parent: 474, level: 6 },
  2876: { id: 2876, name: "Bath Brushes", parent: 474, level: 6 },
  2878: {
    id: 2878,
    name: "Hardware Accessories",
    children: [
      7092, 4696, 499981, 502977, 1318, 7086, 7270, 8470, 1979, 1816, 7557,
      6841, 8112, 500054, 1771, 503773, 6770, 503731, 500030, 6769, 8113,
      499933, 4988, 3974, 505320,
    ],
    parent: 632,
    level: 3,
  },
  2879: {
    id: 2879,
    name: "Vehicle Safety Equipment",
    children: [8447, 8445, 8448, 8446, 8477, 326120, 8476, 8449, 6966, 8506],
    parent: 8301,
    level: 5,
  },
  2881: { id: 2881, name: "Vehicle Engine Degreasers", parent: 2788, level: 6 },
  2882: { id: 2882, name: "Curtains & Drapes", parent: 603, level: 5 },
  2883: { id: 2883, name: "Call Bells", parent: 2986, level: 4 },
  2885: { id: 2885, name: "Window Blinds & Shades", parent: 603, level: 5 },
  2886: { id: 2886, name: "Bathroom Sinks", parent: 1687, level: 6 },
  2887: { id: 2887, name: "Juice", parent: 413, level: 4 },
  2890: {
    id: 2890,
    name: "Fitness & Nutrition",
    children: [2984, 5702, 6242, 6871, 7413, 525],
    parent: 491,
    level: 4,
  },
  2894: { id: 2894, name: "Car Wash Brushes", parent: 2895, level: 6 },
  2895: {
    id: 2895,
    name: "Vehicle Cleaning",
    children: [2894, 2590, 2704, 499766, 2846, 2643],
    parent: 913,
    level: 5,
  },
  2899: { id: 2899, name: "Fresh Cut Flowers", parent: 2559, level: 5 },
  2901: {
    id: 2901,
    name: "Kitchen Appliance Accessories",
    children: [
      3489, 3988, 500004, 5076, 3954, 3443, 500066, 7355, 6944, 4653, 4763,
      505765, 7570, 6747, 4674, 5042, 7187, 4519, 1334, 3684, 2540, 5075, 7006,
      8087, 3848, 502989, 8051, 7444, 3523, 499996, 7118, 8106, 5570,
    ],
    parent: 638,
    level: 4,
  },
  2904: { id: 2904, name: "Eye Shadow", parent: 2779, level: 7 },
  2905: { id: 2905, name: "Yeast", parent: 2660, level: 5 },
  2907: {
    id: 2907,
    name: "Medical Supplies",
    children: [511, 7063, 499696, 505828, 7324],
    parent: 2496,
    level: 4,
  },
  2909: { id: 2909, name: "Pipe Caps & Plugs", parent: 1810, level: 5 },
  2911: {
    id: 2911,
    name: "Camera Lens Accessories",
    children: [5588, 4441, 2829, 4416, 147, 2627],
    parent: 2096,
    level: 4,
  },
  2915: {
    id: 2915,
    name: "Personal Care",
    children: [
      493, 473, 4929, 2934, 484, 506, 7134, 485, 515, 486, 5663, 526, 777, 528,
      4076, 6921, 472, 2656, 1380,
    ],
    parent: 469,
    level: 3,
  },
  2916: {
    id: 2916,
    name: "Vehicle Hydraulic Clutch Fluid",
    parent: 2788,
    level: 6,
  },
  2917: { id: 2917, name: "Bass Drums", parent: 75, level: 6 },
  2918: {
    id: 2918,
    name: "Outdoor Living",
    children: [499908, 499907, 6737, 717, 5910, 2613, 6751, 719, 499955, 718],
    parent: 689,
    level: 4,
  },
  2919: { id: 2919, name: "Electric Massaging Chairs", parent: 443, level: 4 },
  2920: {
    id: 2920,
    name: "Food & Beverage Carriers",
    children: [
      4722, 3435, 1017, 4520, 1444, 2507, 669, 671, 5060, 3800, 3809, 6449,
    ],
    parent: 638,
    level: 4,
  },
  2921: {
    id: 2921,
    name: "Fountain & Pond Accessories",
    parent: 702,
    level: 5,
  },
  2922: { id: 2922, name: "Eye Drops & Lubricants", parent: 1380, level: 5 },
  2923: { id: 2923, name: "Contact Lenses", parent: 1380, level: 5 },
  2926: { id: 2926, name: "Studio Lights & Flashes", parent: 42, level: 5 },
  2927: { id: 2927, name: "Pillowcases & Shams", parent: 569, level: 5 },
  2928: { id: 2928, name: "Scrubs", parent: 2496, level: 4 },
  2932: {
    id: 2932,
    name: "Motor Vehicle Rims & Wheels",
    children: [6090, 6088, 7253],
    parent: 3020,
    level: 6,
  },
  2933: { id: 2933, name: "Liqueurs", parent: 417, level: 6 },
  2934: { id: 2934, name: "Cotton Swabs", parent: 2915, level: 4 },
  2935: {
    id: 2935,
    name: "Motor Vehicle Suspension Parts",
    parent: 899,
    level: 5,
  },
  2939: { id: 2939, name: "Diving Boards", parent: 2832, level: 5 },
  2943: { id: 2943, name: "Vehicle Windshield Fluid", parent: 2788, level: 6 },
  2944: { id: 2944, name: "Incandescent Light Bulbs", parent: 2425, level: 5 },
  2946: { id: 2946, name: "Nail Polish Removers", parent: 478, level: 6 },
  2947: { id: 2947, name: "Compact Fluorescent Lamps", parent: 2425, level: 5 },
  2948: {
    id: 2948,
    name: "Kitchen Organizers",
    children: [
      6480, 3479, 6487, 3177, 8012, 5157, 3072, 3061, 3845, 2344, 5059, 6415,
      4322, 3831,
    ],
    parent: 668,
    level: 5,
  },
  2949: { id: 2949, name: "Diaper Rash Treatments", parent: 548, level: 4 },
  2951: { id: 2951, name: "Tumblers", parent: 674, level: 6 },
  2953: { id: 2953, name: "Wind-Up Toys", parent: 1253, level: 4 },
  2954: {
    id: 2954,
    name: "Antiseptics & Cleaning Supplies",
    parent: 508,
    level: 5,
  },
  2956: {
    id: 2956,
    name: "Lighting Accessories",
    children: [7338, 7447, 3185, 3522, 505312],
    parent: 536,
    level: 3,
  },
  2958: {
    id: 2958,
    name: "Skin Care Tools",
    children: [6760, 7190, 499926, 2511, 6261, 7018, 8132, 6260],
    parent: 2619,
    level: 6,
  },
  2962: {
    id: 2962,
    name: "Gardening",
    children: [
      4085, 691, 113, 500033, 5632, 505326, 3173, 693, 3103, 6381, 6413, 2988,
      499894, 6428, 499962, 721, 6834, 1794,
    ],
    parent: 689,
    level: 4,
  },
  2963: { id: 2963, name: "Petticoats & Pettipants", parent: 213, level: 5 },
  2966: { id: 2966, name: "Kickboards", parent: 1144, level: 6 },
  2967: { id: 2967, name: "Origami Paper", parent: 24, level: 7 },
  2969: { id: 2969, name: "Darkroom Easels", parent: 2520, level: 6 },
  2971: { id: 2971, name: "Shaving Cream", parent: 528, level: 5 },
  2972: { id: 2972, name: "Door Bells & Chimes", parent: 6343, level: 5 },
  2974: { id: 2974, name: "Bedskirts", parent: 569, level: 5 },
  2975: {
    id: 2975,
    name: "Nail Tools",
    children: [2739, 3037, 7494, 6300, 6341, 2828, 499698, 7490, 5880, 2734],
    parent: 2619,
    level: 6,
  },
  2976: { id: 2976, name: "Corkscrews", parent: 649, level: 5 },
  2977: { id: 2977, name: "Motor Vehicle Braking", parent: 899, level: 5 },
  2978: { id: 2978, name: "Fuel Cells", parent: 275, level: 5 },
  2980: { id: 2980, name: "Face Powder", parent: 2571, level: 7 },
  2981: { id: 2981, name: "Pool & Spa Filters", parent: 2832, level: 5 },
  2982: { id: 2982, name: "Spas", parent: 729, level: 4 },
  2984: { id: 2984, name: "Nutrition Bars", parent: 2890, level: 5 },
  2985: { id: 2985, name: "Outdoor Grills", parent: 730, level: 5 },
  2986: {
    id: 2986,
    name: "Office Instruments",
    children: [2883, 935, 505830, 941, 4341, 943, 4499, 947, 503746, 4470, 977],
    parent: 922,
    level: 3,
  },
  2987: { id: 2987, name: "Flash Brackets", parent: 143, level: 5 },
  2988: { id: 2988, name: "Mulch", parent: 2962, level: 5 },
  2989: {
    id: 2989,
    name: "Motor Vehicle Tire Accessories",
    parent: 3020,
    level: 6,
  },
  2991: { id: 2991, name: "Mattress Pads", parent: 4452, level: 6 },
  2992: { id: 2992, name: "Bunion Care Supplies", parent: 515, level: 5 },
  2994: {
    id: 2994,
    name: "Pool Covers & Ground Cloths",
    parent: 2832,
    level: 5,
  },
  2996: {
    id: 2996,
    name: "Bathtub Accessories",
    children: [505368, 5508, 2463],
    parent: 504635,
    level: 5,
  },
  2997: { id: 2997, name: "Pool Sweeps & Vacuums", parent: 2832, level: 5 },
  2999: { id: 2999, name: "Darkroom Sinks", parent: 2234, level: 6 },
  3001: { id: 3001, name: "Field Hockey Goals", parent: 499741, level: 5 },
  3002: { id: 3002, name: "Laboratory Chemicals", parent: 1624, level: 4 },
  3005: { id: 3005, name: "Tom-Toms", parent: 75, level: 6 },
  3006: {
    id: 3006,
    name: "Lighting Fixtures",
    children: [2809, 2524, 2249, 6073],
    parent: 594,
    level: 4,
  },
  3009: { id: 3009, name: "Cuticle Cream & Oil", parent: 478, level: 6 },
  3011: {
    id: 3011,
    name: "Contact Lens Care",
    children: [7363, 6510, 6509],
    parent: 1380,
    level: 5,
  },
  3013: { id: 3013, name: "Hair Shears", parent: 486, level: 5 },
  3014: {
    id: 3014,
    name: "Beer Brewing Grains & Malts",
    parent: 3577,
    level: 5,
  },
  3015: { id: 3015, name: "Hi-Hats", parent: 75, level: 6 },
  3017: { id: 3017, name: "Pool Cleaners & Chemicals", parent: 2832, level: 5 },
  3018: { id: 3018, name: "ATVs & UTVs", parent: 503031, level: 6 },
  3019: { id: 3019, name: "Teeth Whiteners", parent: 526, level: 5 },
  3020: {
    id: 3020,
    name: "Motor Vehicle Wheel Systems",
    children: [2932, 2989, 911, 2556],
    parent: 899,
    level: 5,
  },
  3021: { id: 3021, name: "Lipstick", parent: 2645, level: 7 },
  3022: {
    id: 3022,
    name: "Corn & Callus Care Supplies",
    parent: 515,
    level: 5,
  },
  3024: { id: 3024, name: "Vehicle Immobilizers", parent: 2768, level: 6 },
  3025: { id: 3025, name: "Makeup Brushes", parent: 2548, level: 7 },
  3026: { id: 3026, name: "Art Pencils", parent: 6068, level: 7 },
  3029: { id: 3029, name: "Focusing Aids", parent: 2520, level: 6 },
  3031: {
    id: 3031,
    name: "Bricks & Concrete Blocks",
    parent: 503743,
    level: 5,
  },
  3032: { id: 3032, name: "Handbags", parent: 6551, level: 4 },
  3035: { id: 3035, name: "Tripod Collars & Mounts", parent: 4638, level: 5 },
  3037: { id: 3037, name: "Cuticle Scissors", parent: 2975, level: 7 },
  3040: { id: 3040, name: "Mouthwash", parent: 526, level: 5 },
  3043: { id: 3043, name: "Cymbals", parent: 75, level: 6 },
  3044: { id: 3044, name: "Vehicle Motor Oil", parent: 2788, level: 6 },
  3049: { id: 3049, name: "Foot Odor Removers", parent: 515, level: 5 },
  3051: { id: 3051, name: "Vehicle Brake Fluid", parent: 2788, level: 6 },
  3053: { id: 3053, name: "Ropes & Hardware Cable", parent: 502977, level: 5 },
  3055: { id: 3055, name: "MP3 Player Cases", parent: 232, level: 6 },
  3056: { id: 3056, name: "Flash Diffusers", parent: 2475, level: 6 },
  3057: {
    id: 3057,
    name: "Wrestling Protective Gear",
    children: [499791, 499790],
    parent: 1068,
    level: 5,
  },
  3059: { id: 3059, name: "Billiard Ball Racks", parent: 1003, level: 5 },
  3060: {
    id: 3060,
    name: "American Football Helmet Accessories",
    children: [3247, 3090, 3343, 3063],
    parent: 1097,
    level: 6,
  },
  3061: {
    id: 3061,
    name: "Paper Towel Holders & Dispensers",
    parent: 2948,
    level: 6,
  },
  3062: { id: 3062, name: "Pen & Pencil Cases", parent: 923, level: 4 },
  3063: {
    id: 3063,
    name: "American Football Helmet Visors",
    parent: 3060,
    level: 7,
  },
  3064: { id: 3064, name: "Snowshoes", parent: 1166, level: 6 },
  3066: { id: 3066, name: "Rain Suits", parent: 203, level: 5 },
  3067: {
    id: 3067,
    name: "Skateboarding Protective Gear",
    children: [499776, 7789, 3488],
    parent: 3276,
    level: 5,
  },
  3070: { id: 3070, name: "Unicycle Accessories", parent: 1025, level: 5 },
  3071: { id: 3071, name: "Gardening Forks", parent: 3173, level: 6 },
  3072: {
    id: 3072,
    name: "Napkin Holders & Dispensers",
    parent: 2948,
    level: 6,
  },
  3073: { id: 3073, name: "Snowshoe Bindings", parent: 1166, level: 6 },
  3074: {
    id: 3074,
    name: "Executive Toys",
    children: [5872],
    parent: 1253,
    level: 4,
  },
  3077: { id: 3077, name: "Rugby Headgear", parent: 499782, level: 6 },
  3079: { id: 3079, name: "Toy Drawing Tablets", parent: 3731, level: 5 },
  3080: { id: 3080, name: "Lint Rollers", parent: 627, level: 5 },
  3082: { id: 3082, name: "Furnaces & Boilers", parent: 1626, level: 5 },
  3083: { id: 3083, name: "Drafting Compasses", parent: 505392, level: 7 },
  3084: { id: 3084, name: "Equestrian Gloves", parent: 5594, level: 6 },
  3086: { id: 3086, name: "Bowl Scrapers", parent: 3256, level: 6 },
  3087: { id: 3087, name: "Sailboats", parent: 3540, level: 5 },
  3088: { id: 3088, name: "Ant Farms", parent: 1262, level: 5 },
  3089: { id: 3089, name: "Cots", parent: 1014, level: 6 },
  3090: {
    id: 3090,
    name: "American Football Face Masks",
    parent: 3060,
    level: 7,
  },
  3091: { id: 3091, name: "Cooking Thermometers", parent: 668, level: 5 },
  3092: { id: 3092, name: "Nunchucks", parent: 780, level: 4 },
  3093: { id: 3093, name: "Airsoft Guns", parent: 2443, level: 7 },
  3094: { id: 3094, name: "Transistors", parent: 3991, level: 5 },
  3095: { id: 3095, name: "Motor Boats", parent: 3540, level: 5 },
  3096: { id: 3096, name: "Live Bait", parent: 3334, level: 5 },
  3097: { id: 3097, name: "Watercraft Impellers", parent: 3606, level: 6 },
  3101: { id: 3101, name: "Kneeboards", parent: 3370, level: 7 },
  3103: { id: 3103, name: "Herbicides", parent: 2962, level: 5 },
  3105: {
    id: 3105,
    name: "Tennis Ball Hoppers & Carts",
    parent: 1065,
    level: 5,
  },
  3106: { id: 3106, name: "Golf Tees", parent: 1043, level: 5 },
  3107: { id: 3107, name: "Badminton Nets", parent: 1062, level: 6 },
  3109: { id: 3109, name: "Dart Shafts", parent: 3327, level: 6 },
  3110: {
    id: 3110,
    name: "Food Wraps",
    children: [1496, 5642, 3750, 3956],
    parent: 2626,
    level: 5,
  },
  3111: { id: 3111, name: "Lancing Devices", parent: 3688, level: 6 },
  3113: { id: 3113, name: "Tennis Balls", parent: 1065, level: 5 },
  3116: {
    id: 3116,
    name: "Airsoft Gun Parts & Accessories",
    children: [8005],
    parent: 2443,
    level: 7,
  },
  3117: {
    id: 3117,
    name: "Video Game Arcade Cabinets",
    parent: 3356,
    level: 4,
  },
  3118: { id: 3118, name: "Bicycle Cleats", parent: 3982, level: 6 },
  3119: {
    id: 3119,
    name: "Racquetball & Squash Eyewear",
    parent: 503752,
    level: 5,
  },
  3120: { id: 3120, name: "Hedge Trimmers", parent: 3798, level: 5 },
  3121: { id: 3121, name: "Inductors", parent: 3635, level: 5 },
  3123: { id: 3123, name: "Gymnastics Springboards", parent: 1000, level: 5 },
  3124: { id: 3124, name: "Vacuum Sealer Bags", parent: 3523, level: 6 },
  3125: {
    id: 3125,
    name: "Clay Pigeon Shooting",
    children: [3305, 3528],
    parent: 499824,
    level: 5,
  },
  3126: {
    id: 3126,
    name: "Tetherball",
    children: [499882, 499883, 499884],
    parent: 499846,
    level: 5,
  },
  3127: { id: 3127, name: "Skate Rails", parent: 3276, level: 5 },
  3128: { id: 3128, name: "Bicycle Bibs", parent: 5697, level: 6 },
  3129: {
    id: 3129,
    name: "Pretend Professions & Role Playing",
    parent: 3229,
    level: 5,
  },
  3130: { id: 3130, name: "Camera Battery Chargers", parent: 7166, level: 6 },
  3132: {
    id: 3132,
    name: "Ping Pong Robot Accessories",
    parent: 1008,
    level: 5,
  },
  3135: { id: 3135, name: "Billiard Balls", parent: 1003, level: 5 },
  3136: {
    id: 3136,
    name: "Hunting",
    children: [3674, 7373, 1034, 5917, 3748, 6992, 8011],
    parent: 499824,
    level: 5,
  },
  3139: { id: 3139, name: "Billiard Tables", parent: 1003, level: 5 },
  3140: { id: 3140, name: "Pinball Machines", parent: 3356, level: 4 },
  3141: { id: 3141, name: "Soccer Gloves", parent: 1111, level: 5 },
  3143: { id: 3143, name: "Watercraft Alternators", parent: 3606, level: 6 },
  3144: { id: 3144, name: "Holiday Ornaments", parent: 596, level: 5 },
  3148: { id: 3148, name: "Shuffleboard Tables", parent: 1009, level: 5 },
  3149: { id: 3149, name: "Track Hurdles", parent: 1060, level: 5 },
  3151: { id: 3151, name: "Cigarettes", parent: 435, level: 4 },
  3152: { id: 3152, name: "Paintball Hoppers", parent: 3187, level: 8 },
  3156: { id: 3156, name: "Food Graters & Zesters", parent: 668, level: 5 },
  3160: {
    id: 3160,
    name: "Power Strips & Surge Suppressors",
    parent: 275,
    level: 5,
  },
  3163: { id: 3163, name: "Marble Track Sets", parent: 1254, level: 5 },
  3164: { id: 3164, name: "Free Weights", parent: 499793, level: 5 },
  3166: { id: 3166, name: "Toy Playsets", parent: 1255, level: 5 },
  3170: { id: 3170, name: "Shooting Targets", parent: 499840, level: 6 },
  3172: { id: 3172, name: "Foam Blocks", parent: 1254, level: 5 },
  3173: {
    id: 3173,
    name: "Gardening Tools",
    children: [
      7537, 4000, 3071, 505292, 3644, 1967, 499922, 6967, 3841, 3388, 2147,
      3828, 3616,
    ],
    parent: 2962,
    level: 5,
  },
  3175: {
    id: 3175,
    name: "Scoops",
    children: [3202, 3708, 3258, 502966],
    parent: 668,
    level: 5,
  },
  3176: { id: 3176, name: "Guitar Tuning Pegs", parent: 3502, level: 7 },
  3177: {
    id: 3177,
    name: "Kitchen Counter & Beverage Station Organizers",
    parent: 2948,
    level: 6,
  },
  3178: { id: 3178, name: "Guitar Strings", parent: 3502, level: 7 },
  3181: { id: 3181, name: "Deli Slicers", parent: 730, level: 5 },
  3182: { id: 3182, name: "Pommel Horses", parent: 1000, level: 5 },
  3183: {
    id: 3183,
    name: "Billiard Table Parts & Accessories",
    children: [3574, 3754, 3547, 8065],
    parent: 1003,
    level: 5,
  },
  3185: { id: 3185, name: "Lamp Shades", parent: 2956, level: 4 },
  3187: {
    id: 3187,
    name: "Paintball Gun Parts & Accessories",
    children: [3244, 3690, 8514, 3152],
    parent: 1049,
    level: 7,
  },
  3188: { id: 3188, name: "Bicycle Shorts & Briefs", parent: 5697, level: 6 },
  3189: { id: 3189, name: "Anchors", parent: 3315, level: 6 },
  3190: { id: 3190, name: "Deck Shuffleboard Pucks", parent: 3787, level: 6 },
  3191: { id: 3191, name: "Baseball Uniforms", parent: 3598, level: 6 },
  3192: { id: 3192, name: "Skateboard Trucks", parent: 3670, level: 6 },
  3195: {
    id: 3195,
    name: "Towed Water Sports",
    children: [3370, 6301, 7452, 3282, 1146, 3636],
    parent: 499811,
    level: 5,
  },
  3196: { id: 3196, name: "Spatulas", parent: 668, level: 5 },
  3199: {
    id: 3199,
    name: "Fitness Toys",
    children: [3215],
    parent: 1266,
    level: 5,
  },
  3201: { id: 3201, name: "Climbing Rope Bags", parent: 7059, level: 5 },
  3202: { id: 3202, name: "Ice Cream Scoops", parent: 3175, level: 6 },
  3204: { id: 3204, name: "Lacrosse Training Aids", parent: 499741, level: 5 },
  3206: { id: 3206, name: "Kitchen Slicers", parent: 668, level: 5 },
  3207: { id: 3207, name: "Ball Pits", parent: 1253, level: 4 },
  3208: { id: 3208, name: "Liquid Hand Soap", parent: 474, level: 6 },
  3210: { id: 3210, name: "Shaper Cutters", parent: 3470, level: 5 },
  3211: { id: 3211, name: "Climbing Chalk Bags", parent: 7059, level: 5 },
  3212: { id: 3212, name: "Bubble Blowing Solution", parent: 4352, level: 5 },
  3213: { id: 3213, name: "GPS Mounts", parent: 3895, level: 4 },
  3214: {
    id: 3214,
    name: "Bicycle Accessories",
    children: [
      3778, 3341, 3879, 4145, 500067, 5842, 5540, 3243, 6442, 3719, 1028,
      500092, 1027, 3368, 3827, 6445, 6506, 7448, 3428, 499694, 7223, 505668,
      3811, 3868, 3631, 3558, 6048, 500109,
    ],
    parent: 1025,
    level: 5,
  },
  3215: { id: 3215, name: "Hula Hoops", parent: 3199, level: 6 },
  3216: { id: 3216, name: "Bicycle Wheels", parent: 3618, level: 6 },
  3217: {
    id: 3217,
    name: "Weight Lifting Machine & Exercise Bench Accessories",
    parent: 499793,
    level: 5,
  },
  3218: { id: 3218, name: "Climbing Harnesses", parent: 7059, level: 5 },
  3219: { id: 3219, name: "Bowling Balls", parent: 1004, level: 5 },
  3220: { id: 3220, name: "Capacitors", parent: 3635, level: 5 },
  3221: { id: 3221, name: "Home Decor Decals", parent: 696, level: 4 },
  3222: {
    id: 3222,
    name: "Billiard Cue Accessories",
    children: [499993, 499994, 3720],
    parent: 1003,
    level: 5,
  },
  3224: { id: 3224, name: "Handheld Circular Saws", parent: 1235, level: 5 },
  3226: { id: 3226, name: "Golf Toys", parent: 1266, level: 5 },
  3227: { id: 3227, name: "Disc Golf Baskets", parent: 3484, level: 6 },
  3229: {
    id: 3229,
    name: "Pretend Play",
    children: [3680, 3659, 4004, 3288, 3129, 8295, 3298, 3751],
    parent: 1253,
    level: 4,
  },
  3230: {
    id: 3230,
    name: "Automated External Defibrillators",
    parent: 3477,
    level: 5,
  },
  3232: { id: 3232, name: "Watercraft Manifolds", parent: 3619, level: 6 },
  3234: { id: 3234, name: "Paintball Guns", parent: 1049, level: 7 },
  3235: { id: 3235, name: "Boxing Gloves & Mitts", parent: 4008, level: 6 },
  3237: { id: 3237, name: "Live Animals", parent: 1, level: 3 },
  3238: { id: 3238, name: "Aquariums", parent: 6, level: 5 },
  3239: { id: 3239, name: "Coffee Decanters", parent: 3988, level: 6 },
  3240: {
    id: 3240,
    name: "Sandpaper & Sanding Sponges",
    parent: 4487,
    level: 5,
  },
  3241: { id: 3241, name: "Skate Lace Tighteners", parent: 3791, level: 6 },
  3242: { id: 3242, name: "Wireless Transmitters", parent: 3727, level: 5 },
  3243: { id: 3243, name: "Bicycle Computers", parent: 3214, level: 6 },
  3244: { id: 3244, name: "Paintball Air Tanks", parent: 3187, level: 8 },
  3245: { id: 3245, name: "Air Hockey Tables", parent: 1002, level: 5 },
  3246: { id: 3246, name: "Bicycle Gloves", parent: 3982, level: 6 },
  3247: {
    id: 3247,
    name: "American Football Chin Straps",
    parent: 3060,
    level: 7,
  },
  3248: { id: 3248, name: "Meat Tenderizers", parent: 668, level: 5 },
  3250: { id: 3250, name: "Dart Tips", parent: 3327, level: 6 },
  3252: { id: 3252, name: "Toy Instruments", parent: 1264, level: 5 },
  3253: { id: 3253, name: "Officiating Uniforms", parent: 3598, level: 6 },
  3256: {
    id: 3256,
    name: "Kitchen Scrapers",
    children: [3419, 3086, 3633],
    parent: 668,
    level: 5,
  },
  3257: {
    id: 3257,
    name: "Horse Care",
    children: [6898, 5569, 7482, 499817, 5025, 7481, 7459, 499819],
    parent: 1031,
    level: 5,
  },
  3258: { id: 3258, name: "Melon Ballers", parent: 3175, level: 6 },
  3260: { id: 3260, name: "Bowling Wrist Supports", parent: 1004, level: 5 },
  3261: {
    id: 3261,
    name: "Fencing Protective Gear",
    children: [3366, 499740, 3707],
    parent: 1006,
    level: 5,
  },
  3262: { id: 3262, name: "World Globes", parent: 696, level: 4 },
  3263: { id: 3263, name: "Toy Parachutes", parent: 1261, level: 5 },
  3265: { id: 3265, name: "Riding Crops & Whips", parent: 5594, level: 6 },
  3266: {
    id: 3266,
    name: "Climbing Protection Devices",
    parent: 7059,
    level: 5,
  },
  3268: { id: 3268, name: "Dish Racks & Drain Boards", parent: 668, level: 5 },
  3269: { id: 3269, name: "Ballet Barres", parent: 989, level: 5 },
  3270: { id: 3270, name: "Electronic Tuners", parent: 55, level: 5 },
  3271: { id: 3271, name: "Weight Bars", parent: 6452, level: 6 },
  3272: { id: 3272, name: "Bicycle Shoe Covers", parent: 3982, level: 6 },
  3273: { id: 3273, name: "Money Changers", parent: 4181, level: 5 },
  3276: {
    id: 3276,
    name: "Skateboarding",
    children: [3127, 3626, 3670, 3067, 1059],
    parent: 1011,
    level: 4,
  },
  3277: {
    id: 3277,
    name: "Watercraft Pistons & Parts",
    parent: 3606,
    level: 6,
  },
  3280: { id: 3280, name: "Tool Cabinets & Chests", parent: 3974, level: 5 },
  3281: {
    id: 3281,
    name: "Soldering Iron Accessories",
    children: [3629, 3609],
    parent: 3650,
    level: 4,
  },
  3282: {
    id: 3282,
    name: "Wakeboarding",
    children: [505317, 505291, 3353],
    parent: 3195,
    level: 6,
  },
  3283: { id: 3283, name: "GPS Map Data & Software", parent: 313, level: 4 },
  3284: { id: 3284, name: "Jerky", parent: 423, level: 5 },
  3285: { id: 3285, name: "Windsurfing Board Masts", parent: 3624, level: 7 },
  3287: { id: 3287, name: "Interlocking Blocks", parent: 1254, level: 5 },
  3288: { id: 3288, name: "Pretend Lawn & Garden", parent: 3229, level: 5 },
  3289: { id: 3289, name: "Sit-Down Hydrofoils", parent: 1146, level: 7 },
  3291: {
    id: 3291,
    name: "Arrow Parts & Accessories",
    children: [499831, 499832, 499830],
    parent: 1033,
    level: 6,
  },
  3292: { id: 3292, name: "Bicycle Saddles", parent: 3618, level: 6 },
  3293: { id: 3293, name: "Water Coolers", parent: 730, level: 5 },
  3294: { id: 3294, name: "Mashers", parent: 668, level: 5 },
  3295: { id: 3295, name: "Tennis Racquet Grommets", parent: 3658, level: 6 },
  3296: {
    id: 3296,
    name: "Toy Trucks & Construction Vehicles",
    parent: 2505,
    level: 5,
  },
  3297: {
    id: 3297,
    name: "Punching & Training Bags",
    parent: 499720,
    level: 6,
  },
  3298: {
    id: 3298,
    name: "Toy Kitchens & Play Food",
    children: [543624, 543690, 543622, 543623],
    parent: 3229,
    level: 5,
  },
  3300: { id: 3300, name: "Router Tables", parent: 3744, level: 5 },
  3301: { id: 3301, name: "Kaleidoscopes", parent: 3562, level: 5 },
  3304: { id: 3304, name: "Swim Gloves", parent: 1144, level: 6 },
  3305: { id: 3305, name: "Clay Pigeon Throwers", parent: 3125, level: 6 },
  3306: { id: 3306, name: "Microphone Accessories", parent: 1420, level: 5 },
  3307: { id: 3307, name: "Rug Pads", parent: 630, level: 4 },
  3308: {
    id: 3308,
    name: "Watercraft Steering Cables",
    parent: 3995,
    level: 6,
  },
  3309: {
    id: 3309,
    name: "Watercraft Mufflers & Parts",
    parent: 3619,
    level: 6,
  },
  3311: { id: 3311, name: "Riding Mowers", parent: 694, level: 6 },
  3314: { id: 3314, name: "Climbing Helmets", parent: 499815, level: 6 },
  3315: {
    id: 3315,
    name: "Docking & Anchoring",
    children: [3452, 3362, 3480, 3189, 3655, 3718, 3572, 3899],
    parent: 3391,
    level: 5,
  },
  3318: { id: 3318, name: "Motor Vehicle Lighting", parent: 899, level: 5 },
  3319: { id: 3319, name: "Cotton Candy Machines", parent: 730, level: 5 },
  3320: { id: 3320, name: "Surfboards", parent: 1143, level: 6 },
  3321: {
    id: 3321,
    name: "Watercraft Engine Controls",
    parent: 3606,
    level: 6,
  },
  3322: { id: 3322, name: "Climbing Crash Pads", parent: 7059, level: 5 },
  3324: { id: 3324, name: "Sustain Pedals", parent: 60, level: 6 },
  3326: { id: 3326, name: "Vehicle Jumper Cables", parent: 8236, level: 6 },
  3327: {
    id: 3327,
    name: "Dart Parts",
    children: [3766, 3109, 3250],
    parent: 1005,
    level: 5,
  },
  3328: {
    id: 3328,
    name: "Cable Management",
    children: [3764, 500036, 6402, 5273, 499686, 6780, 4016],
    parent: 2082,
    level: 4,
  },
  3329: { id: 3329, name: "LED Light Bulbs", parent: 2425, level: 5 },
  3330: {
    id: 3330,
    name: "Serving Pitchers & Carafes",
    parent: 4026,
    level: 6,
  },
  3331: { id: 3331, name: "Cross-Country Skis", parent: 6064, level: 7 },
  3332: { id: 3332, name: "Compound Bows", parent: 499825, level: 7 },
  3334: {
    id: 3334,
    name: "Fishing",
    children: [
      8064, 5406, 6495, 7342, 7344, 1037, 3614, 8092, 4926, 8093, 4927, 7343,
      499823, 7221, 7217, 3096, 1041,
    ],
    parent: 1011,
    level: 4,
  },
  3336: {
    id: 3336,
    name: "Lacrosse Stick Parts",
    children: [3785, 3418, 3423],
    parent: 499741,
    level: 5,
  },
  3337: { id: 3337, name: "Bread Boxes & Bags", parent: 2626, level: 5 },
  3339: { id: 3339, name: "Cricket Gloves", parent: 499736, level: 6 },
  3340: { id: 3340, name: "Leaf Blowers", parent: 3798, level: 5 },
  3341: { id: 3341, name: "Bicycle Baskets", parent: 3214, level: 6 },
  3343: {
    id: 3343,
    name: "American Football Helmet Padding",
    parent: 3060,
    level: 7,
  },
  3345: { id: 3345, name: "Ping Pong Tables", parent: 1008, level: 5 },
  3347: { id: 3347, name: "Basters", parent: 668, level: 5 },
  3348: {
    id: 3348,
    name: "Household Appliance Accessories",
    children: [
      2367, 3410, 4667, 5089, 4548, 6773, 7110, 3862, 3456, 4650, 618, 2751,
    ],
    parent: 536,
    level: 3,
  },
  3350: { id: 3350, name: "Water Skis", parent: 1146, level: 7 },
  3352: {
    id: 3352,
    name: "Racquet Vibration Dampeners",
    parent: 3658,
    level: 6,
  },
  3353: { id: 3353, name: "Wakeboards", parent: 3282, level: 7 },
  3354: {
    id: 3354,
    name: "Cheerleading",
    children: [3953],
    parent: 499713,
    level: 4,
  },
  3355: { id: 3355, name: "Household Thermometers", parent: 630, level: 4 },
  3356: {
    id: 3356,
    name: "Arcade Equipment",
    children: [8085, 3946, 3140, 3681, 3676, 3117],
    parent: 222,
    level: 3,
  },
  3358: { id: 3358, name: "Folding Chairs & Stools", parent: 443, level: 4 },
  3359: { id: 3359, name: "Fishing Hooks", parent: 499823, level: 6 },
  3360: { id: 3360, name: "Swim Goggles & Masks", parent: 1144, level: 6 },
  3361: {
    id: 3361,
    name: "Beer Pong",
    children: [3440],
    parent: 3735,
    level: 6,
  },
  3362: { id: 3362, name: "Anchor Lines & Ropes", parent: 3315, level: 6 },
  3363: { id: 3363, name: "Belay Devices", parent: 7059, level: 5 },
  3364: { id: 3364, name: "Wheelchairs", parent: 520, level: 6 },
  3366: { id: 3366, name: "Fencing Gloves & Cuffs", parent: 3261, level: 6 },
  3367: {
    id: 3367,
    name: "Cat Food",
    children: [543684, 543683],
    parent: 4,
    level: 5,
  },
  3368: { id: 3368, name: "Bicycle Mirrors", parent: 3214, level: 6 },
  3369: { id: 3369, name: "Climbing Webbing", parent: 7059, level: 5 },
  3370: {
    id: 3370,
    name: "Kneeboarding",
    children: [3101],
    parent: 3195,
    level: 6,
  },
  3371: { id: 3371, name: "American Football Toys", parent: 1266, level: 5 },
  3373: { id: 3373, name: "Sugar Bowls & Creamers", parent: 4026, level: 6 },
  3374: { id: 3374, name: "Bow Rosin", parent: 4806, level: 7 },
  3375: { id: 3375, name: "Ping Pong Paddles & Sets", parent: 1008, level: 5 },
  3376: {
    id: 3376,
    name: "Rash Guards & Swim Shirts",
    parent: 499813,
    level: 6,
  },
  3378: { id: 3378, name: "Toy Gliders", parent: 1261, level: 5 },
  3379: { id: 3379, name: "Softball Uniforms", parent: 3598, level: 6 },
  3381: {
    id: 3381,
    name: "Food Crackers",
    children: [3586, 3685],
    parent: 668,
    level: 5,
  },
  3382: {
    id: 3382,
    name: "Outdoor Grill Racks & Toppers",
    parent: 3684,
    level: 6,
  },
  3385: { id: 3385, name: "Garlic Presses", parent: 668, level: 5 },
  3387: { id: 3387, name: "Flash Memory Cards", parent: 384, level: 6 },
  3388: { id: 3388, name: "Rakes", parent: 3173, level: 6 },
  3389: { id: 3389, name: "Javelins", parent: 1060, level: 5 },
  3390: {
    id: 3390,
    name: "Pickleball",
    children: [499848, 499847],
    parent: 499846,
    level: 5,
  },
  3391: {
    id: 3391,
    name: "Watercraft Parts & Accessories",
    children: [3315, 1132, 1122, 3606, 1125, 3619, 3400, 6293, 3995],
    parent: 5613,
    level: 4,
  },
  3392: { id: 3392, name: "Guitar Humidifiers", parent: 3502, level: 7 },
  3395: { id: 3395, name: "Aircraft", parent: 5614, level: 4 },
  3400: {
    id: 3400,
    name: "Watercraft Fuel Systems",
    children: [3415, 3968, 3892, 3648],
    parent: 3391,
    level: 5,
  },
  3402: { id: 3402, name: "Humidifier Filters", parent: 3862, level: 5 },
  3403: {
    id: 3403,
    name: "Tennis Racquet Grips & Tape",
    parent: 3658,
    level: 6,
  },
  3405: { id: 3405, name: "Lawn Games", parent: 499846, level: 5 },
  3406: { id: 3406, name: "Row Boats", parent: 1120, level: 6 },
  3407: { id: 3407, name: "Hair Straighteners", parent: 6019, level: 6 },
  3408: { id: 3408, name: "Paintball Grenades", parent: 1049, level: 7 },
  3410: {
    id: 3410,
    name: "Air Purifier Accessories",
    children: [3667],
    parent: 3348,
    level: 4,
  },
  3411: { id: 3411, name: "Boxing Ring Parts", parent: 499719, level: 5 },
  3412: { id: 3412, name: "Electric Guitar Pickups", parent: 3502, level: 7 },
  3413: { id: 3413, name: "Windsurfing Sails", parent: 1148, level: 6 },
  3414: { id: 3414, name: "Security Uniforms", parent: 2306, level: 5 },
  3415: {
    id: 3415,
    name: "Watercraft Fuel Lines & Parts",
    parent: 3400,
    level: 6,
  },
  3416: { id: 3416, name: "Development Boards", parent: 7264, level: 5 },
  3418: { id: 3418, name: "Lacrosse Stick Heads", parent: 3336, level: 6 },
  3419: { id: 3419, name: "Bench Scrapers", parent: 3256, level: 6 },
  3421: { id: 3421, name: "Pizza Cutters", parent: 668, level: 5 },
  3422: {
    id: 3422,
    name: "Memory Accessories",
    children: [3672],
    parent: 2082,
    level: 4,
  },
  3423: { id: 3423, name: "Lacrosse Stick Shafts", parent: 3336, level: 6 },
  3424: { id: 3424, name: "Resistors", parent: 3635, level: 5 },
  3425: { id: 3425, name: "Print Servers", parent: 342, level: 4 },
  3426: { id: 3426, name: "Bridles", parent: 5593, level: 6 },
  3427: { id: 3427, name: "Bottle Openers", parent: 651, level: 6 },
  3428: { id: 3428, name: "Bicycle Stands & Storage", parent: 3214, level: 6 },
  3430: { id: 3430, name: "Basting Brushes", parent: 668, level: 5 },
  3435: { id: 3435, name: "Canteens", parent: 2920, level: 5 },
  3436: {
    id: 3436,
    name: "Vehicle Covers",
    children: [8306, 8316, 8308, 2494, 7031, 8309],
    parent: 913,
    level: 5,
  },
  3437: { id: 3437, name: "Whips", parent: 780, level: 4 },
  3438: { id: 3438, name: "Paintballs", parent: 1049, level: 7 },
  3439: { id: 3439, name: "Basketball Uniforms", parent: 3598, level: 6 },
  3440: { id: 3440, name: "Beer Pong Tables", parent: 3361, level: 7 },
  3441: {
    id: 3441,
    name: "Rocking & Spring Riding Toys",
    parent: 2799,
    level: 5,
  },
  3442: { id: 3442, name: "American Football Gloves", parent: 1093, level: 5 },
  3443: {
    id: 3443,
    name: "Dishwasher Parts & Accessories",
    parent: 2901,
    level: 5,
  },
  3444: { id: 3444, name: "Toy Airplanes", parent: 2505, level: 5 },
  3445: { id: 3445, name: "High Jump Crossbars", parent: 1060, level: 5 },
  3446: { id: 3446, name: "Pretzels", parent: 423, level: 5 },
  3450: { id: 3450, name: "Coffee Filters", parent: 3988, level: 6 },
  3452: { id: 3452, name: "Anchor Chains", parent: 3315, level: 6 },
  3454: {
    id: 3454,
    name: "Climbing Ascenders & Descenders",
    parent: 7059,
    level: 5,
  },
  3455: { id: 3455, name: "Bicycle Jerseys", parent: 5697, level: 6 },
  3456: {
    id: 3456,
    name: "Laundry Appliance Accessories",
    children: [5158, 5159, 5160, 500085],
    parent: 3348,
    level: 4,
  },
  3457: { id: 3457, name: "Stationery", parent: 956, level: 5 },
  3459: { id: 3459, name: "Push & Pull Toys", parent: 2847, level: 4 },
  3460: { id: 3460, name: "Kites", parent: 1261, level: 5 },
  3461: { id: 3461, name: "KVM Cables", parent: 259, level: 5 },
  3463: {
    id: 3463,
    name: "Watercraft Carburetors & Parts",
    parent: 3606,
    level: 6,
  },
  3465: {
    id: 3465,
    name: "Percussion Accessories",
    children: [7100, 7231, 7153, 7152, 7099, 7150, 59, 7455, 7282, 4631, 7308],
    parent: 55,
    level: 5,
  },
  3466: { id: 3466, name: "Spinning Tops", parent: 4352, level: 5 },
  3467: { id: 3467, name: "Rolling Pins", parent: 668, level: 5 },
  3469: { id: 3469, name: "Billiard Table Lights", parent: 1003, level: 5 },
  3470: {
    id: 3470,
    name: "Shaper Accessories",
    children: [3210],
    parent: 3650,
    level: 4,
  },
  3472: {
    id: 3472,
    name: "Motor Vehicle Carrying Racks",
    children: [6041, 2836, 6047, 4240, 6046, 7115, 6044, 6043, 6042],
    parent: 8237,
    level: 5,
  },
  3473: { id: 3473, name: "Music Boxes", parent: 696, level: 4 },
  3474: { id: 3474, name: "Toy Race Car & Track Sets", parent: 2505, level: 5 },
  3475: { id: 3475, name: "Measuring Cups & Spoons", parent: 668, level: 5 },
  3476: { id: 3476, name: "Whitewater Rafts", parent: 1120, level: 6 },
  3477: {
    id: 3477,
    name: "Medical Equipment",
    children: [3230, 503006, 6972, 499858, 4245, 7522, 4364, 6714, 6280],
    parent: 2496,
    level: 4,
  },
  3478: { id: 3478, name: "Discus", parent: 1060, level: 5 },
  3479: { id: 3479, name: "Drinkware Holders", parent: 2948, level: 6 },
  3480: { id: 3480, name: "Anchor Windlasses", parent: 3315, level: 6 },
  3484: {
    id: 3484,
    name: "Disc Golf",
    children: [3993, 3227],
    parent: 499846,
    level: 5,
  },
  3487: { id: 3487, name: "Rugby Gloves", parent: 1110, level: 5 },
  3488: { id: 3488, name: "Skateboarding Pads", parent: 3067, level: 6 },
  3489: { id: 3489, name: "Breadmaker Accessories", parent: 2901, level: 5 },
  3491: { id: 3491, name: "Sprinkler Valves", parent: 3780, level: 6 },
  3492: {
    id: 3492,
    name: "American Football Goal Posts",
    parent: 1093,
    level: 5,
  },
  3494: { id: 3494, name: "Reciprocating Saws", parent: 1235, level: 5 },
  3495: { id: 3495, name: "Hunting & Survival Knives", parent: 3937, level: 6 },
  3497: {
    id: 3497,
    name: "American Football Neck Rolls",
    parent: 1097,
    level: 6,
  },
  3498: { id: 3498, name: "Bowls", parent: 673, level: 6 },
  3500: {
    id: 3500,
    name: "Science & Exploration Sets",
    parent: 1262,
    level: 5,
  },
  3501: { id: 3501, name: "Post Hole Diggers", parent: 1167, level: 4 },
  3502: {
    id: 3502,
    name: "Guitar Accessories",
    children: [
      3775, 5367, 3412, 3882, 503032, 3392, 4111, 5368, 3646, 499688, 503721,
      3178, 3176,
    ],
    parent: 61,
    level: 6,
  },
  3505: { id: 3505, name: "Crossbows", parent: 499825, level: 7 },
  3506: { id: 3506, name: "Toy Helicopters", parent: 2505, level: 5 },
  3507: { id: 3507, name: "Watercraft Motor Locks", parent: 3606, level: 6 },
  3508: { id: 3508, name: "Chemical Hand Warmers", parent: 1013, level: 5 },
  3509: { id: 3509, name: "Temporary Tattoos", parent: 477, level: 6 },
  3510: { id: 3510, name: "American Football Girdles", parent: 1097, level: 6 },
  3512: { id: 3512, name: "Mobility Scooters", parent: 520, level: 6 },
  3515: {
    id: 3515,
    name: "Sports Fan Accessories",
    children: [1095, 1051, 1074, 1084, 4006, 3576, 6187],
    parent: 3865,
    level: 6,
  },
  3516: { id: 3516, name: "Cut-Off Saws", parent: 1235, level: 5 },
  3517: { id: 3517, name: "Miter Saws", parent: 1235, level: 5 },
  3518: { id: 3518, name: "Indoor Climbing Holds", parent: 7059, level: 5 },
  3521: { id: 3521, name: "Food Peelers & Corers", parent: 668, level: 5 },
  3522: { id: 3522, name: "Lighting Timers", parent: 2956, level: 4 },
  3523: {
    id: 3523,
    name: "Vacuum Sealer Accessories",
    children: [3124],
    parent: 2901,
    level: 5,
  },
  3524: {
    id: 3524,
    name: "Foosball Table Parts & Accessories",
    parent: 1007,
    level: 5,
  },
  3525: { id: 3525, name: "Surfboard Fins", parent: 1143, level: 6 },
  3526: { id: 3526, name: "Milk Frothers & Steamers", parent: 730, level: 5 },
  3528: { id: 3528, name: "Clay Pigeons", parent: 3125, level: 6 },
  3530: {
    id: 3530,
    name: "Dog Food",
    children: [543682, 543681],
    parent: 5,
    level: 5,
  },
  3531: { id: 3531, name: "Tricycles", parent: 1025, level: 5 },
  3532: {
    id: 3532,
    name: "Remote Control Boats & Watercraft",
    parent: 2546,
    level: 5,
  },
  3533: { id: 3533, name: "Arrows & Bolts", parent: 1033, level: 6 },
  3534: { id: 3534, name: "Marbles", parent: 4352, level: 5 },
  3535: { id: 3535, name: "Bowling Gloves", parent: 1004, level: 5 },
  3536: { id: 3536, name: "Lacrosse Balls", parent: 499741, level: 5 },
  3538: {
    id: 3538,
    name: "Mosquito Nets & Insect Screens",
    parent: 1013,
    level: 5,
  },
  3539: { id: 3539, name: "E-Book Readers", parent: 298, level: 5 },
  3540: {
    id: 3540,
    name: "Watercraft",
    children: [3095, 1130, 3087, 5644],
    parent: 5614,
    level: 4,
  },
  3541: { id: 3541, name: "Telephone Cables", parent: 259, level: 5 },
  3542: {
    id: 3542,
    name: "Weight Lifting Machines & Racks",
    parent: 499793,
    level: 5,
  },
  3543: { id: 3543, name: "Cricket Helmets", parent: 499736, level: 6 },
  3544: {
    id: 3544,
    name: "Baseball & Softball Bases & Plates",
    parent: 1070,
    level: 5,
  },
  3546: { id: 3546, name: "Ping Pong Robots", parent: 1008, level: 5 },
  3547: { id: 3547, name: "Billiard Table Cloth", parent: 3183, level: 6 },
  3548: { id: 3548, name: "Air Hockey Table Parts", parent: 1002, level: 5 },
  3549: { id: 3549, name: "Snowmobiles", parent: 1267, level: 5 },
  3550: { id: 3550, name: "Ski & Snowboard Goggles", parent: 499845, level: 6 },
  3551: { id: 3551, name: "Toy Cars", parent: 2505, level: 5 },
  3552: { id: 3552, name: "Basketball Toys", parent: 1266, level: 5 },
  3553: { id: 3553, name: "Plates", parent: 673, level: 6 },
  3554: {
    id: 3554,
    name: "Remote Control Helicopters",
    parent: 2546,
    level: 5,
  },
  3556: { id: 3556, name: "Water Tables", parent: 6464, level: 5 },
  3558: {
    id: 3558,
    name: "Bicycle Transport Bags & Cases",
    parent: 3214,
    level: 6,
  },
  3559: { id: 3559, name: "Dartboards", parent: 1005, level: 5 },
  3561: { id: 3561, name: "Household Storage Bags", parent: 636, level: 5 },
  3562: {
    id: 3562,
    name: "Visual Toys",
    children: [3301, 3782],
    parent: 1253,
    level: 4,
  },
  3565: { id: 3565, name: "Tennis Ball Savers", parent: 1065, level: 5 },
  3566: { id: 3566, name: "Watercraft Motor Mounts", parent: 3606, level: 6 },
  3568: {
    id: 3568,
    name: "Watering & Irrigation",
    children: [4718, 4201, 2313, 3780, 7561, 505814, 6318, 230912],
    parent: 689,
    level: 4,
  },
  3572: { id: 3572, name: "Dock Cleats", parent: 3315, level: 6 },
  3573: { id: 3573, name: "Air Conditioner Filters", parent: 2367, level: 5 },
  3574: { id: 3574, name: "Billiard Pockets", parent: 3183, level: 6 },
  3575: { id: 3575, name: "Water Polo Caps", parent: 1145, level: 5 },
  3576: { id: 3576, name: "Soccer Fan Accessories", parent: 3515, level: 7 },
  3577: {
    id: 3577,
    name: "Homebrewing & Winemaking Supplies",
    children: [3014, 502980, 499891, 2579],
    parent: 5710,
    level: 4,
  },
  3578: { id: 3578, name: "Golf Flags", parent: 1043, level: 5 },
  3579: { id: 3579, name: "Surfboard Cases & Bags", parent: 1143, level: 6 },
  3580: { id: 3580, name: "Memory Card Readers", parent: 1928, level: 6 },
  3582: { id: 3582, name: "Band Saws", parent: 1235, level: 5 },
  3583: { id: 3583, name: "Hunting & Wildlife Decoys", parent: 8011, level: 7 },
  3584: {
    id: 3584,
    name: "Doll & Action Figure Accessories",
    parent: 1255,
    level: 5,
  },
  3586: { id: 3586, name: "Lobster & Crab Crackers", parent: 3381, level: 6 },
  3588: { id: 3588, name: "Musical Keyboard Stands", parent: 60, level: 6 },
  3589: { id: 3589, name: "Toy Spaceships", parent: 2505, level: 5 },
  3590: { id: 3590, name: "Toy Motorcycles", parent: 2505, level: 5 },
  3591: { id: 3591, name: "Food Storage Bags", parent: 2626, level: 5 },
  3594: { id: 3594, name: "Hand Saws", parent: 1235, level: 5 },
  3595: { id: 3595, name: "Pull Buoys", parent: 1144, level: 6 },
  3596: { id: 3596, name: "Swimming Machines", parent: 1144, level: 6 },
  3597: { id: 3597, name: "Whisks", parent: 668, level: 5 },
  3598: {
    id: 3598,
    name: "Sports Uniforms",
    children: [
      3888, 3191, 3439, 3683, 3724, 3958, 4003, 3253, 5564, 3379, 3852,
    ],
    parent: 2306,
    level: 5,
  },
  3599: { id: 3599, name: "Autographs", parent: 216, level: 5 },
  3601: {
    id: 3601,
    name: "Remote Control Cars & Trucks",
    parent: 2546,
    level: 5,
  },
  3602: { id: 3602, name: "Barometers", parent: 1305, level: 5 },
  3603: { id: 3603, name: "Fishing Baits & Lures", parent: 499823, level: 6 },
  3606: {
    id: 3606,
    name: "Watercraft Engine Parts",
    children: [3143, 3463, 3321, 3743, 3097, 3507, 3566, 3277, 3806],
    parent: 3391,
    level: 5,
  },
  3609: { id: 3609, name: "Soldering Iron Tips", parent: 3281, level: 5 },
  3610: { id: 3610, name: "Chainsaws", parent: 3798, level: 5 },
  3614: { id: 3614, name: "Fishing Nets", parent: 3334, level: 5 },
  3615: { id: 3615, name: "Hockey Pants", parent: 1105, level: 6 },
  3616: { id: 3616, name: "Wheelbarrows", parent: 3173, level: 6 },
  3617: { id: 3617, name: "Wooden Blocks", parent: 1254, level: 5 },
  3618: {
    id: 3618,
    name: "Bicycle Parts",
    children: [
      3740, 499684, 499685, 4585, 4603, 3639, 499868, 6960, 4582, 7478, 7477,
      8239, 3292, 4595, 4194, 4596, 4583, 499871, 499869, 499870, 4571, 4572,
      4597, 3216,
    ],
    parent: 1025,
    level: 5,
  },
  3619: {
    id: 3619,
    name: "Watercraft Exhaust Parts",
    children: [3232, 3309],
    parent: 3391,
    level: 5,
  },
  3620: { id: 3620, name: "Ladles", parent: 668, level: 5 },
  3621: {
    id: 3621,
    name: "American Football Shoulder Pads",
    parent: 1097,
    level: 6,
  },
  3622: { id: 3622, name: "Fencing Weapons", parent: 1006, level: 5 },
  3623: { id: 3623, name: "Ice Skate Sharpeners", parent: 3791, level: 6 },
  3624: {
    id: 3624,
    name: "Windsurfing Board Parts",
    children: [3908, 3285],
    parent: 1148,
    level: 6,
  },
  3625: { id: 3625, name: "Robotic Toys", parent: 1253, level: 4 },
  3626: { id: 3626, name: "Skate Ramps", parent: 3276, level: 5 },
  3627: { id: 3627, name: "Toy Weapons & Gadgets", parent: 1253, level: 4 },
  3629: { id: 3629, name: "Soldering Iron Stands", parent: 3281, level: 5 },
  3631: { id: 3631, name: "Bicycle Training Wheels", parent: 3214, level: 6 },
  3632: { id: 3632, name: "Diodes", parent: 3991, level: 5 },
  3633: { id: 3633, name: "Grill Scrapers", parent: 3256, level: 6 },
  3634: { id: 3634, name: "Tricycle Accessories", parent: 1025, level: 5 },
  3635: {
    id: 3635,
    name: "Passive Circuit Components",
    children: [3220, 7260, 3121, 3424],
    parent: 3702,
    level: 4,
  },
  3636: { id: 3636, name: "Water Sport Tow Cables", parent: 3195, level: 6 },
  3637: { id: 3637, name: "Skateboard Wheels", parent: 3670, level: 6 },
  3638: { id: 3638, name: "Tennis Racquet Bags", parent: 3658, level: 6 },
  3639: { id: 3639, name: "Bicycle Frames", parent: 3618, level: 6 },
  3640: { id: 3640, name: "Yoga & Pilates Mats", parent: 999, level: 5 },
  3641: { id: 3641, name: "Foosball Balls", parent: 1007, level: 5 },
  3642: {
    id: 3642,
    name: "Golf Club Parts & Accessories",
    children: [4254, 4043, 499780],
    parent: 1043,
    level: 5,
  },
  3644: { id: 3644, name: "Gardening Trowels", parent: 3173, level: 6 },
  3646: { id: 3646, name: "Guitar Stands", parent: 3502, level: 7 },
  3648: {
    id: 3648,
    name: "Watercraft Fuel Tanks & Parts",
    parent: 3400,
    level: 6,
  },
  3649: { id: 3649, name: "Surf Leashes", parent: 1143, level: 6 },
  3650: {
    id: 3650,
    name: "Tool Accessories",
    children: [
      6939, 7326, 8117, 3944, 6471, 2447, 499859, 7056, 2380, 6907, 7472,
      505323, 5526, 499886, 7019, 6295, 6292, 3744, 4487, 6549, 3470, 3281,
      2174, 505810, 8258, 5571, 4658, 505812, 499947,
    ],
    parent: 632,
    level: 3,
  },
  3651: { id: 3651, name: "Fishing Sinkers", parent: 499823, level: 6 },
  3652: { id: 3652, name: "Boxing Rings", parent: 499719, level: 5 },
  3654: { id: 3654, name: "Weight Lifting Belts", parent: 499793, level: 5 },
  3655: { id: 3655, name: "Boat Hooks", parent: 3315, level: 6 },
  3656: {
    id: 3656,
    name: "American Football Kicking Tees & Holders",
    parent: 1093,
    level: 5,
  },
  3658: {
    id: 3658,
    name: "Tennis Racquet Accessories",
    children: [3352, 3638, 3403, 3295, 3922],
    parent: 1065,
    level: 5,
  },
  3659: { id: 3659, name: "Pretend Electronics", parent: 3229, level: 5 },
  3661: { id: 3661, name: "Alphabet Toys", parent: 2847, level: 4 },
  3663: {
    id: 3663,
    name: "Watercraft Steering Wheels",
    parent: 3995,
    level: 6,
  },
  3665: { id: 3665, name: "Bowling Toys", parent: 1266, level: 5 },
  3666: { id: 3666, name: "Swords", parent: 780, level: 4 },
  3667: { id: 3667, name: "Air Purifier Filters", parent: 3410, level: 5 },
  3668: {
    id: 3668,
    name: "Baseball & Softball Batting Helmets",
    parent: 1078,
    level: 6,
  },
  3669: { id: 3669, name: "Bowling Pins", parent: 1004, level: 5 },
  3670: {
    id: 3670,
    name: "Skateboard Parts",
    children: [3869, 505817, 3192, 3637],
    parent: 3276,
    level: 5,
  },
  3671: { id: 3671, name: "Softballs", parent: 1070, level: 5 },
  3672: { id: 3672, name: "Memory Cases", parent: 3422, level: 5 },
  3673: { id: 3673, name: "Router Bits", parent: 3744, level: 5 },
  3674: { id: 3674, name: "Animal Traps", parent: 3136, level: 6 },
  3675: { id: 3675, name: "Boomerangs", parent: 1266, level: 5 },
  3676: {
    id: 3676,
    name: "Video Game Arcade Cabinet Accessories",
    parent: 3356,
    level: 4,
  },
  3677: { id: 3677, name: "Remote Control Planes", parent: 2546, level: 5 },
  3678: { id: 3678, name: "Water Polo Goals", parent: 1145, level: 5 },
  3679: { id: 3679, name: "Softball Bats", parent: 1070, level: 5 },
  3680: { id: 3680, name: "Play Money & Banking", parent: 3229, level: 5 },
  3681: { id: 3681, name: "Skee-Ball Machines", parent: 3356, level: 4 },
  3682: { id: 3682, name: "Cigars", parent: 435, level: 4 },
  3683: { id: 3683, name: "Cheerleading Uniforms", parent: 3598, level: 6 },
  3684: {
    id: 3684,
    name: "Outdoor Grill Accessories",
    children: [5694, 7540, 5670, 3855, 3382, 505667, 4560, 5672, 5671],
    parent: 2901,
    level: 5,
  },
  3685: {
    id: 3685,
    name: "Nutcrackers",
    children: [4214],
    parent: 3381,
    level: 6,
  },
  3686: { id: 3686, name: "Incense", parent: 592, level: 5 },
  3688: {
    id: 3688,
    name: "Blood Glucose Meter Accessories",
    children: [6323, 3905, 3111],
    parent: 5071,
    level: 5,
  },
  3689: { id: 3689, name: "Deck Shuffleboard Cues", parent: 3787, level: 6 },
  3690: { id: 3690, name: "Paintball Gun Barrels", parent: 3187, level: 8 },
  3691: { id: 3691, name: "Hand Sanitizers & Wipes", parent: 474, level: 6 },
  3692: {
    id: 3692,
    name: "Clay & Modeling Dough",
    children: [543628, 543629],
    parent: 44,
    level: 7,
  },
  3694: { id: 3694, name: "Throwing Stars", parent: 780, level: 4 },
  3695: { id: 3695, name: "Air Mattresses", parent: 1014, level: 6 },
  3696: {
    id: 3696,
    name: "Floor & Grandfather Clocks",
    parent: 3890,
    level: 5,
  },
  3697: { id: 3697, name: "Sewing Patterns", parent: 505371, level: 6 },
  3698: { id: 3698, name: "Bowling Ball Bags", parent: 1004, level: 5 },
  3699: { id: 3699, name: "Chopsticks", parent: 675, level: 6 },
  3702: {
    id: 3702,
    name: "Circuit Boards & Components",
    children: [500027, 7259, 3889, 7258, 3635, 7264, 3991],
    parent: 222,
    level: 3,
  },
  3703: { id: 3703, name: "Gravy Boats", parent: 4026, level: 6 },
  3706: { id: 3706, name: "Table Saws", parent: 1235, level: 5 },
  3707: { id: 3707, name: "Fencing Masks", parent: 3261, level: 6 },
  3708: { id: 3708, name: "Ice Scoops", parent: 3175, level: 6 },
  3712: { id: 3712, name: "USB Flash Drives", parent: 2414, level: 6 },
  3713: { id: 3713, name: "Cooking Timers", parent: 668, level: 5 },
  3714: { id: 3714, name: "Racquetball Racquets", parent: 503752, level: 5 },
  3715: { id: 3715, name: "Recurve & Longbows", parent: 499825, level: 7 },
  3717: { id: 3717, name: "Martial Arts Belts", parent: 499719, level: 5 },
  3718: { id: 3718, name: "Boat Ladders", parent: 3315, level: 6 },
  3719: { id: 3719, name: "Bicycle Fenders", parent: 3214, level: 6 },
  3720: { id: 3720, name: "Billiard Cue Racks", parent: 3222, level: 6 },
  3722: { id: 3722, name: "Charging Valets", parent: 5558, level: 6 },
  3723: { id: 3723, name: "Food Dispensers", parent: 668, level: 5 },
  3724: { id: 3724, name: "Cricket Uniforms", parent: 3598, level: 6 },
  3725: { id: 3725, name: "Jigsaws", parent: 1235, level: 5 },
  3727: {
    id: 3727,
    name: "Stage Equipment",
    children: [3242],
    parent: 223,
    level: 4,
  },
  3729: { id: 3729, name: "Bicycle Tights", parent: 5697, level: 6 },
  3730: { id: 3730, name: "Walk-Behind Mowers", parent: 694, level: 6 },
  3731: {
    id: 3731,
    name: "Art & Drawing Toys",
    children: [505818, 3079],
    parent: 1253,
    level: 4,
  },
  3733: { id: 3733, name: "Bouncy Balls", parent: 4352, level: 5 },
  3735: {
    id: 3735,
    name: "Drinking Games",
    children: [3361],
    parent: 96,
    level: 5,
  },
  3738: { id: 3738, name: "Hiking Poles", parent: 1013, level: 5 },
  3740: {
    id: 3740,
    name: "Bicycle Brake Parts",
    children: [4574, 4575, 4576, 4577],
    parent: 3618,
    level: 6,
  },
  3741: { id: 3741, name: "Loose Tobacco", parent: 435, level: 4 },
  3742: {
    id: 3742,
    name: "Network Security & Firewall Devices",
    parent: 342,
    level: 4,
  },
  3743: { id: 3743, name: "Watercraft Ignition Parts", parent: 3606, level: 6 },
  3744: {
    id: 3744,
    name: "Router Accessories",
    children: [3673, 3300],
    parent: 3650,
    level: 4,
  },
  3745: { id: 3745, name: "Tool Files", parent: 1167, level: 4 },
  3746: { id: 3746, name: "Carabiners", parent: 7059, level: 5 },
  3747: {
    id: 3747,
    name: "Baseball & Softball Batting Gloves",
    parent: 1070,
    level: 5,
  },
  3748: { id: 3748, name: "Tree Stands", parent: 3136, level: 6 },
  3749: { id: 3749, name: "Bingo Sets", parent: 3793, level: 4 },
  3750: { id: 3750, name: "Plastic Wrap", parent: 3110, level: 6 },
  3751: { id: 3751, name: "Toy Tools", parent: 3229, level: 5 },
  3754: { id: 3754, name: "Billiard Table Brushes", parent: 3183, level: 6 },
  3755: { id: 3755, name: "Billiard Gloves", parent: 1003, level: 5 },
  3756: { id: 3756, name: "Hunting & Wildlife Calls", parent: 8011, level: 7 },
  3757: { id: 3757, name: "Quivers", parent: 1033, level: 6 },
  3761: { id: 3761, name: "Rugby Balls", parent: 1110, level: 5 },
  3762: { id: 3762, name: "Surfing Tail Pads", parent: 1143, level: 6 },
  3764: { id: 3764, name: "Cable Clips", parent: 3328, level: 5 },
  3766: { id: 3766, name: "Dart Flights", parent: 3327, level: 6 },
  3768: { id: 3768, name: "Baking Peels", parent: 668, level: 5 },
  3769: { id: 3769, name: "PDAs", parent: 298, level: 5 },
  3770: { id: 3770, name: "Shot Puts", parent: 1060, level: 5 },
  3772: { id: 3772, name: "Golf Training Aids", parent: 1043, level: 5 },
  3773: { id: 3773, name: "Archery Armguards", parent: 1033, level: 6 },
  3774: { id: 3774, name: "Gymnastics Rings", parent: 1000, level: 5 },
  3775: { id: 3775, name: "Acoustic Guitar Pickups", parent: 3502, level: 7 },
  3776: { id: 3776, name: "Baseball Toys", parent: 1266, level: 5 },
  3777: { id: 3777, name: "Pillboxes", parent: 491, level: 4 },
  3778: { id: 3778, name: "Bicycle Bags & Panniers", parent: 3214, level: 6 },
  3779: { id: 3779, name: "Vaulting Horses", parent: 1000, level: 5 },
  3780: {
    id: 3780,
    name: "Sprinkler Accessories",
    children: [1302, 3491],
    parent: 3568,
    level: 5,
  },
  3781: { id: 3781, name: "GPS Cases", parent: 3895, level: 4 },
  3782: { id: 3782, name: "Prisms", parent: 3562, level: 5 },
  3783: { id: 3783, name: "Baseballs", parent: 1070, level: 5 },
  3785: { id: 3785, name: "Lacrosse Mesh & String", parent: 3336, level: 6 },
  3787: {
    id: 3787,
    name: "Deck Shuffleboard",
    children: [3689, 3190],
    parent: 499846,
    level: 5,
  },
  3788: { id: 3788, name: "Ping Pong Nets & Posts", parent: 1008, level: 5 },
  3789: {
    id: 3789,
    name: "Hang Gliding & Skydiving",
    children: [5877, 4327, 4023],
    parent: 1011,
    level: 4,
  },
  3790: { id: 3790, name: "Baseball Bats", parent: 1070, level: 5 },
  3791: {
    id: 3791,
    name: "Ice Skate Parts & Accessories",
    children: [6708, 7000, 3623, 4019, 3241],
    parent: 499915,
    level: 5,
  },
  3792: { id: 3792, name: "Toy Boats", parent: 2505, level: 5 },
  3793: {
    id: 3793,
    name: "Games",
    children: [
      6794, 6329, 3749, 7411, 1246, 6853, 1247, 6054, 6037, 7383, 5403, 4554,
      7412, 8472, 6038,
    ],
    parent: 1239,
    level: 3,
  },
  3794: { id: 3794, name: "Water Polo Balls", parent: 1145, level: 5 },
  3797: { id: 3797, name: "Heat-Shrink Tubing", parent: 499768, level: 5 },
  3798: {
    id: 3798,
    name: "Outdoor Power Equipment",
    children: [
      3610, 2218, 3120, 500034, 694, 6789, 3340, 7332, 7245, 500016, 2204, 1226,
      1541, 5866, 1223,
    ],
    parent: 689,
    level: 4,
  },
  3800: { id: 3800, name: "Thermoses", parent: 2920, level: 5 },
  3801: { id: 3801, name: "Surfboard Wax", parent: 1143, level: 6 },
  3802: { id: 3802, name: "Serving Platters", parent: 4026, level: 6 },
  3803: { id: 3803, name: "Display Mannequins", parent: 138, level: 4 },
  3805: { id: 3805, name: "Construction Set Toys", parent: 1254, level: 5 },
  3806: { id: 3806, name: "Watercraft Propellers", parent: 3606, level: 6 },
  3807: { id: 3807, name: "Swim Caps", parent: 1144, level: 6 },
  3808: {
    id: 3808,
    name: "Gymnastics Protective Gear",
    children: [499781],
    parent: 1000,
    level: 5,
  },
  3809: { id: 3809, name: "Water Bottles", parent: 2920, level: 5 },
  3810: { id: 3810, name: "Pilates Machines", parent: 999, level: 5 },
  3811: { id: 3811, name: "Bicycle Trailers", parent: 3214, level: 6 },
  3812: {
    id: 3812,
    name: "Vehicle Paint",
    children: [8450, 8144],
    parent: 913,
    level: 5,
  },
  3815: { id: 3815, name: "Cricket Bats", parent: 1087, level: 5 },
  3817: { id: 3817, name: "Lacrosse Sticks", parent: 499741, level: 5 },
  3819: { id: 3819, name: "Security Safes", parent: 359, level: 4 },
  3821: { id: 3821, name: "Equestrian Helmets", parent: 5594, level: 6 },
  3825: { id: 3825, name: "Climbing Rope", parent: 7059, level: 5 },
  3827: { id: 3827, name: "Bicycle Pumps", parent: 3214, level: 6 },
  3828: { id: 3828, name: "Spreaders", parent: 3173, level: 6 },
  3829: { id: 3829, name: "Basketball Nets", parent: 4676, level: 6 },
  3831: { id: 3831, name: "Utensil & Flatware Trays", parent: 2948, level: 6 },
  3833: { id: 3833, name: "Brass Knuckles", parent: 780, level: 4 },
  3835: { id: 3835, name: "Cooling Racks", parent: 668, level: 5 },
  3838: { id: 3838, name: "Portafilters", parent: 3988, level: 6 },
  3839: { id: 3839, name: "Darts", parent: 1005, level: 5 },
  3840: { id: 3840, name: "Wall Clocks", parent: 3890, level: 5 },
  3841: { id: 3841, name: "Pruning Shears", parent: 3173, level: 6 },
  3844: { id: 3844, name: "Table Knives", parent: 675, level: 6 },
  3845: { id: 3845, name: "Pot Racks", parent: 2948, level: 6 },
  3846: { id: 3846, name: "Paper Napkins", parent: 2530, level: 5 },
  3847: { id: 3847, name: "Foosball Tables", parent: 1007, level: 5 },
  3848: { id: 3848, name: "Refrigerator Accessories", parent: 2901, level: 5 },
  3849: { id: 3849, name: "Quickdraws", parent: 7059, level: 5 },
  3850: { id: 3850, name: "Cookie Cutters", parent: 668, level: 5 },
  3852: { id: 3852, name: "Wrestling Uniforms", parent: 3598, level: 6 },
  3855: { id: 3855, name: "Outdoor Grill Covers", parent: 3684, level: 6 },
  3858: {
    id: 3858,
    name: "Weight Lifting Gloves & Hand Supports",
    parent: 499793,
    level: 5,
  },
  3859: { id: 3859, name: "Fishing Floats", parent: 499823, level: 6 },
  3860: { id: 3860, name: "Sorting & Stacking Toys", parent: 2847, level: 4 },
  3862: {
    id: 3862,
    name: "Humidifier Accessories",
    children: [3402],
    parent: 3348,
    level: 4,
  },
  3864: { id: 3864, name: "High Jump Pits", parent: 1060, level: 5 },
  3865: {
    id: 3865,
    name: "Sports Collectibles",
    children: [4333, 3515],
    parent: 216,
    level: 5,
  },
  3866: { id: 3866, name: "Watercraft Cleaners", parent: 1122, level: 6 },
  3867: {
    id: 3867,
    name: "Puzzles",
    children: [7081, 2618, 4011, 6725],
    parent: 1239,
    level: 3,
  },
  3868: { id: 3868, name: "Bicycle Trainers", parent: 3214, level: 6 },
  3869: { id: 3869, name: "Skateboard Decks", parent: 3670, level: 6 },
  3870: { id: 3870, name: "Cricket Balls", parent: 1087, level: 5 },
  3871: { id: 3871, name: "Post Cards", parent: 956, level: 5 },
  3873: { id: 3873, name: "Home Alarm Systems", parent: 359, level: 4 },
  3874: { id: 3874, name: "Bubble Blowing Toys", parent: 4352, level: 5 },
  3877: { id: 3877, name: "Sports Megaphones", parent: 499799, level: 5 },
  3878: { id: 3878, name: "Relay Batons", parent: 1060, level: 5 },
  3879: { id: 3879, name: "Bicycle Bells & Horns", parent: 3214, level: 6 },
  3880: { id: 3880, name: "Throwing Hammers", parent: 1060, level: 5 },
  3881: { id: 3881, name: "Rugby Posts", parent: 1110, level: 5 },
  3882: { id: 3882, name: "Guitar Cases & Gig Bags", parent: 3502, level: 7 },
  3883: { id: 3883, name: "Archery Targets", parent: 1033, level: 6 },
  3885: { id: 3885, name: "Model Rocketry", parent: 5999, level: 5 },
  3888: {
    id: 3888,
    name: "American Football Uniforms",
    parent: 3598,
    level: 6,
  },
  3889: {
    id: 3889,
    name: "Circuit Prototyping",
    children: [4010],
    parent: 3702,
    level: 4,
  },
  3890: {
    id: 3890,
    name: "Clocks",
    children: [4546, 6912, 3696, 3840],
    parent: 696,
    level: 4,
  },
  3892: {
    id: 3892,
    name: "Watercraft Fuel Pumps & Parts",
    parent: 3400,
    level: 6,
  },
  3893: { id: 3893, name: "Vintage Advertisements", parent: 216, level: 5 },
  3894: { id: 3894, name: "Windsurfing Boards", parent: 1148, level: 6 },
  3895: {
    id: 3895,
    name: "GPS Accessories",
    children: [3781, 3213],
    parent: 222,
    level: 3,
  },
  3898: { id: 3898, name: "Air Fresheners", parent: 592, level: 5 },
  3899: { id: 3899, name: "Dock Steps", parent: 3315, level: 6 },
  3900: {
    id: 3900,
    name: "Ping Pong Paddle Accessories",
    parent: 1008,
    level: 5,
  },
  3905: { id: 3905, name: "Blood Glucose Test Strips", parent: 3688, level: 6 },
  3906: { id: 3906, name: "Tennis Racquets", parent: 1065, level: 5 },
  3907: { id: 3907, name: "Shuttlecocks", parent: 1062, level: 6 },
  3908: { id: 3908, name: "Windsurfing Board Fins", parent: 3624, level: 7 },
  3909: { id: 3909, name: "Footbags", parent: 1266, level: 5 },
  3910: { id: 3910, name: "Billiard Cues & Bridges", parent: 1003, level: 5 },
  3911: { id: 3911, name: "Bath Toys", parent: 1253, level: 4 },
  3912: { id: 3912, name: "Microphone Stands", parent: 1420, level: 5 },
  3913: { id: 3913, name: "Belt Buckles", parent: 167, level: 4 },
  3914: { id: 3914, name: "Salad Spinners", parent: 668, level: 5 },
  3916: { id: 3916, name: "Chewing Tobacco", parent: 435, level: 4 },
  3919: { id: 3919, name: "Work Benches", parent: 3974, level: 5 },
  3922: { id: 3922, name: "Tennis Racquet String", parent: 3658, level: 6 },
  3923: { id: 3923, name: "Prayer Beads", parent: 97, level: 4 },
  3924: { id: 3924, name: "Staff & Stick Weapons", parent: 780, level: 4 },
  3925: { id: 3925, name: "Airsoft Pellets", parent: 2443, level: 7 },
  3928: { id: 3928, name: "Bug Collecting Kits", parent: 1262, level: 5 },
  3929: { id: 3929, name: "Yo-Yos", parent: 4352, level: 5 },
  3931: { id: 3931, name: "Golf Carts", parent: 1267, level: 5 },
  3932: { id: 3932, name: "Mattocks & Pickaxes", parent: 1167, level: 4 },
  3937: {
    id: 3937,
    name: "Camping Tools",
    children: [3495, 4095],
    parent: 1013,
    level: 5,
  },
  3938: { id: 3938, name: "Medicine Balls", parent: 990, level: 4 },
  3939: { id: 3939, name: "Spoons", parent: 675, level: 6 },
  3941: { id: 3941, name: "Tureens", parent: 4026, level: 6 },
  3943: { id: 3943, name: "Hockey Toys", parent: 1266, level: 5 },
  3944: {
    id: 3944,
    name: "Drill & Screwdriver Accessories",
    children: [1540, 7140, 6378, 8276, 8275, 6806],
    parent: 3650,
    level: 4,
  },
  3945: { id: 3945, name: "Microphone Preamps", parent: 246, level: 6 },
  3946: {
    id: 3946,
    name: "Pinball Machine Accessories",
    parent: 3356,
    level: 4,
  },
  3948: { id: 3948, name: "Stilts", parent: 1249, level: 4 },
  3949: { id: 3949, name: "Microcontrollers", parent: 3991, level: 5 },
  3950: { id: 3950, name: "Badminton Racquets & Sets", parent: 1062, level: 6 },
  3951: { id: 3951, name: "American Football Pants", parent: 5322, level: 5 },
  3953: { id: 3953, name: "Cheerleading Pom Poms", parent: 3354, level: 5 },
  3954: { id: 3954, name: "Deep Fryer Accessories", parent: 2901, level: 5 },
  3955: { id: 3955, name: "Watercraft Polishes", parent: 1122, level: 6 },
  3956: { id: 3956, name: "Wax Paper", parent: 3110, level: 6 },
  3957: { id: 3957, name: "Dart Backboards", parent: 1005, level: 5 },
  3958: { id: 3958, name: "Hockey Uniforms", parent: 3598, level: 6 },
  3961: { id: 3961, name: "Tennis Nets", parent: 1065, level: 5 },
  3964: { id: 3964, name: "Ping Pong Balls", parent: 1008, level: 5 },
  3965: { id: 3965, name: "Nut Butters", parent: 5740, level: 5 },
  3966: { id: 3966, name: "Air & Water Rockets", parent: 1261, level: 5 },
  3968: { id: 3968, name: "Watercraft Fuel Meters", parent: 3400, level: 6 },
  3970: { id: 3970, name: "Lacrosse Goals", parent: 499741, level: 5 },
  3971: { id: 3971, name: "Grip Spray & Chalk", parent: 499799, level: 5 },
  3973: { id: 3973, name: "Soccer Corner Flags", parent: 1111, level: 5 },
  3974: {
    id: 3974,
    name: "Tool Storage & Organization",
    children: [4199, 2485, 6876, 3980, 3280, 500103, 4031, 3919],
    parent: 2878,
    level: 4,
  },
  3977: {
    id: 3977,
    name: "Aircraft Parts & Accessories",
    parent: 5613,
    level: 4,
  },
  3980: { id: 3980, name: "Tool Boxes", parent: 3974, level: 5 },
  3982: {
    id: 3982,
    name: "Cycling Apparel & Accessories",
    children: [7474, 3118, 3246, 500028, 1029, 8061, 3272],
    parent: 1025,
    level: 5,
  },
  3983: { id: 3983, name: "Rugby Training Aids", parent: 1110, level: 5 },
  3985: { id: 3985, name: "Tennis Ball Machines", parent: 1065, level: 5 },
  3987: { id: 3987, name: "Pole Vault Pits", parent: 1060, level: 5 },
  3988: {
    id: 3988,
    name: "Coffee Maker & Espresso Machine Accessories",
    children: [6888, 3239, 4500, 3450, 4786, 734, 503736, 5065, 5066, 3838],
    parent: 2901,
    level: 5,
  },
  3991: {
    id: 3991,
    name: "Semiconductors",
    children: [3632, 7257, 3949, 3094],
    parent: 3702,
    level: 4,
  },
  3992: { id: 3992, name: "Saunas", parent: 729, level: 4 },
  3993: { id: 3993, name: "Disc Golf Bags", parent: 3484, level: 6 },
  3994: { id: 3994, name: "Piñatas", parent: 96, level: 5 },
  3995: {
    id: 3995,
    name: "Watercraft Steering Parts",
    children: [3308, 3663],
    parent: 3391,
    level: 5,
  },
  3996: { id: 3996, name: "Table Shuffleboard Powder", parent: 1009, level: 5 },
  3997: { id: 3997, name: "Starter Pistols", parent: 1060, level: 5 },
  3998: {
    id: 3998,
    name: "American Football Training Equipment",
    children: [499779],
    parent: 1093,
    level: 5,
  },
  3999: { id: 3999, name: "Mortars & Pestles", parent: 668, level: 5 },
  4000: { id: 4000, name: "Cultivating Tools", parent: 3173, level: 6 },
  4002: { id: 4002, name: "Squash Racquets", parent: 503752, level: 5 },
  4003: { id: 4003, name: "Martial Arts Uniforms", parent: 3598, level: 6 },
  4004: { id: 4004, name: "Pretend Housekeeping", parent: 3229, level: 5 },
  4005: { id: 4005, name: "Tongs", parent: 668, level: 5 },
  4006: { id: 4006, name: "Hockey Fan Accessories", parent: 3515, level: 7 },
  4008: {
    id: 4008,
    name: "Boxing & Martial Arts Protective Gear",
    children: [499726, 499725, 499723, 499722, 3235, 499724],
    parent: 499719,
    level: 5,
  },
  4009: { id: 4009, name: "Serving Trays", parent: 4026, level: 6 },
  4010: { id: 4010, name: "Breadboards", parent: 3889, level: 5 },
  4011: { id: 4011, name: "Mechanical Puzzles", parent: 3867, level: 4 },
  4015: { id: 4015, name: "Forks", parent: 675, level: 6 },
  4016: { id: 4016, name: "Wire & Cable Ties", parent: 3328, level: 5 },
  4018: { id: 4018, name: "Bridle Bits", parent: 5593, level: 6 },
  4019: { id: 4019, name: "Skate Blade Guards", parent: 3791, level: 6 },
  4020: { id: 4020, name: "Vaulting Poles", parent: 1060, level: 5 },
  4021: { id: 4021, name: "Table Shuffleboard Pucks", parent: 1009, level: 5 },
  4022: { id: 4022, name: "Altimeters", parent: 1305, level: 5 },
  4023: { id: 4023, name: "Parachutes", parent: 3789, level: 5 },
  4024: { id: 4024, name: "Disposable Cameras", parent: 142, level: 4 },
  4026: {
    id: 4026,
    name: "Serveware",
    children: [
      6086, 5135, 4372, 7550, 3703, 4735, 3330, 3802, 4009, 3373, 3941,
    ],
    parent: 672,
    level: 5,
  },
  4027: {
    id: 4027,
    name: "Motor Vehicle Trailers",
    children: [1133, 4037, 4243, 4044],
    parent: 8237,
    level: 5,
  },
  4031: { id: 4031, name: "Tool Sheaths", parent: 3974, level: 5 },
  4032: { id: 4032, name: "Decorative Stamps", parent: 505392, level: 7 },
  4035: { id: 4035, name: "FM Transmitters", parent: 5129, level: 6 },
  4036: { id: 4036, name: "Laboratory Flasks", parent: 4255, level: 5 },
  4037: {
    id: 4037,
    name: "Horse & Livestock Trailers",
    parent: 4027,
    level: 6,
  },
  4040: {
    id: 4040,
    name: "Scopes",
    children: [4136, 165, 1695],
    parent: 156,
    level: 4,
  },
  4043: { id: 4043, name: "Golf Club Headcovers", parent: 3642, level: 6 },
  4044: { id: 4044, name: "Utility & Cargo Trailers", parent: 4027, level: 6 },
  4049: { id: 4049, name: "Shower Caps", parent: 474, level: 6 },
  4050: { id: 4050, name: "Basketball Hoop Posts", parent: 4676, level: 6 },
  4054: { id: 4054, name: "Decorative Stickers", parent: 505379, level: 7 },
  4055: {
    id: 4055,
    name: "Paper Coin Wrappers & Bill Straps",
    parent: 4181,
    level: 5,
  },
  4056: { id: 4056, name: "White Noise Machines", parent: 4076, level: 5 },
  4057: { id: 4057, name: "Hair Extensions", parent: 171, level: 5 },
  4060: { id: 4060, name: "Erotic Magazines", parent: 773, level: 4 },
  4061: { id: 4061, name: "Graduated Cylinders", parent: 4255, level: 5 },
  4063: { id: 4063, name: "Armoires & Wardrobes", parent: 6356, level: 4 },
  4070: { id: 4070, name: "Neon Signs", parent: 4297, level: 5 },
  4072: { id: 4072, name: "Tattooing Needles", parent: 4326, level: 5 },
  4073: { id: 4073, name: "Glue Guns", parent: 504639, level: 6 },
  4074: { id: 4074, name: "pH Meters", parent: 1305, level: 5 },
  4075: { id: 4075, name: "Pipettes", parent: 4255, level: 5 },
  4076: {
    id: 4076,
    name: "Sleeping Aids",
    children: [4313, 6017, 4211, 4056],
    parent: 2915,
    level: 4,
  },
  4077: {
    id: 4077,
    name: "Towels",
    children: [576, 4126, 4257],
    parent: 4171,
    level: 4,
  },
  4080: { id: 4080, name: "Folding Tables", parent: 6392, level: 4 },
  4081: { id: 4081, name: "Bubble Levels", parent: 1191, level: 6 },
  4082: { id: 4082, name: "Ashtrays", parent: 600, level: 4 },
  4085: {
    id: 4085,
    name: "Composting",
    children: [690, 6840, 6436],
    parent: 2962,
    level: 5,
  },
  4086: {
    id: 4086,
    name: "Binder Accessories",
    children: [4212, 4183, 2139],
    parent: 4312,
    level: 5,
  },
  4089: { id: 4089, name: "Basketball Backboards", parent: 4676, level: 6 },
  4091: {
    id: 4091,
    name: "Vaporizers & Electronic Cigarettes",
    children: [543635, 543634],
    parent: 435,
    level: 4,
  },
  4093: {
    id: 4093,
    name: "Soccer Autographed Paraphernalia",
    parent: 4333,
    level: 7,
  },
  4095: {
    id: 4095,
    name: "Multifunction Tools & Knives",
    parent: 3937,
    level: 6,
  },
  4096: { id: 4096, name: "Food Service Baskets", parent: 135, level: 4 },
  4102: {
    id: 4102,
    name: "Laptop Replacement Screens",
    parent: 4224,
    level: 6,
  },
  4105: { id: 4105, name: "Sunloungers", parent: 6368, level: 5 },
  4106: { id: 4106, name: "Makeup Sponges", parent: 2548, level: 7 },
  4111: { id: 4111, name: "Guitar Picks", parent: 3502, level: 7 },
  4116: { id: 4116, name: "Autoclaves", parent: 4335, level: 5 },
  4117: { id: 4117, name: "Shipping Tags", parent: 960, level: 5 },
  4121: { id: 4121, name: "Facial Blotting Paper", parent: 2548, level: 7 },
  4122: { id: 4122, name: "Piercing Needles", parent: 4350, level: 5 },
  4124: {
    id: 4124,
    name: "American Football Autographed Paraphernalia",
    parent: 4333,
    level: 7,
  },
  4126: { id: 4126, name: "Beach Towels", parent: 4077, level: 5 },
  4127: { id: 4127, name: "Pricing Guns", parent: 138, level: 4 },
  4131: { id: 4131, name: "LED Signs", parent: 4297, level: 5 },
  4132: { id: 4132, name: "Power Trowels", parent: 1193, level: 5 },
  4133: { id: 4133, name: "Laboratory Hot Plates", parent: 4335, level: 5 },
  4136: { id: 4136, name: "Spotting Scopes", parent: 4040, level: 5 },
  4137: { id: 4137, name: "Label Clips", parent: 960, level: 5 },
  4140: { id: 4140, name: "Wash Bottles", parent: 4255, level: 5 },
  4142: { id: 4142, name: "Music Stands", parent: 55, level: 5 },
  4143: { id: 4143, name: "Tablecloths", parent: 601, level: 5 },
  4144: {
    id: 4144,
    name: "Hockey Autographed Paraphernalia",
    parent: 4333,
    level: 7,
  },
  4145: { id: 4145, name: "Bicycle Cages", parent: 3214, level: 6 },
  4148: {
    id: 4148,
    name: "Vanities",
    children: [2081, 6360],
    parent: 6356,
    level: 4,
  },
  4149: {
    id: 4149,
    name: "Baseball & Softball Autographed Paraphernalia",
    parent: 4333,
    level: 7,
  },
  4151: { id: 4151, name: "Coin & Bill Counters", parent: 4181, level: 5 },
  4154: { id: 4154, name: "Folder Tabs", parent: 960, level: 5 },
  4155: { id: 4155, name: "Test Tube Racks", parent: 4255, level: 5 },
  4160: { id: 4160, name: "Retail Display Cases", parent: 138, level: 4 },
  4161: { id: 4161, name: "Ice Makers", parent: 730, level: 5 },
  4163: { id: 4163, name: "Room Dividers", parent: 436, level: 3 },
  4164: { id: 4164, name: "Monoculars", parent: 156, level: 4 },
  4167: { id: 4167, name: "Flying Discs", parent: 1266, level: 5 },
  4171: {
    id: 4171,
    name: "Linens & Bedding",
    children: [569, 505832, 601, 4077],
    parent: 536,
    level: 3,
  },
  4174: { id: 4174, name: "Zippers", parent: 505380, level: 7 },
  4175: { id: 4175, name: "Scale Model Kits", parent: 5999, level: 5 },
  4177: { id: 4177, name: "Coiled Spring Toys", parent: 4352, level: 5 },
  4179: { id: 4179, name: "Pinback Buttons", parent: 167, level: 4 },
  4180: {
    id: 4180,
    name: "Auto Racing Autographed Paraphernalia",
    parent: 4333,
    level: 7,
  },
  4181: {
    id: 4181,
    name: "Money Handling",
    children: [4290, 505825, 505824, 4151, 3273, 4329, 4055],
    parent: 138,
    level: 4,
  },
  4182: { id: 4182, name: "Binding Combs & Spines", parent: 4312, level: 5 },
  4183: { id: 4183, name: "Index Dividers", parent: 4086, level: 6 },
  4191: { id: 4191, name: "Desks", parent: 6362, level: 4 },
  4192: { id: 4192, name: "Basketball Rims", parent: 4676, level: 6 },
  4194: { id: 4194, name: "Bicycle Seatposts", parent: 3618, level: 6 },
  4195: { id: 4195, name: "Dressers", parent: 6356, level: 4 },
  4199: { id: 4199, name: "Garden Hose Storage", parent: 3974, level: 5 },
  4200: { id: 4200, name: "Shipping Labels", parent: 960, level: 5 },
  4201: { id: 4201, name: "Garden Hose Spray Nozzles", parent: 3568, level: 5 },
  4203: { id: 4203, name: "Cloth Napkins", parent: 601, level: 5 },
  4205: {
    id: 4205,
    name: "Storage Chests",
    children: [6947, 4268],
    parent: 6356,
    level: 4,
  },
  4211: { id: 4211, name: "Travel Pillows", parent: 4076, level: 5 },
  4212: { id: 4212, name: "Binder Rings", parent: 4086, level: 6 },
  4214: { id: 4214, name: "Decorative Nutcrackers", parent: 3685, level: 7 },
  4215: { id: 4215, name: "Tattooing Inks", parent: 4326, level: 5 },
  4216: { id: 4216, name: "Toy Jacks", parent: 4352, level: 5 },
  4217: { id: 4217, name: "Bus Tubs", parent: 135, level: 4 },
  4218: { id: 4218, name: "False Nails", parent: 478, level: 6 },
  4220: { id: 4220, name: "Fly Swatters", parent: 728, level: 5 },
  4222: { id: 4222, name: "Bar Ice Picks", parent: 651, level: 6 },
  4224: {
    id: 4224,
    name: "Laptop Parts",
    children: [6416, 4270, 7501, 4301, 4102, 43617, 8160],
    parent: 285,
    level: 5,
  },
  4226: { id: 4226, name: "Buttons & Snaps", parent: 505380, level: 7 },
  4231: { id: 4231, name: "Laboratory Ovens", parent: 4335, level: 5 },
  4233: { id: 4233, name: "Trunks", parent: 696, level: 4 },
  4237: { id: 4237, name: "Photo Storage Boxes", parent: 4360, level: 6 },
  4240: { id: 4240, name: "Vehicle Cargo Racks", parent: 3472, level: 6 },
  4241: { id: 4241, name: "Vanity Benches", parent: 441, level: 4 },
  4243: { id: 4243, name: "Travel Trailers", parent: 4027, level: 6 },
  4244: { id: 4244, name: "Clothing Display Racks", parent: 138, level: 4 },
  4245: {
    id: 4245,
    name: "Otoscopes & Ophthalmoscopes",
    parent: 3477,
    level: 5,
  },
  4247: { id: 4247, name: "Can Crushers", parent: 668, level: 5 },
  4248: { id: 4248, name: "Crutches", parent: 5164, level: 6 },
  4254: { id: 4254, name: "Golf Club Grips", parent: 3642, level: 6 },
  4255: {
    id: 4255,
    name: "Laboratory Supplies",
    children: [4310, 4061, 4036, 4276, 4075, 4155, 4306, 4140],
    parent: 1624,
    level: 4,
  },
  4257: { id: 4257, name: "Kitchen Towels", parent: 4077, level: 5 },
  4268: { id: 4268, name: "Toy Chests", parent: 4205, level: 5 },
  4270: { id: 4270, name: "Laptop Housings & Trim", parent: 4224, level: 6 },
  4274: { id: 4274, name: "Thermal Optic Accessories", parent: 160, level: 5 },
  4276: { id: 4276, name: "Petri Dishes", parent: 4255, level: 5 },
  4279: {
    id: 4279,
    name: "Basketball Autographed Paraphernalia",
    parent: 4333,
    level: 7,
  },
  4282: { id: 4282, name: "Martial Arts Weapons", parent: 499719, level: 5 },
  4283: { id: 4283, name: "Cash Drawers & Trays", parent: 505825, level: 6 },
  4285: {
    id: 4285,
    name: "Piercing & Tattooing",
    children: [4350, 4326],
    parent: 111,
    level: 3,
  },
  4290: { id: 4290, name: "Banknote Verifiers", parent: 4181, level: 5 },
  4292: { id: 4292, name: "Steam Tables", parent: 5103, level: 6 },
  4294: { id: 4294, name: "Sight Levels", parent: 1191, level: 6 },
  4295: { id: 4295, name: "Piggy Banks & Money Jars", parent: 696, level: 4 },
  4297: {
    id: 4297,
    name: "Electric Signs",
    children: [4131, 4070],
    parent: 976,
    level: 4,
  },
  4299: {
    id: 4299,
    name: "Outdoor Furniture",
    children: [6892, 6367, 6822, 6368, 7310, 2684],
    parent: 436,
    level: 3,
  },
  4301: {
    id: 4301,
    name: "Laptop Replacement Keyboards",
    parent: 4224,
    level: 6,
  },
  4303: { id: 4303, name: "Binders", parent: 4312, level: 5 },
  4306: { id: 4306, name: "Test Tubes", parent: 4255, level: 5 },
  4310: { id: 4310, name: "Beakers", parent: 4255, level: 5 },
  4312: {
    id: 4312,
    name: "Binding Supplies",
    children: [4086, 4303, 4182, 7080],
    parent: 923,
    level: 4,
  },
  4313: { id: 4313, name: "Eye Masks", parent: 4076, level: 5 },
  4316: { id: 4316, name: "Toothpicks", parent: 526, level: 5 },
  4317: { id: 4317, name: "Conference Room Tables", parent: 6363, level: 5 },
  4322: {
    id: 4322,
    name: "Toothpick Holders & Dispensers",
    parent: 2948,
    level: 6,
  },
  4325: { id: 4325, name: "Compactors", parent: 1167, level: 4 },
  4326: {
    id: 4326,
    name: "Tattooing Supplies",
    children: [5853, 4215, 4379, 4072],
    parent: 4285,
    level: 4,
  },
  4327: { id: 4327, name: "Hang Gliders", parent: 3789, level: 5 },
  4329: { id: 4329, name: "Money Deposit Bags", parent: 4181, level: 5 },
  4332: { id: 4332, name: "Pastry Blenders", parent: 668, level: 5 },
  4333: {
    id: 4333,
    name: "Autographed Sports Paraphernalia",
    children: [4124, 4180, 4149, 4279, 8210, 4144, 4093, 6186],
    parent: 3865,
    level: 6,
  },
  4334: { id: 4334, name: "Spoon Rests", parent: 668, level: 5 },
  4335: {
    id: 4335,
    name: "Laboratory Equipment",
    children: [
      4116, 4336, 7218, 500057, 4474, 500114, 503722, 4133, 4231, 4555, 158,
      7437, 7468, 7393,
    ],
    parent: 1624,
    level: 4,
  },
  4336: { id: 4336, name: "Centrifuges", parent: 4335, level: 5 },
  4340: { id: 4340, name: "Theodolites", parent: 1305, level: 5 },
  4341: { id: 4341, name: "Office Rubber Stamps", parent: 2986, level: 4 },
  4343: { id: 4343, name: "Doilies", parent: 601, level: 5 },
  4350: {
    id: 4350,
    name: "Piercing Supplies",
    children: [4122],
    parent: 4285,
    level: 4,
  },
  4351: { id: 4351, name: "Spray String", parent: 96, level: 5 },
  4352: {
    id: 4352,
    name: "Activity Toys",
    children: [
      7519, 3733, 3212, 3874, 4177, 3534, 7425, 7473, 3466, 4216, 7148, 3929,
    ],
    parent: 1253,
    level: 4,
  },
  4355: {
    id: 4355,
    name: "Kitchen & Dining Room Tables",
    parent: 6392,
    level: 4,
  },
  4358: { id: 4358, name: "Parasols & Rain Umbrellas", parent: 536, level: 3 },
  4360: {
    id: 4360,
    name: "Photo Storage",
    children: [40, 4237],
    parent: 636,
    level: 5,
  },
  4364: { id: 4364, name: "Stethoscopes", parent: 3477, level: 5 },
  4366: { id: 4366, name: "Bath Pillows", parent: 574, level: 4 },
  4368: {
    id: 4368,
    name: "Photo Negative & Slide Storage",
    parent: 39,
    level: 4,
  },
  4372: { id: 4372, name: "Cake Stands", parent: 4026, level: 6 },
  4375: { id: 4375, name: "Window Screens", parent: 603, level: 5 },
  4377: { id: 4377, name: "Address Labels", parent: 960, level: 5 },
  4379: { id: 4379, name: "Tattooing Machines", parent: 4326, level: 5 },
  4386: {
    id: 4386,
    name: "Baby Transport Accessories",
    children: [4486, 4916, 4387, 8537, 5845],
    parent: 537,
    level: 3,
  },
  4387: { id: 4387, name: "Baby Stroller Accessories", parent: 4386, level: 4 },
  4415: { id: 4415, name: "Radio Scanners", parent: 2471, level: 5 },
  4416: { id: 4416, name: "Lens Converters", parent: 2911, level: 5 },
  4417: { id: 4417, name: "Hard Drive Docks", parent: 1623, level: 7 },
  4418: { id: 4418, name: "Coffee Creamer", parent: 428, level: 5 },
  4419: { id: 4419, name: "Sanding Blocks", parent: 1167, level: 4 },
  4420: { id: 4420, name: "Mattress Encasements", parent: 4452, level: 6 },
  4421: { id: 4421, name: "Electric Skillets & Woks", parent: 730, level: 5 },
  4423: { id: 4423, name: "Sauté Pans", parent: 654, level: 6 },
  4424: {
    id: 4424,
    name: "Cookware Accessories",
    children: [4661, 4660, 4501, 4529, 4427],
    parent: 6070,
    level: 5,
  },
  4427: {
    id: 4427,
    name: "Wok Accessories",
    children: [4663, 4662],
    parent: 4424,
    level: 6,
  },
  4432: { id: 4432, name: "Camera Lenses", parent: 149, level: 5 },
  4433: { id: 4433, name: "Cat Beds", parent: 4, level: 5 },
  4434: { id: 4434, name: "Dog Beds", parent: 5, level: 5 },
  4435: { id: 4435, name: "Homecare & Hospital Beds", parent: 5167, level: 5 },
  4437: {
    id: 4437,
    name: "Bed & Bed Frame Accessories",
    parent: 6433,
    level: 4,
  },
  4441: { id: 4441, name: "Lens Bags", parent: 2911, level: 5 },
  4450: { id: 4450, name: "Marine Radios", parent: 340, level: 4 },
  4451: {
    id: 4451,
    name: "Air Mattress & Sleeping Pad Accessories",
    parent: 1014,
    level: 6,
  },
  4452: {
    id: 4452,
    name: "Mattress Protectors",
    children: [4420, 2991],
    parent: 569,
    level: 5,
  },
  4453: { id: 4453, name: "Chair & Sofa Cushions", parent: 696, level: 4 },
  4454: { id: 4454, name: "Throw Pillows", parent: 696, level: 4 },
  4456: { id: 4456, name: "Backrest Pillows", parent: 696, level: 4 },
  4458: { id: 4458, name: "TV & Monitor Mounts", parent: 403, level: 6 },
  4459: { id: 4459, name: "Paella Pans", parent: 654, level: 6 },
  4463: {
    id: 4463,
    name: "Audio & Video Cable Adapters & Couplers",
    parent: 258,
    level: 5,
  },
  4465: { id: 4465, name: "Wireless Presenters", parent: 964, level: 4 },
  4466: { id: 4466, name: "Golf Gloves", parent: 1043, level: 5 },
  4467: { id: 4467, name: "Golf Towels", parent: 1043, level: 5 },
  4468: { id: 4468, name: "Garage Doors", parent: 119, level: 5 },
  4469: { id: 4469, name: "Pull Chains", parent: 502977, level: 5 },
  4470: {
    id: 4470,
    name: "Writing & Drawing Instrument Accessories",
    children: [7117, 4471, 4472],
    parent: 2986,
    level: 4,
  },
  4471: { id: 4471, name: "Pen Ink & Refills", parent: 4470, level: 5 },
  4472: { id: 4472, name: "Pencil Lead & Refills", parent: 4470, level: 5 },
  4474: { id: 4474, name: "Laboratory Blenders", parent: 4335, level: 5 },
  4475: { id: 4475, name: "Hair Dryer Accessories", parent: 6018, level: 6 },
  4482: { id: 4482, name: "Mochi Makers", parent: 730, level: 5 },
  4483: { id: 4483, name: "Futon Dryers", parent: 604, level: 4 },
  4484: { id: 4484, name: "Kotatsu", parent: 6392, level: 4 },
  4485: { id: 4485, name: "Ventilation Fans", parent: 608, level: 6 },
  4486: {
    id: 4486,
    name: "Baby & Toddler Car Seat Accessories",
    parent: 4386,
    level: 4,
  },
  4487: {
    id: 4487,
    name: "Sanding Accessories",
    children: [3240],
    parent: 3650,
    level: 4,
  },
  4488: { id: 4488, name: "Toll Collection Devices", parent: 222, level: 3 },
  4490: { id: 4490, name: "Earthquake Alarms", parent: 5835, level: 4 },
  4491: { id: 4491, name: "Furniture Anchors", parent: 5835, level: 4 },
  4492: { id: 4492, name: "Document Cameras", parent: 964, level: 4 },
  4493: { id: 4493, name: "Headphone Amplifiers", parent: 224, level: 6 },
  4495: {
    id: 4495,
    name: "Roaster Ovens & Rotisseries",
    parent: 730,
    level: 5,
  },
  4497: { id: 4497, name: "Pet Doors", parent: 2, level: 4 },
  4499: { id: 4499, name: "Staple Removers", parent: 2986, level: 4 },
  4500: { id: 4500, name: "Coffee Filter Baskets", parent: 3988, level: 6 },
  4501: {
    id: 4501,
    name: "Pressure Cooker & Canner Accessories",
    parent: 4424,
    level: 6,
  },
  4502: {
    id: 4502,
    name: "Bakeware Accessories",
    children: [4503, 7131, 4726],
    parent: 6070,
    level: 5,
  },
  4503: { id: 4503, name: "Baking Mats & Liners", parent: 4502, level: 6 },
  4506: { id: 4506, name: "Infrared Thermometers", parent: 1305, level: 5 },
  4507: {
    id: 4507,
    name: "Hair Removal",
    children: [4508, 4509, 4510, 8136, 7199, 4511],
    parent: 528,
    level: 5,
  },
  4508: { id: 4508, name: "Depilatories", parent: 4507, level: 6 },
  4509: { id: 4509, name: "Electrolysis Devices", parent: 4507, level: 6 },
  4510: { id: 4510, name: "Epilators", parent: 4507, level: 6 },
  4511: { id: 4511, name: "Waxing Kits & Supplies", parent: 4507, level: 6 },
  4512: { id: 4512, name: "Numeric Keypads", parent: 1928, level: 6 },
  4513: { id: 4513, name: "Outdoor Sofas", parent: 6368, level: 5 },
  4515: { id: 4515, name: "Dustpans", parent: 623, level: 5 },
  4516: {
    id: 4516,
    name: "Waste Containment",
    children: [500039, 5143, 4517, 637],
    parent: 630,
    level: 4,
  },
  4517: { id: 4517, name: "Recycling Containers", parent: 4516, level: 5 },
  4519: { id: 4519, name: "Juicer Accessories", parent: 2901, level: 5 },
  4520: {
    id: 4520,
    name: "Drink Sleeves",
    children: [4521, 4522],
    parent: 2920,
    level: 5,
  },
  4521: { id: 4521, name: "Can & Bottle Sleeves", parent: 4520, level: 6 },
  4522: { id: 4522, name: "Cup Sleeves", parent: 4520, level: 6 },
  4525: { id: 4525, name: "Golf Bag Covers & Cases", parent: 4605, level: 6 },
  4527: { id: 4527, name: "Eye Wash Supplies", parent: 508, level: 5 },
  4528: { id: 4528, name: "Tea Makers", parent: 730, level: 5 },
  4529: { id: 4529, name: "Steamer Baskets", parent: 4424, level: 6 },
  4530: { id: 4530, name: "Mixing Bowls", parent: 668, level: 5 },
  4532: {
    id: 4532,
    name: "Food Cookers & Steamers",
    children: [739, 760, 757, 737, 6523, 6279],
    parent: 730,
    level: 5,
  },
  4537: { id: 4537, name: "Golf Bag Carts", parent: 4605, level: 6 },
  4539: { id: 4539, name: "Wine Fridges", parent: 730, level: 5 },
  4540: { id: 4540, name: "Bassoons", parent: 87, level: 6 },
  4541: { id: 4541, name: "Oboes & English Horns", parent: 87, level: 6 },
  4544: { id: 4544, name: "Gutter Accessories", parent: 123, level: 5 },
  4546: { id: 4546, name: "Alarm Clocks", parent: 3890, level: 5 },
  4548: {
    id: 4548,
    name: "Floor & Steam Cleaner Accessories",
    parent: 3348,
    level: 4,
  },
  4550: {
    id: 4550,
    name: "Mobile Phone Charms & Straps",
    parent: 264,
    level: 6,
  },
  4551: {
    id: 4551,
    name: "Respiratory Care",
    children: [4552, 499692, 7317, 7316, 505669],
    parent: 491,
    level: 4,
  },
  4552: { id: 4552, name: "Nebulizers", parent: 4551, level: 5 },
  4554: { id: 4554, name: "Portable Electronic Games", parent: 3793, level: 4 },
  4555: {
    id: 4555,
    name: "Microscope Accessories",
    children: [4557, 4556, 4665, 4664, 4558],
    parent: 4335,
    level: 5,
  },
  4556: {
    id: 4556,
    name: "Microscope Eyepieces & Adapters",
    parent: 4555,
    level: 6,
  },
  4557: { id: 4557, name: "Microscope Cameras", parent: 4555, level: 6 },
  4558: { id: 4558, name: "Microscope Slides", parent: 4555, level: 6 },
  4559: { id: 4559, name: "Tea Strainers", parent: 668, level: 5 },
  4560: {
    id: 4560,
    name: "Outdoor Grill Spits & Baskets",
    parent: 3684,
    level: 6,
  },
  4562: { id: 4562, name: "Bottle Stoppers & Savers", parent: 649, level: 5 },
  4563: { id: 4563, name: "Wine Aerators", parent: 649, level: 5 },
  4564: {
    id: 4564,
    name: "Outdoor Power Equipment Accessories",
    children: [
      4565, 7563, 7265, 4566, 7168, 8485, 7333, 6328, 4567, 5867, 7169,
    ],
    parent: 689,
    level: 4,
  },
  4565: {
    id: 4565,
    name: "Chainsaw Accessories",
    children: [4647, 4646],
    parent: 4564,
    level: 5,
  },
  4566: {
    id: 4566,
    name: "Lawn Mower Accessories",
    children: [
      6542, 4645, 4643, 4641, 4642, 499923, 499960, 4644, 499872, 6095, 6094,
      499921, 6541,
    ],
    parent: 4564,
    level: 5,
  },
  4567: { id: 4567, name: "Snow Blower Accessories", parent: 4564, level: 5 },
  4569: { id: 4569, name: "Hair Iron Accessories", parent: 6018, level: 6 },
  4570: { id: 4570, name: "Projection Screen Stands", parent: 393, level: 6 },
  4571: { id: 4571, name: "Bicycle Tires", parent: 3618, level: 6 },
  4572: { id: 4572, name: "Bicycle Tubes", parent: 3618, level: 6 },
  4574: { id: 4574, name: "Bicycle Brake Calipers", parent: 3740, level: 7 },
  4575: { id: 4575, name: "Bicycle Brake Levers", parent: 3740, level: 7 },
  4576: { id: 4576, name: "Bicycle Brake Rotors", parent: 3740, level: 7 },
  4577: { id: 4577, name: "Bicycle Brake Sets", parent: 3740, level: 7 },
  4579: { id: 4579, name: "Sewing Machine Needles", parent: 505393, level: 7 },
  4580: {
    id: 4580,
    name: "Craft Machine Cases & Covers",
    parent: 504643,
    level: 6,
  },
  4582: { id: 4582, name: "Bicycle Handlebars", parent: 3618, level: 6 },
  4583: { id: 4583, name: "Bicycle Stems", parent: 3618, level: 6 },
  4585: {
    id: 4585,
    name: "Bicycle Drivetrain Parts",
    children: [4590, 4586, 4591, 4587, 4592, 4588, 4593, 4594],
    parent: 3618,
    level: 6,
  },
  4586: {
    id: 4586,
    name: "Bicycle Cassettes & Freewheels",
    parent: 4585,
    level: 7,
  },
  4587: { id: 4587, name: "Bicycle Chains", parent: 4585, level: 7 },
  4588: { id: 4588, name: "Bicycle Derailleurs", parent: 4585, level: 7 },
  4589: {
    id: 4589,
    name: "Cardio Machines",
    children: [992, 994, 995, 996, 997],
    parent: 499792,
    level: 5,
  },
  4590: { id: 4590, name: "Bicycle Bottom Brackets", parent: 4585, level: 7 },
  4591: { id: 4591, name: "Bicycle Chainrings", parent: 4585, level: 7 },
  4592: { id: 4592, name: "Bicycle Cranks", parent: 4585, level: 7 },
  4593: { id: 4593, name: "Bicycle Pedals", parent: 4585, level: 7 },
  4594: { id: 4594, name: "Bicycle Shifters", parent: 4585, level: 7 },
  4595: { id: 4595, name: "Bicycle Seatpost Clamps", parent: 3618, level: 6 },
  4596: { id: 4596, name: "Bicycle Small Parts", parent: 3618, level: 6 },
  4597: {
    id: 4597,
    name: "Bicycle Wheel Parts",
    children: [7538, 500053, 4599, 499875, 4600, 8528, 4601, 4602],
    parent: 3618,
    level: 6,
  },
  4598: {
    id: 4598,
    name: "Cardio Machine Accessories",
    children: [499703, 499702, 499701, 499700, 499699],
    parent: 499792,
    level: 5,
  },
  4599: { id: 4599, name: "Bicycle Hubs", parent: 4597, level: 7 },
  4600: { id: 4600, name: "Bicycle Spokes", parent: 4597, level: 7 },
  4601: { id: 4601, name: "Bicycle Wheel Nipples", parent: 4597, level: 7 },
  4602: { id: 4602, name: "Bicycle Wheel Rims", parent: 4597, level: 7 },
  4603: { id: 4603, name: "Bicycle Forks", parent: 3618, level: 6 },
  4605: {
    id: 4605,
    name: "Golf Bag Accessories",
    children: [4537, 4525],
    parent: 1043,
    level: 5,
  },
  4608: {
    id: 4608,
    name: "Seasonings & Spices",
    children: [1529, 4610, 6199, 4611],
    parent: 422,
    level: 4,
  },
  4610: { id: 4610, name: "MSG", parent: 4608, level: 5 },
  4611: { id: 4611, name: "Salt", parent: 4608, level: 5 },
  4613: { id: 4613, name: "Bean Paste", parent: 2660, level: 5 },
  4614: { id: 4614, name: "Hot Sauce", parent: 427, level: 5 },
  4615: { id: 4615, name: "Satay Sauce", parent: 427, level: 5 },
  4616: { id: 4616, name: "Soy Sauce", parent: 427, level: 5 },
  4617: { id: 4617, name: "Electronics Cleaners", parent: 2082, level: 4 },
  4627: {
    id: 4627,
    name: "Eggs",
    children: [543554, 543555, 543556, 543557],
    parent: 432,
    level: 5,
  },
  4628: {
    id: 4628,
    name: "Meat",
    children: [5811, 5805, 5804],
    parent: 432,
    level: 5,
  },
  4629: {
    id: 4629,
    name: "Seafood",
    children: [5813, 5812],
    parent: 432,
    level: 5,
  },
  4630: { id: 4630, name: "Cake Decorating Supplies", parent: 668, level: 5 },
  4631: { id: 4631, name: "Percussion Mallets", parent: 3465, level: 6 },
  4632: {
    id: 4632,
    name: "Disposable Tableware",
    children: [5098, 5099, 5100, 5101],
    parent: 135,
    level: 4,
  },
  4633: { id: 4633, name: "Scroll Saws", parent: 1235, level: 5 },
  4634: { id: 4634, name: "Home Doors", parent: 119, level: 5 },
  4636: { id: 4636, name: "Lamps", parent: 594, level: 4 },
  4638: {
    id: 4638,
    name: "Tripod & Monopod Accessories",
    children: [4640, 4639, 3035, 503726, 503016],
    parent: 2096,
    level: 4,
  },
  4639: { id: 4639, name: "Tripod & Monopod Heads", parent: 4638, level: 5 },
  4640: { id: 4640, name: "Tripod & Monopod Cases", parent: 4638, level: 5 },
  4641: { id: 4641, name: "Lawn Mower Blades", parent: 4566, level: 6 },
  4642: { id: 4642, name: "Lawn Mower Covers", parent: 4566, level: 6 },
  4643: { id: 4643, name: "Lawn Mower Belts", parent: 4566, level: 6 },
  4644: {
    id: 4644,
    name: "Lawn Mower Pulleys & Idlers",
    parent: 4566,
    level: 6,
  },
  4645: { id: 4645, name: "Lawn Mower Bags", parent: 4566, level: 6 },
  4646: { id: 4646, name: "Chainsaw Chains", parent: 4565, level: 6 },
  4647: { id: 4647, name: "Chainsaw Bars", parent: 4565, level: 6 },
  4648: { id: 4648, name: "Game Timers", parent: 1239, level: 3 },
  4650: {
    id: 4650,
    name: "Patio Heater Accessories",
    children: [4651],
    parent: 3348,
    level: 4,
  },
  4651: { id: 4651, name: "Patio Heater Covers", parent: 4650, level: 5 },
  4652: { id: 4652, name: "Jukeboxes", parent: 242, level: 5 },
  4653: {
    id: 4653,
    name: "Food Dehydrator Accessories",
    children: [4655, 4654],
    parent: 2901,
    level: 5,
  },
  4654: { id: 4654, name: "Food Dehydrator Trays", parent: 4653, level: 6 },
  4655: { id: 4655, name: "Food Dehydrator Sheets", parent: 4653, level: 6 },
  4656: {
    id: 4656,
    name: "Ironing Board Pads & Covers",
    parent: 627,
    level: 5,
  },
  4657: { id: 4657, name: "Fabric Stain Removers", parent: 627, level: 5 },
  4658: {
    id: 4658,
    name: "Tool Stands",
    children: [4659],
    parent: 3650,
    level: 4,
  },
  4659: { id: 4659, name: "Saw Stands", parent: 4658, level: 5 },
  4660: { id: 4660, name: "Pot & Pan Lids", parent: 4424, level: 6 },
  4661: { id: 4661, name: "Pot & Pan Handles", parent: 4424, level: 6 },
  4662: { id: 4662, name: "Wok Rings", parent: 4427, level: 7 },
  4663: { id: 4663, name: "Wok Brushes", parent: 4427, level: 7 },
  4664: {
    id: 4664,
    name: "Microscope Replacement Bulbs",
    parent: 4555,
    level: 6,
  },
  4665: {
    id: 4665,
    name: "Microscope Objective Lenses",
    parent: 4555,
    level: 6,
  },
  4666: { id: 4666, name: "Conference Phones", parent: 270, level: 5 },
  4667: { id: 4667, name: "Dehumidifier Accessories", parent: 3348, level: 4 },
  4669: { id: 4669, name: "Exercise Equipment Mats", parent: 990, level: 4 },
  4670: { id: 4670, name: "Scrub Brushes", parent: 623, level: 5 },
  4671: { id: 4671, name: "Broom & Mop Handles", parent: 623, level: 5 },
  4672: { id: 4672, name: "Concrete Brooms", parent: 1167, level: 4 },
  4674: {
    id: 4674,
    name: "Ice Cream Maker Accessories",
    children: [4675],
    parent: 2901,
    level: 5,
  },
  4675: {
    id: 4675,
    name: "Ice Cream Maker Freezer Bowls",
    parent: 4674,
    level: 6,
  },
  4676: {
    id: 4676,
    name: "Basketball Hoop Parts & Accessories",
    children: [4089, 7251, 4050, 3829, 4192],
    parent: 1081,
    level: 5,
  },
  4677: { id: 4677, name: "Carpet Sweepers", parent: 623, level: 5 },
  4678: {
    id: 4678,
    name: "Baby Bathing",
    children: [4679, 7082],
    parent: 537,
    level: 3,
  },
  4679: {
    id: 4679,
    name: "Baby Bathtubs & Bath Seats",
    parent: 4678,
    level: 4,
  },
  4682: { id: 4682, name: "Rice", parent: 431, level: 5 },
  4683: { id: 4683, name: "Amaranth", parent: 431, level: 5 },
  4684: { id: 4684, name: "Buckwheat", parent: 431, level: 5 },
  4686: { id: 4686, name: "Millet", parent: 431, level: 5 },
  4687: { id: 4687, name: "Barley", parent: 431, level: 5 },
  4688: { id: 4688, name: "Wheat", parent: 431, level: 5 },
  4689: { id: 4689, name: "Cereal & Granola", parent: 431, level: 5 },
  4690: { id: 4690, name: "Oats, Grits & Hot Cereal", parent: 431, level: 5 },
  4692: { id: 4692, name: "Tahini", parent: 427, level: 5 },
  4696: {
    id: 4696,
    name: "Cabinet Hardware",
    children: [232167, 4697, 4698, 4699, 4700],
    parent: 2878,
    level: 4,
  },
  4697: { id: 4697, name: "Cabinet Backplates", parent: 4696, level: 5 },
  4698: { id: 4698, name: "Cabinet Catches", parent: 4696, level: 5 },
  4699: { id: 4699, name: "Cabinet Doors", parent: 4696, level: 5 },
  4700: { id: 4700, name: "Cabinet Knobs & Handles", parent: 4696, level: 5 },
  4705: {
    id: 4705,
    name: "Rolling Pin Accessories",
    children: [4706, 4707],
    parent: 668,
    level: 5,
  },
  4706: {
    id: 4706,
    name: "Rolling Pin Covers & Sleeves",
    parent: 4705,
    level: 6,
  },
  4707: { id: 4707, name: "Rolling Pin Rings", parent: 4705, level: 6 },
  4708: { id: 4708, name: "Pastry Cloths", parent: 668, level: 5 },
  4709: { id: 4709, name: "Generator Accessories", parent: 127, level: 4 },
  4714: { id: 4714, name: "Solar Panels", parent: 127, level: 4 },
  4715: { id: 4715, name: "Solar Energy Kits", parent: 127, level: 4 },
  4716: { id: 4716, name: "Power Tool Combo Sets", parent: 4919, level: 5 },
  4717: { id: 4717, name: "Waste Container Lids", parent: 6757, level: 5 },
  4718: {
    id: 4718,
    name: "Garden Hose Fittings & Valves",
    parent: 3568,
    level: 5,
  },
  4720: { id: 4720, name: "Fondue Pots & Sets", parent: 730, level: 5 },
  4721: { id: 4721, name: "Cookware Sets", parent: 654, level: 6 },
  4722: { id: 4722, name: "Airpots", parent: 2920, level: 5 },
  4726: { id: 4726, name: "Roasting Pan Racks", parent: 4502, level: 6 },
  4728: { id: 4728, name: "Shower Bases", parent: 2206, level: 6 },
  4730: { id: 4730, name: "Birthday Candles", parent: 96, level: 5 },
  4735: { id: 4735, name: "Punch Bowls", parent: 4026, level: 6 },
  4736: {
    id: 4736,
    name: "E-Book Reader Accessories",
    children: [4738],
    parent: 280,
    level: 6,
  },
  4737: {
    id: 4737,
    name: "PDA Accessories",
    children: [4739],
    parent: 280,
    level: 6,
  },
  4738: { id: 4738, name: "E-Book Reader Cases", parent: 4736, level: 7 },
  4739: { id: 4739, name: "PDA Cases", parent: 4737, level: 7 },
  4740: { id: 4740, name: "Potpourri", parent: 592, level: 5 },
  4741: { id: 4741, name: "Incense Holders", parent: 500121, level: 5 },
  4742: { id: 4742, name: "Food Service Carts", parent: 135, level: 4 },
  4743: { id: 4743, name: "Harmonicas", parent: 87, level: 6 },
  4744: { id: 4744, name: "Jew's Harps", parent: 87, level: 6 },
  4745: { id: 4745, name: "Tablet Computers", parent: 278, level: 4 },
  4746: { id: 4746, name: "Ice Cube Trays", parent: 668, level: 5 },
  4748: { id: 4748, name: "Candy & Chocolate", parent: 422, level: 4 },
  4752: { id: 4752, name: "Pastels", parent: 977, level: 5 },
  4753: { id: 4753, name: "Ice Packs", parent: 516, level: 6 },
  4754: { id: 4754, name: "Hygrometers", parent: 1305, level: 5 },
  4755: { id: 4755, name: "Moisture Meters", parent: 1305, level: 5 },
  4756: { id: 4756, name: "Seismometer", parent: 1305, level: 5 },
  4757: { id: 4757, name: "Sound Meters", parent: 1305, level: 5 },
  4758: { id: 4758, name: "UV Light Meters", parent: 1305, level: 5 },
  4759: { id: 4759, name: "Vibration Meters", parent: 1305, level: 5 },
  4760: { id: 4760, name: "3D Glasses", parent: 2027, level: 5 },
  4762: { id: 4762, name: "Spice Grinders", parent: 668, level: 5 },
  4763: { id: 4763, name: "Food Grinder Accessories", parent: 2901, level: 5 },
  4764: { id: 4764, name: "Bakeware Sets", parent: 640, level: 6 },
  4765: { id: 4765, name: "Kitchen Utensil Sets", parent: 668, level: 5 },
  4766: { id: 4766, name: "Hair Loss Treatments", parent: 486, level: 5 },
  4767: {
    id: 4767,
    name: "Prenatal Heart Rate Monitors",
    parent: 494,
    level: 5,
  },
  4768: { id: 4768, name: "Baby Care Timers", parent: 561, level: 4 },
  4770: { id: 4770, name: "Flameless Candles", parent: 696, level: 4 },
  4771: { id: 4771, name: "Oven Bags", parent: 668, level: 5 },
  4775: {
    id: 4775,
    name: "Toothbrush Accessories",
    children: [6920, 4776, 4942],
    parent: 526,
    level: 5,
  },
  4776: {
    id: 4776,
    name: "Toothbrush Replacement Heads",
    parent: 4775,
    level: 6,
  },
  4777: { id: 4777, name: "Condiment Dispensers", parent: 668, level: 5 },
  4778: { id: 4778, name: "Drawing & Painting Kits", parent: 505370, level: 6 },
  4779: { id: 4779, name: "Costume & Stage Makeup", parent: 477, level: 6 },
  4785: { id: 4785, name: "Navigational Compasses", parent: 1013, level: 5 },
  4786: {
    id: 4786,
    name: "Coffee Grinder Accessories",
    parent: 3988,
    level: 6,
  },
  4788: { id: 4788, name: "Spice Grinder Accessories", parent: 668, level: 5 },
  4789: { id: 4789, name: "Extension Cords", parent: 127, level: 4 },
  4790: {
    id: 4790,
    name: "Bassoon Accessories",
    children: [4809, 4810, 4811, 4812, 4813, 4814],
    parent: 62,
    level: 6,
  },
  4791: {
    id: 4791,
    name: "Clarinet Accessories",
    children: [4818, 4819, 4820, 4822, 4823, 4824, 4825],
    parent: 62,
    level: 6,
  },
  4792: {
    id: 4792,
    name: "Flute Accessories",
    children: [4833, 4834, 4836, 4837],
    parent: 62,
    level: 6,
  },
  4793: {
    id: 4793,
    name: "Oboe & English Horn Accessories",
    children: [4843, 4844, 4845, 4846, 4847, 4848],
    parent: 62,
    level: 6,
  },
  4794: {
    id: 4794,
    name: "Saxophone Accessories",
    children: [4852, 4853, 4854, 4856, 4857, 4858, 4859],
    parent: 62,
    level: 6,
  },
  4797: {
    id: 4797,
    name: "Brass Instrument Care & Cleaning",
    children: [4891, 4892, 4890, 4893, 4894, 4895],
    parent: 57,
    level: 6,
  },
  4798: {
    id: 4798,
    name: "Brass Instrument Replacement Parts",
    parent: 57,
    level: 6,
  },
  4806: {
    id: 4806,
    name: "String Instrument Care & Cleaning",
    children: [3374, 4911, 4912],
    parent: 61,
    level: 6,
  },
  4809: {
    id: 4809,
    name: "Bassoon Care & Cleaning",
    children: [4815],
    parent: 4790,
    level: 7,
  },
  4810: { id: 4810, name: "Bassoon Cases & Gigbags", parent: 4790, level: 7 },
  4811: {
    id: 4811,
    name: "Bassoon Parts",
    children: [4816, 4817],
    parent: 4790,
    level: 7,
  },
  4812: { id: 4812, name: "Bassoon Reeds", parent: 4790, level: 7 },
  4813: { id: 4813, name: "Bassoon Stands", parent: 4790, level: 7 },
  4814: { id: 4814, name: "Bassoon Straps & Supports", parent: 4790, level: 7 },
  4815: { id: 4815, name: "Bassoon Swabs", parent: 4809, level: 8 },
  4816: { id: 4816, name: "Bassoon Bocals", parent: 4811, level: 8 },
  4817: { id: 4817, name: "Bassoon Small Parts", parent: 4811, level: 8 },
  4818: {
    id: 4818,
    name: "Clarinet Care & Cleaning",
    children: [4826, 4827, 4828],
    parent: 4791,
    level: 7,
  },
  4819: { id: 4819, name: "Clarinet Cases & Gigbags", parent: 4791, level: 7 },
  4820: { id: 4820, name: "Clarinet Ligatures & Caps", parent: 4791, level: 7 },
  4822: {
    id: 4822,
    name: "Clarinet Parts",
    children: [4829, 4830, 4831, 4832],
    parent: 4791,
    level: 7,
  },
  4823: { id: 4823, name: "Clarinet Pegs & Stands", parent: 4791, level: 7 },
  4824: { id: 4824, name: "Clarinet Reeds", parent: 4791, level: 7 },
  4825: {
    id: 4825,
    name: "Clarinet Straps & Supports",
    parent: 4791,
    level: 7,
  },
  4826: { id: 4826, name: "Clarinet Care Kits", parent: 4818, level: 8 },
  4827: { id: 4827, name: "Clarinet Pad Savers", parent: 4818, level: 8 },
  4828: { id: 4828, name: "Clarinet Swabs", parent: 4818, level: 8 },
  4829: { id: 4829, name: "Clarinet Barrels", parent: 4822, level: 8 },
  4830: { id: 4830, name: "Clarinet Bells", parent: 4822, level: 8 },
  4831: { id: 4831, name: "Clarinet Mouthpieces", parent: 4822, level: 8 },
  4832: { id: 4832, name: "Clarinet Small Parts", parent: 4822, level: 8 },
  4833: {
    id: 4833,
    name: "Flute Care & Cleaning",
    children: [4838, 4839, 4840],
    parent: 4792,
    level: 7,
  },
  4834: { id: 4834, name: "Flute Cases & Gigbags", parent: 4792, level: 7 },
  4836: {
    id: 4836,
    name: "Flute Parts",
    children: [4841, 4842],
    parent: 4792,
    level: 7,
  },
  4837: { id: 4837, name: "Flute Pegs & Stands", parent: 4792, level: 7 },
  4838: { id: 4838, name: "Flute Care Kits", parent: 4833, level: 8 },
  4839: { id: 4839, name: "Flute Cleaning Rods", parent: 4833, level: 8 },
  4840: { id: 4840, name: "Flute Swabs", parent: 4833, level: 8 },
  4841: { id: 4841, name: "Flute Headjoints", parent: 4836, level: 8 },
  4842: { id: 4842, name: "Flute Small Parts", parent: 4836, level: 8 },
  4843: {
    id: 4843,
    name: "Oboe Care & Cleaning",
    children: [4849, 4850],
    parent: 4793,
    level: 7,
  },
  4844: { id: 4844, name: "Oboe Cases & Gigbags", parent: 4793, level: 7 },
  4845: {
    id: 4845,
    name: "Oboe Parts",
    children: [4851],
    parent: 4793,
    level: 7,
  },
  4846: { id: 4846, name: "Oboe Pegs & Stands", parent: 4793, level: 7 },
  4847: { id: 4847, name: "Oboe Reeds", parent: 4793, level: 7 },
  4848: { id: 4848, name: "Oboe Straps & Supports", parent: 4793, level: 7 },
  4849: { id: 4849, name: "Oboe Care Kits", parent: 4843, level: 8 },
  4850: { id: 4850, name: "Oboe Swabs", parent: 4843, level: 8 },
  4851: { id: 4851, name: "Oboe Small Parts", parent: 4845, level: 8 },
  4852: {
    id: 4852,
    name: "Saxophone Care & Cleaning",
    children: [4860, 4861, 4862],
    parent: 4794,
    level: 7,
  },
  4853: { id: 4853, name: "Saxophone Cases & Gigbags", parent: 4794, level: 7 },
  4854: {
    id: 4854,
    name: "Saxophone Ligatures & Caps",
    parent: 4794,
    level: 7,
  },
  4856: {
    id: 4856,
    name: "Saxophone Parts",
    children: [4863, 4864, 4865],
    parent: 4794,
    level: 7,
  },
  4857: { id: 4857, name: "Saxophone Pegs & Stands", parent: 4794, level: 7 },
  4858: { id: 4858, name: "Saxophone Reeds", parent: 4794, level: 7 },
  4859: {
    id: 4859,
    name: "Saxophone Straps & Supports",
    parent: 4794,
    level: 7,
  },
  4860: { id: 4860, name: "Saxophone Care Kits", parent: 4852, level: 8 },
  4861: { id: 4861, name: "Saxophone Pad Savers", parent: 4852, level: 8 },
  4862: { id: 4862, name: "Saxophone Swabs", parent: 4852, level: 8 },
  4863: { id: 4863, name: "Saxophone Mouthpieces", parent: 4856, level: 8 },
  4864: { id: 4864, name: "Saxophone Necks", parent: 4856, level: 8 },
  4865: { id: 4865, name: "Saxophone Small Parts", parent: 4856, level: 8 },
  4866: { id: 4866, name: "Woodwind Cork Grease", parent: 62, level: 6 },
  4867: { id: 4867, name: "Woodwind Polishing Cloths", parent: 62, level: 6 },
  4890: {
    id: 4890,
    name: "Brass Instrument Cleaning Tools",
    parent: 4797,
    level: 7,
  },
  4891: {
    id: 4891,
    name: "Brass Instrument Care Kits",
    parent: 4797,
    level: 7,
  },
  4892: {
    id: 4892,
    name: "Brass Instrument Cleaners & Sanitizers",
    parent: 4797,
    level: 7,
  },
  4893: { id: 4893, name: "Brass Instrument Guards", parent: 4797, level: 7 },
  4894: {
    id: 4894,
    name: "Brass Instrument Lubricants",
    parent: 4797,
    level: 7,
  },
  4895: {
    id: 4895,
    name: "Brass Instrument Polishing Cloths",
    parent: 4797,
    level: 7,
  },
  4911: {
    id: 4911,
    name: "String Instrument Cleaning Cloths",
    parent: 4806,
    level: 7,
  },
  4912: { id: 4912, name: "String Instrument Polish", parent: 4806, level: 7 },
  4914: { id: 4914, name: "Fireworks & Firecrackers", parent: 96, level: 5 },
  4915: { id: 4915, name: "Sparklers", parent: 96, level: 5 },
  4916: { id: 4916, name: "Baby Carrier Accessories", parent: 4386, level: 4 },
  4918: { id: 4918, name: "Wood Stove Fans & Blowers", parent: 2862, level: 4 },
  4919: {
    id: 4919,
    name: "Tool Sets",
    children: [6965, 4716],
    parent: 1167,
    level: 4,
  },
  4921: {
    id: 4921,
    name: "DJ & Specialty Audio",
    children: [4922, 4923],
    parent: 223,
    level: 4,
  },
  4922: { id: 4922, name: "DJ CD Players", parent: 4921, level: 5 },
  4923: { id: 4923, name: "DJ Systems", parent: 4921, level: 5 },
  4926: { id: 4926, name: "Fishing Reels", parent: 3334, level: 5 },
  4927: { id: 4927, name: "Fishing Rods", parent: 3334, level: 5 },
  4928: { id: 4928, name: "General Purpose Batteries", parent: 276, level: 6 },
  4929: { id: 4929, name: "Cotton Balls", parent: 2915, level: 4 },
  4931: { id: 4931, name: "Laser Levels", parent: 1191, level: 6 },
  4932: { id: 4932, name: "Track Lighting Fixtures", parent: 6274, level: 5 },
  4939: { id: 4939, name: "Woodwind Reed Knives", parent: 62, level: 6 },
  4941: { id: 4941, name: "Book Lights", parent: 6174, level: 4 },
  4942: { id: 4942, name: "Toothbrush Sanitizers", parent: 4775, level: 6 },
  4943: { id: 4943, name: "Syrup", parent: 427, level: 5 },
  4947: { id: 4947, name: "Honey", parent: 427, level: 5 },
  4949: { id: 4949, name: "Home Publishing Software", parent: 319, level: 5 },
  4950: { id: 4950, name: "Animation Editing Software", parent: 319, level: 5 },
  4951: {
    id: 4951,
    name: "Graphic Design & Illustration Software",
    parent: 319,
    level: 5,
  },
  4952: { id: 4952, name: "Sound Editing Software", parent: 319, level: 5 },
  4953: { id: 4953, name: "Video Editing Software", parent: 319, level: 5 },
  4954: { id: 4954, name: "Web Design Software", parent: 319, level: 5 },
  4955: {
    id: 4955,
    name: "Harmonica Accessories",
    children: [4956, 5046],
    parent: 62,
    level: 6,
  },
  4956: { id: 4956, name: "Harmonica Cases", parent: 4955, level: 7 },
  4957: { id: 4957, name: "Woodwind Reed Cases", parent: 62, level: 6 },
  4971: { id: 4971, name: "Soap & Lotion Dispensers", parent: 574, level: 4 },
  4972: { id: 4972, name: "Wheelbarrow Parts", parent: 505326, level: 6 },
  4973: {
    id: 4973,
    name: "Household Cleaning Products",
    children: [
      7330, 4974, 500065, 4975, 7510, 8043, 4977, 5825, 4976, 6474, 4978, 4979,
      7552, 7426, 4980, 4981, 7462,
    ],
    parent: 623,
    level: 5,
  },
  4974: { id: 4974, name: "Carpet Cleaners", parent: 4973, level: 6 },
  4975: { id: 4975, name: "Dish Detergent & Soap", parent: 4973, level: 6 },
  4976: {
    id: 4976,
    name: "Glass & Surface Cleaners",
    children: [543649, 543650],
    parent: 4973,
    level: 6,
  },
  4977: { id: 4977, name: "Floor Cleaners", parent: 4973, level: 6 },
  4978: { id: 4978, name: "Oven & Grill Cleaners", parent: 4973, level: 6 },
  4979: { id: 4979, name: "Pet Odor & Stain Removers", parent: 4973, level: 6 },
  4980: { id: 4980, name: "Toilet Bowl Cleaners", parent: 4973, level: 6 },
  4981: { id: 4981, name: "Tub & Tile Cleaners", parent: 4973, level: 6 },
  4982: { id: 4982, name: "Bleach", parent: 627, level: 5 },
  4983: { id: 4983, name: "Accordions & Concertinas", parent: 54, level: 5 },
  4984: { id: 4984, name: "Bagpipes", parent: 54, level: 5 },
  4986: { id: 4986, name: "Toy Craft Kits", parent: 505370, level: 6 },
  4988: { id: 4988, name: "Tarps", parent: 2878, level: 4 },
  4989: { id: 4989, name: "Bird Cages & Stands", parent: 3, level: 5 },
  4990: { id: 4990, name: "Bird Food", parent: 3, level: 5 },
  4991: { id: 4991, name: "Bird Ladders & Perches", parent: 3, level: 5 },
  4992: { id: 4992, name: "Bird Toys", parent: 3, level: 5 },
  4993: { id: 4993, name: "Bird Treats", parent: 3, level: 5 },
  4997: { id: 4997, name: "Cat Furniture", parent: 4, level: 5 },
  4999: { id: 4999, name: "Cat Litter", parent: 4, level: 5 },
  5000: { id: 5000, name: "Cat Litter Boxes", parent: 4, level: 5 },
  5001: { id: 5001, name: "Cat Toys", parent: 4, level: 5 },
  5002: { id: 5002, name: "Cat Treats", parent: 4, level: 5 },
  5004: { id: 5004, name: "Dog Apparel", parent: 5, level: 5 },
  5010: { id: 5010, name: "Dog Toys", parent: 5, level: 5 },
  5011: { id: 5011, name: "Dog Treats", parent: 5, level: 5 },
  5013: {
    id: 5013,
    name: "Small Animal Supplies",
    children: [5014, 5015, 5016, 5017, 7517],
    parent: 2,
    level: 4,
  },
  5014: { id: 5014, name: "Small Animal Bedding", parent: 5013, level: 5 },
  5015: { id: 5015, name: "Small Animal Food", parent: 5013, level: 5 },
  5016: {
    id: 5016,
    name: "Small Animal Habitat Accessories",
    parent: 5013,
    level: 5,
  },
  5017: {
    id: 5017,
    name: "Small Animal Habitats & Cages",
    parent: 5013,
    level: 5,
  },
  5019: { id: 5019, name: "Aquarium Decor", parent: 6, level: 5 },
  5020: { id: 5020, name: "Aquarium Filters", parent: 6, level: 5 },
  5021: { id: 5021, name: "Aquarium Gravel & Substrates", parent: 6, level: 5 },
  5023: { id: 5023, name: "Aquarium Stands", parent: 6, level: 5 },
  5024: { id: 5024, name: "Fish Food", parent: 6, level: 5 },
  5025: {
    id: 5025,
    name: "Horse Grooming",
    children: [6386, 499818],
    parent: 3257,
    level: 6,
  },
  5026: { id: 5026, name: "Reptile & Amphibian Food", parent: 7, level: 5 },
  5027: {
    id: 5027,
    name: "Reptile & Amphibian Habitat Accessories",
    parent: 7,
    level: 5,
  },
  5028: {
    id: 5028,
    name: "Reptile & Amphibian Habitat Heating & Lighting",
    parent: 7,
    level: 5,
  },
  5029: { id: 5029, name: "Reptile & Amphibian Habitats", parent: 7, level: 5 },
  5030: {
    id: 5030,
    name: "Reptile & Amphibian Substrates",
    parent: 7,
    level: 5,
  },
  5032: {
    id: 5032,
    name: "Digital Goods & Currency",
    children: [5034, 5035, 500046, 8022, 5036, 2065, 5935],
    parent: 2092,
    level: 3,
  },
  5034: { id: 5034, name: "Computer Icons", parent: 5032, level: 4 },
  5035: { id: 5035, name: "Desktop Wallpaper", parent: 5032, level: 4 },
  5036: { id: 5036, name: "Fonts", parent: 5032, level: 4 },
  5037: {
    id: 5037,
    name: "Product Manuals",
    children: [499821, 5038, 5861, 5039, 5040, 5860, 499866, 7516, 5041],
    parent: 783,
    level: 3,
  },
  5038: { id: 5038, name: "Electronics Manuals", parent: 5037, level: 4 },
  5039: {
    id: 5039,
    name: "Household Appliance Manuals",
    parent: 5037,
    level: 4,
  },
  5040: { id: 5040, name: "Kitchen Appliance Manuals", parent: 5037, level: 4 },
  5041: { id: 5041, name: "Vehicle Service Manuals", parent: 5037, level: 4 },
  5042: {
    id: 5042,
    name: "Ice Crusher & Shaver Accessories",
    parent: 2901,
    level: 5,
  },
  5043: { id: 5043, name: "Drinking Straws & Stirrers", parent: 96, level: 5 },
  5044: { id: 5044, name: "Outdoor Benches", parent: 6368, level: 5 },
  5046: { id: 5046, name: "Harmonica Holders", parent: 4955, level: 7 },
  5048: { id: 5048, name: "Shower Water Filters", parent: 2206, level: 6 },
  5049: {
    id: 5049,
    name: "Baby Safety Harnesses & Leashes",
    parent: 540,
    level: 4,
  },
  5050: {
    id: 5050,
    name: "Ski & Snowboard Goggle Lenses",
    parent: 8203,
    level: 7,
  },
  5054: { id: 5054, name: "Fudge", parent: 1876, level: 5 },
  5055: { id: 5055, name: "Erotic Food & Edibles", parent: 773, level: 4 },
  5056: {
    id: 5056,
    name: "Trash Compactor Accessories",
    parent: 630,
    level: 4,
  },
  5057: { id: 5057, name: "Soy Milk Makers", parent: 730, level: 5 },
  5059: {
    id: 5059,
    name: "Straw Holders & Dispensers",
    parent: 2948,
    level: 6,
  },
  5060: { id: 5060, name: "Replacement Drink Lids", parent: 2920, level: 5 },
  5062: { id: 5062, name: "Craft Pipe Cleaners", parent: 505377, level: 7 },
  5065: {
    id: 5065,
    name: "Coffee Maker Water Filters",
    parent: 3988,
    level: 6,
  },
  5066: { id: 5066, name: "Frothing Pitchers", parent: 3988, level: 6 },
  5067: { id: 5067, name: "Gun Lights", parent: 2214, level: 5 },
  5068: { id: 5068, name: "Vehicle Jump Starters", parent: 8236, level: 6 },
  5070: { id: 5070, name: "Greeting Card Organizers", parent: 923, level: 4 },
  5071: {
    id: 5071,
    name: "Biometric Monitor Accessories",
    children: [505819, 3688, 6284, 5072],
    parent: 491,
    level: 4,
  },
  5072: {
    id: 5072,
    name: "Body Weight Scale Accessories",
    parent: 5071,
    level: 5,
  },
  5075: { id: 5075, name: "Popcorn Maker Accessories", parent: 2901, level: 5 },
  5076: {
    id: 5076,
    name: "Cotton Candy Machine Accessories",
    parent: 2901,
    level: 5,
  },
  5077: { id: 5077, name: "Milling Machines", parent: 1167, level: 4 },
  5078: { id: 5078, name: "Carving Forks", parent: 668, level: 5 },
  5079: { id: 5079, name: "Aquarium Lighting", parent: 6, level: 5 },
  5080: { id: 5080, name: "Sink Mats & Grids", parent: 668, level: 5 },
  5081: { id: 5081, name: "Pet Vitamins & Supplements", parent: 2, level: 4 },
  5082: { id: 5082, name: "Cat Apparel", parent: 4, level: 5 },
  5084: { id: 5084, name: "Laundry Balls", parent: 627, level: 5 },
  5085: { id: 5085, name: "Laundry Wash Bags & Frames", parent: 627, level: 5 },
  5086: { id: 5086, name: "Pet Medicine", parent: 2, level: 4 },
  5087: { id: 5087, name: "Pet Playpens", parent: 2, level: 4 },
  5088: { id: 5088, name: "Snowboard Binding Parts", parent: 499845, level: 6 },
  5089: { id: 5089, name: "Fan Accessories", parent: 3348, level: 4 },
  5090: {
    id: 5090,
    name: "Chocolate Tempering Machines",
    parent: 730,
    level: 5,
  },
  5091: { id: 5091, name: "Gift Boxes & Tins", parent: 94, level: 6 },
  5092: { id: 5092, name: "Pet Bells & Charms", parent: 2, level: 4 },
  5093: { id: 5093, name: "Pet ID Tags", parent: 2, level: 4 },
  5094: { id: 5094, name: "Dog Houses", parent: 5, level: 5 },
  5096: { id: 5096, name: "Music Composition Software", parent: 319, level: 5 },
  5097: { id: 5097, name: "Take-Out Containers", parent: 135, level: 4 },
  5098: { id: 5098, name: "Disposable Bowls", parent: 4632, level: 5 },
  5099: { id: 5099, name: "Disposable Cups", parent: 4632, level: 5 },
  5100: { id: 5100, name: "Disposable Cutlery", parent: 4632, level: 5 },
  5101: { id: 5101, name: "Disposable Plates", parent: 4632, level: 5 },
  5102: { id: 5102, name: "Concession Food Containers", parent: 135, level: 4 },
  5103: {
    id: 5103,
    name: "Food Warmers",
    children: [6548, 5349, 504633, 4292],
    parent: 730,
    level: 5,
  },
  5104: { id: 5104, name: "Plate & Dish Warmers", parent: 135, level: 4 },
  5105: { id: 5105, name: "Floss Sugar", parent: 2660, level: 5 },
  5106: { id: 5106, name: "Motorcycle Goggles", parent: 5547, level: 6 },
  5107: { id: 5107, name: "Yoga Mat Bags & Straps", parent: 999, level: 5 },
  5109: { id: 5109, name: "Ricers", parent: 668, level: 5 },
  5110: { id: 5110, name: "Grill Presses", parent: 654, level: 6 },
  5111: { id: 5111, name: "Shaving Kits", parent: 528, level: 5 },
  5112: { id: 5112, name: "USB Drive Duplicators", parent: 5268, level: 7 },
  5113: { id: 5113, name: "Cleaning Gloves", parent: 623, level: 5 },
  5114: { id: 5114, name: "Decorative Fans", parent: 167, level: 4 },
  5119: { id: 5119, name: "Twine", parent: 502977, level: 5 },
  5120: { id: 5120, name: "Sewing Machine Feet", parent: 504643, level: 6 },
  5121: { id: 5121, name: "Sewing Machine Tables", parent: 6392, level: 4 },
  5122: {
    id: 5122,
    name: "Watch Accessories",
    children: [5123, 7471, 6870],
    parent: 188,
    level: 4,
  },
  5123: { id: 5123, name: "Watch Bands", parent: 5122, level: 5 },
  5124: { id: 5124, name: "Watch Repair Kits", parent: 5573, level: 4 },
  5127: {
    id: 5127,
    name: "Dictionary & Translation Software",
    parent: 313,
    level: 4,
  },
  5128: { id: 5128, name: "Flatware Chests", parent: 636, level: 5 },
  5129: {
    id: 5129,
    name: "Audio Transmitters",
    children: [5130, 4035],
    parent: 2165,
    level: 5,
  },
  5130: { id: 5130, name: "Bluetooth Transmitters", parent: 5129, level: 6 },
  5133: { id: 5133, name: "PDA Batteries", parent: 276, level: 6 },
  5134: { id: 5134, name: "Honey Jars", parent: 2626, level: 5 },
  5135: { id: 5135, name: "Cake Boards", parent: 4026, level: 6 },
  5136: { id: 5136, name: "Craft Knives", parent: 504640, level: 7 },
  5137: { id: 5137, name: "Cutting Mats", parent: 504639, level: 6 },
  5138: { id: 5138, name: "Garment Steamers", parent: 2706, level: 5 },
  5139: { id: 5139, name: "Irons & Ironing Systems", parent: 2706, level: 5 },
  5140: { id: 5140, name: "Steam Presses", parent: 2706, level: 5 },
  5141: { id: 5141, name: "Hand Dryers", parent: 574, level: 4 },
  5142: { id: 5142, name: "Power Inverters", parent: 127, level: 4 },
  5143: {
    id: 5143,
    name: "Hazardous Waste Containers",
    parent: 4516,
    level: 5,
  },
  5144: { id: 5144, name: "Pet Muzzles", parent: 2, level: 4 },
  5145: { id: 5145, name: "Pet Medical Collars", parent: 2, level: 4 },
  5150: { id: 5150, name: "Model Trains & Train Sets", parent: 5999, level: 5 },
  5151: { id: 5151, name: "Model Train Accessories", parent: 5999, level: 5 },
  5152: { id: 5152, name: "Toy Trains & Train Sets", parent: 2505, level: 5 },
  5153: { id: 5153, name: "Toy Train Accessories", parent: 5970, level: 5 },
  5154: {
    id: 5154,
    name: "Toothpaste Squeezers & Dispensers",
    parent: 526,
    level: 5,
  },
  5155: { id: 5155, name: "Denture Adhesives", parent: 526, level: 5 },
  5156: { id: 5156, name: "Frozen Drink Makers", parent: 730, level: 5 },
  5157: { id: 5157, name: "Knife Blocks & Holders", parent: 2948, level: 6 },
  5158: {
    id: 5158,
    name: "Garment Steamer Accessories",
    parent: 3456,
    level: 5,
  },
  5159: { id: 5159, name: "Iron Accessories", parent: 3456, level: 5 },
  5160: { id: 5160, name: "Steam Press Accessories", parent: 3456, level: 5 },
  5161: { id: 5161, name: "Aquarium Water Treatments", parent: 6, level: 5 },
  5162: { id: 5162, name: "Pet Food Containers", parent: 2, level: 4 },
  5163: { id: 5163, name: "Pet Food Scoops", parent: 2, level: 4 },
  5164: {
    id: 5164,
    name: "Walking Aids",
    children: [5165, 4248, 5166],
    parent: 519,
    level: 5,
  },
  5165: { id: 5165, name: "Canes & Walking Sticks", parent: 5164, level: 6 },
  5166: { id: 5166, name: "Walkers", parent: 5164, level: 6 },
  5167: {
    id: 5167,
    name: "Medical Furniture",
    children: [5168, 5169, 4435, 5170, 5171, 5172],
    parent: 2496,
    level: 4,
  },
  5168: { id: 5168, name: "Chiropractic Tables", parent: 5167, level: 5 },
  5169: {
    id: 5169,
    name: "Examination Chairs & Tables",
    parent: 5167,
    level: 5,
  },
  5170: { id: 5170, name: "Medical Cabinets", parent: 5167, level: 5 },
  5171: {
    id: 5171,
    name: "Medical Carts",
    children: [5173, 5174],
    parent: 5167,
    level: 5,
  },
  5172: { id: 5172, name: "Surgical Tables", parent: 5167, level: 5 },
  5173: { id: 5173, name: "Crash Carts", parent: 5171, level: 6 },
  5174: { id: 5174, name: "IV Poles & Carts", parent: 5171, level: 6 },
  5181: {
    icon: <LuggageBags />,
    id: 5181,
    name: "Luggage & Bags",
    children: [
      100, 101, 108, 549, 502974, 103, 104, 105, 110, 106, 5608, 107, 6553,
    ],
    parent: 0,
    level: 1,
  },
  5182: {
    id: 5182,
    name: "One-Pieces",
    children: [5250, 5490, 7132],
    parent: 1604,
    level: 4,
  },
  5183: { id: 5183, name: "Pant Suits", parent: 1594, level: 5 },
  5192: {
    id: 5192,
    name: "Costume Accessories",
    children: [7304, 8017, 5907, 8200, 5426, 500118, 500008, 8018],
    parent: 184,
    level: 4,
  },
  5193: { id: 5193, name: "Costumes", parent: 184, level: 4 },
  5194: { id: 5194, name: "Masks", parent: 184, level: 4 },
  5207: { id: 5207, name: "Handkerchiefs", parent: 167, level: 4 },
  5250: { id: 5250, name: "Jumpsuits & Rompers", parent: 5182, level: 5 },
  5251: { id: 5251, name: "Kitchen Shears", parent: 668, level: 5 },
  5252: {
    id: 5252,
    name: "Baby Health",
    children: [6290, 5253, 7016, 7309, 566],
    parent: 537,
    level: 3,
  },
  5253: { id: 5253, name: "Nasal Aspirators", parent: 5252, level: 4 },
  5254: { id: 5254, name: "Barebone Computers", parent: 278, level: 4 },
  5255: { id: 5255, name: "Interactive Kiosks", parent: 278, level: 4 },
  5256: { id: 5256, name: "Data Collectors", parent: 298, level: 5 },
  5257: { id: 5257, name: "Projector Mounts", parent: 393, level: 6 },
  5258: {
    id: 5258,
    name: "Printer Consumables",
    children: [5259, 5266, 5262, 5260, 5261, 7362, 356],
    parent: 502990,
    level: 5,
  },
  5259: { id: 5259, name: "Printer Drums & Drum Kits", parent: 5258, level: 6 },
  5260: { id: 5260, name: "Printer Ribbons", parent: 5258, level: 6 },
  5261: { id: 5261, name: "Printheads", parent: 5258, level: 6 },
  5262: { id: 5262, name: "Printer Maintenance Kits", parent: 5258, level: 6 },
  5264: { id: 5264, name: "Blank ID Cards", parent: 956, level: 5 },
  5265: { id: 5265, name: "Printer Duplexers", parent: 502990, level: 5 },
  5266: { id: 5266, name: "Printer Filters", parent: 5258, level: 6 },
  5268: {
    id: 5268,
    name: "Disk Duplicators",
    children: [376, 5271, 5112],
    parent: 2414,
    level: 6,
  },
  5269: { id: 5269, name: "Network Storage Systems", parent: 2414, level: 6 },
  5271: { id: 5271, name: "Hard Drive Duplicators", parent: 5268, level: 7 },
  5272: { id: 5272, name: "Hard Drive Arrays", parent: 2414, level: 6 },
  5273: { id: 5273, name: "Patch Panels", parent: 3328, level: 5 },
  5274: { id: 5274, name: "Power Supply Enclosures", parent: 275, level: 5 },
  5275: { id: 5275, name: "Caller IDs", parent: 262, level: 4 },
  5276: {
    id: 5276,
    name: "Streaming & Home Media Players",
    parent: 387,
    level: 5,
  },
  5278: { id: 5278, name: "Video Servers", parent: 386, level: 4 },
  5280: { id: 5280, name: "Video Camera Lenses", parent: 149, level: 5 },
  5282: {
    id: 5282,
    name: "Binocular & Monocular Accessories",
    parent: 160,
    level: 5,
  },
  5283: { id: 5283, name: "Rangefinder Accessories", parent: 160, level: 5 },
  5284: { id: 5284, name: "Telescope Accessories", parent: 160, level: 5 },
  5286: { id: 5286, name: "Vacuum Coffee Makers", parent: 736, level: 6 },
  5287: { id: 5287, name: "Beverage Warmers", parent: 730, level: 5 },
  5289: {
    id: 5289,
    name: "Toasters & Grills",
    children: [761, 6819, 5318, 6278, 5291, 6516, 759, 762, 5292, 764],
    parent: 730,
    level: 5,
  },
  5291: { id: 5291, name: "Pizzelle Makers", parent: 5289, level: 6 },
  5292: {
    id: 5292,
    name: "Tortilla & Flatbread Makers",
    parent: 5289,
    level: 6,
  },
  5294: { id: 5294, name: "Floor Polishers & Buffers", parent: 604, level: 4 },
  5295: { id: 5295, name: "Dental Water Jets", parent: 526, level: 5 },
  5296: {
    id: 5296,
    name: "Bottle Warmers & Sterilizers",
    parent: 561,
    level: 4,
  },
  5298: { id: 5298, name: "Nursing Pillows", parent: 561, level: 4 },
  5299: {
    id: 5299,
    name: "Antivirus & Security Software",
    parent: 313,
    level: 4,
  },
  5300: {
    id: 5300,
    name: "Business & Productivity Software",
    parent: 313,
    level: 4,
  },
  5301: {
    id: 5301,
    name: "Computer Utilities & Maintenance Software",
    parent: 313,
    level: 4,
  },
  5302: { id: 5302, name: "Network Software", parent: 313, level: 4 },
  5303: {
    id: 5303,
    name: "Office Application Software",
    parent: 313,
    level: 4,
  },
  5304: {
    id: 5304,
    name: "Financial, Tax & Accounting Software",
    parent: 313,
    level: 4,
  },
  5308: { id: 5308, name: "Stylus Pens", parent: 279, level: 5 },
  5309: { id: 5309, name: "Digital Note Taking Pens", parent: 1928, level: 6 },
  5310: { id: 5310, name: "Signature Capture Pads", parent: 505825, level: 6 },
  5311: { id: 5311, name: "Collectible Knives", parent: 220, level: 6 },
  5312: { id: 5312, name: "Diving Knives & Shears", parent: 1135, level: 6 },
  5317: { id: 5317, name: "Hair Curler Clips & Pins", parent: 6018, level: 6 },
  5318: { id: 5318, name: "Muffin & Cupcake Makers", parent: 5289, level: 6 },
  5319: { id: 5319, name: "Foam Rollers", parent: 990, level: 4 },
  5322: {
    id: 5322,
    name: "Activewear",
    children: [3951, 5697, 5378, 499979, 5460, 5379, 5517, 5555],
    parent: 1604,
    level: 4,
  },
  5327: { id: 5327, name: "Jock Straps", parent: 213, level: 5 },
  5329: { id: 5329, name: "Wedding Dresses", parent: 5441, level: 5 },
  5330: { id: 5330, name: "Bridal Party Dresses", parent: 5441, level: 5 },
  5338: { id: 5338, name: "Self Tanner", parent: 2740, level: 7 },
  5339: { id: 5339, name: "Tanning Oil & Lotion", parent: 2740, level: 7 },
  5340: {
    id: 5340,
    name: "Tagines & Clay Cooking Pots",
    parent: 654,
    level: 6,
  },
  5343: {
    id: 5343,
    name: "Kimonos",
    children: [5684, 5683, 5682, 5681, 5680, 5679],
    parent: 5388,
    level: 5,
  },
  5344: { id: 5344, name: "Skorts", parent: 1604, level: 4 },
  5346: { id: 5346, name: "Surveillance Camera Lenses", parent: 149, level: 5 },
  5349: { id: 5349, name: "Food Heat Lamps", parent: 5103, level: 6 },
  5359: { id: 5359, name: "Advent Calendars", parent: 596, level: 5 },
  5362: {
    id: 5362,
    name: "Snow Removal",
    children: [5364, 5363],
    parent: 689,
    level: 4,
  },
  5363: { id: 5363, name: "Snow Shovels", parent: 5362, level: 5 },
  5364: {
    id: 5364,
    name: "Ice Scrapers & Snow Brushes",
    parent: 5362,
    level: 5,
  },
  5366: { id: 5366, name: "Fingerprint Readers", parent: 1928, level: 6 },
  5367: { id: 5367, name: "Capos", parent: 3502, level: 7 },
  5368: { id: 5368, name: "Guitar Slides", parent: 3502, level: 7 },
  5369: { id: 5369, name: "Phono Preamps", parent: 246, level: 6 },
  5370: { id: 5370, name: "Flour Sifters", parent: 668, level: 5 },
  5371: { id: 5371, name: "Geiger Counters", parent: 1305, level: 5 },
  5378: { id: 5378, name: "Boxing Shorts", parent: 5322, level: 5 },
  5379: { id: 5379, name: "Martial Arts Shorts", parent: 5322, level: 5 },
  5380: { id: 5380, name: "Surge Protection Devices", parent: 275, level: 5 },
  5381: { id: 5381, name: "Power Amplifiers", parent: 224, level: 6 },
  5385: { id: 5385, name: "Shoe Covers", parent: 1933, level: 4 },
  5387: { id: 5387, name: "Costume Shoes", parent: 184, level: 4 },
  5388: {
    id: 5388,
    name: "Traditional & Ceremonial Clothing",
    children: [6031, 5674, 6227, 5673, 5343, 5483, 8248, 7281, 5676],
    parent: 1604,
    level: 4,
  },
  5390: {
    id: 5390,
    name: "Traditional Clothing Accessories",
    children: [5688, 5686, 5687, 5685],
    parent: 167,
    level: 4,
  },
  5394: { id: 5394, name: "Crampons", parent: 499815, level: 6 },
  5395: {
    id: 5395,
    name: "Karaoke System Accessories",
    children: [5396],
    parent: 1420,
    level: 5,
  },
  5396: { id: 5396, name: "Karaoke Chips", parent: 5395, level: 6 },
  5399: {
    id: 5399,
    name: "Wetsuit Hoods, Gloves & Boots",
    parent: 499814,
    level: 7,
  },
  5400: { id: 5400, name: "Wetsuit Bottoms", parent: 499814, level: 7 },
  5401: { id: 5401, name: "Wetsuit Tops", parent: 499814, level: 7 },
  5402: { id: 5402, name: "Trail Cameras", parent: 142, level: 4 },
  5403: { id: 5403, name: "Poker Chips & Sets", parent: 3793, level: 4 },
  5404: { id: 5404, name: "Intercom Accessories", parent: 262, level: 4 },
  5406: { id: 5406, name: "Fishing & Hunting Waders", parent: 3334, level: 5 },
  5408: { id: 5408, name: "Baby & Toddler Bottoms", parent: 182, level: 5 },
  5409: { id: 5409, name: "Baby & Toddler Swimwear", parent: 182, level: 5 },
  5410: { id: 5410, name: "Baby & Toddler Tops", parent: 182, level: 5 },
  5411: { id: 5411, name: "Baby One-Pieces", parent: 182, level: 5 },
  5412: { id: 5412, name: "Baby & Toddler Sleepwear", parent: 182, level: 5 },
  5422: {
    id: 5422,
    name: "Baby & Toddler Clothing Accessories",
    children: [5623, 5624, 5625, 5626],
    parent: 167,
    level: 4,
  },
  5423: {
    id: 5423,
    name: "Baby & Toddler Socks & Tights",
    parent: 182,
    level: 5,
  },
  5424: { id: 5424, name: "Baby & Toddler Dresses", parent: 182, level: 5 },
  5425: { id: 5425, name: "Baby & Toddler Outerwear", parent: 182, level: 5 },
  5426: { id: 5426, name: "Costume Hats", parent: 5192, level: 5 },
  5429: { id: 5429, name: "Camera Straps", parent: 143, level: 5 },
  5431: { id: 5431, name: "Flash Reflectors", parent: 2475, level: 6 },
  5432: { id: 5432, name: "Softboxes", parent: 2475, level: 6 },
  5434: { id: 5434, name: "Multitrack Recorders", parent: 242, level: 5 },
  5435: { id: 5435, name: "Crossovers", parent: 246, level: 6 },
  5438: {
    id: 5438,
    name: "Motor Vehicle Cassette Players",
    parent: 8526,
    level: 5,
  },
  5441: {
    id: 5441,
    name: "Wedding & Bridal Party Dresses",
    children: [5330, 5329],
    parent: 1604,
    level: 4,
  },
  5443: {
    id: 5443,
    name: "Bridal Accessories",
    children: [5446],
    parent: 167,
    level: 4,
  },
  5446: { id: 5446, name: "Bridal Veils", parent: 5443, level: 5 },
  5450: { id: 5450, name: "Video Transmitters", parent: 386, level: 4 },
  5452: {
    id: 5452,
    name: "Party Favors",
    children: [5453],
    parent: 96,
    level: 5,
  },
  5453: { id: 5453, name: "Wedding Favors", parent: 5452, level: 6 },
  5455: {
    id: 5455,
    name: "Wedding Ceremony Supplies",
    children: [503723, 5456, 5457],
    parent: 5605,
    level: 3,
  },
  5456: { id: 5456, name: "Flower Girl Baskets", parent: 5455, level: 4 },
  5457: { id: 5457, name: "Ring Pillows & Holders", parent: 5455, level: 4 },
  5459: { id: 5459, name: "Printer Stands", parent: 502990, level: 5 },
  5460: {
    id: 5460,
    name: "Hunting Clothing",
    children: [5462, 5461, 5552],
    parent: 5322,
    level: 5,
  },
  5461: { id: 5461, name: "Hunting & Fishing Vests", parent: 5460, level: 6 },
  5462: { id: 5462, name: "Ghillie Suits", parent: 5460, level: 6 },
  5463: { id: 5463, name: "Motorcycle Suits", parent: 5517, level: 6 },
  5466: {
    id: 5466,
    name: "Electronics Films & Shields",
    children: [5523, 5469, 5467, 5468],
    parent: 2082,
    level: 4,
  },
  5467: { id: 5467, name: "Privacy Filters", parent: 5466, level: 5 },
  5468: { id: 5468, name: "Screen Protectors", parent: 5466, level: 5 },
  5469: { id: 5469, name: "Keyboard Protectors", parent: 5466, level: 5 },
  5471: { id: 5471, name: "TV Replacement Lamps", parent: 403, level: 6 },
  5472: { id: 5472, name: "Place Card Holders", parent: 96, level: 5 },
  5473: { id: 5473, name: "Signal Boosters", parent: 2082, level: 4 },
  5476: {
    id: 5476,
    name: "Antenna Accessories",
    children: [5477, 5478, 6016],
    parent: 2082,
    level: 4,
  },
  5477: { id: 5477, name: "Antenna Mounts & Brackets", parent: 5476, level: 5 },
  5478: { id: 5478, name: "Antenna Rotators", parent: 5476, level: 5 },
  5479: { id: 5479, name: "Camera Flash Accessories", parent: 143, level: 5 },
  5481: { id: 5481, name: "Melodicas", parent: 87, level: 6 },
  5483: {
    id: 5483,
    name: "Religious Ceremonial Clothing",
    children: [8149],
    parent: 5388,
    level: 5,
  },
  5484: {
    id: 5484,
    name: "Contractor Pants & Coveralls",
    parent: 2306,
    level: 5,
  },
  5487: { id: 5487, name: "Distance Meters", parent: 1305, level: 5 },
  5488: {
    id: 5488,
    name: "Accessibility Furniture & Fixtures",
    children: [7243],
    parent: 519,
    level: 5,
  },
  5489: { id: 5489, name: "Computer Risers & Stands", parent: 279, level: 5 },
  5490: { id: 5490, name: "Leotards & Unitards", parent: 5182, level: 5 },
  5491: { id: 5491, name: "Dummy Surveillance Cameras", parent: 359, level: 4 },
  5493: { id: 5493, name: "Outdoor Umbrella Bases", parent: 6751, level: 6 },
  5494: { id: 5494, name: "Umbrella Stands & Racks", parent: 2446, level: 6 },
  5496: { id: 5496, name: "Wireless Access Points", parent: 1350, level: 5 },
  5497: { id: 5497, name: "Wireless Routers", parent: 1350, level: 5 },
  5499: { id: 5499, name: "Light Meter Accessories", parent: 42, level: 5 },
  5502: { id: 5502, name: "Label Tapes & Refill Rolls", parent: 960, level: 5 },
  5503: { id: 5503, name: "TV Converter Boxes", parent: 403, level: 6 },
  5504: { id: 5504, name: "Christmas Tree Skirts", parent: 596, level: 5 },
  5506: { id: 5506, name: "Chaps", parent: 203, level: 5 },
  5507: { id: 5507, name: "Eyewear Cases & Holders", parent: 2521, level: 6 },
  5508: { id: 5508, name: "Bathtub Skirts", parent: 2996, level: 6 },
  5512: {
    id: 5512,
    name: "Motorcycle Bags & Panniers",
    parent: 8237,
    level: 5,
  },
  5513: { id: 5513, name: "Nightgowns", parent: 208, level: 5 },
  5514: { id: 5514, name: "Rain Pants", parent: 203, level: 5 },
  5515: { id: 5515, name: "Air Quality Meters", parent: 1305, level: 5 },
  5516: { id: 5516, name: "Color Calibrators", parent: 283, level: 6 },
  5517: {
    id: 5517,
    name: "Motorcycle Protective Clothing",
    children: [6006, 7003, 5463],
    parent: 5322,
    level: 5,
  },
  5521: {
    id: 5521,
    name: "Bird & Wildlife Feeder Accessories",
    parent: 696,
    level: 4,
  },
  5523: {
    id: 5523,
    name: "Electronics Stickers & Decals",
    parent: 5466,
    level: 5,
  },
  5524: { id: 5524, name: "Trampoline Accessories", parent: 1249, level: 4 },
  5525: {
    id: 5525,
    name: "Motor Vehicle Cassette Adapters",
    parent: 8526,
    level: 5,
  },
  5526: {
    id: 5526,
    name: "Measuring Tool & Sensor Accessories",
    children: [5557, 5556, 503007, 7415],
    parent: 3650,
    level: 4,
  },
  5529: { id: 5529, name: "Reading Toys", parent: 1262, level: 5 },
  5531: { id: 5531, name: "Card Files", parent: 923, level: 4 },
  5532: { id: 5532, name: "Camera Remote Controls", parent: 143, level: 5 },
  5533: { id: 5533, name: "Power Converters", parent: 127, level: 4 },
  5537: { id: 5537, name: "Dinnerware Sets", parent: 673, level: 6 },
  5540: {
    id: 5540,
    name: "Bicycle Computer Accessories",
    parent: 3214,
    level: 6,
  },
  5542: { id: 5542, name: "Spotting Scope Accessories", parent: 160, level: 5 },
  5543: {
    id: 5543,
    name: "Weapon Scope & Sight Accessories",
    parent: 160,
    level: 5,
  },
  5545: { id: 5545, name: "Optics Bags & Cases", parent: 160, level: 5 },
  5546: { id: 5546, name: "Pool Water Slides", parent: 2832, level: 5 },
  5547: {
    id: 5547,
    name: "Motorcycle Protective Gear",
    children: [5959, 5963, 5908, 5106, 8507, 6493, 2110, 5960, 5962, 5961],
    parent: 8301,
    level: 5,
  },
  5549: {
    id: 5549,
    name: "Baby & Toddler Diaper Covers",
    parent: 182,
    level: 5,
  },
  5551: { id: 5551, name: "Pulse Oximeters", parent: 494, level: 5 },
  5552: { id: 5552, name: "Hunting & Tactical Pants", parent: 5460, level: 6 },
  5555: { id: 5555, name: "Paintball Clothing", parent: 5322, level: 5 },
  5556: { id: 5556, name: "Gas Detector Accessories", parent: 5526, level: 5 },
  5557: {
    id: 5557,
    name: "Electrical Testing Tool Accessories",
    parent: 5526,
    level: 5,
  },
  5558: {
    id: 5558,
    name: "Clothing & Closet Storage",
    children: [3722, 5714, 5716, 631, 7514, 5559],
    parent: 636,
    level: 5,
  },
  5559: { id: 5559, name: "Shoe Racks & Organizers", parent: 5558, level: 6 },
  5561: {
    id: 5561,
    name: "Satellite & Cable TV",
    children: [5562, 401],
    parent: 386,
    level: 4,
  },
  5562: { id: 5562, name: "Cable TV Receivers", parent: 5561, level: 5 },
  5564: { id: 5564, name: "Soccer Uniforms", parent: 3598, level: 6 },
  5566: { id: 5566, name: "Mobile Phone Stands", parent: 264, level: 6 },
  5567: { id: 5567, name: "Boot Liners", parent: 1933, level: 4 },
  5569: { id: 5569, name: "Horse Boots & Leg Wraps", parent: 3257, level: 6 },
  5570: { id: 5570, name: "Yogurt Maker Accessories", parent: 2901, level: 5 },
  5571: { id: 5571, name: "Tool Sockets", parent: 3650, level: 4 },
  5572: {
    id: 5572,
    name: "Motor Vehicle Speakerphones",
    parent: 8526,
    level: 5,
  },
  5573: {
    id: 5573,
    name: "Jewelry Cleaning & Care",
    children: [499919, 500082, 5974, 500083, 5124],
    parent: 469,
    level: 3,
  },
  5576: { id: 5576, name: "Modem Accessories", parent: 342, level: 4 },
  5577: { id: 5577, name: "Soda Makers", parent: 730, level: 5 },
  5578: { id: 5578, name: "Wine Racks", parent: 6356, level: 4 },
  5579: {
    id: 5579,
    name: "Kitesurfing",
    children: [5584, 5581, 5580, 5583, 5582],
    parent: 499811,
    level: 5,
  },
  5580: { id: 5580, name: "Kiteboards", parent: 5579, level: 6 },
  5581: { id: 5581, name: "Kiteboard Parts", parent: 5579, level: 6 },
  5582: { id: 5582, name: "Kitesurfing Kites", parent: 5579, level: 6 },
  5583: {
    id: 5583,
    name: "Kitesurfing & Windsurfing Harnesses",
    parent: 5579,
    level: 6,
  },
  5584: { id: 5584, name: "Kiteboard Cases", parent: 5579, level: 6 },
  5587: { id: 5587, name: "Multifunction Power Tools", parent: 1167, level: 4 },
  5588: {
    id: 5588,
    name: "Lens & Filter Adapter Rings",
    parent: 2911,
    level: 5,
  },
  5589: { id: 5589, name: "Radar Jammers", parent: 5695, level: 5 },
  5590: { id: 5590, name: "Aquatic Plants", parent: 985, level: 4 },
  5591: { id: 5591, name: "Safety Gloves", parent: 2047, level: 4 },
  5592: { id: 5592, name: "Work Lights", parent: 1167, level: 4 },
  5593: {
    id: 5593,
    name: "Horse Tack",
    children: [4018, 3426, 1491, 499710, 2756, 499709, 1754, 2210, 8109],
    parent: 1031,
    level: 5,
  },
  5594: {
    id: 5594,
    name: "Riding Apparel & Accessories",
    children: [3084, 3821, 3265, 6914],
    parent: 1031,
    level: 5,
  },
  5596: { id: 5596, name: "Noise Gates & Compressors", parent: 246, level: 6 },
  5597: {
    id: 5597,
    name: "Loudspeaker Management Systems",
    parent: 246,
    level: 6,
  },
  5598: { id: 5598, name: "Coats & Jackets", parent: 203, level: 5 },
  5599: { id: 5599, name: "Projection & Tripod Skirts", parent: 393, level: 6 },
  5600: { id: 5600, name: "Boot Pulls", parent: 628, level: 5 },
  5601: { id: 5601, name: "Shoe Horns & Dressing Aids", parent: 628, level: 5 },
  5602: { id: 5602, name: "Scrub Caps", parent: 2496, level: 4 },
  5604: { id: 5604, name: "Shoe Treatments & Dyes", parent: 628, level: 5 },
  5605: {
    icon: <ReligiousCeremonial />,
    id: 5605,
    name: "Religious & Ceremonial",
    children: [5606, 97, 5455],
    parent: 0,
    level: 1,
  },
  5606: {
    id: 5606,
    name: "Memorial Ceremony Supplies",
    children: [5607],
    parent: 5605,
    level: 3,
  },
  5607: { id: 5607, name: "Memorial Urns", parent: 5606, level: 4 },
  5608: { id: 5608, name: "Shopping Totes", parent: 5181, level: 3 },
  5609: { id: 5609, name: "Figurines", parent: 696, level: 4 },
  5612: { id: 5612, name: "GPS Jammers", parent: 5695, level: 5 },
  5613: {
    id: 5613,
    name: "Vehicle Parts & Accessories",
    children: [3977, 8526, 899, 913, 8301, 8237, 3391],
    parent: 888,
    level: 3,
  },
  5614: {
    id: 5614,
    name: "Vehicles",
    children: [3395, 1267, 3540],
    parent: 888,
    level: 3,
  },
  5620: { id: 5620, name: "Packing Organizers", parent: 110, level: 4 },
  5621: { id: 5621, name: "Toddler Underwear", parent: 182, level: 5 },
  5622: { id: 5622, name: "Baby & Toddler Outfits", parent: 182, level: 5 },
  5623: { id: 5623, name: "Baby & Toddler Belts", parent: 5422, level: 5 },
  5624: {
    id: 5624,
    name: "Baby & Toddler Gloves & Mittens",
    parent: 5422,
    level: 5,
  },
  5625: { id: 5625, name: "Baby & Toddler Hats", parent: 5422, level: 5 },
  5626: { id: 5626, name: "Baby Protective Wear", parent: 5422, level: 5 },
  5627: { id: 5627, name: "Electrical Plug Caps", parent: 127, level: 4 },
  5628: { id: 5628, name: "Changing Mats & Trays", parent: 548, level: 4 },
  5629: { id: 5629, name: "Burp Cloths", parent: 561, level: 4 },
  5630: {
    id: 5630,
    name: "Baby Bottle Nipples & Liners",
    children: [543637, 543636],
    parent: 561,
    level: 4,
  },
  5631: {
    id: 5631,
    name: "Household Storage Containers",
    parent: 636,
    level: 5,
  },
  5632: {
    id: 5632,
    name: "Gardening Accessories",
    children: [503756, 5633, 7184],
    parent: 2962,
    level: 5,
  },
  5633: { id: 5633, name: "Gardening Totes", parent: 5632, level: 6 },
  5635: { id: 5635, name: "Hydration Systems", parent: 1011, level: 4 },
  5636: { id: 5636, name: "Compression Sacks", parent: 1013, level: 5 },
  5642: { id: 5642, name: "Parchment Paper", parent: 3110, level: 6 },
  5644: { id: 5644, name: "Yachts", parent: 3540, level: 5 },
  5646: { id: 5646, name: "Water Softener Salt", parent: 2273, level: 5 },
  5647: { id: 5647, name: "Flatware Sets", parent: 675, level: 6 },
  5650: { id: 5650, name: "Travel Pouches", parent: 110, level: 4 },
  5651: { id: 5651, name: "Luggage Tags", parent: 110, level: 4 },
  5652: { id: 5652, name: "Luggage Straps", parent: 110, level: 4 },
  5654: { id: 5654, name: "Pool Heaters", parent: 2832, level: 5 },
  5655: {
    id: 5655,
    name: "Tent Accessories",
    children: [499680, 5656, 5658, 5657],
    parent: 1013,
    level: 5,
  },
  5656: { id: 5656, name: "Tent Footprints", parent: 5655, level: 6 },
  5657: { id: 5657, name: "Tent Vestibules", parent: 5655, level: 6 },
  5658: { id: 5658, name: "Tent Poles & Stakes", parent: 5655, level: 6 },
  5659: { id: 5659, name: "Network Bridges", parent: 1350, level: 5 },
  5663: {
    id: 5663,
    name: "Massage & Relaxation",
    children: [500060, 233420, 1442, 5664, 8530, 8135, 2074, 471],
    parent: 2915,
    level: 4,
  },
  5664: { id: 5664, name: "Massage Oil", parent: 5663, level: 5 },
  5665: { id: 5665, name: "Toilet Tanks", parent: 2691, level: 6 },
  5666: { id: 5666, name: "Toilet Tank Covers", parent: 2691, level: 6 },
  5669: { id: 5669, name: "Stylus Pen Nibs & Refills", parent: 279, level: 5 },
  5670: { id: 5670, name: "Outdoor Grill Carts", parent: 3684, level: 6 },
  5671: { id: 5671, name: "Smoking Chips & Pellets", parent: 3684, level: 6 },
  5672: { id: 5672, name: "Outdoor Grilling Planks", parent: 3684, level: 6 },
  5673: {
    id: 5673,
    name: "Kimono Outerwear",
    children: [5678, 5677],
    parent: 5388,
    level: 5,
  },
  5674: { id: 5674, name: "Hakama Trousers", parent: 5388, level: 5 },
  5676: { id: 5676, name: "Yukata", parent: 5388, level: 5 },
  5677: { id: 5677, name: "Kimono Coats", parent: 5673, level: 6 },
  5678: { id: 5678, name: "Haori Jackets", parent: 5673, level: 6 },
  5679: {
    id: 5679,
    name: "Tomesode & Houmongi Kimonos",
    parent: 5343,
    level: 6,
  },
  5680: { id: 5680, name: "Komon Kimonos", parent: 5343, level: 6 },
  5681: { id: 5681, name: "Iromuji Kimonos", parent: 5343, level: 6 },
  5682: { id: 5682, name: "Furisode Kimonos", parent: 5343, level: 6 },
  5683: { id: 5683, name: "Casual Kimonos", parent: 5343, level: 6 },
  5684: { id: 5684, name: "Bridal Kimonos", parent: 5343, level: 6 },
  5685: { id: 5685, name: "Tabi Socks", parent: 5390, level: 5 },
  5686: { id: 5686, name: "Obi Accessories", parent: 5390, level: 5 },
  5687: { id: 5687, name: "Obis", parent: 5390, level: 5 },
  5688: { id: 5688, name: "Kimono Underclothes", parent: 5390, level: 5 },
  5690: { id: 5690, name: "Hearing Aids", parent: 491, level: 4 },
  5693: { id: 5693, name: "Stopwatches", parent: 990, level: 4 },
  5694: { id: 5694, name: "Charcoal Briquettes", parent: 3684, level: 6 },
  5695: {
    id: 5695,
    name: "Signal Jammers",
    children: [5612, 5696, 5589],
    parent: 2082,
    level: 4,
  },
  5696: { id: 5696, name: "Mobile Phone Jammers", parent: 5695, level: 5 },
  5697: {
    id: 5697,
    name: "Bicycle Activewear",
    children: [3128, 3455, 3188, 6087, 3729],
    parent: 5322,
    level: 5,
  },
  5702: { id: 5702, name: "Nutrition Drinks & Shakes", parent: 2890, level: 5 },
  5704: { id: 5704, name: "Clothespins", parent: 627, level: 5 },
  5705: { id: 5705, name: "Fabric Shavers", parent: 627, level: 5 },
  5706: { id: 5706, name: "Ear Candles", parent: 506, level: 5 },
  5707: { id: 5707, name: "Utility Hooks", parent: 2446, level: 6 },
  5708: { id: 5708, name: "Coat & Hat Racks", parent: 696, level: 4 },
  5709: {
    id: 5709,
    name: "Party & Celebration",
    children: [2559, 96, 408, 5868],
    parent: 8,
    level: 3,
  },
  5710: {
    id: 5710,
    name: "Hobbies & Creative Arts",
    children: [16, 216, 3577, 33, 35, 5999, 55, 54],
    parent: 8,
    level: 3,
  },
  5711: { id: 5711, name: "Disco Balls", parent: 408, level: 5 },
  5713: { id: 5713, name: "Loungewear", parent: 208, level: 5 },
  5714: {
    id: 5714,
    name: "Closet Organizers & Garment Racks",
    parent: 5558,
    level: 6,
  },
  5716: { id: 5716, name: "Clothes Valets", parent: 5558, level: 6 },
  5718: { id: 5718, name: "Baby Drinks", parent: 562, level: 5 },
  5719: { id: 5719, name: "Baby Food", parent: 562, level: 5 },
  5720: { id: 5720, name: "Baby Snacks", parent: 562, level: 5 },
  5721: { id: 5721, name: "Baby Cereal", parent: 562, level: 5 },
  5723: { id: 5723, name: "Sports & Energy Drinks", parent: 413, level: 4 },
  5724: { id: 5724, name: "Non-Dairy Milk", parent: 413, level: 4 },
  5725: {
    id: 5725,
    name: "Cocktail Mixes",
    children: [543537, 543536],
    parent: 499676,
    level: 5,
  },
  5740: {
    id: 5740,
    name: "Dips & Spreads",
    children: [6204, 6831, 5785, 5742, 5741, 2188, 3965, 1702, 6784, 6830],
    parent: 422,
    level: 4,
  },
  5741: { id: 5741, name: "Hummus", parent: 5740, level: 5 },
  5742: { id: 5742, name: "Guacamole", parent: 5740, level: 5 },
  5743: { id: 5743, name: "Pudding & Gelatin Snacks", parent: 423, level: 5 },
  5744: { id: 5744, name: "Fruit Snacks", parent: 423, level: 5 },
  5745: { id: 5745, name: "Trail & Snack Mixes", parent: 423, level: 5 },
  5746: { id: 5746, name: "Croutons", parent: 423, level: 5 },
  5747: {
    id: 5747,
    name: "Cereal & Granola Bars",
    children: [543651, 543652],
    parent: 423,
    level: 5,
  },
  5748: { id: 5748, name: "Tortillas & Wraps", parent: 1876, level: 5 },
  5749: { id: 5749, name: "Pies & Tarts", parent: 1876, level: 5 },
  5750: { id: 5750, name: "Pastries & Scones", parent: 1876, level: 5 },
  5751: { id: 5751, name: "Donuts", parent: 1876, level: 5 },
  5752: {
    id: 5752,
    name: "Dough",
    children: [5755, 5756, 5753],
    parent: 2660,
    level: 5,
  },
  5753: { id: 5753, name: "Pie Crusts", parent: 5752, level: 6 },
  5755: { id: 5755, name: "Bread & Pastry Dough", parent: 5752, level: 6 },
  5756: { id: 5756, name: "Cookie & Brownie Dough", parent: 5752, level: 6 },
  5759: { id: 5759, name: "Pasta Sauce", parent: 427, level: 5 },
  5760: { id: 5760, name: "Olives & Capers", parent: 427, level: 5 },
  5762: { id: 5762, name: "Gravy", parent: 427, level: 5 },
  5763: { id: 5763, name: "Fish Sauce", parent: 427, level: 5 },
  5765: { id: 5765, name: "Shortening & Lard", parent: 2660, level: 5 },
  5766: { id: 5766, name: "Molasses", parent: 2660, level: 5 },
  5767: { id: 5767, name: "Marshmallows", parent: 2660, level: 5 },
  5768: { id: 5768, name: "Unflavored Gelatin", parent: 2660, level: 5 },
  5769: { id: 5769, name: "Frosting & Icing", parent: 2660, level: 5 },
  5770: { id: 5770, name: "Corn Syrup", parent: 2660, level: 5 },
  5771: { id: 5771, name: "Cooking Starch", parent: 2660, level: 5 },
  5773: { id: 5773, name: "Bread Crumbs", parent: 2660, level: 5 },
  5774: { id: 5774, name: "Baking Soda", parent: 2660, level: 5 },
  5775: { id: 5775, name: "Baking Flavors & Extracts", parent: 2660, level: 5 },
  5776: { id: 5776, name: "Baking Chocolate", parent: 2660, level: 5 },
  5777: { id: 5777, name: "Cooking Wine", parent: 2660, level: 5 },
  5778: { id: 5778, name: "Waffle & Pancake Mixes", parent: 2660, level: 5 },
  5785: { id: 5785, name: "Cream Cheese", parent: 5740, level: 5 },
  5786: { id: 5786, name: "Cream", parent: 428, level: 5 },
  5787: { id: 5787, name: "Sour Cream", parent: 428, level: 5 },
  5788: {
    id: 5788,
    name: "Frozen Desserts & Novelties",
    children: [499991, 6873, 5789],
    parent: 422,
    level: 4,
  },
  5789: { id: 5789, name: "Ice Pops", parent: 5788, level: 5 },
  5790: { id: 5790, name: "Ice Cream Cones", parent: 1876, level: 5 },
  5792: { id: 5792, name: "Salad Mixes", parent: 6622, level: 7 },
  5793: {
    id: 5793,
    name: "Fresh & Frozen Vegetables",
    children: [
      6716, 6570, 6568, 6577, 6580, 6587, 6591, 6590, 6592, 6808, 6596, 6595,
      6600, 6599, 6598, 6609, 6608, 6613, 6816, 6615, 6616, 6617, 6620, 6619,
      6618, 6622, 6631, 6630, 6628, 6627, 6644, 6643, 6653, 6657, 6655, 6664,
      6663, 6669, 6668, 6586, 6682, 6681, 6818, 503761, 505354, 6694, 6693,
      6585, 6692, 6704, 6703, 505329, 499905, 6701, 6700, 7193, 8515, 6705,
    ],
    parent: 430,
    level: 5,
  },
  5794: { id: 5794, name: "Fruit Sauces", parent: 430, level: 5 },
  5795: {
    id: 5795,
    name: "Fresh & Frozen Fruits",
    children: [
      6566, 6571, 6572, 6573, 6574, 6582, 6589, 6593, 6602, 503759, 6809, 6812,
      6614, 6810, 499906, 6626, 6625, 6624, 6633, 6640, 6639, 6638, 6813, 6647,
      6645, 6649, 6661, 6667, 6665, 6672, 6671, 6670, 6676, 6673, 6679, 6678,
      6688, 6687, 6691, 6594, 503760, 6814, 6698,
    ],
    parent: 430,
    level: 5,
  },
  5796: { id: 5796, name: "Dry Beans", parent: 430, level: 5 },
  5797: { id: 5797, name: "Canned & Prepared Beans", parent: 430, level: 5 },
  5798: { id: 5798, name: "Canned & Jarred Vegetables", parent: 430, level: 5 },
  5799: { id: 5799, name: "Canned & Jarred Fruits", parent: 430, level: 5 },
  5800: { id: 5800, name: "Pie & Pastry Fillings", parent: 2660, level: 5 },
  5804: { id: 5804, name: "Lunch & Deli Meats", parent: 4628, level: 6 },
  5805: { id: 5805, name: "Fresh & Frozen Meats", parent: 4628, level: 6 },
  5807: {
    id: 5807,
    name: "Tofu, Soy & Vegetarian Products",
    children: [6839, 6843, 5808, 5810, 5809],
    parent: 422,
    level: 4,
  },
  5808: { id: 5808, name: "Seitan", parent: 5807, level: 5 },
  5809: { id: 5809, name: "Tofu", parent: 5807, level: 5 },
  5810: { id: 5810, name: "Tempeh", parent: 5807, level: 5 },
  5811: { id: 5811, name: "Canned Meats", parent: 4628, level: 6 },
  5812: { id: 5812, name: "Fresh & Frozen Seafood", parent: 4629, level: 6 },
  5813: { id: 5813, name: "Canned Seafood", parent: 4629, level: 6 },
  5814: {
    id: 5814,
    name: "Prepared Foods",
    children: [499989, 499988],
    parent: 422,
    level: 4,
  },
  5820: { id: 5820, name: "Skin Insect Repellent", parent: 567, level: 6 },
  5821: { id: 5821, name: "Feminine Douches & Creams", parent: 485, level: 5 },
  5823: { id: 5823, name: "Dental Mouthguards", parent: 526, level: 5 },
  5824: { id: 5824, name: "Denture Cleaners", parent: 526, level: 5 },
  5825: {
    id: 5825,
    name: "Furniture Cleaners & Polish",
    parent: 4973,
    level: 6,
  },
  5826: { id: 5826, name: "Air Conditioner Covers", parent: 2367, level: 5 },
  5827: { id: 5827, name: "Butter & Margarine", parent: 428, level: 5 },
  5828: { id: 5828, name: "Oil Filter Drains", parent: 1167, level: 4 },
  5829: { id: 5829, name: "Impulse Sealers", parent: 922, level: 3 },
  5830: {
    id: 5830,
    name: "Industrial Storage",
    children: [5832, 5833, 5831, 8274],
    parent: 111,
    level: 3,
  },
  5831: { id: 5831, name: "Shipping Containers", parent: 5830, level: 4 },
  5832: { id: 5832, name: "Industrial Cabinets", parent: 5830, level: 4 },
  5833: { id: 5833, name: "Industrial Shelving", parent: 5830, level: 4 },
  5834: { id: 5834, name: "Underwear Slips", parent: 213, level: 5 },
  5835: {
    id: 5835,
    name: "Emergency Preparedness",
    children: [4490, 6897, 5836, 7058, 4491],
    parent: 536,
    level: 3,
  },
  5836: { id: 5836, name: "Emergency Food Kits", parent: 5835, level: 4 },
  5837: { id: 5837, name: "Twist Ties & Bag Clips", parent: 6478, level: 5 },
  5838: { id: 5838, name: "Gift Bags", parent: 94, level: 6 },
  5841: { id: 5841, name: "Wallet Chains", parent: 6552, level: 4 },
  5842: { id: 5842, name: "Bicycle Child Seats", parent: 3214, level: 6 },
  5843: { id: 5843, name: "Nursing Covers", parent: 561, level: 4 },
  5845: {
    id: 5845,
    name: "Shopping Cart & High Chair Covers",
    parent: 4386,
    level: 4,
  },
  5847: { id: 5847, name: "Fragrance Oil", parent: 592, level: 5 },
  5848: { id: 5848, name: "Heat Rubs", parent: 516, level: 6 },
  5849: {
    id: 5849,
    name: "Acupuncture",
    children: [5850, 5851],
    parent: 491,
    level: 4,
  },
  5850: { id: 5850, name: "Acupuncture Models", parent: 5849, level: 5 },
  5851: { id: 5851, name: "Acupuncture Needles", parent: 5849, level: 5 },
  5853: { id: 5853, name: "Tattoo Cover-Ups", parent: 4326, level: 5 },
  5859: { id: 5859, name: "Baby Gift Sets", parent: 537, level: 3 },
  5860: { id: 5860, name: "Model & Toys Manuals", parent: 5037, level: 4 },
  5861: {
    id: 5861,
    name: "Exercise & Fitness Equipment Manuals",
    parent: 5037,
    level: 4,
  },
  5863: {
    id: 5863,
    name: "Advertising & Marketing",
    children: [5884, 5864, 5865],
    parent: 111,
    level: 3,
  },
  5864: { id: 5864, name: "Trade Show Counters", parent: 5863, level: 4 },
  5865: { id: 5865, name: "Trade Show Displays", parent: 5863, level: 4 },
  5866: { id: 5866, name: "Tractors", parent: 3798, level: 5 },
  5867: {
    id: 5867,
    name: "Tractor Parts & Accessories",
    children: [499880, 499881],
    parent: 4564,
    level: 5,
  },
  5868: {
    id: 5868,
    name: "Trophies & Awards",
    children: [543656, 543655, 543657, 543654, 543653],
    parent: 5709,
    level: 4,
  },
  5869: { id: 5869, name: "Exercise Bands", parent: 990, level: 4 },
  5870: {
    id: 5870,
    name: "Occupational & Physical Therapy Equipment",
    children: [8541, 505352],
    parent: 491,
    level: 4,
  },
  5872: { id: 5872, name: "Magnet Toys", parent: 3074, level: 5 },
  5873: { id: 5873, name: "Magnetic Sweepers", parent: 1167, level: 4 },
  5875: { id: 5875, name: "Decorative Plates", parent: 696, level: 4 },
  5876: { id: 5876, name: "Refrigerator Magnets", parent: 696, level: 4 },
  5877: { id: 5877, name: "Air Suits", parent: 3789, level: 5 },
  5878: { id: 5878, name: "Flight Suits", parent: 2306, level: 5 },
  5879: { id: 5879, name: "Riding Scooters", parent: 1011, level: 4 },
  5880: { id: 5880, name: "Nail Dryers", parent: 2975, level: 7 },
  5881: { id: 5881, name: "Sleeping Bag Liners", parent: 1013, level: 5 },
  5883: { id: 5883, name: "Stencil Machines", parent: 505392, level: 7 },
  5884: { id: 5884, name: "Brochures", parent: 5863, level: 4 },
  5885: { id: 5885, name: "Novelty Signs", parent: 696, level: 4 },
  5886: {
    id: 5886,
    name: "Kitchen & Dining Room Chairs",
    parent: 443,
    level: 4,
  },
  5887: {
    id: 5887,
    name: "Flavored Alcoholic Beverages",
    parent: 499676,
    level: 5,
  },
  5892: { id: 5892, name: "Safety & Warning Signs", parent: 976, level: 4 },
  5893: { id: 5893, name: "Security Signs", parent: 976, level: 4 },
  5894: { id: 5894, name: "Emergency & Exit Signs", parent: 976, level: 4 },
  5895: { id: 5895, name: "Road & Traffic Signs", parent: 976, level: 4 },
  5896: { id: 5896, name: "Parking Signs & Permits", parent: 976, level: 4 },
  5897: {
    id: 5897,
    name: "Facility Identification Signs",
    parent: 976,
    level: 4,
  },
  5898: { id: 5898, name: "Retail & Sale Signs", parent: 976, level: 4 },
  5899: { id: 5899, name: "Sidewalk & Yard Signs", parent: 976, level: 4 },
  5900: { id: 5900, name: "Policy Signs", parent: 976, level: 4 },
  5904: { id: 5904, name: "Bakery Assortments", parent: 1876, level: 5 },
  5907: { id: 5907, name: "Costume Capes", parent: 5192, level: 5 },
  5908: { id: 5908, name: "Motorcycle Gloves", parent: 5547, level: 6 },
  5909: { id: 5909, name: "Snow Pants & Suits", parent: 203, level: 5 },
  5910: {
    id: 5910,
    name: "Outdoor Blankets",
    children: [5911, 5913, 5912],
    parent: 2918,
    level: 5,
  },
  5911: { id: 5911, name: "Beach Mats", parent: 5910, level: 6 },
  5912: { id: 5912, name: "Poncho Liners", parent: 5910, level: 6 },
  5913: { id: 5913, name: "Picnic Blankets", parent: 5910, level: 6 },
  5914: { id: 5914, name: "Tiaras", parent: 171, level: 5 },
  5915: { id: 5915, name: "Hair Wreaths", parent: 171, level: 5 },
  5916: { id: 5916, name: "Corsages & Boutonnières", parent: 2559, level: 5 },
  5917: { id: 5917, name: "Hunting Dog Equipment", parent: 3136, level: 6 },
  5918: { id: 5918, name: "Business Forms & Receipts", parent: 956, level: 5 },
  5919: {
    id: 5919,
    name: "Receipt & Adding Machine Paper Rolls",
    parent: 956,
    level: 5,
  },
  5921: { id: 5921, name: "Light Boxes", parent: 504639, level: 6 },
  5922: { id: 5922, name: "Shadow Boxes", parent: 696, level: 4 },
  5923: { id: 5923, name: "Surgical Lubricants", parent: 491, level: 4 },
  5927: { id: 5927, name: "Hardware Torches", parent: 1167, level: 4 },
  5928: { id: 5928, name: "Cooking Torches", parent: 668, level: 5 },
  5930: { id: 5930, name: "Japanese Traditional Dolls", parent: 596, level: 5 },
  5935: { id: 5935, name: "Virtual Currency", parent: 5032, level: 4 },
  5937: {
    id: 5937,
    name: "Surveillance Camera Accessories",
    parent: 143,
    level: 5,
  },
  5938: {
    id: 5938,
    name: "Storage Cabinets & Lockers",
    parent: 6356,
    level: 4,
  },
  5939: { id: 5939, name: "Turbans", parent: 2020, level: 5 },
  5941: { id: 5941, name: "Leg Warmers", parent: 167, level: 4 },
  5942: { id: 5942, name: "Arm Warmers & Sleeves", parent: 167, level: 4 },
  5949: { id: 5949, name: "Military Uniforms", parent: 2306, level: 5 },
  5959: {
    id: 5959,
    name: "Motorcycle Chest & Back Protectors",
    parent: 5547,
    level: 6,
  },
  5960: { id: 5960, name: "Motorcycle Kidney Belts", parent: 5547, level: 6 },
  5961: { id: 5961, name: "Motorcycle Neck Braces", parent: 5547, level: 6 },
  5962: {
    id: 5962,
    name: "Motorcycle Knee & Shin Guards",
    parent: 5547,
    level: 6,
  },
  5963: {
    id: 5963,
    name: "Motorcycle Elbow & Wrist Guards",
    parent: 5547,
    level: 6,
  },
  5965: {
    id: 5965,
    name: "Medical Identification Tags & Jewelry",
    parent: 491,
    level: 4,
  },
  5966: { id: 5966, name: "Medical Alarm Systems", parent: 491, level: 4 },
  5967: {
    id: 5967,
    name: "Special Effects Controllers",
    parent: 408,
    level: 5,
  },
  5968: {
    id: 5968,
    name: "Remote Control Motorcycles",
    parent: 2546,
    level: 5,
  },
  5969: { id: 5969, name: "Remote Control Tanks", parent: 2546, level: 5 },
  5970: {
    id: 5970,
    name: "Play Vehicle Accessories",
    children: [5971, 5153],
    parent: 1253,
    level: 4,
  },
  5971: {
    id: 5971,
    name: "Toy Race Car & Track Accessories",
    parent: 5970,
    level: 5,
  },
  5974: { id: 5974, name: "Jewelry Holders", parent: 5573, level: 4 },
  5975: {
    id: 5975,
    name: "Nail Art Kits & Accessories",
    parent: 478,
    level: 6,
  },
  5976: {
    id: 5976,
    name: "Toners & Astringents",
    children: [543659, 543658],
    parent: 567,
    level: 6,
  },
  5977: { id: 5977, name: "Hair Coloring Accessories", parent: 486, level: 5 },
  5978: {
    id: 5978,
    name: "Body Makeup",
    children: [5981, 5979],
    parent: 477,
    level: 6,
  },
  5979: { id: 5979, name: "Body Paint & Foundation", parent: 5978, level: 7 },
  5980: { id: 5980, name: "Body Powder", parent: 567, level: 6 },
  5981: { id: 5981, name: "Body & Hair Glitter", parent: 5978, level: 7 },
  5982: { id: 5982, name: "Rhinestones & Flatbacks", parent: 505379, level: 7 },
  5989: { id: 5989, name: "Window Films", parent: 603, level: 5 },
  5990: { id: 5990, name: "Holiday Stocking Hangers", parent: 596, level: 5 },
  5991: { id: 5991, name: "Holiday Stockings", parent: 596, level: 5 },
  5992: { id: 5992, name: "Hand-Sewing Needles", parent: 505393, level: 7 },
  5994: {
    id: 5994,
    name: "Vehicle License Plate Frames",
    parent: 2495,
    level: 6,
  },
  5995: {
    id: 5995,
    name: "Vehicle Emblems & Hood Ornaments",
    parent: 2495,
    level: 6,
  },
  5996: { id: 5996, name: "Jewelry Findings", parent: 505379, level: 7 },
  5997: { id: 5997, name: "Address Books", parent: 923, level: 4 },
  5998: {
    id: 5998,
    name: "Hydration System Accessories",
    parent: 1011,
    level: 4,
  },
  5999: {
    id: 5999,
    name: "Model Making",
    children: [3885, 5151, 5150, 4175],
    parent: 5710,
    level: 4,
  },
  6000: { id: 6000, name: "Scale Model Accessories", parent: 216, level: 5 },
  6001: {
    id: 6001,
    name: "Electronic Musical Instruments",
    children: [245, 6002, 74, 6003],
    parent: 54,
    level: 5,
  },
  6002: { id: 6002, name: "MIDI Controllers", parent: 6001, level: 6 },
  6003: { id: 6003, name: "Sound Synthesizers", parent: 6001, level: 6 },
  6006: { id: 6006, name: "Motorcycle Jackets", parent: 5517, level: 6 },
  6016: { id: 6016, name: "Satellite LNBs", parent: 5476, level: 5 },
  6017: {
    id: 6017,
    name: "Snoring & Sleep Apnea Aids",
    parent: 4076,
    level: 5,
  },
  6018: {
    id: 6018,
    name: "Hair Styling Tool Accessories",
    children: [5317, 4475, 4569],
    parent: 486,
    level: 5,
  },
  6019: {
    id: 6019,
    name: "Hair Styling Tools",
    children: [487, 489, 488, 490, 3407, 499992],
    parent: 486,
    level: 5,
  },
  6027: { id: 6027, name: "3D Modeling Software", parent: 319, level: 5 },
  6028: { id: 6028, name: "Media Viewing Software", parent: 319, level: 5 },
  6029: {
    id: 6029,
    name: "Home & Interior Design Software",
    parent: 319,
    level: 5,
  },
  6030: {
    id: 6030,
    name: "Mobile Phone Pre-Paid Cards & SIM Cards",
    children: [543515, 543516],
    parent: 264,
    level: 6,
  },
  6031: { id: 6031, name: "Dirndls", parent: 5388, level: 5 },
  6034: { id: 6034, name: "Makeup Removers", parent: 567, level: 6 },
  6037: { id: 6037, name: "Dice Sets & Games", parent: 3793, level: 4 },
  6038: { id: 6038, name: "Tile Games", parent: 3793, level: 4 },
  6040: { id: 6040, name: "Erotic Games", parent: 773, level: 4 },
  6041: { id: 6041, name: "Vehicle Base Rack Systems", parent: 3472, level: 6 },
  6042: {
    id: 6042,
    name: "Vehicle Water Sport Board Racks",
    parent: 3472,
    level: 6,
  },
  6043: {
    id: 6043,
    name: "Vehicle Ski & Snowboard Racks",
    parent: 3472,
    level: 6,
  },
  6044: {
    id: 6044,
    name: "Vehicle Motorcycle & Scooter Racks",
    parent: 3472,
    level: 6,
  },
  6046: { id: 6046, name: "Vehicle Fishing Rod Racks", parent: 3472, level: 6 },
  6047: { id: 6047, name: "Vehicle Boat Racks", parent: 3472, level: 6 },
  6048: {
    id: 6048,
    name: "Bicycle Water Sport Board Racks",
    parent: 3214,
    level: 6,
  },
  6049: { id: 6049, name: "Coffee & Tea Cups", parent: 674, level: 6 },
  6051: { id: 6051, name: "Coffee & Tea Saucers", parent: 674, level: 6 },
  6052: {
    id: 6052,
    name: "Hair Permanents & Straighteners",
    parent: 486,
    level: 5,
  },
  6053: { id: 6053, name: "Hair Color Removers", parent: 486, level: 5 },
  6054: { id: 6054, name: "Dexterity Games", parent: 3793, level: 4 },
  6055: { id: 6055, name: "Soccer Goal Accessories", parent: 1111, level: 5 },
  6056: {
    id: 6056,
    name: "Puppet & Puppet Theater Accessories",
    parent: 1255,
    level: 5,
  },
  6057: { id: 6057, name: "Puppet Theaters", parent: 1255, level: 5 },
  6058: { id: 6058, name: "Action & Toy Figures", parent: 1255, level: 5 },
  6059: { id: 6059, name: "Remote Control Robots", parent: 2546, level: 5 },
  6062: { id: 6062, name: "Ski Boots", parent: 499845, level: 6 },
  6063: { id: 6063, name: "Ski Bindings", parent: 499845, level: 6 },
  6064: {
    id: 6064,
    name: "Skis",
    children: [3331, 1158],
    parent: 499845,
    level: 6,
  },
  6065: {
    id: 6065,
    name: "Pens & Pencils",
    children: [6066, 6068, 982],
    parent: 977,
    level: 5,
  },
  6066: { id: 6066, name: "Pen & Pencil Sets", parent: 6065, level: 6 },
  6067: {
    id: 6067,
    name: "Multifunction Writing Instruments",
    parent: 977,
    level: 5,
  },
  6068: {
    id: 6068,
    name: "Pencils",
    children: [3026, 981],
    parent: 6065,
    level: 6,
  },
  6069: { id: 6069, name: "Cosmetic Sets", parent: 473, level: 5 },
  6070: {
    id: 6070,
    name: "Cookware & Bakeware",
    children: [640, 4502, 654, 6071, 4424],
    parent: 638,
    level: 4,
  },
  6071: {
    id: 6071,
    name: "Cookware & Bakeware Combo Sets",
    parent: 6070,
    level: 5,
  },
  6072: { id: 6072, name: "Makeup Finishing Sprays", parent: 477, level: 6 },
  6073: { id: 6073, name: "Wall Light Fixtures", parent: 3006, level: 5 },
  6074: { id: 6074, name: "Hockey Goals", parent: 499915, level: 5 },
  6076: { id: 6076, name: "Hockey Sticks", parent: 499915, level: 5 },
  6077: { id: 6077, name: "Hockey Balls & Pucks", parent: 499915, level: 5 },
  6078: { id: 6078, name: "Hockey Gloves", parent: 1105, level: 6 },
  6080: { id: 6080, name: "Hockey Helmets", parent: 1105, level: 6 },
  6083: { id: 6083, name: "Motorcycle Alarms & Locks", parent: 2768, level: 6 },
  6084: {
    id: 6084,
    name: "Automotive Alarm Accessories",
    parent: 2768,
    level: 6,
  },
  6085: { id: 6085, name: "Aquatic Plant Fertilizers", parent: 6, level: 5 },
  6086: { id: 6086, name: "Butter Dishes", parent: 4026, level: 6 },
  6087: { id: 6087, name: "Bicycle Skinsuits", parent: 5697, level: 6 },
  6088: { id: 6088, name: "Motorcycle Rims & Wheels", parent: 2932, level: 7 },
  6090: { id: 6090, name: "Automotive Rims & Wheels", parent: 2932, level: 7 },
  6091: { id: 6091, name: "Motorcycle Tires", parent: 911, level: 7 },
  6093: { id: 6093, name: "Automotive Tires", parent: 911, level: 7 },
  6094: { id: 6094, name: "Lawn Mower Wheels", parent: 4566, level: 6 },
  6095: { id: 6095, name: "Lawn Mower Tires", parent: 4566, level: 6 },
  6097: { id: 6097, name: "Pedal Boats", parent: 1120, level: 6 },
  6098: { id: 6098, name: "Gongs", parent: 75, level: 6 },
  6099: { id: 6099, name: "Hair Loss Concealers", parent: 486, level: 5 },
  6100: {
    id: 6100,
    name: "Corsage & Boutonnière Pins",
    parent: 2559,
    level: 5,
  },
  6101: { id: 6101, name: "Safety Pins", parent: 504639, level: 6 },
  6102: { id: 6102, name: "Jewelry & Beading Wire", parent: 505377, level: 7 },
  6103: { id: 6103, name: "Weighted Clothing", parent: 990, level: 4 },
  6104: { id: 6104, name: "Body Oil", parent: 567, level: 6 },
  6105: {
    id: 6105,
    name: "Canopy & Gazebo Accessories",
    children: [6107, 6106, 6108, 7423, 7424],
    parent: 2613,
    level: 6,
  },
  6106: { id: 6106, name: "Canopy & Gazebo Frames", parent: 6105, level: 7 },
  6107: {
    id: 6107,
    name: "Canopy & Gazebo Enclosure Kits",
    parent: 6105,
    level: 7,
  },
  6108: { id: 6108, name: "Canopy & Gazebo Tops", parent: 6105, level: 7 },
  6109: { id: 6109, name: "Combat Knives", parent: 780, level: 4 },
  6110: { id: 6110, name: "Transfer Paper", parent: 24, level: 7 },
  6117: { id: 6117, name: "Craft Foam & Styrofoam", parent: 505381, level: 7 },
  6119: { id: 6119, name: "Craft Scoring Tools", parent: 504640, level: 7 },
  6121: { id: 6121, name: "Embossing Powder", parent: 505372, level: 6 },
  6122: {
    id: 6122,
    name: "Embossing Pens & Styluses",
    parent: 504640,
    level: 7,
  },
  6125: {
    id: 6125,
    name: "Screen Printing Squeegees",
    parent: 505392,
    level: 7,
  },
  6126: { id: 6126, name: "Brayer Rollers", parent: 505392, level: 7 },
  6127: { id: 6127, name: "Crochet Hooks", parent: 505393, level: 7 },
  6133: { id: 6133, name: "Felting Pads & Mats", parent: 504639, level: 6 },
  6134: {
    id: 6134,
    name: "Felting Needles & Machines",
    parent: 505388,
    level: 7,
  },
  6135: { id: 6135, name: "Felting Molds", parent: 505371, level: 6 },
  6136: {
    id: 6136,
    name: "Spinning Wheel Accessories",
    parent: 504643,
    level: 6,
  },
  6137: { id: 6137, name: "Spinning Wheels", parent: 505388, level: 7 },
  6138: { id: 6138, name: "Hand Spindles", parent: 505387, level: 7 },
  6139: { id: 6139, name: "Knitting Needles", parent: 505393, level: 7 },
  6140: { id: 6140, name: "Unspun Fiber", parent: 505382, level: 7 },
  6142: {
    id: 6142,
    name: "Filling & Padding Material",
    children: [505407, 505406, 505405],
    parent: 505372,
    level: 6,
  },
  6144: { id: 6144, name: "Plastic Canvas", parent: 505397, level: 8 },
  6145: { id: 6145, name: "Hook and Loop Fasteners", parent: 505380, level: 7 },
  6146: { id: 6146, name: "Elastic", parent: 505379, level: 7 },
  6150: { id: 6150, name: "Dress Forms", parent: 504639, level: 6 },
  6151: { id: 6151, name: "Blocking Wires", parent: 504639, level: 6 },
  6152: { id: 6152, name: "Blocking Mats", parent: 504639, level: 6 },
  6153: {
    id: 6153,
    name: "Thread, Yarn & Bobbin Winders",
    parent: 505387,
    level: 7,
  },
  6154: { id: 6154, name: "Thread & Yarn Spools", parent: 505387, level: 7 },
  6155: { id: 6155, name: "Thread & Yarn Guides", parent: 505387, level: 7 },
  6156: {
    id: 6156,
    name: "Thimbles & Sewing Palms",
    children: [543639, 543638],
    parent: 504639,
    level: 6,
  },
  6157: {
    id: 6157,
    name: "Textile Art Gauges & Rulers",
    parent: 505392,
    level: 7,
  },
  6158: {
    id: 6158,
    name: "Frames, Hoops & Stretchers",
    parent: 504639,
    level: 6,
  },
  6159: { id: 6159, name: "Straight Pins", parent: 504639, level: 6 },
  6160: {
    id: 6160,
    name: "Stitch Markers & Counters",
    parent: 505392,
    level: 7,
  },
  6161: { id: 6161, name: "Seam Rippers", parent: 504640, level: 7 },
  6163: { id: 6163, name: "Needle Threaders", parent: 505387, level: 7 },
  6164: { id: 6164, name: "Fiber Cards & Brushes", parent: 505387, level: 7 },
  6166: { id: 6166, name: "Weaving Shuttles", parent: 505387, level: 7 },
  6167: { id: 6167, name: "Weaving Beaters", parent: 505387, level: 7 },
  6168: { id: 6168, name: "Latch & Locker Hooks", parent: 505393, level: 7 },
  6169: { id: 6169, name: "Business Card Cases", parent: 6551, level: 4 },
  6170: { id: 6170, name: "Badge & Pass Holders", parent: 6551, level: 4 },
  6171: { id: 6171, name: "Business Card Stands", parent: 923, level: 4 },
  6173: { id: 6173, name: "Umbrella Sleeves & Cases", parent: 536, level: 3 },
  6174: {
    id: 6174,
    name: "Book Accessories",
    children: [6176, 4941, 6175, 93],
    parent: 922,
    level: 3,
  },
  6175: { id: 6175, name: "Book Stands & Rests", parent: 6174, level: 4 },
  6176: { id: 6176, name: "Book Covers", parent: 6174, level: 4 },
  6177: { id: 6177, name: "Card Sleeves", parent: 923, level: 4 },
  6178: { id: 6178, name: "Pencil Boards", parent: 2014, level: 4 },
  6179: { id: 6179, name: "Utility Carts", parent: 6373, level: 4 },
  6180: { id: 6180, name: "File Carts", parent: 6373, level: 4 },
  6181: { id: 6181, name: "Mail Carts", parent: 6373, level: 4 },
  6182: { id: 6182, name: "Book Carts", parent: 6373, level: 4 },
  6183: { id: 6183, name: "Hair Nets", parent: 171, level: 5 },
  6186: {
    id: 6186,
    name: "Tennis Autographed Sports Paraphernalia",
    parent: 4333,
    level: 7,
  },
  6187: { id: 6187, name: "Tennis Fan Accessories", parent: 3515, level: 7 },
  6189: { id: 6189, name: "Breath Spray", parent: 526, level: 5 },
  6190: { id: 6190, name: "Business Card Books", parent: 923, level: 4 },
  6191: { id: 6191, name: "De-icers", parent: 2277, level: 5 },
  6192: { id: 6192, name: "Cheese Puffs", parent: 423, level: 5 },
  6194: { id: 6194, name: "Pork Rinds", parent: 423, level: 5 },
  6195: { id: 6195, name: "Cupcakes", parent: 1876, level: 5 },
  6196: { id: 6196, name: "Coffee Cakes", parent: 1876, level: 5 },
  6199: { id: 6199, name: "Pepper", parent: 4608, level: 5 },
  6203: { id: 6203, name: "Pizza Sauce", parent: 427, level: 5 },
  6204: { id: 6204, name: "Apple Butter", parent: 5740, level: 5 },
  6205: { id: 6205, name: "Heating Pads", parent: 516, level: 6 },
  6206: { id: 6206, name: "Cast & Bandage Protectors", parent: 508, level: 5 },
  6219: {
    id: 6219,
    name: "Candied & Chocolate Covered Fruit",
    parent: 422,
    level: 4,
  },
  6227: {
    id: 6227,
    name: "Japanese Black Formal Wear",
    parent: 5388,
    level: 5,
  },
  6228: { id: 6228, name: "Long Skirts", parent: 1581, level: 5 },
  6229: { id: 6229, name: "Knee-Length Skirts", parent: 1581, level: 5 },
  6238: { id: 6238, name: "Earmuffs", parent: 167, level: 4 },
  6240: { id: 6240, name: "Fabric Refreshers", parent: 627, level: 5 },
  6242: { id: 6242, name: "Nutrition Gels & Chews", parent: 2890, level: 5 },
  6246: { id: 6246, name: "Worcestershire Sauce", parent: 427, level: 5 },
  6248: { id: 6248, name: "Pet Flea & Tick Control", parent: 2, level: 4 },
  6249: { id: 6249, name: "Pet Leashes", parent: 2, level: 4 },
  6250: { id: 6250, name: "Pet Collars & Harnesses", parent: 2, level: 4 },
  6251: { id: 6251, name: "Pet Carriers & Crates", parent: 2, level: 4 },
  6252: {
    id: 6252,
    name: "Pet Bowls, Feeders & Waterers",
    parent: 2,
    level: 4,
  },
  6253: { id: 6253, name: "Pet Leash Extensions", parent: 2, level: 4 },
  6254: {
    id: 6254,
    name: "Window Treatment Accessories",
    children: [6256, 6257, 6255, 8042],
    parent: 696,
    level: 4,
  },
  6255: {
    id: 6255,
    name: "Curtain Holdbacks & Tassels",
    parent: 6254,
    level: 5,
  },
  6256: { id: 6256, name: "Curtain & Drape Rings", parent: 6254, level: 5 },
  6257: { id: 6257, name: "Curtain & Drape Rods", parent: 6254, level: 5 },
  6258: { id: 6258, name: "Tow-Behind Mowers", parent: 694, level: 6 },
  6259: { id: 6259, name: "Quinoa", parent: 431, level: 5 },
  6260: {
    id: 6260,
    name: "Skin Cleansing Brushes & Systems",
    parent: 2958,
    level: 7,
  },
  6261: { id: 6261, name: "Skin Care Extractors", parent: 2958, level: 7 },
  6262: { id: 6262, name: "Skin Care Masks & Peels", parent: 567, level: 6 },
  6263: { id: 6263, name: "Duster Refills", parent: 623, level: 5 },
  6264: { id: 6264, name: "Mop Heads & Refills", parent: 623, level: 5 },
  6265: { id: 6265, name: "Artificial Flora", parent: 696, level: 4 },
  6266: { id: 6266, name: "Artificial Food", parent: 696, level: 4 },
  6267: { id: 6267, name: "Wreaths & Garlands", parent: 696, level: 4 },
  6268: { id: 6268, name: "Leis", parent: 167, level: 4 },
  6269: {
    id: 6269,
    name: "Swing Set & Playset Accessories",
    parent: 1249,
    level: 4,
  },
  6270: { id: 6270, name: "Play Swings", parent: 1249, level: 4 },
  6271: { id: 6271, name: "Swing Sets & Playsets", parent: 1249, level: 4 },
  6272: {
    id: 6272,
    name: "Track Lighting Accessories",
    parent: 6274,
    level: 5,
  },
  6273: { id: 6273, name: "Track Lighting Rails", parent: 6274, level: 5 },
  6274: {
    id: 6274,
    name: "Track Lighting",
    children: [6272, 4932, 6273],
    parent: 594,
    level: 4,
  },
  6275: { id: 6275, name: "Hospital Curtains", parent: 2496, level: 4 },
  6276: { id: 6276, name: "Pet Strollers", parent: 2, level: 4 },
  6277: { id: 6277, name: "Lanyards", parent: 6552, level: 4 },
  6278: { id: 6278, name: "Pizza Makers & Ovens", parent: 5289, level: 6 },
  6279: { id: 6279, name: "Water Ovens", parent: 4532, level: 6 },
  6280: { id: 6280, name: "Vital Signs Monitors", parent: 3477, level: 5 },
  6281: { id: 6281, name: "Medical Forceps", parent: 230913, level: 5 },
  6282: { id: 6282, name: "Eyelash Curler Refills", parent: 2548, level: 7 },
  6284: {
    id: 6284,
    name: "Blood Pressure Monitor Accessories",
    children: [6285],
    parent: 5071,
    level: 5,
  },
  6285: {
    id: 6285,
    name: "Blood Pressure Monitor Cuffs",
    parent: 6284,
    level: 6,
  },
  6286: { id: 6286, name: "Skimboards", parent: 1143, level: 6 },
  6287: { id: 6287, name: "Bodyboards", parent: 1143, level: 6 },
  6288: { id: 6288, name: "Paddleboards", parent: 1143, level: 6 },
  6289: { id: 6289, name: "UPS Batteries", parent: 276, level: 6 },
  6290: {
    id: 6290,
    name: "Baby Health & Grooming Kits",
    parent: 5252,
    level: 4,
  },
  6291: {
    id: 6291,
    name: "Keyboard & Mouse Wrist Rests",
    parent: 279,
    level: 5,
  },
  6292: { id: 6292, name: "Power Tool Chargers", parent: 3650, level: 4 },
  6293: { id: 6293, name: "Watercraft Lighting", parent: 3391, level: 5 },
  6295: { id: 6295, name: "Power Tool Batteries", parent: 3650, level: 4 },
  6296: { id: 6296, name: "Water Ski Cases & Bags", parent: 1146, level: 7 },
  6297: { id: 6297, name: "Tie Down Straps", parent: 502977, level: 5 },
  6298: { id: 6298, name: "Bungee Cords", parent: 502977, level: 5 },
  6300: { id: 6300, name: "Manicure Tool Sets", parent: 2975, level: 7 },
  6301: { id: 6301, name: "Towable Rafts & Tubes", parent: 3195, level: 6 },
  6302: { id: 6302, name: "Water Ski Bindings", parent: 1146, level: 7 },
  6303: { id: 6303, name: "Medical Bedding", parent: 2496, level: 4 },
  6304: { id: 6304, name: "Highlighters & Luminizers", parent: 2571, level: 7 },
  6305: { id: 6305, name: "Blushes & Bronzers", parent: 2571, level: 7 },
  6306: { id: 6306, name: "Lip & Cheek Stains", parent: 2645, level: 7 },
  6307: { id: 6307, name: "Underwater Camera Housings", parent: 143, level: 5 },
  6308: { id: 6308, name: "Camera Bags & Cases", parent: 143, level: 5 },
  6311: { id: 6311, name: "Balloon Kits", parent: 96, level: 5 },
  6312: { id: 6312, name: "Kayak Accessories", parent: 1120, level: 6 },
  6314: { id: 6314, name: "Canoe Accessories", parent: 1120, level: 6 },
  6317: { id: 6317, name: "Decorative Bottles", parent: 696, level: 4 },
  6318: { id: 6318, name: "Watering Cans", parent: 3568, level: 5 },
  6319: { id: 6319, name: "Brass Fasteners", parent: 932, level: 4 },
  6321: { id: 6321, name: "Pet Containment Systems", parent: 2, level: 4 },
  6322: { id: 6322, name: "Table Skirts", parent: 601, level: 5 },
  6323: {
    id: 6323,
    name: "Blood Glucose Control Solution",
    parent: 3688,
    level: 6,
  },
  6324: { id: 6324, name: "Napkin Rings", parent: 696, level: 4 },
  6325: { id: 6325, name: "Table Runners", parent: 601, level: 5 },
  6328: {
    id: 6328,
    name: "Pressure Washer Accessories",
    parent: 4564,
    level: 5,
  },
  6329: { id: 6329, name: "Battle Tops", parent: 3793, level: 4 },
  6330: {
    id: 6330,
    name: "Swim Goggle & Mask Accessories",
    parent: 1144,
    level: 6,
  },
  6331: { id: 6331, name: "Cosmetic Tool Cleansers", parent: 473, level: 5 },
  6333: {
    id: 6333,
    name: "Mailbox Accessories",
    children: [7177, 7052, 7176, 6334, 7339],
    parent: 696,
    level: 4,
  },
  6334: { id: 6334, name: "Mailbox Posts", parent: 6333, level: 5 },
  6336: { id: 6336, name: "Candle & Oil Warmers", parent: 500121, level: 5 },
  6337: {
    id: 6337,
    name: "Foam Roller Accessories",
    children: [6338],
    parent: 990,
    level: 4,
  },
  6338: { id: 6338, name: "Foam Roller Storage Bags", parent: 6337, level: 5 },
  6340: {
    id: 6340,
    name: "Lash & Brow Growth Treatments",
    parent: 2779,
    level: 7,
  },
  6341: { id: 6341, name: "Nail Buffers", parent: 2975, level: 7 },
  6342: { id: 6342, name: "Cookie Presses", parent: 668, level: 5 },
  6343: {
    id: 6343,
    name: "Door Hardware",
    children: [2972, 6446, 503727, 99338, 1356, 2795, 499970, 2665, 6458],
    parent: 115,
    level: 4,
  },
  6344: {
    id: 6344,
    name: "Stadium Seats & Cushions",
    parent: 499799,
    level: 5,
  },
  6345: {
    id: 6345,
    name: "Furniture Sets",
    children: [500000, 6346, 6347, 6348],
    parent: 436,
    level: 3,
  },
  6346: { id: 6346, name: "Bedroom Furniture Sets", parent: 6345, level: 4 },
  6347: {
    id: 6347,
    name: "Kitchen & Dining Furniture Sets",
    parent: 6345,
    level: 4,
  },
  6348: {
    id: 6348,
    name: "Living Room Furniture Sets",
    parent: 6345,
    level: 4,
  },
  6349: {
    id: 6349,
    name: "Baby & Toddler Furniture Sets",
    parent: 554,
    level: 4,
  },
  6351: { id: 6351, name: "Activity Tables", parent: 6392, level: 4 },
  6356: {
    id: 6356,
    name: "Cabinets & Storage",
    children: [
      4063, 447, 448, 4195, 463, 465846, 6934, 6539, 6358, 5938, 4205, 4148,
      6357, 5578,
    ],
    parent: 436,
    level: 3,
  },
  6357: { id: 6357, name: "Wine & Liquor Cabinets", parent: 6356, level: 4 },
  6358: {
    id: 6358,
    name: "Media Storage Cabinets & Racks",
    parent: 6356,
    level: 4,
  },
  6360: { id: 6360, name: "Bedroom Vanities", parent: 4148, level: 5 },
  6362: {
    id: 6362,
    name: "Office Furniture",
    children: [4191, 2045, 500061, 6363, 6908],
    parent: 436,
    level: 3,
  },
  6363: {
    id: 6363,
    name: "Workspace Tables",
    children: [2242, 4317],
    parent: 6362,
    level: 4,
  },
  6367: { id: 6367, name: "Outdoor Furniture Sets", parent: 4299, level: 4 },
  6368: {
    id: 6368,
    name: "Outdoor Seating",
    children: [5044, 6828, 500111, 4513, 4105],
    parent: 4299,
    level: 4,
  },
  6369: {
    id: 6369,
    name: "Accent Tables",
    children: [1395, 1549, 1602],
    parent: 6392,
    level: 4,
  },
  6372: { id: 6372, name: "Wall Shelves & Ledges", parent: 464, level: 4 },
  6373: {
    id: 6373,
    name: "Office Carts",
    children: [1996, 6182, 6180, 6181, 6179],
    parent: 922,
    level: 3,
  },
  6374: { id: 6374, name: "Kitchen Islands", parent: 442, level: 4 },
  6375: { id: 6375, name: "Extension Cord Accessories", parent: 127, level: 4 },
  6378: { id: 6378, name: "Drill Bit Sharpeners", parent: 3944, level: 5 },
  6379: { id: 6379, name: "Wagons", parent: 2799, level: 5 },
  6381: { id: 6381, name: "Landscape Fabric", parent: 2962, level: 5 },
  6382: { id: 6382, name: "Fabric Repair Kits", parent: 505370, level: 6 },
  6383: {
    id: 6383,
    name: "Pet Grooming Supplies",
    children: [6385, 503733, 6384, 8167, 7318, 7319, 6406, 499917],
    parent: 2,
    level: 4,
  },
  6384: {
    id: 6384,
    name: "Pet Hair Clippers & Trimmers",
    parent: 6383,
    level: 5,
  },
  6385: { id: 6385, name: "Pet Combs & Brushes", parent: 6383, level: 5 },
  6386: { id: 6386, name: "Horse Clippers & Trimmers", parent: 5025, level: 7 },
  6387: { id: 6387, name: "Fabric Starch", parent: 627, level: 5 },
  6388: { id: 6388, name: "Slotted Spoons", parent: 668, level: 5 },
  6392: {
    id: 6392,
    name: "Tables",
    children: [6369, 6351, 4080, 4355, 4484, 462, 2693, 5121],
    parent: 436,
    level: 3,
  },
  6393: { id: 6393, name: "Bassinets & Cradles", parent: 554, level: 4 },
  6394: { id: 6394, name: "Cribs & Toddler Beds", parent: 554, level: 4 },
  6396: { id: 6396, name: "Inflatable Bouncers", parent: 1249, level: 4 },
  6397: { id: 6397, name: "Play Tents & Tunnels", parent: 1249, level: 4 },
  6402: { id: 6402, name: "Cable Trays", parent: 3328, level: 5 },
  6403: { id: 6403, name: "Fish Feeders", parent: 6, level: 5 },
  6404: { id: 6404, name: "Time & Attendance Clocks", parent: 950, level: 4 },
  6406: { id: 6406, name: "Pet Shampoo & Conditioner", parent: 6383, level: 5 },
  6407: { id: 6407, name: "Hobby Horses", parent: 2799, level: 5 },
  6408: { id: 6408, name: "Cake Servers", parent: 668, level: 5 },
  6411: {
    id: 6411,
    name: "Electric Knife Accessories",
    children: [6412],
    parent: 668,
    level: 5,
  },
  6412: {
    id: 6412,
    name: "Electric Knife Replacement Blades",
    parent: 6411,
    level: 6,
  },
  6413: {
    id: 6413,
    name: "Landscape Fabric Accessories",
    children: [6422, 6421],
    parent: 2962,
    level: 5,
  },
  6415: { id: 6415, name: "Sugar Caddies", parent: 2948, level: 6 },
  6416: { id: 6416, name: "Laptop Hinges", parent: 4224, level: 6 },
  6418: { id: 6418, name: "Fusible Tape", parent: 505376, level: 7 },
  6419: {
    id: 6419,
    name: "Fabric & Upholstery Protectors",
    parent: 623,
    level: 5,
  },
  6421: { id: 6421, name: "Landscape Fabric Tape", parent: 6413, level: 6 },
  6422: {
    id: 6422,
    name: "Landscape Fabric Staples & Pins",
    parent: 6413,
    level: 6,
  },
  6424: {
    id: 6424,
    name: "Vase Fillers & Table Scatters",
    parent: 696,
    level: 4,
  },
  6425: {
    id: 6425,
    name: "Serveware Accessories",
    children: [6434, 6427, 6426],
    parent: 672,
    level: 5,
  },
  6426: { id: 6426, name: "Tureen Stands", parent: 6425, level: 6 },
  6427: { id: 6427, name: "Tureen Lids", parent: 6425, level: 6 },
  6428: { id: 6428, name: "Plant Stands", parent: 2962, level: 5 },
  6429: { id: 6429, name: "Hair Steamers & Heat Caps", parent: 486, level: 5 },
  6433: {
    id: 6433,
    name: "Beds & Accessories",
    children: [4437, 505764, 451, 2720, 2696],
    parent: 436,
    level: 3,
  },
  6434: { id: 6434, name: "Punch Bowl Stands", parent: 6425, level: 6 },
  6436: { id: 6436, name: "Composters", parent: 4085, level: 6 },
  6437: { id: 6437, name: "Buckets", parent: 623, level: 5 },
  6439: { id: 6439, name: "Chopstick Accessories", parent: 675, level: 6 },
  6440: { id: 6440, name: "Fishing Yarn", parent: 7217, level: 6 },
  6441: { id: 6441, name: "Tongue Scrapers", parent: 526, level: 5 },
  6442: { id: 6442, name: "Bicycle Covers", parent: 3214, level: 6 },
  6445: {
    id: 6445,
    name: "Bicycle Saddle Pads & Seat Covers",
    parent: 3214,
    level: 6,
  },
  6446: { id: 6446, name: "Door Closers", parent: 6343, level: 5 },
  6447: { id: 6447, name: "Thread & Yarn Cutters", parent: 504640, level: 7 },
  6449: { id: 6449, name: "Wine Carrier Bags", parent: 2920, level: 5 },
  6450: { id: 6450, name: "See Saws", parent: 1249, level: 4 },
  6452: {
    id: 6452,
    name: "Free Weight Accessories",
    children: [8083, 499794, 3271],
    parent: 499793,
    level: 5,
  },
  6454: {
    id: 6454,
    name: "Motor Vehicle Carrying Rack Accessories",
    children: [7122, 8086],
    parent: 8237,
    level: 5,
  },
  6455: {
    id: 6455,
    name: "Dental Water Jet Replacement Tips",
    parent: 526,
    level: 5,
  },
  6456: { id: 6456, name: "Decorative Trays", parent: 696, level: 4 },
  6457: { id: 6457, name: "Decorative Bowls", parent: 696, level: 4 },
  6458: { id: 6458, name: "Door Strikes", parent: 6343, level: 5 },
  6459: {
    id: 6459,
    name: "Electrical Switches",
    children: [1935, 499932],
    parent: 127,
    level: 4,
  },
  6460: { id: 6460, name: "Checkbook Covers", parent: 6552, level: 4 },
  6461: { id: 6461, name: "Electrician Fish Tape", parent: 1167, level: 4 },
  6462: { id: 6462, name: "Anti-Fatigue Mats", parent: 6519, level: 4 },
  6463: { id: 6463, name: "Jewelry Sets", parent: 188, level: 4 },
  6464: {
    id: 6464,
    name: "Water Play Equipment",
    children: [6465, 500095, 3556],
    parent: 1249,
    level: 4,
  },
  6465: { id: 6465, name: "Play Sprinkers", parent: 6464, level: 5 },
  6466: { id: 6466, name: "Toy Abacuses", parent: 1262, level: 5 },
  6467: { id: 6467, name: "Hole Punches", parent: 2014, level: 4 },
  6471: { id: 6471, name: "Driver Accessories", parent: 3650, level: 4 },
  6473: { id: 6473, name: "Hand Paddles", parent: 1144, level: 6 },
  6474: { id: 6474, name: "Household Disinfectants", parent: 4973, level: 6 },
  6475: {
    id: 6475,
    name: "Input Device Accessories",
    children: [6476, 8008, 503003, 500052],
    parent: 285,
    level: 5,
  },
  6476: { id: 6476, name: "Barcode Scanner Stands", parent: 6475, level: 6 },
  6478: {
    id: 6478,
    name: "Food Storage Accessories",
    children: [499924, 8039, 6479, 5837],
    parent: 638,
    level: 4,
  },
  6479: { id: 6479, name: "Oxygen Absorbers", parent: 6478, level: 5 },
  6480: { id: 6480, name: "Can Organizers", parent: 2948, level: 6 },
  6481: { id: 6481, name: "Food Container Covers", parent: 2626, level: 5 },
  6482: {
    id: 6482,
    name: "Vehicle Diagnostic Scanners",
    parent: 8236,
    level: 6,
  },
  6486: { id: 6486, name: "Fingertip Grips", parent: 2014, level: 4 },
  6487: {
    id: 6487,
    name: "Kitchen Cabinet Organizers",
    parent: 2948,
    level: 6,
  },
  6488: { id: 6488, name: "Key Blanks", parent: 1974, level: 4 },
  6490: {
    id: 6490,
    name: "Medical Teaching Equipment",
    children: [6491],
    parent: 2496,
    level: 4,
  },
  6491: {
    id: 6491,
    name: "Medical & Emergency Response Training Mannequins",
    parent: 6490,
    level: 5,
  },
  6492: { id: 6492, name: "Stained Glass Panels", parent: 603, level: 5 },
  6493: {
    id: 6493,
    name: "Motorcycle Helmet Parts & Accessories",
    parent: 5547,
    level: 6,
  },
  6495: {
    id: 6495,
    name: "Fishing Bait & Chum Containers",
    parent: 3334,
    level: 5,
  },
  6496: { id: 6496, name: "Life Jacket Accessories", parent: 499813, level: 6 },
  6497: {
    id: 6497,
    name: "Salad Dressing Mixers & Shakers",
    parent: 668,
    level: 5,
  },
  6499: {
    id: 6499,
    name: "Arm Chairs, Recliners & Sleeper Chairs",
    parent: 443,
    level: 4,
  },
  6501: { id: 6501, name: "Table Saw Accessories", parent: 6549, level: 5 },
  6503: { id: 6503, name: "Miter Saw Accessories", parent: 6549, level: 5 },
  6506: { id: 6506, name: "Bicycle Shock Pumps", parent: 3214, level: 6 },
  6508: {
    id: 6508,
    name: "Power Over Ethernet Adapters",
    parent: 342,
    level: 4,
  },
  6509: { id: 6509, name: "Contact Lens Solution", parent: 3011, level: 6 },
  6510: { id: 6510, name: "Contact Lens Cases", parent: 3011, level: 6 },
  6511: {
    id: 6511,
    name: "Swimming Fins",
    children: [6512, 2512],
    parent: 1144,
    level: 6,
  },
  6512: { id: 6512, name: "Monofins", parent: 6511, level: 7 },
  6513: { id: 6513, name: "Swim Belts", parent: 1144, level: 6 },
  6514: { id: 6514, name: "Diving Belts", parent: 1135, level: 6 },
  6515: { id: 6515, name: "Swimming Nose Clips", parent: 1144, level: 6 },
  6516: { id: 6516, name: "Pretzel Makers", parent: 5289, level: 6 },
  6517: { id: 6517, name: "Hot Dog Rollers", parent: 135, level: 4 },
  6518: {
    id: 6518,
    name: "Fermentation & Pickling Crocks",
    parent: 654,
    level: 6,
  },
  6519: {
    id: 6519,
    name: "Office & Chair Mats",
    children: [6462, 6521, 6520],
    parent: 922,
    level: 3,
  },
  6520: { id: 6520, name: "Office Mats", parent: 6519, level: 4 },
  6521: { id: 6521, name: "Chair Mats", parent: 6519, level: 4 },
  6522: { id: 6522, name: "Channel Knives", parent: 668, level: 5 },
  6523: { id: 6523, name: "Thermal Cookers", parent: 4532, level: 6 },
  6524: { id: 6524, name: "Gas Griddles", parent: 730, level: 5 },
  6526: { id: 6526, name: "Oil & Vinegar Dispensers", parent: 668, level: 5 },
  6530: { id: 6530, name: "Window Magnets", parent: 696, level: 4 },
  6531: { id: 6531, name: "Nativity Sets", parent: 596, level: 5 },
  6532: {
    id: 6532,
    name: "Holiday Ornament Displays & Stands",
    parent: 596,
    level: 5,
  },
  6534: { id: 6534, name: "Candy Buckets", parent: 2626, level: 5 },
  6535: { id: 6535, name: "Snow Globes", parent: 696, level: 4 },
  6536: { id: 6536, name: "Pole Dancing Kits", parent: 773, level: 4 },
  6539: { id: 6539, name: "Magazine Racks", parent: 6356, level: 4 },
  6540: { id: 6540, name: "Jewelry & Beading Cord", parent: 505382, level: 7 },
  6541: { id: 6541, name: "Lawn Sweepers", parent: 4566, level: 6 },
  6542: { id: 6542, name: "Brush Mower Attachments", parent: 4566, level: 6 },
  6543: { id: 6543, name: "Hot Drink Makers", parent: 730, level: 5 },
  6544: { id: 6544, name: "GPS Tracking Devices", parent: 222, level: 3 },
  6545: { id: 6545, name: "Channel Strips", parent: 2165, level: 5 },
  6546: { id: 6546, name: "Direct Boxes", parent: 2165, level: 5 },
  6547: {
    id: 6547,
    name: "Lawn Ornaments & Garden Sculptures",
    parent: 696,
    level: 4,
  },
  6548: { id: 6548, name: "Chafing Dishes", parent: 5103, level: 6 },
  6549: {
    id: 6549,
    name: "Saw Accessories",
    children: [7515, 7345, 7346, 6503, 6501],
    parent: 3650,
    level: 4,
  },
  6550: { id: 6550, name: "Swim Weights", parent: 1144, level: 6 },
  6551: {
    id: 6551,
    name: "Handbags, Wallets & Cases",
    children: [6170, 6169, 3032, 2668],
    parent: 166,
    level: 3,
  },
  6552: {
    id: 6552,
    name: "Handbag & Wallet Accessories",
    children: [6460, 175, 6277, 5841],
    parent: 166,
    level: 3,
  },
  6553: { id: 6553, name: "Train Cases", parent: 5181, level: 3 },
  6554: { id: 6554, name: "Food Sticks & Skewers", parent: 668, level: 5 },
  6555: { id: 6555, name: "Eyebrow Stencils", parent: 2548, level: 7 },
  6556: { id: 6556, name: "Paint Edgers", parent: 2077, level: 5 },
  6557: { id: 6557, name: "Paint Trays", parent: 2077, level: 5 },
  6558: { id: 6558, name: "Paint Mediums", parent: 505378, level: 7 },
  6559: { id: 6559, name: "Ear Drops", parent: 506, level: 5 },
  6560: { id: 6560, name: "Ear Dryers", parent: 506, level: 5 },
  6561: { id: 6561, name: "Ear Syringes", parent: 506, level: 5 },
  6562: { id: 6562, name: "Ear Wax Removal Kits", parent: 506, level: 5 },
  6563: {
    id: 6563,
    name: "Fireplace & Wood Stove Grates",
    parent: 2862,
    level: 4,
  },
  6566: { id: 6566, name: "Apples", parent: 5795, level: 6 },
  6567: { id: 6567, name: "Apricots", parent: 503760, level: 7 },
  6568: { id: 6568, name: "Asparagus", parent: 5793, level: 6 },
  6569: { id: 6569, name: "Arugula", parent: 6622, level: 7 },
  6570: { id: 6570, name: "Artichokes", parent: 5793, level: 6 },
  6571: { id: 6571, name: "Atemoyas", parent: 5795, level: 6 },
  6572: { id: 6572, name: "Avocados", parent: 5795, level: 6 },
  6573: { id: 6573, name: "Babacos", parent: 5795, level: 6 },
  6574: { id: 6574, name: "Bananas", parent: 5795, level: 6 },
  6577: { id: 6577, name: "Beans", parent: 5793, level: 6 },
  6580: { id: 6580, name: "Beets", parent: 5793, level: 6 },
  6581: { id: 6581, name: "Beet Greens", parent: 6622, level: 7 },
  6582: { id: 6582, name: "Berries", parent: 5795, level: 6 },
  6584: { id: 6584, name: "Bok Choy", parent: 6622, level: 7 },
  6585: { id: 6585, name: "Sweet Potatoes", parent: 5793, level: 6 },
  6586: { id: 6586, name: "Potatoes", parent: 5793, level: 6 },
  6587: { id: 6587, name: "Borage", parent: 5793, level: 6 },
  6589: { id: 6589, name: "Breadfruit", parent: 5795, level: 6 },
  6590: { id: 6590, name: "Brussel Sprouts", parent: 5793, level: 6 },
  6591: { id: 6591, name: "Broccoli", parent: 5793, level: 6 },
  6592: { id: 6592, name: "Cabbage", parent: 5793, level: 6 },
  6593: { id: 6593, name: "Cactus Pears", parent: 5795, level: 6 },
  6594: { id: 6594, name: "Starfruits", parent: 5795, level: 6 },
  6595: { id: 6595, name: "Carrots", parent: 5793, level: 6 },
  6596: { id: 6596, name: "Cardoon", parent: 5793, level: 6 },
  6597: { id: 6597, name: "Chard", parent: 6622, level: 7 },
  6598: { id: 6598, name: "Celery Roots", parent: 5793, level: 6 },
  6599: { id: 6599, name: "Celery", parent: 5793, level: 6 },
  6600: { id: 6600, name: "Cauliflower", parent: 5793, level: 6 },
  6601: { id: 6601, name: "Cherries", parent: 503760, level: 7 },
  6602: { id: 6602, name: "Cherimoyas", parent: 5795, level: 6 },
  6603: { id: 6603, name: "Christmas Tree Stands", parent: 596, level: 5 },
  6608: { id: 6608, name: "Cucumbers", parent: 5793, level: 6 },
  6609: { id: 6609, name: "Corn", parent: 5793, level: 6 },
  6610: { id: 6610, name: "Choy Sum", parent: 6622, level: 7 },
  6613: { id: 6613, name: "Eggplants", parent: 5793, level: 6 },
  6614: { id: 6614, name: "Feijoas", parent: 5795, level: 6 },
  6615: { id: 6615, name: "Fiddlehead Ferns", parent: 5793, level: 6 },
  6616: { id: 6616, name: "Gai Choy", parent: 5793, level: 6 },
  6617: { id: 6617, name: "Gai Lan", parent: 5793, level: 6 },
  6618: { id: 6618, name: "Gobo Root", parent: 5793, level: 6 },
  6619: { id: 6619, name: "Ginger Root", parent: 5793, level: 6 },
  6620: { id: 6620, name: "Garlic", parent: 5793, level: 6 },
  6621: { id: 6621, name: "Grapefruits", parent: 503759, level: 7 },
  6622: {
    id: 6622,
    name: "Greens",
    children: [
      6569, 6581, 6584, 6597, 6717, 6610, 6629, 6637, 6656, 5792, 6695, 6706,
    ],
    parent: 5793,
    level: 6,
  },
  6624: { id: 6624, name: "Homely Fruits", parent: 5795, level: 6 },
  6625: { id: 6625, name: "Guavas", parent: 5795, level: 6 },
  6626: { id: 6626, name: "Grapes", parent: 5795, level: 6 },
  6627: { id: 6627, name: "Leeks", parent: 5793, level: 6 },
  6628: { id: 6628, name: "Kohlrabi", parent: 5793, level: 6 },
  6629: { id: 6629, name: "Kale", parent: 6622, level: 7 },
  6630: { id: 6630, name: "Jicama", parent: 5793, level: 6 },
  6631: { id: 6631, name: "Horseradish Root", parent: 5793, level: 6 },
  6632: { id: 6632, name: "Kumquats", parent: 503759, level: 7 },
  6633: { id: 6633, name: "Kiwis", parent: 5795, level: 6 },
  6636: { id: 6636, name: "Lemons", parent: 503759, level: 7 },
  6637: { id: 6637, name: "Lettuce", parent: 6622, level: 7 },
  6638: { id: 6638, name: "Lychees", parent: 5795, level: 6 },
  6639: { id: 6639, name: "Loquats", parent: 5795, level: 6 },
  6640: { id: 6640, name: "Longan", parent: 5795, level: 6 },
  6641: { id: 6641, name: "Limequats", parent: 503759, level: 7 },
  6642: { id: 6642, name: "Limes", parent: 503759, level: 7 },
  6643: { id: 6643, name: "Malangas", parent: 5793, level: 6 },
  6644: { id: 6644, name: "Lotus Roots", parent: 5793, level: 6 },
  6645: { id: 6645, name: "Mangosteens", parent: 5795, level: 6 },
  6646: { id: 6646, name: "Mangoes", parent: 503760, level: 7 },
  6647: { id: 6647, name: "Mamey", parent: 5795, level: 6 },
  6649: { id: 6649, name: "Melons", parent: 5795, level: 6 },
  6653: { id: 6653, name: "Mushrooms", parent: 5793, level: 6 },
  6655: { id: 6655, name: "Onions", parent: 5793, level: 6 },
  6656: { id: 6656, name: "On Choy", parent: 6622, level: 7 },
  6657: { id: 6657, name: "Okra", parent: 5793, level: 6 },
  6658: { id: 6658, name: "Oranges", parent: 503759, level: 7 },
  6661: { id: 6661, name: "Papayas", parent: 5795, level: 6 },
  6663: { id: 6663, name: "Parsnips", parent: 5793, level: 6 },
  6664: { id: 6664, name: "Parsley Roots", parent: 5793, level: 6 },
  6665: { id: 6665, name: "Pears", parent: 5795, level: 6 },
  6667: { id: 6667, name: "Passion Fruit", parent: 5795, level: 6 },
  6668: { id: 6668, name: "Peppers", parent: 5793, level: 6 },
  6669: { id: 6669, name: "Peas", parent: 5793, level: 6 },
  6670: { id: 6670, name: "Pineapples", parent: 5795, level: 6 },
  6671: { id: 6671, name: "Physalis", parent: 5795, level: 6 },
  6672: { id: 6672, name: "Persimmons", parent: 5795, level: 6 },
  6673: { id: 6673, name: "Pomegranates", parent: 5795, level: 6 },
  6674: { id: 6674, name: "Plums", parent: 503760, level: 7 },
  6675: { id: 6675, name: "Plumcots", parent: 503760, level: 7 },
  6676: { id: 6676, name: "Pitahayas", parent: 5795, level: 6 },
  6678: { id: 6678, name: "Rambutans", parent: 5795, level: 6 },
  6679: { id: 6679, name: "Quince", parent: 5795, level: 6 },
  6681: { id: 6681, name: "Rhubarb", parent: 5793, level: 6 },
  6682: { id: 6682, name: "Radishes", parent: 5793, level: 6 },
  6687: { id: 6687, name: "Sapote", parent: 5795, level: 6 },
  6688: { id: 6688, name: "Sapodillo", parent: 5795, level: 6 },
  6691: { id: 6691, name: "Soursops", parent: 5795, level: 6 },
  6692: { id: 6692, name: "Tamarillos", parent: 5793, level: 6 },
  6693: { id: 6693, name: "Sunchokes", parent: 5793, level: 6 },
  6694: { id: 6694, name: "Sugar Cane", parent: 5793, level: 6 },
  6695: { id: 6695, name: "Spinach", parent: 6622, level: 7 },
  6697: { id: 6697, name: "Tangelos", parent: 503759, level: 7 },
  6698: { id: 6698, name: "Tamarindo", parent: 5795, level: 6 },
  6700: { id: 6700, name: "Watercress", parent: 5793, level: 6 },
  6701: { id: 6701, name: "Water Chestnuts", parent: 5793, level: 6 },
  6703: { id: 6703, name: "Tomatoes", parent: 5793, level: 6 },
  6704: { id: 6704, name: "Taro Root", parent: 5793, level: 6 },
  6705: { id: 6705, name: "Yuca Root", parent: 5793, level: 6 },
  6706: { id: 6706, name: "Yu Choy", parent: 6622, level: 7 },
  6708: { id: 6708, name: "Figure Skate Boots", parent: 3791, level: 6 },
  6709: { id: 6709, name: "Outdoor Misting Systems", parent: 1626, level: 5 },
  6712: { id: 6712, name: "Tissue Paper", parent: 94, level: 6 },
  6714: {
    id: 6714,
    name: "Vital Signs Monitor Accessories",
    parent: 3477,
    level: 5,
  },
  6715: { id: 6715, name: "Power Flossers", parent: 526, level: 5 },
  6716: { id: 6716, name: "Arracachas", parent: 5793, level: 6 },
  6717: { id: 6717, name: "Chicory", parent: 6622, level: 7 },
  6721: { id: 6721, name: "Tin Whistles", parent: 87, level: 6 },
  6723: { id: 6723, name: "Dough Wheels", parent: 668, level: 5 },
  6725: { id: 6725, name: "Wooden & Pegged Puzzles", parent: 3867, level: 4 },
  6726: {
    id: 6726,
    name: "Waste Container Enclosures",
    parent: 6757,
    level: 5,
  },
  6727: { id: 6727, name: "Evaporative Coolers", parent: 1626, level: 5 },
  6728: { id: 6728, name: "Train Whistles", parent: 87, level: 6 },
  6729: { id: 6729, name: "Penalty Cards & Flags", parent: 6739, level: 5 },
  6730: { id: 6730, name: "Sport & Safety Whistles", parent: 6739, level: 5 },
  6731: { id: 6731, name: "Linesman Flags", parent: 6739, level: 5 },
  6732: { id: 6732, name: "Gaskets & O-Rings", parent: 1810, level: 5 },
  6734: { id: 6734, name: "Broomball Equipment", parent: 499713, level: 4 },
  6737: {
    id: 6737,
    name: "Hammock Parts & Accessories",
    parent: 2918,
    level: 5,
  },
  6739: {
    id: 6739,
    name: "Coaching & Officiating",
    children: [
      499729, 505813, 499732, 6731, 6729, 499731, 499733, 499727, 8505, 6730,
      499730,
    ],
    parent: 499713,
    level: 4,
  },
  6740: { id: 6740, name: "Coffee & Tea Sets", parent: 672, level: 5 },
  6741: {
    id: 6741,
    name: "Garage Door Keypads & Remotes",
    parent: 604,
    level: 4,
  },
  6743: { id: 6743, name: "Yoga & Pilates Towels", parent: 999, level: 5 },
  6744: { id: 6744, name: "Motor Vehicle Cargo Nets", parent: 8237, level: 5 },
  6746: { id: 6746, name: "Sink Caddies", parent: 668, level: 5 },
  6747: {
    id: 6747,
    name: "Garbage Disposal Accessories",
    parent: 2901,
    level: 5,
  },
  6748: {
    id: 6748,
    name: "Paintball Grenade Launchers",
    parent: 1049,
    level: 7,
  },
  6749: { id: 6749, name: "Pasta Molds & Stamps", parent: 668, level: 5 },
  6750: { id: 6750, name: "Yoga & Pilates Blocks", parent: 999, level: 5 },
  6751: {
    id: 6751,
    name: "Outdoor Umbrella & Sunshade Accessories",
    children: [7108, 5493, 7107, 499948, 8020],
    parent: 2918,
    level: 5,
  },
  6753: { id: 6753, name: "Petroleum Jelly", parent: 567, level: 6 },
  6754: { id: 6754, name: "Baking Chips", parent: 2660, level: 5 },
  6756: { id: 6756, name: "Broiling Pans", parent: 640, level: 6 },
  6757: {
    id: 6757,
    name: "Waste Containment Accessories",
    children: [6765, 6726, 500115, 4717, 6758],
    parent: 630,
    level: 4,
  },
  6758: { id: 6758, name: "Waste Container Wheels", parent: 6757, level: 5 },
  6760: { id: 6760, name: "Facial Saunas", parent: 2958, level: 7 },
  6761: { id: 6761, name: "Hard Cider", parent: 499676, level: 5 },
  6762: {
    id: 6762,
    name: "Indoor & Outdoor Plants",
    children: [543559, 543560, 543558],
    parent: 985,
    level: 4,
  },
  6763: { id: 6763, name: "Fountains & Waterfalls", parent: 702, level: 5 },
  6764: { id: 6764, name: "Protective Aprons", parent: 2047, level: 4 },
  6765: { id: 6765, name: "Waste Container Carts", parent: 6757, level: 5 },
  6766: {
    id: 6766,
    name: "Pool Ladders, Steps & Ramps",
    parent: 2832,
    level: 5,
  },
  6767: { id: 6767, name: "Wax Tarts", parent: 592, level: 5 },
  6769: { id: 6769, name: "Pneumatic Hoses", parent: 2878, level: 4 },
  6770: { id: 6770, name: "Lubrication Hoses", parent: 2878, level: 4 },
  6771: { id: 6771, name: "Pool Cleaner Hoses", parent: 2832, level: 5 },
  6772: { id: 6772, name: "Cocktail Sauce", parent: 427, level: 5 },
  6773: {
    id: 6773,
    name: "Furnace & Boiler Accessories",
    parent: 3348,
    level: 4,
  },
  6774: { id: 6774, name: "Batter & Coating Mixes", parent: 2660, level: 5 },
  6775: { id: 6775, name: "Edible Baking Decorations", parent: 2660, level: 5 },
  6779: { id: 6779, name: "Recipe Card Boxes", parent: 923, level: 4 },
  6780: { id: 6780, name: "Wire & Cable Sleeves", parent: 3328, level: 5 },
  6781: {
    id: 6781,
    name: "Paintball Harnesses & Packs",
    parent: 1049,
    level: 7,
  },
  6782: { id: 6782, name: "Horseradish Sauce", parent: 427, level: 5 },
  6783: { id: 6783, name: "Tartar Sauce", parent: 427, level: 5 },
  6784: { id: 6784, name: "Tapenade", parent: 5740, level: 5 },
  6785: { id: 6785, name: "Snack Cakes", parent: 423, level: 5 },
  6786: { id: 6786, name: "Food Washers & Dryers", parent: 135, level: 4 },
  6787: { id: 6787, name: "Gelatin Molds", parent: 668, level: 5 },
  6788: { id: 6788, name: "Robotic Mowers", parent: 694, level: 6 },
  6789: { id: 6789, name: "Lawn Vacuums", parent: 3798, level: 5 },
  6790: { id: 6790, name: "Power Control Units", parent: 275, level: 5 },
  6791: { id: 6791, name: "Facial Pore Strips", parent: 567, level: 6 },
  6792: { id: 6792, name: "Fireplaces", parent: 536, level: 3 },
  6794: { id: 6794, name: "Battle Top Accessories", parent: 3793, level: 4 },
  6795: { id: 6795, name: "Ammonia", parent: 2277, level: 5 },
  6797: { id: 6797, name: "Buttermilk", parent: 413, level: 4 },
  6799: { id: 6799, name: "Thermal Imaging Cameras", parent: 1305, level: 5 },
  6800: { id: 6800, name: "Gaming Chairs", parent: 443, level: 4 },
  6806: { id: 6806, name: "Hole Saws", parent: 3944, level: 5 },
  6807: { id: 6807, name: "Circuit Breaker Panels", parent: 127, level: 4 },
  6808: { id: 6808, name: "Cactus Leaves", parent: 5793, level: 6 },
  6809: { id: 6809, name: "Coconuts", parent: 5795, level: 6 },
  6810: { id: 6810, name: "Figs", parent: 5795, level: 6 },
  6811: { id: 6811, name: "Pet Apparel Hangers", parent: 2, level: 4 },
  6812: { id: 6812, name: "Dates", parent: 5795, level: 6 },
  6813: { id: 6813, name: "Madroño", parent: 5795, level: 6 },
  6814: { id: 6814, name: "Sugar Apples", parent: 5795, level: 6 },
  6816: { id: 6816, name: "Fennel Bulbs", parent: 5793, level: 6 },
  6817: {
    id: 6817,
    name: "Battery Charge Controllers",
    parent: 7166,
    level: 6,
  },
  6818: { id: 6818, name: "Shallots", parent: 5793, level: 6 },
  6819: { id: 6819, name: "Donut Makers", parent: 5289, level: 6 },
  6821: { id: 6821, name: "Whipped Cream", parent: 428, level: 5 },
  6822: { id: 6822, name: "Outdoor Ottomans", parent: 4299, level: 4 },
  6828: { id: 6828, name: "Outdoor Chairs", parent: 6368, level: 5 },
  6829: { id: 6829, name: "Mosaic Kits", parent: 505370, level: 6 },
  6830: { id: 6830, name: "Vegetable Dip", parent: 5740, level: 5 },
  6831: { id: 6831, name: "Cheese Dips & Spreads", parent: 5740, level: 5 },
  6832: { id: 6832, name: "Water Timers", parent: 133, level: 4 },
  6833: {
    id: 6833,
    name: "Wall Socket Controls & Sensors",
    parent: 127,
    level: 4,
  },
  6834: { id: 6834, name: "Rain Barrels", parent: 2962, level: 5 },
  6838: { id: 6838, name: "Crêpe & Blini Pans", parent: 654, level: 6 },
  6839: { id: 6839, name: "Cheese Alternatives", parent: 5807, level: 5 },
  6840: { id: 6840, name: "Compost Aerators", parent: 4085, level: 6 },
  6841: { id: 6841, name: "Gas Hoses", parent: 2878, level: 4 },
  6842: {
    id: 6842,
    name: "Hair Clipper & Trimmer Accessories",
    parent: 528,
    level: 5,
  },
  6843: { id: 6843, name: "Meat Alternatives", parent: 5807, level: 5 },
  6844: { id: 6844, name: "Ice Cream Syrup", parent: 6845, level: 6 },
  6845: {
    id: 6845,
    name: "Dessert Toppings",
    children: [6854, 6844],
    parent: 427,
    level: 5,
  },
  6846: { id: 6846, name: "Pet Training Pads", parent: 505314, level: 5 },
  6847: { id: 6847, name: "Salad Toppings", parent: 423, level: 5 },
  6848: { id: 6848, name: "Powdered Beverage Mixes", parent: 413, level: 4 },
  6850: { id: 6850, name: "Kitchen & Dining Benches", parent: 441, level: 4 },
  6851: { id: 6851, name: "Storage & Entryway Benches", parent: 441, level: 4 },
  6852: { id: 6852, name: "Hockey Stick Blades", parent: 7011, level: 6 },
  6853: { id: 6853, name: "Card Game Accessories", parent: 3793, level: 4 },
  6854: { id: 6854, name: "Fruit Toppings", parent: 6845, level: 6 },
  6857: { id: 6857, name: "Hockey Sledges", parent: 499915, level: 5 },
  6858: { id: 6858, name: "Bathroom Accessory Sets", parent: 574, level: 4 },
  6859: { id: 6859, name: "Slipper Chairs", parent: 443, level: 4 },
  6860: { id: 6860, name: "Futon Frames", parent: 436, level: 3 },
  6861: { id: 6861, name: "Pet Medical Tape & Bandages", parent: 2, level: 4 },
  6862: { id: 6862, name: "Feminine Deodorant", parent: 485, level: 5 },
  6863: { id: 6863, name: "Wart Removers", parent: 567, level: 6 },
  6864: { id: 6864, name: "Golf Ball Markers", parent: 1043, level: 5 },
  6865: { id: 6865, name: "3D Printers", parent: 345, level: 4 },
  6867: { id: 6867, name: "Hand Exercisers", parent: 990, level: 4 },
  6868: { id: 6868, name: "Planers", parent: 1167, level: 4 },
  6869: {
    id: 6869,
    name: "Baby & Pet Gate Accessories",
    parent: 540,
    level: 4,
  },
  6870: { id: 6870, name: "Watch Winders", parent: 5122, level: 5 },
  6871: { id: 6871, name: "Nutritional Food Purées", parent: 2890, level: 5 },
  6873: { id: 6873, name: "Ice Cream Novelties", parent: 5788, level: 5 },
  6876: { id: 6876, name: "Tool Bags", parent: 3974, level: 5 },
  6878: { id: 6878, name: "Humidors", parent: 600, level: 4 },
  6879: { id: 6879, name: "Cigar Cutters & Punches", parent: 600, level: 4 },
  6880: { id: 6880, name: "Humidor Accessories", parent: 600, level: 4 },
  6881: { id: 6881, name: "Cigarette Cases", parent: 600, level: 4 },
  6882: { id: 6882, name: "Cigar Cases", parent: 600, level: 4 },
  6883: { id: 6883, name: "Diaper Organizers", parent: 548, level: 4 },
  6884: {
    id: 6884,
    name: "Folders & Report Covers",
    children: [543663, 543662],
    parent: 923,
    level: 4,
  },
  6885: {
    id: 6885,
    name: "Portfolios & Padfolios",
    children: [543641, 543640],
    parent: 923,
    level: 4,
  },
  6886: { id: 6886, name: "Radios", parent: 242, level: 5 },
  6887: { id: 6887, name: "Airbrush Accessories", parent: 7019, level: 5 },
  6888: { id: 6888, name: "Coffee Decanter Warmers", parent: 3988, level: 6 },
  6891: { id: 6891, name: "Taco Shells & Tostadas", parent: 1876, level: 5 },
  6892: { id: 6892, name: "Outdoor Beds", parent: 4299, level: 4 },
  6893: { id: 6893, name: "Manicure Glue", parent: 478, level: 6 },
  6897: { id: 6897, name: "Emergency Blankets", parent: 5835, level: 4 },
  6898: { id: 6898, name: "Horse Blankets & Sheets", parent: 3257, level: 6 },
  6899: {
    id: 6899,
    name: "Swaddling & Receiving Blankets",
    children: [543664, 543665],
    parent: 537,
    level: 3,
  },
  6904: { id: 6904, name: "Utility Wire", parent: 502977, level: 5 },
  6905: { id: 6905, name: "Curry Sauce", parent: 427, level: 5 },
  6906: { id: 6906, name: "Party Hats", parent: 96, level: 5 },
  6907: { id: 6907, name: "Jigs", parent: 3650, level: 4 },
  6908: { id: 6908, name: "Workstations & Cubicles", parent: 6362, level: 4 },
  6909: {
    id: 6909,
    name: "Workstation & Cubicle Accessories",
    parent: 503765,
    level: 4,
  },
  6910: { id: 6910, name: "Table Tops", parent: 6913, level: 4 },
  6911: { id: 6911, name: "Table Legs", parent: 6913, level: 4 },
  6912: { id: 6912, name: "Desk & Shelf Clocks", parent: 3890, level: 5 },
  6913: {
    id: 6913,
    name: "Table Accessories",
    children: [6911, 6910],
    parent: 436,
    level: 3,
  },
  6914: { id: 6914, name: "Riding Pants", parent: 5594, level: 6 },
  6915: { id: 6915, name: "Room Divider Accessories", parent: 436, level: 3 },
  6919: {
    id: 6919,
    name: "Travel Bottles & Containers",
    parent: 110,
    level: 4,
  },
  6920: { id: 6920, name: "Toothbrush Covers", parent: 4775, level: 6 },
  6921: { id: 6921, name: "Spray Tanning Tents", parent: 2915, level: 4 },
  6922: { id: 6922, name: "Tomato Paste", parent: 2660, level: 5 },
  6927: { id: 6927, name: "Ottoman Cushions", parent: 696, level: 4 },
  6928: { id: 6928, name: "Ladders", parent: 130, level: 5 },
  6929: { id: 6929, name: "Walking Aid Accessories", parent: 519, level: 5 },
  6930: { id: 6930, name: "Checks", parent: 956, level: 5 },
  6932: { id: 6932, name: "Blade Server Enclosures", parent: 285, level: 5 },
  6933: {
    id: 6933,
    name: "Power Adapter & Charger Accessories",
    parent: 275,
    level: 5,
  },
  6934: { id: 6934, name: "Kitchen Cabinets", parent: 6356, level: 4 },
  6935: { id: 6935, name: "Ecospheres", parent: 696, level: 4 },
  6936: { id: 6936, name: "Dried Flowers", parent: 696, level: 4 },
  6938: { id: 6938, name: "Abrasive Blasters", parent: 1167, level: 4 },
  6939: {
    id: 6939,
    name: "Abrasive Blaster Accessories",
    children: [6940],
    parent: 3650,
    level: 4,
  },
  6940: { id: 6940, name: "Sandblasting Cabinets", parent: 6939, level: 5 },
  6942: { id: 6942, name: "Hockey Stick Shafts", parent: 7011, level: 6 },
  6943: { id: 6943, name: "Shutters", parent: 115, level: 4 },
  6944: {
    id: 6944,
    name: "Fondue Set Accessories",
    children: [503725, 6945, 6946],
    parent: 2901,
    level: 5,
  },
  6945: { id: 6945, name: "Fondue Forks", parent: 6944, level: 6 },
  6946: { id: 6946, name: "Fondue Pot Stands", parent: 6944, level: 6 },
  6947: { id: 6947, name: "Hope Chests", parent: 4205, level: 5 },
  6949: { id: 6949, name: "Diaper Liners", parent: 548, level: 4 },
  6950: { id: 6950, name: "Sippy Cups", parent: 561, level: 4 },
  6951: { id: 6951, name: "Aquarium Overflow Boxes", parent: 6, level: 5 },
  6952: {
    id: 6952,
    name: "Potty Training",
    children: [552, 6953],
    parent: 537,
    level: 3,
  },
  6953: { id: 6953, name: "Potty Training Kits", parent: 6952, level: 4 },
  6955: { id: 6955, name: "Appliques & Patches", parent: 505379, level: 7 },
  6956: { id: 6956, name: "Cocktail Shakers", parent: 651, level: 6 },
  6957: {
    id: 6957,
    name: "Cocktail & Barware Tool Sets",
    parent: 649,
    level: 5,
  },
  6958: { id: 6958, name: "Drinkware Sets", parent: 674, level: 6 },
  6960: {
    id: 6960,
    name: "Bicycle Handlebar Extensions",
    parent: 3618,
    level: 6,
  },
  6962: { id: 6962, name: "Mail Sorters", parent: 923, level: 4 },
  6963: {
    id: 6963,
    name: "Outdoor Furniture Accessories",
    children: [6964],
    parent: 436,
    level: 3,
  },
  6964: { id: 6964, name: "Outdoor Furniture Covers", parent: 6963, level: 4 },
  6965: { id: 6965, name: "Hand Tool Sets", parent: 4919, level: 5 },
  6966: { id: 6966, name: "Vehicle Warning Whips", parent: 2879, level: 6 },
  6967: { id: 6967, name: "Pruning Saws", parent: 3173, level: 6 },
  6968: {
    id: 6968,
    name: "Motor Vehicle Parking Cameras",
    parent: 8526,
    level: 5,
  },
  6969: {
    id: 6969,
    name: "High Chair & Booster Seat Accessories",
    parent: 554,
    level: 4,
  },
  6970: {
    id: 6970,
    name: "Musical Instrument Amplifier Cabinets",
    parent: 8072,
    level: 6,
  },
  6971: { id: 6971, name: "Diaper Wet Bags", parent: 548, level: 4 },
  6972: {
    id: 6972,
    name: "Medical Reflex Hammers & Tuning Forks",
    parent: 3477,
    level: 5,
  },
  6973: { id: 6973, name: "Pet Steps & Ramps", parent: 2, level: 4 },
  6974: { id: 6974, name: "Sugar Dispensers", parent: 668, level: 5 },
  6975: { id: 6975, name: "Biochemicals", parent: 1624, level: 4 },
  6977: { id: 6977, name: "Sunglass Lenses", parent: 1380, level: 5 },
  6978: {
    id: 6978,
    name: "Pet Biometric Monitors",
    children: [6980, 6982, 6981],
    parent: 2,
    level: 4,
  },
  6979: { id: 6979, name: "Keyboard Trays & Platforms", parent: 279, level: 5 },
  6980: { id: 6980, name: "Pet Glucose Meters", parent: 6978, level: 5 },
  6981: { id: 6981, name: "Pet Thermometers", parent: 6978, level: 5 },
  6982: { id: 6982, name: "Pet Pedometers", parent: 6978, level: 5 },
  6983: { id: 6983, name: "Pet Agility Equipment", parent: 2, level: 4 },
  6984: { id: 6984, name: "Collar Stays", parent: 167, level: 4 },
  6985: { id: 6985, name: "Button Studs", parent: 167, level: 4 },
  6986: { id: 6986, name: "Household Storage Caddies", parent: 636, level: 5 },
  6987: {
    id: 6987,
    name: "Material Handling",
    children: [6988, 131, 503011],
    parent: 111,
    level: 3,
  },
  6988: { id: 6988, name: "Conveyors", parent: 6987, level: 4 },
  6989: { id: 6989, name: "Incense Making Kits", parent: 505370, level: 6 },
  6990: {
    id: 6990,
    name: "Livestock Feeders & Waterers",
    parent: 6991,
    level: 5,
  },
  6991: {
    id: 6991,
    name: "Animal Husbandry",
    children: [499997, 505821, 6990, 499946],
    parent: 112,
    level: 4,
  },
  6992: { id: 6992, name: "Wild Game Feeders", parent: 3136, level: 6 },
  6993: {
    id: 6993,
    name: "Bird & Wildlife Feeders",
    children: [698, 6995, 6994],
    parent: 696,
    level: 4,
  },
  6994: { id: 6994, name: "Squirrel Feeders", parent: 6993, level: 5 },
  6995: { id: 6995, name: "Butterfly Feeders", parent: 6993, level: 5 },
  6996: { id: 6996, name: "Pool Brushes & Brooms", parent: 2832, level: 5 },
  6997: { id: 6997, name: "Collectible Trading Cards", parent: 216, level: 5 },
  6999: { id: 6999, name: "Beading Patterns", parent: 505371, level: 6 },
  7000: { id: 7000, name: "Ice Skate Blades", parent: 3791, level: 6 },
  7001: { id: 7001, name: "Diaper Pail Accessories", parent: 548, level: 4 },
  7002: { id: 7002, name: "Contraceptive Cases", parent: 491, level: 4 },
  7003: { id: 7003, name: "Motorcycle Pants", parent: 5517, level: 6 },
  7004: { id: 7004, name: "Staircases", parent: 115, level: 4 },
  7006: {
    id: 7006,
    name: "Portable Cooking Stove Accessories",
    parent: 2901,
    level: 5,
  },
  7007: { id: 7007, name: "Cocktail Decorations", parent: 96, level: 5 },
  7008: { id: 7008, name: "Wine Glass Charms", parent: 649, level: 5 },
  7010: { id: 7010, name: "Multi-Game Tables", parent: 1001, level: 4 },
  7011: {
    id: 7011,
    name: "Hockey Stick Parts",
    children: [6852, 6942],
    parent: 499915,
    level: 5,
  },
  7012: { id: 7012, name: "Hockey Stick Care", parent: 499915, level: 5 },
  7014: { id: 7014, name: "Diaper Kits", parent: 548, level: 4 },
  7016: { id: 7016, name: "Pacifier Clips & Holders", parent: 5252, level: 4 },
  7018: { id: 7018, name: "Skin Care Rollers", parent: 2958, level: 7 },
  7019: {
    id: 7019,
    name: "Paint Tool Accessories",
    children: [6887, 328062, 7020],
    parent: 3650,
    level: 4,
  },
  7020: { id: 7020, name: "Paint Roller Accessories", parent: 7019, level: 5 },
  7022: {
    id: 7022,
    name: "Vehicle License Plate Covers",
    parent: 2495,
    level: 6,
  },
  7029: {
    id: 7029,
    name: "Log Rack & Carrier Accessories",
    parent: 2862,
    level: 4,
  },
  7030: { id: 7030, name: "Log Splitters", parent: 1167, level: 4 },
  7031: { id: 7031, name: "Vehicle Soft Tops", parent: 3436, level: 6 },
  7052: { id: 7052, name: "Mailbox Enclosures", parent: 6333, level: 5 },
  7053: { id: 7053, name: "Wall Paneling", parent: 115, level: 4 },
  7054: { id: 7054, name: "Fascinators", parent: 2020, level: 5 },
  7055: { id: 7055, name: "Hammer Handles", parent: 7056, level: 5 },
  7056: {
    id: 7056,
    name: "Hammer Accessories",
    children: [7087, 7055, 7057],
    parent: 3650,
    level: 4,
  },
  7057: { id: 7057, name: "Hammer Heads", parent: 7056, level: 5 },
  7058: { id: 7058, name: "Emergency Tools & Kits", parent: 5835, level: 4 },
  7059: {
    id: 7059,
    name: "Climbing",
    children: [
      3363, 3746, 499815, 3454, 3211, 3322, 3218, 3266, 3825, 3201, 3369, 7060,
      7061, 3518, 3849,
    ],
    parent: 1011,
    level: 4,
  },
  7060: { id: 7060, name: "Ice Climbing Tools", parent: 7059, level: 5 },
  7061: { id: 7061, name: "Ice Screws", parent: 7059, level: 5 },
  7062: { id: 7062, name: "Rivets", parent: 500054, level: 5 },
  7063: { id: 7063, name: "Finger Cots", parent: 2907, level: 5 },
  7064: {
    id: 7064,
    name: "Riveting Tools",
    children: [7065, 7066],
    parent: 1167,
    level: 4,
  },
  7065: { id: 7065, name: "Rivet Guns", parent: 7064, level: 5 },
  7066: { id: 7066, name: "Rivet Pliers", parent: 7064, level: 5 },
  7068: {
    id: 7068,
    name: "Bassinet & Cradle Accessories",
    parent: 554,
    level: 4,
  },
  7070: {
    id: 7070,
    name: "Crib & Toddler Bed Accessories",
    children: [7072, 7071],
    parent: 554,
    level: 4,
  },
  7071: { id: 7071, name: "Crib Conversion Kits", parent: 7070, level: 5 },
  7072: { id: 7072, name: "Crib Bumpers & Liners", parent: 7070, level: 5 },
  7075: { id: 7075, name: "Absinthe Fountains", parent: 649, level: 5 },
  7076: { id: 7076, name: "Interfacing", parent: 505384, level: 7 },
  7077: { id: 7077, name: "Masonry & Tile Saws", parent: 1235, level: 5 },
  7078: { id: 7078, name: "Gaiters", parent: 1933, level: 4 },
  7080: { id: 7080, name: "Binding Machines", parent: 4312, level: 5 },
  7081: { id: 7081, name: "Jigsaw Puzzle Accessories", parent: 3867, level: 4 },
  7082: { id: 7082, name: "Shower Visors", parent: 4678, level: 4 },
  7085: { id: 7085, name: "Work Safety Tethers", parent: 2047, level: 4 },
  7086: { id: 7086, name: "Concrete Molds", parent: 2878, level: 4 },
  7087: { id: 7087, name: "Air Hammer Accessories", parent: 7056, level: 5 },
  7088: {
    id: 7088,
    name: "Disposable Serveware",
    children: [7089],
    parent: 135,
    level: 4,
  },
  7089: { id: 7089, name: "Disposable Serving Trays", parent: 7088, level: 5 },
  7090: {
    id: 7090,
    name: "Remote Control Airships & Blimps",
    parent: 2546,
    level: 5,
  },
  7091: { id: 7091, name: "Hearth Pads", parent: 2862, level: 4 },
  7092: {
    id: 7092,
    name: "Brackets & Reinforcement Braces",
    parent: 2878,
    level: 4,
  },
  7093: { id: 7093, name: "Bathroom Accessory Mounts", parent: 574, level: 4 },
  7096: { id: 7096, name: "Needlecraft Kits", parent: 505370, level: 6 },
  7097: {
    id: 7097,
    name: "Special Occasion Card Boxes & Holders",
    parent: 96,
    level: 5,
  },
  7099: {
    id: 7099,
    name: "Drum Kit Hardware",
    children: [7103, 7102, 7101],
    parent: 3465,
    level: 6,
  },
  7100: { id: 7100, name: "Cymbal & Drum Cases", parent: 3465, level: 6 },
  7101: { id: 7101, name: "Drum Pedals", parent: 7099, level: 7 },
  7102: {
    id: 7102,
    name: "Drum Kit Mounting Hardware",
    parent: 7099,
    level: 7,
  },
  7103: { id: 7103, name: "Bass Drum Beaters", parent: 7099, level: 7 },
  7104: { id: 7104, name: "Child Swimming Aids", parent: 1144, level: 6 },
  7107: { id: 7107, name: "Outdoor Umbrella Covers", parent: 6751, level: 6 },
  7108: {
    id: 7108,
    name: "Outdoor Umbrella & Sunshade Fabric",
    parent: 6751,
    level: 6,
  },
  7109: { id: 7109, name: "Fireplace Reflectors", parent: 2862, level: 4 },
  7110: {
    id: 7110,
    name: "Heating Radiator Accessories",
    children: [7111],
    parent: 3348,
    level: 4,
  },
  7111: {
    id: 7111,
    name: "Heating Radiator Reflectors",
    parent: 7110,
    level: 5,
  },
  7112: { id: 7112, name: "Molding", parent: 115, level: 4 },
  7113: { id: 7113, name: "Decorative Jars", parent: 696, level: 4 },
  7114: { id: 7114, name: "Bobblehead Figures", parent: 1255, level: 5 },
  7115: { id: 7115, name: "Vehicle Gun Racks", parent: 3472, level: 6 },
  7116: { id: 7116, name: "Grappling Dummies", parent: 499720, level: 6 },
  7117: {
    id: 7117,
    name: "Marker & Highlighter Ink Refills",
    children: [543667, 543666],
    parent: 4470,
    level: 5,
  },
  7118: {
    id: 7118,
    name: "Water Cooler Accessories",
    children: [7119],
    parent: 2901,
    level: 5,
  },
  7119: { id: 7119, name: "Water Cooler Bottles", parent: 7118, level: 6 },
  7120: { id: 7120, name: "Religious Altars", parent: 97, level: 4 },
  7121: { id: 7121, name: "Wallpaper Steamers", parent: 604, level: 4 },
  7122: {
    id: 7122,
    name: "Vehicle Bicycle Rack Accessories",
    parent: 6454,
    level: 6,
  },
  7125: { id: 7125, name: "Fishing Beads", parent: 7217, level: 6 },
  7127: { id: 7127, name: "Food Coloring", parent: 2660, level: 5 },
  7128: { id: 7128, name: "Mannequin Parts", parent: 138, level: 4 },
  7129: {
    id: 7129,
    name: "Punching & Training Bag Accessories",
    parent: 499720,
    level: 6,
  },
  7130: { id: 7130, name: "Shower Walls & Surrounds", parent: 2206, level: 6 },
  7131: { id: 7131, name: "Baking Weights", parent: 4502, level: 6 },
  7132: { id: 7132, name: "Overalls", parent: 5182, level: 5 },
  7133: { id: 7133, name: "Hand Muffs", parent: 167, level: 4 },
  7134: { id: 7134, name: "Enema Kits & Supplies", parent: 2915, level: 4 },
  7135: {
    id: 7135,
    name: "Travel Converters & Adapters",
    parent: 275,
    level: 5,
  },
  7136: { id: 7136, name: "Wall & Ceiling Tile", parent: 115, level: 4 },
  7137: { id: 7137, name: "Animal & Pet Repellents", parent: 2865, level: 6 },
  7138: { id: 7138, name: "Stair Lifts", parent: 520, level: 6 },
  7139: { id: 7139, name: "Foil Cutters", parent: 649, level: 5 },
  7140: { id: 7140, name: "Drill Bit Extensions", parent: 3944, level: 5 },
  7142: { id: 7142, name: "Cat Litter Box Mats", parent: 4, level: 5 },
  7143: { id: 7143, name: "Pet Bowl Mats", parent: 2, level: 4 },
  7144: { id: 7144, name: "Pet Oral Care Supplies", parent: 2, level: 4 },
  7148: { id: 7148, name: "Yo-Yo Parts & Accessories", parent: 4352, level: 5 },
  7149: { id: 7149, name: "Beverage Dispensers", parent: 668, level: 5 },
  7150: {
    id: 7150,
    name: "Drum Stick & Brush Accessories",
    children: [7151],
    parent: 3465,
    level: 6,
  },
  7151: {
    id: 7151,
    name: "Drum Stick & Brush Bags & Holders",
    parent: 7150,
    level: 7,
  },
  7152: { id: 7152, name: "Drum Keys", parent: 3465, level: 6 },
  7153: { id: 7153, name: "Drum Heads", parent: 3465, level: 6 },
  7154: { id: 7154, name: "Hiking Pole Accessories", parent: 1013, level: 5 },
  7156: {
    id: 7156,
    name: "Rounders",
    children: [7158, 7157],
    parent: 499713,
    level: 4,
  },
  7157: { id: 7157, name: "Rounders Gloves", parent: 7156, level: 5 },
  7158: { id: 7158, name: "Rounders Bats", parent: 7156, level: 5 },
  7159: { id: 7159, name: "Breadsticks", parent: 423, level: 5 },
  7160: { id: 7160, name: "Party Games", parent: 96, level: 5 },
  7163: {
    id: 7163,
    name: "Speaker Accessories",
    children: [500112, 500120, 8047, 8049, 500119],
    parent: 1420,
    level: 5,
  },
  7165: { id: 7165, name: "Electric Griddles & Grills", parent: 730, level: 5 },
  7166: {
    id: 7166,
    name: "Battery Accessories",
    children: [6817, 8243, 3130, 7167, 499928],
    parent: 275,
    level: 5,
  },
  7167: {
    id: 7167,
    name: "General Purpose Battery Chargers",
    parent: 7166,
    level: 6,
  },
  7168: {
    id: 7168,
    name: "Leaf Blower Accessories",
    children: [7171],
    parent: 4564,
    level: 5,
  },
  7169: {
    id: 7169,
    name: "Weed Trimmer Accessories",
    children: [7170, 8034],
    parent: 4564,
    level: 5,
  },
  7170: {
    id: 7170,
    name: "Weed Trimmer Blades & Spools",
    parent: 7169,
    level: 6,
  },
  7171: { id: 7171, name: "Leaf Blower Tubes", parent: 7168, level: 6 },
  7172: { id: 7172, name: "Dreamcatchers", parent: 696, level: 4 },
  7173: { id: 7173, name: "Suncatchers", parent: 696, level: 4 },
  7174: { id: 7174, name: "Reaction Balls", parent: 990, level: 4 },
  7175: { id: 7175, name: "Spears", parent: 780, level: 4 },
  7176: { id: 7176, name: "Mailbox Flags", parent: 6333, level: 5 },
  7177: { id: 7177, name: "Mailbox Covers", parent: 6333, level: 5 },
  7178: {
    id: 7178,
    name: "Watercraft Storage Racks",
    children: [8172, 8173],
    parent: 499811,
    level: 5,
  },
  7182: { id: 7182, name: "USB Adapters", parent: 258, level: 5 },
  7183: { id: 7183, name: "Ballasts & Starters", parent: 127, level: 4 },
  7184: { id: 7184, name: "Potting Benches", parent: 5632, level: 6 },
  7186: { id: 7186, name: "Spermicides", parent: 491, level: 4 },
  7187: { id: 7187, name: "Ice Maker Accessories", parent: 2901, level: 5 },
  7188: { id: 7188, name: "Flutophones", parent: 87, level: 6 },
  7190: { id: 7190, name: "Foot Files", parent: 2958, level: 7 },
  7191: { id: 7191, name: "Baby Mobile Accessories", parent: 2847, level: 4 },
  7192: { id: 7192, name: "Floral Tape", parent: 505376, level: 7 },
  7193: { id: 7193, name: "Wheatgrass", parent: 5793, level: 6 },
  7196: { id: 7196, name: "Couscous", parent: 431, level: 5 },
  7197: { id: 7197, name: "Hanging Chairs", parent: 443, level: 4 },
  7198: { id: 7198, name: "Baby Activity Toys", parent: 2847, level: 4 },
  7199: {
    id: 7199,
    name: "Laser & IPL Hair Removal Devices",
    parent: 4507,
    level: 6,
  },
  7200: {
    id: 7200,
    name: "Baby Wipe Dispensers & Warmers",
    parent: 548,
    level: 4,
  },
  7202: { id: 7202, name: "Riding Toy Accessories", parent: 1253, level: 4 },
  7206: { id: 7206, name: "Decorative Bells", parent: 696, level: 4 },
  7207: {
    id: 7207,
    name: "Bra Accessories",
    children: [7208, 7211, 7210, 7209],
    parent: 213,
    level: 5,
  },
  7208: { id: 7208, name: "Bra Strap Pads", parent: 7207, level: 6 },
  7209: {
    id: 7209,
    name: "Breast Petals & Concealers",
    parent: 7207,
    level: 6,
  },
  7210: { id: 7210, name: "Breast Enhancing Inserts", parent: 7207, level: 6 },
  7211: { id: 7211, name: "Bra Straps & Extenders", parent: 7207, level: 6 },
  7212: {
    id: 7212,
    name: "Sofa Accessories",
    children: [7213, 500064],
    parent: 436,
    level: 3,
  },
  7213: { id: 7213, name: "Chair & Sofa Supports", parent: 7212, level: 4 },
  7214: { id: 7214, name: "Furniture Floor Protectors", parent: 630, level: 4 },
  7215: {
    id: 7215,
    name: "Horse Tack Accessories",
    children: [499820, 8107],
    parent: 1031,
    level: 5,
  },
  7216: { id: 7216, name: "Saddle Racks", parent: 8107, level: 7 },
  7217: {
    id: 7217,
    name: "Fly Tying Materials",
    children: [7125, 6440],
    parent: 3334,
    level: 5,
  },
  7218: { id: 7218, name: "Dry Ice Makers", parent: 4335, level: 5 },
  7219: {
    id: 7219,
    name: "Inflatable Bouncer Accessories",
    parent: 1249,
    level: 4,
  },
  7220: { id: 7220, name: "Bed Pans", parent: 491, level: 4 },
  7221: { id: 7221, name: "Fishing Traps", parent: 3334, level: 5 },
  7222: { id: 7222, name: "Fishing Snaps & Swivels", parent: 499823, level: 6 },
  7223: {
    id: 7223,
    name: "Bicycle Toe Straps & Clips",
    parent: 3214,
    level: 6,
  },
  7224: { id: 7224, name: "Ski & Snowboard Bags", parent: 499845, level: 6 },
  7225: { id: 7225, name: "Restore Disks", parent: 313, level: 4 },
  7226: { id: 7226, name: "Fire Alarm Control Panels", parent: 1679, level: 4 },
  7227: { id: 7227, name: "Heat Detectors", parent: 1679, level: 4 },
  7230: { id: 7230, name: "Neck Gaiters", parent: 167, level: 4 },
  7231: { id: 7231, name: "Cymbal & Drum Mutes", parent: 3465, level: 6 },
  7232: { id: 7232, name: "Practice Pads", parent: 75, level: 6 },
  7234: {
    id: 7234,
    name: "Breast Milk Storage Containers",
    parent: 561,
    level: 4,
  },
  7235: {
    id: 7235,
    name: "Food Service Uniforms",
    children: [7237, 2396, 7236],
    parent: 2306,
    level: 5,
  },
  7236: { id: 7236, name: "Chef's Pants", parent: 7235, level: 6 },
  7237: { id: 7237, name: "Chef's Hats", parent: 7235, level: 6 },
  7238: { id: 7238, name: "Coaster Holders", parent: 649, level: 5 },
  7240: {
    id: 7240,
    name: "Hairdressing & Cosmetology",
    children: [505670, 7242, 7241],
    parent: 111,
    level: 3,
  },
  7241: { id: 7241, name: "Salon Chairs", parent: 7240, level: 4 },
  7242: { id: 7242, name: "Pedicure Chairs", parent: 7240, level: 4 },
  7243: { id: 7243, name: "Shower Benches & Seats", parent: 5488, level: 6 },
  7244: { id: 7244, name: "Shower Stalls & Kits", parent: 1673, level: 5 },
  7245: {
    id: 7245,
    name: "Outdoor Power Equipment Sets",
    parent: 3798,
    level: 5,
  },
  7247: { id: 7247, name: "Sushi Mats", parent: 668, level: 5 },
  7248: {
    id: 7248,
    name: "Chair Accessories",
    children: [8206],
    parent: 436,
    level: 3,
  },
  7249: { id: 7249, name: "Ocarinas", parent: 87, level: 6 },
  7250: { id: 7250, name: "Musical Pipes", parent: 87, level: 6 },
  7251: { id: 7251, name: "Basketball Hoop Padding", parent: 4676, level: 6 },
  7252: {
    id: 7252,
    name: "Off-Road and All-Terrain Vehicle Tires",
    parent: 911,
    level: 7,
  },
  7253: {
    id: 7253,
    name: "Off-Road and All-Terrain Vehicle Rims & Wheels",
    parent: 2932,
    level: 7,
  },
  7255: { id: 7255, name: "Household Storage Drawers", parent: 636, level: 5 },
  7256: { id: 7256, name: "False Eyelash Adhesive", parent: 502996, level: 8 },
  7257: {
    id: 7257,
    name: "Integrated Circuits & Chips",
    parent: 3991,
    level: 5,
  },
  7258: { id: 7258, name: "Electronic Filters", parent: 3702, level: 4 },
  7259: {
    id: 7259,
    name: "Circuit Decoders & Encoders",
    parent: 3702,
    level: 4,
  },
  7260: { id: 7260, name: "Electronic Oscillators", parent: 3635, level: 5 },
  7261: {
    id: 7261,
    name: "Automation Control Components",
    children: [7263, 7262],
    parent: 111,
    level: 3,
  },
  7262: {
    id: 7262,
    name: "Variable Frequency & Adjustable Speed Drives",
    parent: 7261,
    level: 4,
  },
  7263: {
    id: 7263,
    name: "Programmable Logic Controllers",
    parent: 7261,
    level: 4,
  },
  7264: {
    id: 7264,
    name: "Printed Circuit Boards",
    children: [298419, 499898, 3416, 499889, 8545, 8549, 8544, 499675, 8516],
    parent: 3702,
    level: 4,
  },
  7265: { id: 7265, name: "Hedge Trimmer Accessories", parent: 4564, level: 5 },
  7270: { id: 7270, name: "Dowel Pins & Rods", parent: 2878, level: 4 },
  7271: { id: 7271, name: "Carpentry Jointers", parent: 1167, level: 4 },
  7274: { id: 7274, name: "Dog Kennels & Runs", parent: 5, level: 5 },
  7275: { id: 7275, name: "Electrical Motors", parent: 127, level: 4 },
  7277: {
    id: 7277,
    name: "Music Stand Accessories",
    children: [7279, 7280, 7278],
    parent: 55,
    level: 5,
  },
  7278: { id: 7278, name: "Sheet Music Clips", parent: 7277, level: 6 },
  7279: { id: 7279, name: "Music Stand Bags", parent: 7277, level: 6 },
  7280: { id: 7280, name: "Music Stand Lights", parent: 7277, level: 6 },
  7281: { id: 7281, name: "Traditional Leather Pants", parent: 5388, level: 5 },
  7282: {
    id: 7282,
    name: "Hand Percussion Accessories",
    children: [7283, 7284],
    parent: 3465,
    level: 6,
  },
  7283: {
    id: 7283,
    name: "Hand Percussion Bags & Cases",
    parent: 7282,
    level: 7,
  },
  7284: {
    id: 7284,
    name: "Hand Percussion Stands & Mounts",
    parent: 7282,
    level: 7,
  },
  7285: {
    id: 7285,
    name: "Hand Percussion",
    children: [
      7289, 7288, 7555, 7295, 7291, 7293, 7286, 7287, 7290, 2515, 7294,
    ],
    parent: 75,
    level: 6,
  },
  7286: {
    id: 7286,
    name: "Musical Scrapers & Ratchets",
    parent: 7285,
    level: 7,
  },
  7287: { id: 7287, name: "Musical Shakers", parent: 7285, level: 7 },
  7288: { id: 7288, name: "Finger & Hand Cymbals", parent: 7285, level: 7 },
  7289: { id: 7289, name: "Claves & Castanets", parent: 7285, level: 7 },
  7290: { id: 7290, name: "Musical Triangles", parent: 7285, level: 7 },
  7291: { id: 7291, name: "Musical Blocks", parent: 7285, level: 7 },
  7293: { id: 7293, name: "Musical Cowbells", parent: 7285, level: 7 },
  7294: { id: 7294, name: "Vibraslaps", parent: 7285, level: 7 },
  7295: {
    id: 7295,
    name: "Hand Drums",
    children: [7298, 7297, 7296, 7300, 7299, 7302, 7301],
    parent: 7285,
    level: 7,
  },
  7296: { id: 7296, name: "Congas", parent: 7295, level: 8 },
  7297: { id: 7297, name: "Cajons", parent: 7295, level: 8 },
  7298: { id: 7298, name: "Bongos", parent: 7295, level: 8 },
  7299: { id: 7299, name: "Goblet Drums", parent: 7295, level: 8 },
  7300: { id: 7300, name: "Frame Drums", parent: 7295, level: 8 },
  7301: { id: 7301, name: "Talking Drums", parent: 7295, level: 8 },
  7302: { id: 7302, name: "Tablas", parent: 7295, level: 8 },
  7303: { id: 7303, name: "Bakery Boxes", parent: 135, level: 4 },
  7304: { id: 7304, name: "Bald Caps", parent: 5192, level: 5 },
  7305: {
    id: 7305,
    name: "Wig Accessories",
    children: [7307, 7306],
    parent: 171,
    level: 5,
  },
  7306: { id: 7306, name: "Wig Glue & Tape", parent: 7305, level: 6 },
  7307: { id: 7307, name: "Wig Caps", parent: 7305, level: 6 },
  7308: { id: 7308, name: "Percussion Stands", parent: 3465, level: 6 },
  7309: { id: 7309, name: "Pacifier Wipes", parent: 5252, level: 4 },
  7310: { id: 7310, name: "Outdoor Storage Boxes", parent: 4299, level: 4 },
  7311: {
    id: 7311,
    name: "Ball Pit Accessories",
    children: [7312],
    parent: 1253,
    level: 4,
  },
  7312: { id: 7312, name: "Ball Pit Balls", parent: 7311, level: 5 },
  7313: { id: 7313, name: "Outfit Sets", parent: 1604, level: 4 },
  7314: { id: 7314, name: "Golf Accessory Sets", parent: 1043, level: 5 },
  7316: { id: 7316, name: "PAP Masks", parent: 4551, level: 5 },
  7317: { id: 7317, name: "PAP Machines", parent: 4551, level: 5 },
  7318: { id: 7318, name: "Pet Nail Polish", parent: 6383, level: 5 },
  7319: { id: 7319, name: "Pet Nail Tools", parent: 6383, level: 5 },
  7320: { id: 7320, name: "Dry Cleaning Kits", parent: 627, level: 5 },
  7322: { id: 7322, name: "Business Hour Signs", parent: 976, level: 4 },
  7323: { id: 7323, name: "Open & Closed Signs", parent: 976, level: 4 },
  7324: { id: 7324, name: "Tongue Depressors", parent: 2907, level: 5 },
  7325: { id: 7325, name: "Dissection Kits", parent: 1624, level: 4 },
  7326: {
    id: 7326,
    name: "Axe Accessories",
    children: [7370, 7369],
    parent: 3650,
    level: 4,
  },
  7327: { id: 7327, name: "Sticky Rice Cakes", parent: 423, level: 5 },
  7328: { id: 7328, name: "Duct Heaters", parent: 1626, level: 5 },
  7329: { id: 7329, name: "Food Steaming Bags", parent: 668, level: 5 },
  7330: { id: 7330, name: "All-Purpose Cleaners", parent: 4973, level: 6 },
  7331: {
    id: 7331,
    name: "Cooking Thermometer Accessories",
    parent: 668,
    level: 5,
  },
  7332: {
    id: 7332,
    name: "Outdoor Power Equipment Base Units",
    parent: 3798,
    level: 5,
  },
  7333: {
    id: 7333,
    name: "Outdoor Power Equipment Batteries",
    parent: 4564,
    level: 5,
  },
  7334: { id: 7334, name: "Hedge Trimmer Attachments", parent: 8485, level: 6 },
  7335: { id: 7335, name: "Weed Trimmer Attachments", parent: 8485, level: 6 },
  7336: { id: 7336, name: "HIV Tests", parent: 505293, level: 5 },
  7337: { id: 7337, name: "Blood Typing Test Kits", parent: 505293, level: 5 },
  7338: { id: 7338, name: "Lamp Post Bases", parent: 2956, level: 4 },
  7339: { id: 7339, name: "Mailbox Replacement Doors", parent: 6333, level: 5 },
  7340: { id: 7340, name: "Embossing Heat Tools", parent: 504640, level: 7 },
  7342: { id: 7342, name: "Fishing Gaffs", parent: 3334, level: 5 },
  7343: { id: 7343, name: "Fishing Spears", parent: 3334, level: 5 },
  7344: {
    id: 7344,
    name: "Fishing Hook Removal Tools",
    parent: 3334,
    level: 5,
  },
  7345: {
    id: 7345,
    name: "Handheld Circular Saw Accessories",
    parent: 6549,
    level: 5,
  },
  7346: { id: 7346, name: "Jigsaw Accessories", parent: 6549, level: 5 },
  7347: {
    id: 7347,
    name: "Mobile Phone Replacement Parts",
    parent: 264,
    level: 6,
  },
  7349: {
    id: 7349,
    name: "Tablet Computer Parts",
    children: [503002, 45262, 500013],
    parent: 285,
    level: 5,
  },
  7351: { id: 7351, name: "Drawer & Shelf Liners", parent: 630, level: 4 },
  7353: { id: 7353, name: "Ice Bins", parent: 135, level: 4 },
  7354: { id: 7354, name: "Starter Cultures", parent: 2660, level: 5 },
  7355: {
    id: 7355,
    name: "Electric Skillet & Wok Accessories",
    parent: 2901,
    level: 5,
  },
  7356: { id: 7356, name: "Double Eyelid Glue & Tape", parent: 2548, level: 7 },
  7357: {
    id: 7357,
    name: "Musical Keyboard Bags & Cases",
    parent: 60,
    level: 6,
  },
  7358: { id: 7358, name: "Toilet Seat Covers", parent: 2691, level: 6 },
  7360: { id: 7360, name: "Baby Soothers", parent: 2847, level: 4 },
  7362: {
    id: 7362,
    name: "Toner & Inkjet Cartridge Refills",
    parent: 5258,
    level: 6,
  },
  7363: { id: 7363, name: "Contact Lens Care Kits", parent: 3011, level: 6 },
  7364: {
    id: 7364,
    name: "Musical Instrument Amplifier Stands",
    parent: 8072,
    level: 6,
  },
  7365: { id: 7365, name: "Pizza Cutter Accessories", parent: 668, level: 5 },
  7366: {
    id: 7366,
    name: "Flying Toy Accessories",
    children: [7368],
    parent: 1253,
    level: 4,
  },
  7368: {
    id: 7368,
    name: "Kite Accessories",
    children: [7371],
    parent: 7366,
    level: 5,
  },
  7369: { id: 7369, name: "Axe Heads", parent: 7326, level: 5 },
  7370: { id: 7370, name: "Axe Handles", parent: 7326, level: 5 },
  7371: { id: 7371, name: "Kite Line Reels & Winders", parent: 7368, level: 6 },
  7372: { id: 7372, name: "Dog Diaper Pads & Liners", parent: 5, level: 5 },
  7373: { id: 7373, name: "Hearing Enhancers", parent: 3136, level: 6 },
  7374: { id: 7374, name: "Rye", parent: 431, level: 5 },
  7375: {
    id: 7375,
    name: "Kite Buggying",
    children: [7376, 7377],
    parent: 1011,
    level: 4,
  },
  7376: { id: 7376, name: "Kite Buggies", parent: 7375, level: 5 },
  7377: { id: 7377, name: "Kite Buggy Accessories", parent: 7375, level: 5 },
  7380: { id: 7380, name: "Cardboard Cutouts", parent: 696, level: 4 },
  7382: { id: 7382, name: "House Numbers & Letters", parent: 696, level: 4 },
  7383: {
    id: 7383,
    name: "Poker Chip Accessories",
    children: [7384],
    parent: 3793,
    level: 4,
  },
  7384: {
    id: 7384,
    name: "Poker Chip Carriers & Trays",
    parent: 7383,
    level: 5,
  },
  7385: {
    id: 7385,
    name: "Bird Cage Accessories",
    children: [499954, 7386],
    parent: 3,
    level: 5,
  },
  7386: {
    id: 7386,
    name: "Bird Cage Food & Water Dishes",
    parent: 7385,
    level: 6,
  },
  7387: { id: 7387, name: "Dried Vegetables", parent: 430, level: 5 },
  7393: { id: 7393, name: "Spectrometers", parent: 4335, level: 5 },
  7394: { id: 7394, name: "Transducers", parent: 1305, level: 5 },
  7395: { id: 7395, name: "Accelerometers", parent: 1801, level: 4 },
  7396: { id: 7396, name: "Pet Sunscreen", parent: 2, level: 4 },
  7397: {
    id: 7397,
    name: "Ball Carrying Bags & Carts",
    parent: 499799,
    level: 5,
  },
  7398: { id: 7398, name: "Bird Gyms & Playstands", parent: 3, level: 5 },
  7399: { id: 7399, name: "Tiki Torches & Oil Lamps", parent: 594, level: 4 },
  7400: { id: 7400, name: "Landscape Pathway Lighting", parent: 594, level: 4 },
  7401: { id: 7401, name: "In-Ground Lights", parent: 594, level: 4 },
  7402: { id: 7402, name: "Wicks", parent: 505372, level: 6 },
  7403: { id: 7403, name: "Wick Tabs", parent: 505372, level: 6 },
  7404: {
    id: 7404,
    name: "Back & Lumbar Support Cushions",
    parent: 493,
    level: 5,
  },
  7406: { id: 7406, name: "Moisture Absorbers", parent: 630, level: 4 },
  7407: { id: 7407, name: "Dust Masks", parent: 503724, level: 5 },
  7411: { id: 7411, name: "Blackjack & Craps Sets", parent: 3793, level: 4 },
  7412: { id: 7412, name: "Roulette Wheels & Sets", parent: 3793, level: 4 },
  7413: { id: 7413, name: "Tube Feeding Supplements", parent: 2890, level: 5 },
  7414: { id: 7414, name: "Vehicle Battery Chargers", parent: 8236, level: 6 },
  7415: { id: 7415, name: "Multimeter Accessories", parent: 5526, level: 5 },
  7416: { id: 7416, name: "Inspection Mirrors", parent: 1167, level: 4 },
  7417: { id: 7417, name: "Powdered Hand Soap", parent: 474, level: 6 },
  7419: {
    id: 7419,
    name: "Flag & Windsock Accessories",
    children: [7420, 503010, 7421],
    parent: 696,
    level: 4,
  },
  7420: {
    id: 7420,
    name: "Flag & Windsock Pole Lights",
    parent: 7419,
    level: 5,
  },
  7421: { id: 7421, name: "Flag & Windsock Poles", parent: 7419, level: 5 },
  7422: { id: 7422, name: "Finials", parent: 696, level: 4 },
  7423: { id: 7423, name: "Canopy Poles", parent: 6105, level: 7 },
  7424: { id: 7424, name: "Canopy Weights", parent: 6105, level: 7 },
  7425: { id: 7425, name: "Paddle Ball Toys", parent: 4352, level: 5 },
  7426: {
    id: 7426,
    name: "Stainless Steel Cleaners & Polishes",
    parent: 4973,
    level: 6,
  },
  7427: { id: 7427, name: "Sesame Sticks", parent: 423, level: 5 },
  7428: { id: 7428, name: "Dog Kennel & Run Accessories", parent: 5, level: 5 },
  7429: { id: 7429, name: "Anti-Aging Skin Care Kits", parent: 567, level: 6 },
  7430: { id: 7430, name: "Paddle Ball Sets", parent: 499846, level: 5 },
  7431: { id: 7431, name: "Glockenspiels & Xylophones", parent: 75, level: 6 },
  7433: {
    id: 7433,
    name: "Ball Pump Accessories",
    children: [7435],
    parent: 499799,
    level: 5,
  },
  7434: { id: 7434, name: "Ball Pumps", parent: 499799, level: 5 },
  7435: { id: 7435, name: "Ball Pump Needles", parent: 7433, level: 6 },
  7436: { id: 7436, name: "Mail Slots", parent: 696, level: 4 },
  7437: { id: 7437, name: "Microtomes", parent: 4335, level: 5 },
  7438: { id: 7438, name: "Tablet Computer Batteries", parent: 276, level: 6 },
  7439: {
    id: 7439,
    name: "Sound Dampening Panels & Foam",
    parent: 115,
    level: 4,
  },
  7444: { id: 7444, name: "Toaster Accessories", parent: 2901, level: 5 },
  7445: { id: 7445, name: "Nail Polish Thinners", parent: 478, level: 6 },
  7446: { id: 7446, name: "Toilet Seat Lid Covers", parent: 2691, level: 6 },
  7447: { id: 7447, name: "Lamp Post Mounts", parent: 2956, level: 4 },
  7448: { id: 7448, name: "Bicycle Spoke Beads", parent: 3214, level: 6 },
  7449: { id: 7449, name: "Boating Gloves", parent: 1120, level: 6 },
  7451: { id: 7451, name: "Surfing Gloves", parent: 1143, level: 6 },
  7452: { id: 7452, name: "Towed Water Sport Gloves", parent: 3195, level: 6 },
  7453: { id: 7453, name: "Bicycle Cleat Covers", parent: 7474, level: 7 },
  7455: { id: 7455, name: "Electronic Drum Modules", parent: 3465, level: 6 },
  7457: { id: 7457, name: "Garment Shields", parent: 627, level: 5 },
  7459: {
    id: 7459,
    name: "Horse Vitamins & Supplements",
    parent: 3257,
    level: 6,
  },
  7460: {
    id: 7460,
    name: "Hunting & Shooting Protective Gear",
    children: [7461, 7518],
    parent: 499824,
    level: 5,
  },
  7461: { id: 7461, name: "Hunting & Shooting Gloves", parent: 7460, level: 6 },
  7462: { id: 7462, name: "Washing Machine Cleaners", parent: 4973, level: 6 },
  7467: { id: 7467, name: "Facial Cleansing Kits", parent: 567, level: 6 },
  7468: { id: 7468, name: "Spectrometer Accessories", parent: 4335, level: 5 },
  7470: {
    id: 7470,
    name: "Septic Tank & Cesspool Treatments",
    parent: 2277,
    level: 5,
  },
  7471: { id: 7471, name: "Watch Stickers & Decals", parent: 5122, level: 5 },
  7472: {
    id: 7472,
    name: "Magnetizers & Demagnetizers",
    parent: 3650,
    level: 4,
  },
  7473: { id: 7473, name: "Ribbon & Streamer Toys", parent: 4352, level: 5 },
  7474: {
    id: 7474,
    name: "Bicycle Cleat Accessories",
    children: [7476, 7453, 7475],
    parent: 3982,
    level: 6,
  },
  7475: {
    id: 7475,
    name: "Bicycle Cleat Shims & Wedges",
    parent: 7474,
    level: 7,
  },
  7476: { id: 7476, name: "Bicycle Cleat Bolts", parent: 7474, level: 7 },
  7477: { id: 7477, name: "Bicycle Headsets", parent: 3618, level: 6 },
  7478: {
    id: 7478,
    name: "Bicycle Headset Parts",
    children: [7480, 7479],
    parent: 3618,
    level: 6,
  },
  7479: { id: 7479, name: "Bicycle Headset Spacers", parent: 7478, level: 7 },
  7480: { id: 7480, name: "Bicycle Headset Bearings", parent: 7478, level: 7 },
  7481: { id: 7481, name: "Horse Treats", parent: 3257, level: 6 },
  7482: { id: 7482, name: "Horse Feed", parent: 3257, level: 6 },
  7484: { id: 7484, name: "Grout Sponges", parent: 1193, level: 5 },
  7485: { id: 7485, name: "Jerky Guns", parent: 668, level: 5 },
  7486: { id: 7486, name: "Bitters", parent: 499676, level: 5 },
  7490: { id: 7490, name: "Nail Drills", parent: 2975, level: 7 },
  7493: {
    id: 7493,
    name: "False Eyelash Applicators",
    parent: 502996,
    level: 8,
  },
  7494: {
    id: 7494,
    name: "Manicure & Pedicure Spacers",
    parent: 2975,
    level: 7,
  },
  7495: { id: 7495, name: "Toe Spacers", parent: 515, level: 5 },
  7496: { id: 7496, name: "Pool Deck Kits", parent: 2832, level: 5 },
  7497: {
    id: 7497,
    name: "Dentistry",
    children: [7500, 7499, 8130],
    parent: 111,
    level: 3,
  },
  7498: { id: 7498, name: "Dental Mirrors", parent: 7499, level: 5 },
  7499: {
    id: 7499,
    name: "Dental Tools",
    children: [8490, 7498, 7531, 8121, 8120],
    parent: 7497,
    level: 4,
  },
  7500: { id: 7500, name: "Dental Cement", parent: 7497, level: 4 },
  7501: { id: 7501, name: "Laptop Replacement Cables", parent: 4224, level: 6 },
  7502: {
    id: 7502,
    name: "Wrinkle Releasers & Anti-Static Sprays",
    parent: 627,
    level: 5,
  },
  7503: { id: 7503, name: "Deck & Fence Cleaners", parent: 2277, level: 5 },
  7504: {
    id: 7504,
    name: "Concrete & Masonry Cleaners",
    parent: 2277,
    level: 5,
  },
  7506: { id: 7506, name: "Cookie Decorating Kits", parent: 2660, level: 5 },
  7509: {
    id: 7509,
    name: "Toilet Brush Replacement Heads",
    parent: 574,
    level: 4,
  },
  7510: { id: 7510, name: "Dishwasher Cleaners", parent: 4973, level: 6 },
  7514: { id: 7514, name: "Hat Boxes", parent: 5558, level: 6 },
  7515: { id: 7515, name: "Band Saw Accessories", parent: 6549, level: 5 },
  7516: {
    id: 7516,
    name: "Power Tool & Equipment Manuals",
    parent: 5037,
    level: 4,
  },
  7517: { id: 7517, name: "Small Animal Treats", parent: 5013, level: 5 },
  7518: {
    id: 7518,
    name: "Hunting & Shooting Jackets",
    parent: 7460,
    level: 6,
  },
  7519: { id: 7519, name: "Ball & Cup Games", parent: 4352, level: 5 },
  7521: { id: 7521, name: "Luggage Covers", parent: 110, level: 4 },
  7522: { id: 7522, name: "Patient Lifts", parent: 3477, level: 5 },
  7523: { id: 7523, name: "Fireplace Andirons", parent: 2862, level: 4 },
  7525: {
    id: 7525,
    name: "Bulletin Board Accessories",
    children: [7526, 543688],
    parent: 966,
    level: 5,
  },
  7526: { id: 7526, name: "Bulletin Board Trim", parent: 7525, level: 6 },
  7527: {
    id: 7527,
    name: "Powered Hand Fans & Misters",
    parent: 608,
    level: 6,
  },
  7528: { id: 7528, name: "Vinegar Drinks", parent: 413, level: 4 },
  7530: { id: 7530, name: "Computer Covers & Skins", parent: 279, level: 5 },
  7531: { id: 7531, name: "Dental Tool Sets", parent: 7499, level: 5 },
  7532: { id: 7532, name: "Vehicle Magnets", parent: 2495, level: 6 },
  7537: { id: 7537, name: "Bulb Planting Tools", parent: 3173, level: 6 },
  7538: { id: 7538, name: "Bicycle Foot Pegs", parent: 4597, level: 7 },
  7539: { id: 7539, name: "Sleds", parent: 499844, level: 5 },
  7540: { id: 7540, name: "Charcoal Chimneys", parent: 3684, level: 6 },
  7542: { id: 7542, name: "Earplug Dispensers", parent: 506, level: 5 },
  7550: { id: 7550, name: "Egg Cups", parent: 4026, level: 6 },
  7551: { id: 7551, name: "E-Book Reader Batteries", parent: 276, level: 6 },
  7552: { id: 7552, name: "Rinse Aids", parent: 4973, level: 6 },
  7553: { id: 7553, name: "Tilt Skillets", parent: 135, level: 4 },
  7555: { id: 7555, name: "Hand Bells & Chimes", parent: 7285, level: 7 },
  7556: { id: 7556, name: "Grease Guns", parent: 1167, level: 4 },
  7557: { id: 7557, name: "Flagging & Caution Tape", parent: 2878, level: 4 },
  7558: {
    id: 7558,
    name: "Ski & Snowboard Storage Racks",
    parent: 499845,
    level: 6,
  },
  7559: {
    id: 7559,
    name: "Office Chair Accessories",
    parent: 503765,
    level: 4,
  },
  7561: {
    id: 7561,
    name: "Sprinklers & Sprinkler Heads",
    parent: 3568,
    level: 5,
  },
  7562: { id: 7562, name: "Nippers", parent: 1180, level: 5 },
  7563: { id: 7563, name: "Grass Edger Accessories", parent: 4564, level: 5 },
  7564: { id: 7564, name: "Grass Edger Attachments", parent: 8485, level: 6 },
  7565: { id: 7565, name: "Bullion", parent: 1813, level: 4 },
  7566: {
    id: 7566,
    name: "MP3 Player & Mobile Phone Accessory Sets",
    parent: 232,
    level: 6,
  },
  7567: { id: 7567, name: "Clubs & Batons", parent: 780, level: 4 },
  7568: { id: 7568, name: "Beer Glasses", parent: 674, level: 6 },
  7569: {
    id: 7569,
    name: "Beverage Chilling Cubes & Sticks",
    parent: 649,
    level: 5,
  },
  7570: { id: 7570, name: "Freezer Accessories", parent: 2901, level: 5 },
  7789: { id: 7789, name: "Skateboarding Gloves", parent: 3067, level: 6 },
  8005: { id: 8005, name: "Airsoft Gun Batteries", parent: 3116, level: 8 },
  8006: { id: 8006, name: "Kitchen Molds", parent: 668, level: 5 },
  8007: { id: 8007, name: "Craft Molds", parent: 505371, level: 6 },
  8008: {
    id: 8008,
    name: "Game Controller Accessories",
    parent: 6475,
    level: 6,
  },
  8011: {
    id: 8011,
    name: "Wildlife Attractants",
    children: [8080, 3756, 3583, 8081],
    parent: 3136,
    level: 6,
  },
  8012: {
    id: 8012,
    name: "Kitchen Utensil Holders & Racks",
    parent: 2948,
    level: 6,
  },
  8015: {
    id: 8015,
    name: "Laminating Film, Pouches & Sheets",
    parent: 932,
    level: 4,
  },
  8016: { id: 8016, name: "Hand Dryer Accessories", parent: 574, level: 4 },
  8017: { id: 8017, name: "Costume Accessory Sets", parent: 5192, level: 5 },
  8018: { id: 8018, name: "Pretend Jewelry", parent: 5192, level: 5 },
  8020: { id: 8020, name: "Outdoor Umbrella Lights", parent: 6751, level: 6 },
  8021: { id: 8021, name: "Paper & Magnetic Dolls", parent: 1255, level: 5 },
  8022: { id: 8022, name: "Document Templates", parent: 5032, level: 4 },
  8023: {
    id: 8023,
    name: "Shelving Accessories",
    children: [8024],
    parent: 436,
    level: 3,
  },
  8024: { id: 8024, name: "Replacement Shelves", parent: 8023, level: 4 },
  8025: {
    id: 8025,
    name: "Industrial Storage Accessories",
    parent: 111,
    level: 3,
  },
  8026: { id: 8026, name: "Scalpels", parent: 230913, level: 5 },
  8029: {
    id: 8029,
    name: "Compressed Skin Care Mask Sheets",
    parent: 567,
    level: 6,
  },
  8030: { id: 8030, name: "Eggnog", parent: 413, level: 4 },
  8031: { id: 8031, name: "Shoe Scrapers", parent: 628, level: 5 },
  8032: { id: 8032, name: "Shoe Polishers", parent: 628, level: 5 },
  8033: { id: 8033, name: "Shoe Care Kits", parent: 628, level: 5 },
  8034: { id: 8034, name: "Weed Trimmer Spool Covers", parent: 7169, level: 6 },
  8036: { id: 8036, name: "Fruit Flavored Drinks", parent: 413, level: 4 },
  8038: { id: 8038, name: "Event Programs", parent: 96, level: 5 },
  8039: { id: 8039, name: "Food Wrap Dispensers", parent: 6478, level: 5 },
  8042: {
    id: 8042,
    name: "Window Treatment Replacement Parts",
    parent: 6254,
    level: 5,
  },
  8043: {
    id: 8043,
    name: "Fabric & Upholstery Cleaners",
    parent: 4973,
    level: 6,
  },
  8044: { id: 8044, name: "Divot Tools", parent: 1043, level: 5 },
  8046: { id: 8046, name: "Tablecloth Clips & Weights", parent: 672, level: 5 },
  8047: { id: 8047, name: "Speaker Stand Bags", parent: 7163, level: 6 },
  8049: { id: 8049, name: "Speaker Stands & Mounts", parent: 7163, level: 6 },
  8050: { id: 8050, name: "Pet Eye Drops & Lubricants", parent: 2, level: 4 },
  8051: {
    id: 8051,
    name: "Steam Table Accessories",
    children: [8052, 8053],
    parent: 2901,
    level: 5,
  },
  8052: { id: 8052, name: "Steam Table Pan Covers", parent: 8051, level: 6 },
  8053: { id: 8053, name: "Steam Table Pans", parent: 8051, level: 6 },
  8058: {
    id: 8058,
    name: "Household Drawer Organizer Inserts",
    parent: 636,
    level: 5,
  },
  8059: { id: 8059, name: "Disposable Lids", parent: 135, level: 4 },
  8061: { id: 8061, name: "Bicycle Protective Pads", parent: 3982, level: 6 },
  8062: {
    id: 8062,
    name: "Speed & Resistance Parachutes",
    parent: 990,
    level: 4,
  },
  8064: { id: 8064, name: "Bite Alarms", parent: 3334, level: 5 },
  8065: { id: 8065, name: "Billiard Table Covers", parent: 3183, level: 6 },
  8066: {
    id: 8066,
    name: "Vibration Exercise Machines",
    parent: 990,
    level: 4,
  },
  8067: { id: 8067, name: "Key Caps", parent: 1974, level: 4 },
  8068: {
    id: 8068,
    name: "Pet First Aid & Emergency Kits",
    parent: 2,
    level: 4,
  },
  8069: { id: 8069, name: "Cat Litter Box Liners", parent: 4, level: 5 },
  8070: { id: 8070, name: "Pet Waste Bags", parent: 2, level: 4 },
  8071: {
    id: 8071,
    name: "Shop Towels & General-Purpose Cleaning Cloths",
    parent: 623,
    level: 5,
  },
  8072: {
    id: 8072,
    name: "Musical Instrument Amplifier Accessories",
    children: [6970, 8461, 8073, 8462, 7364, 8480],
    parent: 55,
    level: 5,
  },
  8073: {
    id: 8073,
    name: "Musical Instrument Amplifier Footswitches",
    parent: 8072,
    level: 6,
  },
  8074: { id: 8074, name: "Ski & Snowboard Wax", parent: 499845, level: 6 },
  8075: { id: 8075, name: "Nursing Pillow Covers", parent: 561, level: 4 },
  8076: { id: 8076, name: "Meal", parent: 2660, level: 5 },
  8077: { id: 8077, name: "Practice Nets & Screens", parent: 499799, level: 5 },
  8078: { id: 8078, name: "Desk Pads & Blotters", parent: 922, level: 3 },
  8079: { id: 8079, name: "Windbreaks", parent: 1013, level: 5 },
  8080: {
    id: 8080,
    name: "Cover Scents & Scent Attractants",
    parent: 8011,
    level: 7,
  },
  8081: {
    id: 8081,
    name: "Wildlife Bait, Feed & Minerals",
    parent: 8011,
    level: 7,
  },
  8082: { id: 8082, name: "Specimen Cups", parent: 491, level: 4 },
  8083: { id: 8083, name: "Free Weight Storage Racks", parent: 6452, level: 6 },
  8085: { id: 8085, name: "Basketball Arcade Games", parent: 3356, level: 4 },
  8086: {
    id: 8086,
    name: "Vehicle Ski & Snowboard Rack Accessories",
    parent: 6454,
    level: 6,
  },
  8087: { id: 8087, name: "Range Hood Accessories", parent: 2901, level: 5 },
  8090: { id: 8090, name: "Wall Mount Fans", parent: 608, level: 6 },
  8092: {
    id: 8092,
    name: "Fishing Reel Accessories",
    children: [8273, 8094, 8208],
    parent: 3334,
    level: 5,
  },
  8093: {
    id: 8093,
    name: "Fishing Rod Accessories",
    children: [8272, 499942],
    parent: 3334,
    level: 5,
  },
  8094: { id: 8094, name: "Fishing Reel Lubricants", parent: 8092, level: 6 },
  8105: { id: 8105, name: "Stump Shrinkers", parent: 491, level: 4 },
  8106: { id: 8106, name: "Wine Fridge Accessories", parent: 2901, level: 5 },
  8107: {
    id: 8107,
    name: "Saddle Accessories",
    children: [326122, 499959, 8108, 7216],
    parent: 7215,
    level: 6,
  },
  8108: { id: 8108, name: "Saddle Pads & Blankets", parent: 8107, level: 7 },
  8109: { id: 8109, name: "Stirrups", parent: 5593, level: 6 },
  8110: {
    id: 8110,
    name: "Inflatable Party Decorations",
    parent: 96,
    level: 5,
  },
  8111: {
    id: 8111,
    name: "Mobile Phone Camera Accessories",
    parent: 264,
    level: 6,
  },
  8112: { id: 8112, name: "Ground Spikes", parent: 2878, level: 4 },
  8113: { id: 8113, name: "Post Base Plates", parent: 2878, level: 4 },
  8114: { id: 8114, name: "Safety Grab Bars", parent: 574, level: 4 },
  8115: { id: 8115, name: "Faucet Aerators", parent: 1489, level: 6 },
  8116: { id: 8116, name: "Faucet Handles & Controls", parent: 1489, level: 6 },
  8117: {
    id: 8117,
    name: "Cutter Accessories",
    children: [8118],
    parent: 3650,
    level: 4,
  },
  8118: { id: 8118, name: "Nibbler Dies", parent: 8117, level: 5 },
  8119: { id: 8119, name: "Laboratory Specimens", parent: 1624, level: 4 },
  8120: { id: 8120, name: "Prophy Heads", parent: 7499, level: 5 },
  8121: { id: 8121, name: "Prophy Cups", parent: 7499, level: 5 },
  8122: { id: 8122, name: "Menstrual Cups", parent: 485, level: 5 },
  8123: { id: 8123, name: "Dog Treadmills", parent: 5, level: 5 },
  8127: {
    id: 8127,
    name: "Sports Toy Accessories",
    children: [8129],
    parent: 1253,
    level: 4,
  },
  8128: { id: 8128, name: "Hula Hoop Accessories", parent: 8129, level: 6 },
  8129: {
    id: 8129,
    name: "Fitness Toy Accessories",
    children: [8128],
    parent: 8127,
    level: 5,
  },
  8130: { id: 8130, name: "Prophy Paste", parent: 7497, level: 4 },
  8132: {
    id: 8132,
    name: "Skin Cleansing Brush Heads",
    parent: 2958,
    level: 7,
  },
  8134: {
    id: 8134,
    name: "Marine Audio & Video Receivers",
    parent: 340,
    level: 4,
  },
  8135: { id: 8135, name: "Massage Stones", parent: 5663, level: 5 },
  8136: { id: 8136, name: "Hair Removal Wax Warmers", parent: 4507, level: 6 },
  8137: { id: 8137, name: "Motor Vehicle Engines", parent: 899, level: 5 },
  8142: { id: 8142, name: "Vehicle Shift Knobs", parent: 2495, level: 6 },
  8144: {
    id: 8144,
    name: "Motor Vehicle Brake Caliper Paint",
    parent: 3812,
    level: 6,
  },
  8145: { id: 8145, name: "Vehicle Hitch Covers", parent: 2495, level: 6 },
  8147: {
    id: 8147,
    name: "Motor Vehicle Loading Ramps",
    parent: 8237,
    level: 5,
  },
  8149: {
    id: 8149,
    name: "Baptism & Communion Dresses",
    parent: 5483,
    level: 6,
  },
  8155: { id: 8155, name: "Digital Signs", parent: 976, level: 4 },
  8156: {
    id: 8156,
    name: "Audio & Video Splitters & Switches",
    children: [499944, 8164, 499945],
    parent: 2082,
    level: 4,
  },
  8158: {
    id: 8158,
    name: "Computer Backplates & I/O Shields",
    parent: 285,
    level: 5,
  },
  8159: { id: 8159, name: "Bullhorns", parent: 223, level: 4 },
  8160: { id: 8160, name: "Laptop Screen Digitizers", parent: 4224, level: 6 },
  8161: {
    id: 8161,
    name: "Prefabricated Kitchens & Kitchenettes",
    parent: 638,
    level: 4,
  },
  8162: {
    id: 8162,
    name: "E-Book Reader Parts",
    children: [8163],
    parent: 285,
    level: 5,
  },
  8163: {
    id: 8163,
    name: "E-Book Reader Screens & Screen Digitizers",
    parent: 8162,
    level: 6,
  },
  8164: { id: 8164, name: "HDMI Splitters & Switches", parent: 8156, level: 5 },
  8167: { id: 8167, name: "Pet Hair Dryers", parent: 6383, level: 5 },
  8168: { id: 8168, name: "Craft Foil", parent: 24, level: 7 },
  8172: { id: 8172, name: "Boat Storage Racks", parent: 7178, level: 6 },
  8173: {
    id: 8173,
    name: "Water Sport Board Storage Racks",
    parent: 7178,
    level: 6,
  },
  8174: { id: 8174, name: "Camera Accessory Sets", parent: 143, level: 5 },
  8200: { id: 8200, name: "Costume Gloves", parent: 5192, level: 5 },
  8202: { id: 8202, name: "Vehicle Wraps", parent: 2495, level: 6 },
  8203: {
    id: 8203,
    name: "Ski & Snowboard Goggle Accessories",
    children: [5050],
    parent: 499845,
    level: 6,
  },
  8204: { id: 8204, name: "Eyewear Straps & Chains", parent: 2521, level: 6 },
  8206: {
    id: 8206,
    name: "Hanging Chair Replacement Parts",
    parent: 7248,
    level: 4,
  },
  8208: {
    id: 8208,
    name: "Fishing Reel Replacement Spools",
    parent: 8092,
    level: 6,
  },
  8209: {
    id: 8209,
    name: "Orchestral String Instrument Bow Cases",
    parent: 503033,
    level: 7,
  },
  8210: {
    id: 8210,
    name: "Boxing Autographed Paraphernalia",
    parent: 4333,
    level: 7,
  },
  8213: { id: 8213, name: "Gift Tags & Labels", parent: 94, level: 6 },
  8214: { id: 8214, name: "Body & Facial Hair Bleach", parent: 528, level: 5 },
  8215: { id: 8215, name: "Push Up & Pull Up Bars", parent: 990, level: 4 },
  8216: { id: 8216, name: "Decorative Pom-Poms", parent: 96, level: 5 },
  8217: { id: 8217, name: "Lip Primer", parent: 2645, level: 7 },
  8218: { id: 8218, name: "Face Primer", parent: 2571, level: 7 },
  8219: { id: 8219, name: "Mascara Primer", parent: 2779, level: 7 },
  8220: { id: 8220, name: "Eye Primer", parent: 2779, level: 7 },
  8222: { id: 8222, name: "Altitude Training Masks", parent: 499799, level: 5 },
  8227: {
    id: 8227,
    name: "Motor Vehicle Frame & Body Parts",
    parent: 899,
    level: 5,
  },
  8228: { id: 8228, name: "Motor Vehicle Towing", parent: 899, level: 5 },
  8231: {
    id: 8231,
    name: "Motor Vehicle Power & Electrical Systems",
    parent: 899,
    level: 5,
  },
  8232: {
    id: 8232,
    name: "Motor Vehicle Carpet & Upholstery",
    parent: 899,
    level: 5,
  },
  8233: {
    id: 8233,
    name: "Motor Vehicle Interior Fittings",
    parent: 899,
    level: 5,
  },
  8234: {
    id: 8234,
    name: "Motor Vehicle Sensors & Gauges",
    parent: 899,
    level: 5,
  },
  8235: { id: 8235, name: "Motor Vehicle Controls", parent: 899, level: 5 },
  8236: {
    id: 8236,
    name: "Vehicle Repair & Specialty Tools",
    children: [8260, 8259, 7414, 499929, 499774, 6482, 5068, 3326, 8261, 2647],
    parent: 913,
    level: 5,
  },
  8237: {
    id: 8237,
    name: "Vehicle Storage & Cargo",
    children: [6744, 6454, 3472, 8147, 4027, 5512, 8378, 8475, 2290],
    parent: 5613,
    level: 4,
  },
  8238: { id: 8238, name: "Motor Vehicle Seating", parent: 899, level: 5 },
  8239: { id: 8239, name: "Bicycle Kickstands", parent: 3618, level: 6 },
  8243: { id: 8243, name: "Battery Holders", parent: 7166, level: 6 },
  8248: { id: 8248, name: "Saris & Lehengas", parent: 5388, level: 5 },
  8258: { id: 8258, name: "Tool Safety Tethers", parent: 3650, level: 4 },
  8259: {
    id: 8259,
    name: "Motor Vehicle Clutch Alignment & Removal Tools",
    parent: 8236,
    level: 6,
  },
  8260: {
    id: 8260,
    name: "Motor Vehicle Brake Service Kits",
    parent: 8236,
    level: 6,
  },
  8261: {
    id: 8261,
    name: "Vehicle Tire Repair & Tire Changing Tools",
    parent: 8236,
    level: 6,
  },
  8269: {
    id: 8269,
    name: "Gas Mask & Respirator Accessories",
    parent: 2047,
    level: 4,
  },
  8270: { id: 8270, name: "Roofing Shingles & Tiles", parent: 123, level: 5 },
  8271: {
    id: 8271,
    name: "Reel-to-Reel Tape Players & Recorders",
    parent: 242,
    level: 5,
  },
  8272: { id: 8272, name: "Fishing Rod Bags & Cases", parent: 8093, level: 6 },
  8273: { id: 8273, name: "Fishing Reel Bags & Cases", parent: 8092, level: 6 },
  8274: {
    id: 8274,
    name: "Wire Partitions, Enclosures & Doors",
    parent: 5830,
    level: 4,
  },
  8275: { id: 8275, name: "Drill Stands & Guides", parent: 3944, level: 5 },
  8276: { id: 8276, name: "Drill Chucks", parent: 3944, level: 5 },
  8277: { id: 8277, name: "Electric & Power Showers", parent: 2206, level: 6 },
  8278: { id: 8278, name: "Traffic Cones & Barrels", parent: 114, level: 4 },
  8295: {
    id: 8295,
    name: "Pretend Shopping & Grocery",
    parent: 3229,
    level: 5,
  },
  8298: {
    id: 8298,
    name: "Vehicle License Plate Mounts & Holders",
    parent: 2495,
    level: 6,
  },
  8301: {
    id: 8301,
    name: "Vehicle Safety & Security",
    children: [5547, 362737, 2768, 2879],
    parent: 5613,
    level: 4,
  },
  8302: {
    id: 8302,
    name: "Vehicle Door Locks & Parts",
    children: [8305, 8304, 8303],
    parent: 2768,
    level: 6,
  },
  8303: {
    id: 8303,
    name: "Vehicle Door Locks & Locking Systems",
    parent: 8302,
    level: 7,
  },
  8304: { id: 8304, name: "Vehicle Door Lock Knobs", parent: 8302, level: 7 },
  8305: {
    id: 8305,
    name: "Vehicle Door Lock Actuators",
    parent: 8302,
    level: 7,
  },
  8306: { id: 8306, name: "Golf Cart Enclosures", parent: 3436, level: 6 },
  8308: { id: 8308, name: "Tonneau Covers", parent: 3436, level: 6 },
  8309: {
    id: 8309,
    name: "Vehicle Storage Covers",
    children: [8310, 8314, 8313, 8311, 8312],
    parent: 3436,
    level: 6,
  },
  8310: { id: 8310, name: "Automotive Storage Covers", parent: 8309, level: 7 },
  8311: {
    id: 8311,
    name: "Recreational Vehicle Storage Covers",
    parent: 8309,
    level: 7,
  },
  8312: { id: 8312, name: "Watercraft Storage Covers", parent: 8309, level: 7 },
  8313: { id: 8313, name: "Motorcycle Storage Covers", parent: 8309, level: 7 },
  8314: { id: 8314, name: "Golf Cart Storage Covers", parent: 8309, level: 7 },
  8316: {
    id: 8316,
    name: "Motor Vehicle Windshield Covers",
    parent: 3436,
    level: 6,
  },
  8319: { id: 8319, name: "Sports & Agility Cones", parent: 499799, level: 5 },
  8320: { id: 8320, name: "Bathtub & Shower Jets", parent: 2206, level: 6 },
  8378: {
    id: 8378,
    name: "Truck Bed Storage Boxes & Organizers",
    parent: 8237,
    level: 5,
  },
  8436: {
    id: 8436,
    name: "Toddler Nutrition Drinks & Shakes",
    parent: 562,
    level: 5,
  },
  8445: { id: 8445, name: "Emergency Road Flares", parent: 2879, level: 6 },
  8446: {
    id: 8446,
    name: "Motor Vehicle Roll Cages & Bars",
    parent: 2879,
    level: 6,
  },
  8447: { id: 8447, name: "Car Window Nets", parent: 2879, level: 6 },
  8448: {
    id: 8448,
    name: "Motor Vehicle Airbag Parts",
    parent: 2879,
    level: 6,
  },
  8449: { id: 8449, name: "Vehicle Seat Belts", parent: 2879, level: 6 },
  8450: { id: 8450, name: "Motor Vehicle Body Paint", parent: 3812, level: 6 },
  8451: { id: 8451, name: "Hair Bun & Volume Shapers", parent: 171, level: 5 },
  8452: { id: 8452, name: "Hair Care Kits", parent: 486, level: 5 },
  8461: {
    id: 8461,
    name: "Musical Instrument Amplifier Covers & Cases",
    parent: 8072,
    level: 6,
  },
  8462: {
    id: 8462,
    name: "Musical Instrument Amplifier Knobs",
    parent: 8072,
    level: 6,
  },
  8463: { id: 8463, name: "Vehicle Shift Boots", parent: 2495, level: 6 },
  8464: {
    id: 8464,
    name: "Vehicle Steering Wheel Covers",
    parent: 2495,
    level: 6,
  },
  8469: {
    id: 8469,
    name: "Vehicle Decor Accessory Sets",
    parent: 2495,
    level: 6,
  },
  8470: { id: 8470, name: "Drawer Slides", parent: 2878, level: 4 },
  8471: { id: 8471, name: "Exercise Wedges", parent: 990, level: 4 },
  8472: { id: 8472, name: "Slot Machines", parent: 3793, level: 4 },
  8473: { id: 8473, name: "Marine Speakers", parent: 340, level: 4 },
  8474: { id: 8474, name: "Vehicle Pet Barriers", parent: 2, level: 4 },
  8475: {
    id: 8475,
    name: "Vehicle Headrest Hangers & Hooks",
    parent: 8237,
    level: 5,
  },
  8476: { id: 8476, name: "Vehicle Seat Belt Straps", parent: 2879, level: 6 },
  8477: { id: 8477, name: "Vehicle Seat Belt Buckles", parent: 2879, level: 6 },
  8478: {
    id: 8478,
    name: "Vehicle Rear View Mirror Ornaments",
    parent: 2495,
    level: 6,
  },
  8480: {
    id: 8480,
    name: "Musical Instrument Amplifier Tubes",
    parent: 8072,
    level: 6,
  },
  8483: {
    id: 8483,
    name: "Motor Vehicle Video Monitor Mounts",
    parent: 8526,
    level: 5,
  },
  8485: {
    id: 8485,
    name: "Multifunction Outdoor Power Equipment Attachments",
    children: [7564, 8487, 7334, 8489, 8488, 7335],
    parent: 4564,
    level: 5,
  },
  8487: {
    id: 8487,
    name: "Ground & Leaf Blower Attachments",
    parent: 8485,
    level: 6,
  },
  8488: {
    id: 8488,
    name: "Tiller & Cultivator Attachments",
    parent: 8485,
    level: 6,
  },
  8489: { id: 8489, name: "Pole Saw Attachments", parent: 8485, level: 6 },
  8490: { id: 8490, name: "Dappen Dishes", parent: 7499, level: 5 },
  8493: { id: 8493, name: "Wine Bottle Holders", parent: 649, level: 5 },
  8499: { id: 8499, name: "Lap Desks", parent: 922, level: 3 },
  8505: { id: 8505, name: "Scoreboards", parent: 6739, level: 5 },
  8506: { id: 8506, name: "Vehicle Wheel Chocks", parent: 2879, level: 6 },
  8507: { id: 8507, name: "Motorcycle Hand Guards", parent: 5547, level: 6 },
  8513: { id: 8513, name: "Pet Bowl Stands", parent: 2, level: 4 },
  8514: {
    id: 8514,
    name: "Paintball Gun Drop Forwards",
    parent: 3187,
    level: 8,
  },
  8515: { id: 8515, name: "Yams", parent: 5793, level: 6 },
  8516: { id: 8516, name: "Television Circuit Boards", parent: 7264, level: 5 },
  8522: { id: 8522, name: "Garage Floor Mats", parent: 630, level: 4 },
  8526: {
    id: 8526,
    name: "Motor Vehicle Electronics",
    children: [505766, 891, 5525, 5438, 894, 6968, 5572, 895, 2833, 8483],
    parent: 5613,
    level: 4,
  },
  8528: {
    id: 8528,
    name: "Bicycle Wheel Axles & Skewers",
    parent: 4597,
    level: 7,
  },
  8529: { id: 8529, name: "Fishing Toys", parent: 1266, level: 5 },
  8530: { id: 8530, name: "Massage Stone Warmers", parent: 5663, level: 5 },
  8531: { id: 8531, name: "Shaving Bowls & Mugs", parent: 528, level: 5 },
  8532: { id: 8532, name: "Check Presenters", parent: 135, level: 4 },
  8533: { id: 8533, name: "Sneeze Guards", parent: 135, level: 4 },
  8534: { id: 8534, name: "Portable Fuel Cans", parent: 913, level: 5 },
  8535: {
    id: 8535,
    name: "Underwater Camera Housing Accessories",
    parent: 143,
    level: 5,
  },
  8537: {
    id: 8537,
    name: "Baby Transport Liners & Sacks",
    parent: 4386,
    level: 4,
  },
  8539: {
    id: 8539,
    name: "Thin & Zero Clients",
    children: [543668, 543669],
    parent: 278,
    level: 4,
  },
  8541: {
    id: 8541,
    name: "Electrical Muscle Stimulators",
    parent: 5870,
    level: 5,
  },
  8543: { id: 8543, name: "Denture Repair Kits", parent: 526, level: 5 },
  8544: {
    id: 8544,
    name: "Printer, Copier, & Fax Machine Circuit Boards",
    parent: 7264,
    level: 5,
  },
  8545: {
    id: 8545,
    name: "Household Appliance Circuit Boards",
    parent: 7264,
    level: 5,
  },
  8546: {
    id: 8546,
    name: "Hard Drive Circuit Boards",
    parent: 499898,
    level: 6,
  },
  8549: { id: 8549, name: "Pool & Spa Circuit Boards", parent: 7264, level: 5 },
  43616: { id: 43616, name: "TV Replacement Speakers", parent: 403, level: 6 },
  43617: {
    id: 43617,
    name: "Laptop Replacement Speakers",
    parent: 4224,
    level: 6,
  },
  45262: {
    id: 45262,
    name: "Tablet Computer Replacement Speakers",
    parent: 7349,
    level: 6,
  },
  99338: {
    id: 99338,
    name: "Door Keyhole Escutcheons",
    parent: 6343,
    level: 5,
  },
  230911: {
    id: 230911,
    name: "Bird & Wildlife House Accessories",
    parent: 696,
    level: 4,
  },
  230912: {
    id: 230912,
    name: "Watering Globes & Spikes",
    parent: 3568,
    level: 5,
  },
  230913: {
    id: 230913,
    name: "Medical Instruments",
    children: [6281, 232166, 8026, 499935],
    parent: 2496,
    level: 4,
  },
  232166: { id: 232166, name: "Scalpel Blades", parent: 230913, level: 5 },
  232167: {
    id: 232167,
    name: "Cabinet & Furniture Keyhole Escutcheons",
    parent: 4696,
    level: 5,
  },
  232168: { id: 232168, name: "Craft Knife Blades", parent: 504643, level: 6 },
  233419: {
    id: 233419,
    name: "Nail Polish Drying Drops & Sprays",
    parent: 478,
    level: 6,
  },
  233420: { id: 233420, name: "Eye Pillows", parent: 5663, level: 5 },
  234670: {
    id: 234670,
    name: "Baseball & Softball Pitching Mounds",
    parent: 1070,
    level: 5,
  },
  234671: {
    id: 234671,
    name: "Baseball & Softball Pitching Mats",
    parent: 1070,
    level: 5,
  },
  235920: { id: 235920, name: "Floor & Carpet Dryers", parent: 604, level: 4 },
  235921: { id: 235921, name: "Vehicle Hitch Locks", parent: 2768, level: 6 },
  237166: { id: 237166, name: "Aerobic Steps", parent: 990, level: 4 },
  296246: {
    id: 296246,
    name: "Camera Body Replacement Panels & Doors",
    parent: 143,
    level: 5,
  },
  296247: {
    id: 296247,
    name: "Camera Replacement Buttons & Knobs",
    parent: 143,
    level: 5,
  },
  296248: { id: 296248, name: "Camera Image Sensors", parent: 143, level: 5 },
  296249: {
    id: 296249,
    name: "Camera Replacement Screens & Displays",
    parent: 143,
    level: 5,
  },
  298419: { id: 298419, name: "Camera Circuit Boards", parent: 7264, level: 5 },
  298420: { id: 298420, name: "Camera Digital Backs", parent: 143, level: 5 },
  326120: {
    id: 326120,
    name: "Vehicle Seat Belt Covers",
    parent: 2879,
    level: 6,
  },
  326122: {
    id: 326122,
    name: "Saddle Bags & Panniers",
    parent: 8107,
    level: 7,
  },
  328060: { id: 328060, name: "Prayer Cards", parent: 97, level: 4 },
  328061: { id: 328061, name: "Advice Cards", parent: 96, level: 5 },
  328062: {
    id: 328062,
    name: "Paint Brush Cleaning Solutions",
    parent: 7019,
    level: 5,
  },
  352853: {
    id: 352853,
    name: "Eyewear Lens Cleaning Solutions",
    parent: 2521,
    level: 6,
  },
  355576: {
    id: 355576,
    name: "Inversion Tables & Systems",
    parent: 990,
    level: 4,
  },
  362737: {
    id: 362737,
    name: "Off-Road & All-Terrain Vehicle Protective Gear",
    children: [362738],
    parent: 8301,
    level: 5,
  },
  362738: { id: 362738, name: "ATV & UTV Bar Pads", parent: 362737, level: 6 },
  461567: { id: 461567, name: "Camera Gears", parent: 143, level: 5 },
  461568: { id: 461568, name: "Camera Lens Zoom Units", parent: 143, level: 5 },
  463625: {
    id: 463625,
    name: "Camera & Optic Replacement Cables",
    parent: 2096,
    level: 4,
  },
  465846: { id: 465846, name: "Ironing Centers", parent: 6356, level: 4 },
  499673: {
    id: 499673,
    name: "Smoke & Carbon Monoxide Detectors",
    children: [2164, 1471],
    parent: 1679,
    level: 4,
  },
  499674: {
    id: 499674,
    name: "Floor Protection Films & Runners",
    parent: 630,
    level: 4,
  },
  499675: {
    id: 499675,
    name: "Scanner Circuit Boards",
    parent: 7264,
    level: 5,
  },
  499676: {
    id: 499676,
    name: "Alcoholic Beverages",
    children: [414, 7486, 5725, 5887, 6761, 417, 421],
    parent: 413,
    level: 4,
  },
  499680: { id: 499680, name: "Inner Tents", parent: 5655, level: 6 },
  499681: {
    id: 499681,
    name: "Ski & Snowboard Leashes",
    parent: 499845,
    level: 6,
  },
  499682: { id: 499682, name: "3D Printer Accessories", parent: 345, level: 4 },
  499684: {
    id: 499684,
    name: "Bicycle Cable Housings",
    parent: 3618,
    level: 6,
  },
  499685: { id: 499685, name: "Bicycle Cables", parent: 3618, level: 6 },
  499686: {
    id: 499686,
    name: "Wire & Cable Identification Markers",
    parent: 3328,
    level: 5,
  },
  499687: { id: 499687, name: "Water Sport Helmets", parent: 499813, level: 6 },
  499688: { id: 499688, name: "Guitar Straps", parent: 3502, level: 7 },
  499691: { id: 499691, name: "Luggage Racks & Stands", parent: 110, level: 4 },
  499692: { id: 499692, name: "Oxygen Tanks", parent: 4551, level: 5 },
  499693: { id: 499693, name: "Growth Charts", parent: 696, level: 4 },
  499694: {
    id: 499694,
    name: "Bicycle Tire Repair Supplies & Kits",
    parent: 3214,
    level: 6,
  },
  499696: {
    id: 499696,
    name: "Medical Needles & Syringes",
    children: [543672, 543670, 543671],
    parent: 2907,
    level: 5,
  },
  499697: {
    id: 499697,
    name: "In-Wall Carriers & Mounting Frames",
    parent: 1810,
    level: 5,
  },
  499698: {
    id: 499698,
    name: "Nail Drill Accessories",
    parent: 2975,
    level: 7,
  },
  499699: { id: 499699, name: "Treadmill Accessories", parent: 4598, level: 6 },
  499700: {
    id: 499700,
    name: "Stair Climber & Stepper Accessories",
    parent: 4598,
    level: 6,
  },
  499701: {
    id: 499701,
    name: "Rowing Machine Accessories",
    parent: 4598,
    level: 6,
  },
  499702: {
    id: 499702,
    name: "Exercise Bike Accessories",
    parent: 4598,
    level: 6,
  },
  499703: {
    id: 499703,
    name: "Elliptical Trainer Accessories",
    parent: 4598,
    level: 6,
  },
  499707: { id: 499707, name: "Tapioca Pearls", parent: 2660, level: 5 },
  499708: { id: 499708, name: "Work Safety Harnesses", parent: 2047, level: 4 },
  499709: { id: 499709, name: "Horse Leads", parent: 5593, level: 6 },
  499710: { id: 499710, name: "Horse Halters", parent: 5593, level: 6 },
  499711: { id: 499711, name: "Tarot Cards", parent: 97, level: 4 },
  499712: { id: 499712, name: "Toy Gift Baskets", parent: 1253, level: 4 },
  499713: {
    id: 499713,
    name: "Athletics",
    children: [
      1093, 1070, 1081, 499719, 6734, 3354, 6739, 1087, 989, 1006, 499741,
      499915, 499799, 1000, 503752, 7156, 1110, 1111, 1047, 1065, 1060, 1115,
      499861, 1145, 1068,
    ],
    parent: 988,
    level: 3,
  },
  499715: {
    id: 499715,
    name: "Baseball & Softball Chest Protectors",
    parent: 1078,
    level: 6,
  },
  499716: {
    id: 499716,
    name: "Catchers Equipment Sets",
    parent: 1078,
    level: 6,
  },
  499717: {
    id: 499717,
    name: "Catchers Helmets & Masks",
    parent: 1078,
    level: 6,
  },
  499718: {
    id: 499718,
    name: "Baseball & Softball Leg Guards",
    parent: 1078,
    level: 6,
  },
  499719: {
    id: 499719,
    name: "Boxing & Martial Arts",
    children: [4008, 499720, 3411, 3652, 3717, 4282],
    parent: 499713,
    level: 4,
  },
  499720: {
    id: 499720,
    name: "Boxing & Martial Arts Training Equipment",
    children: [499769, 7116, 7129, 3297, 499721],
    parent: 499719,
    level: 5,
  },
  499721: { id: 499721, name: "Strike Shields", parent: 499720, level: 6 },
  499722: {
    id: 499722,
    name: "Boxing & Martial Arts Headgear",
    parent: 4008,
    level: 6,
  },
  499723: {
    id: 499723,
    name: "Boxing & Martial Arts Body Protectors",
    parent: 4008,
    level: 6,
  },
  499724: { id: 499724, name: "MMA Shin Guards", parent: 4008, level: 6 },
  499725: {
    id: 499725,
    name: "Boxing & Martial Arts Arm Guards",
    parent: 4008,
    level: 6,
  },
  499726: {
    id: 499726,
    name: "Boxing & MMA Hand Wraps",
    parent: 4008,
    level: 6,
  },
  499727: { id: 499727, name: "Referee Wallets", parent: 6739, level: 5 },
  499729: { id: 499729, name: "Captains Armbands", parent: 6739, level: 5 },
  499730: { id: 499730, name: "Umpire Indicators", parent: 6739, level: 5 },
  499731: { id: 499731, name: "Pitch Counters", parent: 6739, level: 5 },
  499732: { id: 499732, name: "Flip Coins & Discs", parent: 6739, level: 5 },
  499733: {
    id: 499733,
    name: "Referee Stands & Chairs",
    parent: 6739,
    level: 5,
  },
  499734: { id: 499734, name: "Cricket Stumps", parent: 1087, level: 5 },
  499735: {
    id: 499735,
    name: "Cricket Equipment Sets",
    parent: 1087,
    level: 5,
  },
  499736: {
    id: 499736,
    name: "Cricket Protective Gear",
    children: [3339, 3543, 499739],
    parent: 1087,
    level: 5,
  },
  499737: {
    id: 499737,
    name: "Cricket Bat Accessories",
    children: [499738],
    parent: 1087,
    level: 5,
  },
  499738: { id: 499738, name: "Cricket Bat Grips", parent: 499737, level: 6 },
  499739: { id: 499739, name: "Cricket Leg Guards", parent: 499736, level: 6 },
  499740: {
    id: 499740,
    name: "Fencing Jackets & Lamés",
    parent: 3261,
    level: 6,
  },
  499741: {
    id: 499741,
    name: "Field Hockey & Lacrosse",
    children: [499744, 1089, 3001, 1092, 3536, 499742, 3970, 3336, 3817, 3204],
    parent: 499713,
    level: 4,
  },
  499742: {
    id: 499742,
    name: "Lacrosse Equipment Sets",
    parent: 499741,
    level: 5,
  },
  499743: { id: 499743, name: "Pet Heating Pads", parent: 2, level: 4 },
  499744: {
    id: 499744,
    name: "Field Hockey & Lacrosse Protective Gear",
    children: [499745, 499746, 499747, 502970],
    parent: 499741,
    level: 5,
  },
  499745: {
    id: 499745,
    name: "Field Hockey & Lacrosse Gloves",
    parent: 499744,
    level: 6,
  },
  499746: {
    id: 499746,
    name: "Field Hockey & Lacrosse Helmets",
    parent: 499744,
    level: 6,
  },
  499747: {
    id: 499747,
    name: "Field Hockey & Lacrosse Masks & Goggles",
    parent: 499744,
    level: 6,
  },
  499751: {
    id: 499751,
    name: "Basketball Training Aids",
    parent: 1081,
    level: 5,
  },
  499755: {
    id: 499755,
    name: "Hockey Shin Guards & Leg Pads",
    parent: 1105,
    level: 6,
  },
  499756: { id: 499756, name: "Hockey Elbow Pads", parent: 1105, level: 6 },
  499757: {
    id: 499757,
    name: "Hockey Shoulder Pads & Chest Protectors",
    parent: 1105,
    level: 6,
  },
  499759: { id: 499759, name: "Inline Skate Parts", parent: 499761, level: 5 },
  499760: { id: 499760, name: "Roller Skate Parts", parent: 499761, level: 5 },
  499761: {
    id: 499761,
    name: "Inline & Roller Skating",
    children: [499771, 499759, 1058, 499760, 2837, 500029],
    parent: 1011,
    level: 4,
  },
  499766: {
    id: 499766,
    name: "Vehicle Fuel Injection Cleaning Kits",
    parent: 2895,
    level: 6,
  },
  499767: {
    id: 499767,
    name: "Scrub Brush Heads & Refills",
    parent: 623,
    level: 5,
  },
  499768: {
    id: 499768,
    name: "Conduit & Housings",
    children: [499770, 3797],
    parent: 127,
    level: 4,
  },
  499769: {
    id: 499769,
    name: "Boxing & MMA Punch Mitts",
    parent: 499720,
    level: 6,
  },
  499770: { id: 499770, name: "Electrical Conduit", parent: 499768, level: 5 },
  499771: {
    id: 499771,
    name: "Inline & Roller Skating Protective Gear",
    children: [499775],
    parent: 499761,
    level: 5,
  },
  499772: {
    id: 499772,
    name: "Window Hardware",
    children: [499773, 503728],
    parent: 115,
    level: 4,
  },
  499773: { id: 499773, name: "Window Cranks", parent: 499772, level: 5 },
  499774: { id: 499774, name: "Vehicle Body Filler", parent: 8236, level: 6 },
  499775: { id: 499775, name: "Roller Skating Pads", parent: 499771, level: 6 },
  499776: { id: 499776, name: "Skate Helmets", parent: 3067, level: 6 },
  499778: {
    id: 499778,
    name: "American Football Rib Protection Shirts & Vests",
    parent: 1097,
    level: 6,
  },
  499779: {
    id: 499779,
    name: "American Football Dummies & Sleds",
    parent: 3998,
    level: 6,
  },
  499780: { id: 499780, name: "Golf Club Shafts", parent: 3642, level: 6 },
  499781: { id: 499781, name: "Gymnastics Grips", parent: 3808, level: 6 },
  499782: {
    id: 499782,
    name: "Rugby Protective Gear",
    children: [3077],
    parent: 1110,
    level: 5,
  },
  499783: {
    id: 499783,
    name: "Racquetball & Squash Gloves",
    parent: 503752,
    level: 5,
  },
  499784: {
    id: 499784,
    name: "Soccer Protective Gear",
    children: [1114],
    parent: 1111,
    level: 5,
  },
  499785: { id: 499785, name: "Handballs", parent: 1047, level: 5 },
  499786: { id: 499786, name: "Track Starting Blocks", parent: 1060, level: 5 },
  499787: {
    id: 499787,
    name: "Volleyball Training Aids",
    parent: 1115,
    level: 5,
  },
  499788: {
    id: 499788,
    name: "Volleyball Protective Gear",
    children: [499789],
    parent: 1115,
    level: 5,
  },
  499789: {
    id: 499789,
    name: "Volleyball Knee Pads",
    parent: 499788,
    level: 6,
  },
  499790: { id: 499790, name: "Wrestling Knee Pads", parent: 3057, level: 6 },
  499791: { id: 499791, name: "Wrestling Headgear", parent: 3057, level: 6 },
  499792: {
    id: 499792,
    name: "Cardio",
    children: [4598, 4589, 2614],
    parent: 990,
    level: 4,
  },
  499793: {
    id: 499793,
    name: "Weight Lifting",
    children: [6452, 3164, 3654, 3858, 3217, 3542],
    parent: 990,
    level: 4,
  },
  499794: { id: 499794, name: "Weight Bar Collars", parent: 6452, level: 6 },
  499795: { id: 499795, name: "Exercise Benches", parent: 990, level: 4 },
  499796: { id: 499796, name: "Balance Trainers", parent: 990, level: 4 },
  499797: { id: 499797, name: "Ab Wheels & Rollers", parent: 990, level: 4 },
  499798: { id: 499798, name: "Suspension Trainers", parent: 990, level: 4 },
  499799: {
    id: 499799,
    name: "General Purpose Athletic Equipment",
    children: [
      8222, 499800, 7397, 7433, 7434, 499903, 3971, 499803, 8077, 499802, 8319,
      3877, 499801, 6344,
    ],
    parent: 499713,
    level: 4,
  },
  499800: { id: 499800, name: "Athletic Cups", parent: 499799, level: 5 },
  499801: { id: 499801, name: "Sports Mouthguards", parent: 499799, level: 5 },
  499802: {
    id: 499802,
    name: "Speed & Agility Ladders & Hurdles",
    parent: 499799,
    level: 5,
  },
  499803: { id: 499803, name: "Gym Mats", parent: 499799, level: 5 },
  499804: { id: 499804, name: "Holiday Ornament Hooks", parent: 596, level: 5 },
  499805: {
    id: 499805,
    name: "Easter Egg Decorating Kits",
    parent: 596,
    level: 5,
  },
  499810: { id: 499810, name: "MP3 Player Batteries", parent: 276, level: 6 },
  499811: {
    id: 499811,
    name: "Boating & Water Sports",
    children: [1120, 499813, 1135, 5579, 1143, 1144, 3195, 7178, 1148],
    parent: 1011,
    level: 4,
  },
  499813: {
    id: 499813,
    name: "Boating & Water Sport Apparel",
    children: [1138, 6496, 1128, 3376, 499687, 499814, 1147],
    parent: 499811,
    level: 5,
  },
  499814: {
    id: 499814,
    name: "Wetsuit Pieces",
    children: [5400, 5399, 5401],
    parent: 499813,
    level: 6,
  },
  499815: {
    id: 499815,
    name: "Climbing Apparel & Accessories",
    children: [499816, 3314, 5394],
    parent: 7059,
    level: 5,
  },
  499816: { id: 499816, name: "Climbing Gloves", parent: 499815, level: 6 },
  499817: { id: 499817, name: "Horse Fly Masks", parent: 3257, level: 6 },
  499818: {
    id: 499818,
    name: "Horse Grooming Combs, Brushes & Mitts",
    parent: 5025,
    level: 7,
  },
  499819: { id: 499819, name: "Horse Wormers", parent: 3257, level: 6 },
  499820: { id: 499820, name: "Horse Tack Boxes", parent: 7215, level: 6 },
  499821: {
    id: 499821,
    name: "Camera & Optics Manuals",
    parent: 5037,
    level: 4,
  },
  499822: {
    id: 499822,
    name: "Refillable Makeup Palettes & Cases",
    parent: 2548,
    level: 7,
  },
  499823: {
    id: 499823,
    name: "Fishing Tackle",
    children: [3603, 3859, 3359, 3651, 7222],
    parent: 3334,
    level: 5,
  },
  499824: {
    id: 499824,
    name: "Hunting & Shooting",
    children: [1033, 3125, 3136, 7460, 499834, 499840],
    parent: 1011,
    level: 4,
  },
  499825: {
    id: 499825,
    name: "Bows & Crossbows",
    children: [3332, 3505, 3715],
    parent: 1033,
    level: 6,
  },
  499826: {
    id: 499826,
    name: "Bow & Crossbow Accessories",
    parent: 1033,
    level: 6,
  },
  499830: {
    id: 499830,
    name: "Broadheads & Field Points",
    parent: 3291,
    level: 7,
  },
  499831: { id: 499831, name: "Arrow Fletchings", parent: 3291, level: 7 },
  499832: { id: 499832, name: "Arrow Nocks", parent: 3291, level: 7 },
  499833: {
    id: 499833,
    name: "Archery Gloves & Releases",
    parent: 1033,
    level: 6,
  },
  499834: {
    id: 499834,
    name: "Paintball & Airsoft",
    children: [2443, 1049, 499835],
    parent: 499824,
    level: 5,
  },
  499835: {
    id: 499835,
    name: "Paintball & Airsoft Protective Gear",
    children: [499836, 499838, 499839, 499837],
    parent: 499834,
    level: 6,
  },
  499836: {
    id: 499836,
    name: "Paintball & Airsoft Gloves",
    parent: 499835,
    level: 7,
  },
  499837: {
    id: 499837,
    name: "Paintball & Airsoft Vests",
    parent: 499835,
    level: 7,
  },
  499838: {
    id: 499838,
    name: "Paintball & Airsoft Goggles & Masks",
    parent: 499835,
    level: 7,
  },
  499839: {
    id: 499839,
    name: "Paintball & Airsoft Pads",
    parent: 499835,
    level: 7,
  },
  499840: {
    id: 499840,
    name: "Shooting & Range Accessories",
    children: [499842, 499841, 3170],
    parent: 499824,
    level: 5,
  },
  499841: {
    id: 499841,
    name: "Shooting Sticks & Bipods",
    parent: 499840,
    level: 6,
  },
  499842: { id: 499842, name: "Shooting Rests", parent: 499840, level: 6 },
  499844: {
    id: 499844,
    name: "Winter Sports & Activities",
    children: [499951, 499845, 7539, 1166],
    parent: 1011,
    level: 4,
  },
  499845: {
    id: 499845,
    name: "Skiing & Snowboarding",
    children: [
      7224, 8203, 3550, 1161, 499681, 7558, 505772, 8074, 505296, 6063, 6062,
      1157, 6064, 5088, 1162, 1163, 1164,
    ],
    parent: 499844,
    level: 5,
  },
  499846: {
    id: 499846,
    name: "Outdoor Games",
    children: [1062, 3787, 3484, 3405, 7430, 3390, 499904, 3126],
    parent: 1011,
    level: 4,
  },
  499847: { id: 499847, name: "Pickleballs", parent: 3390, level: 6 },
  499848: { id: 499848, name: "Pickleball Paddles", parent: 3390, level: 6 },
  499849: {
    id: 499849,
    name: "Platform Tennis Balls",
    parent: 499904,
    level: 6,
  },
  499850: {
    id: 499850,
    name: "Platform & Paddle Tennis Paddles",
    parent: 499904,
    level: 6,
  },
  499853: { id: 499853, name: "Gun Slings", parent: 2214, level: 5 },
  499854: {
    id: 499854,
    name: "Gun Cleaning Solvents",
    parent: 503021,
    level: 6,
  },
  499855: {
    id: 499855,
    name: "Gun Cleaning Cloths & Swabs",
    parent: 503021,
    level: 6,
  },
  499856: {
    id: 499856,
    name: "Gun Cleaning Patches",
    parent: 503021,
    level: 6,
  },
  499857: {
    id: 499857,
    name: "Ammunition Reloading Presses",
    parent: 503026,
    level: 6,
  },
  499858: {
    id: 499858,
    name: "Medical Stretchers & Gurneys",
    parent: 3477,
    level: 5,
  },
  499859: {
    id: 499859,
    name: "Grinder Accessories",
    children: [499860],
    parent: 3650,
    level: 4,
  },
  499860: {
    id: 499860,
    name: "Grinding Wheels & Points",
    parent: 499859,
    level: 5,
  },
  499861: { id: 499861, name: "Wallyball Equipment", parent: 499713, level: 4 },
  499864: { id: 499864, name: "Calculator Accessories", parent: 950, level: 4 },
  499865: {
    id: 499865,
    name: "Security Safe Accessories",
    parent: 359,
    level: 4,
  },
  499866: { id: 499866, name: "Office Supply Manuals", parent: 5037, level: 4 },
  499867: {
    id: 499867,
    name: "Diving & Snorkeling Equipment Sets",
    parent: 1135,
    level: 6,
  },
  499868: { id: 499868, name: "Bicycle Groupsets", parent: 3618, level: 6 },
  499869: {
    id: 499869,
    name: "Bicycle Tire Valve Caps",
    parent: 3618,
    level: 6,
  },
  499870: { id: 499870, name: "Bicycle Tire Valves", parent: 3618, level: 6 },
  499871: {
    id: 499871,
    name: "Bicycle Tire Valve Adapters",
    parent: 3618,
    level: 6,
  },
  499872: { id: 499872, name: "Lawn Mower Tire Tubes", parent: 4566, level: 6 },
  499873: {
    id: 499873,
    name: "Heating, Ventilation & Air Conditioning",
    children: [500090, 499874, 1519, 2766],
    parent: 632,
    level: 3,
  },
  499874: { id: 499874, name: "Air Ducts", parent: 499873, level: 4 },
  499875: { id: 499875, name: "Bicycle Rim Strips", parent: 4597, level: 7 },
  499876: { id: 499876, name: "Grout", parent: 503743, level: 5 },
  499877: {
    id: 499877,
    name: "Avalanche Safety Airbags",
    parent: 499951,
    level: 6,
  },
  499878: {
    id: 499878,
    name: "Mobile Phone & Tablet Tripods & Monopods",
    parent: 2082,
    level: 4,
  },
  499879: {
    id: 499879,
    name: "Ceramic & Pottery Glazes",
    parent: 505378,
    level: 7,
  },
  499880: { id: 499880, name: "Tractor Tires", parent: 5867, level: 6 },
  499881: { id: 499881, name: "Tractor Wheels", parent: 5867, level: 6 },
  499882: { id: 499882, name: "Tetherball Poles", parent: 3126, level: 6 },
  499883: { id: 499883, name: "Tetherball Sets", parent: 3126, level: 6 },
  499884: { id: 499884, name: "Tetherballs", parent: 3126, level: 6 },
  499885: { id: 499885, name: "Stair Treads", parent: 630, level: 4 },
  499886: { id: 499886, name: "Mixing Tool Paddles", parent: 3650, level: 4 },
  499887: { id: 499887, name: "Handheld Power Mixers", parent: 1167, level: 4 },
  499888: { id: 499888, name: "Paint Shakers", parent: 2077, level: 5 },
  499889: {
    id: 499889,
    name: "Exercise Machine Circuit Boards",
    parent: 7264,
    level: 5,
  },
  499890: {
    id: 499890,
    name: "Hockey Goalie Equipment Sets",
    parent: 1105,
    level: 6,
  },
  499891: {
    id: 499891,
    name: "Homebrewing & Winemaking Kits",
    parent: 3577,
    level: 5,
  },
  499892: { id: 499892, name: "Broom Heads", parent: 623, level: 5 },
  499893: { id: 499893, name: "Carbon Brushes", parent: 127, level: 4 },
  499894: {
    id: 499894,
    name: "Plant Cages & Supports",
    parent: 2962,
    level: 5,
  },
  499897: {
    id: 499897,
    name: "Retail Display Props & Models",
    parent: 138,
    level: 4,
  },
  499898: {
    id: 499898,
    name: "Computer Circuit Boards",
    children: [499899, 8546, 289],
    parent: 7264,
    level: 5,
  },
  499899: {
    id: 499899,
    name: "Computer Inverter Boards",
    parent: 499898,
    level: 6,
  },
  499900: { id: 499900, name: "Dog Diapers", parent: 5, level: 5 },
  499903: {
    id: 499903,
    name: "Exercise & Gym Mat Storage Racks & Carts",
    parent: 499799,
    level: 5,
  },
  499904: {
    id: 499904,
    name: "Platform & Paddle Tennis",
    children: [499850, 499849],
    parent: 499846,
    level: 5,
  },
  499905: { id: 499905, name: "Vegetable Mixes", parent: 5793, level: 6 },
  499906: { id: 499906, name: "Fruit Mixes", parent: 5795, level: 6 },
  499907: { id: 499907, name: "Awnings", parent: 2918, level: 5 },
  499908: { id: 499908, name: "Awning Accessories", parent: 2918, level: 5 },
  499912: { id: 499912, name: "Power Towers", parent: 990, level: 4 },
  499913: { id: 499913, name: "Adult Hygienic Wipes", parent: 474, level: 6 },
  499915: {
    id: 499915,
    name: "Figure Skating & Hockey",
    children: [6077, 6074, 1105, 6857, 7012, 7011, 6076, 3791, 1057],
    parent: 499713,
    level: 4,
  },
  499916: {
    id: 499916,
    name: "SIM Card Ejection Tools",
    parent: 264,
    level: 6,
  },
  499917: { id: 499917, name: "Pet Wipes", parent: 6383, level: 5 },
  499918: { id: 499918, name: "Stamp Blocks", parent: 504643, level: 6 },
  499919: {
    id: 499919,
    name: "Jewelry Cleaning Solutions & Polishes",
    parent: 5573,
    level: 4,
  },
  499921: { id: 499921, name: "Lawn Striping Kits", parent: 4566, level: 6 },
  499922: { id: 499922, name: "Lawn Rollers", parent: 3173, level: 6 },
  499923: { id: 499923, name: "Lawn Mower Mulch Kits", parent: 4566, level: 6 },
  499924: {
    id: 499924,
    name: "Food & Beverage Labels",
    parent: 6478,
    level: 5,
  },
  499926: {
    id: 499926,
    name: "Lotion & Sunscreen Applicators",
    parent: 2958,
    level: 7,
  },
  499927: { id: 499927, name: "Welding Helmets", parent: 2047, level: 4 },
  499928: {
    id: 499928,
    name: "General Purpose Battery Testers",
    parent: 7166,
    level: 6,
  },
  499929: {
    id: 499929,
    name: "Vehicle Battery Testers",
    parent: 8236,
    level: 6,
  },
  499930: {
    id: 499930,
    name: "Ironing Board Hooks & Racks",
    parent: 2446,
    level: 6,
  },
  499931: {
    id: 499931,
    name: "Ironing Board Replacement Parts",
    parent: 627,
    level: 5,
  },
  499932: {
    id: 499932,
    name: "Specialty Electrical Switches & Relays",
    parent: 6459,
    level: 5,
  },
  499933: { id: 499933, name: "Springs", parent: 2878, level: 4 },
  499934: { id: 499934, name: "Allergy Test Kits", parent: 505293, level: 5 },
  499935: {
    id: 499935,
    name: "Surgical Needles & Sutures",
    parent: 230913,
    level: 5,
  },
  499937: { id: 499937, name: "Iron Rests", parent: 627, level: 5 },
  499938: {
    id: 499938,
    name: "Astronomy Toys & Models",
    parent: 1262,
    level: 5,
  },
  499942: {
    id: 499942,
    name: "Fishing Rod Holders & Storage Racks",
    parent: 8093,
    level: 6,
  },
  499944: {
    id: 499944,
    name: "DVI Splitters & Switches",
    parent: 8156,
    level: 5,
  },
  499945: {
    id: 499945,
    name: "VGA Splitters & Switches",
    parent: 8156,
    level: 5,
  },
  499946: { id: 499946, name: "Livestock Halters", parent: 6991, level: 5 },
  499947: { id: 499947, name: "Welding Accessories", parent: 3650, level: 4 },
  499948: {
    id: 499948,
    name: "Outdoor Umbrella Enclosure Kits",
    parent: 6751,
    level: 6,
  },
  499949: {
    id: 499949,
    name: "Handrails & Railing Systems",
    parent: 115,
    level: 4,
  },
  499950: {
    id: 499950,
    name: "Gesture Control Input Devices",
    parent: 1928,
    level: 6,
  },
  499951: {
    id: 499951,
    name: "Avalanche Safety",
    children: [499952, 499877],
    parent: 499844,
    level: 5,
  },
  499952: { id: 499952, name: "Avalanche Probes", parent: 499951, level: 6 },
  499953: { id: 499953, name: "Collectible Guns", parent: 220, level: 6 },
  499954: { id: 499954, name: "Bird Cage Bird Baths", parent: 7385, level: 6 },
  499955: {
    id: 499955,
    name: "Porch Swing Accessories",
    parent: 2918,
    level: 5,
  },
  499956: {
    id: 499956,
    name: "Tablet Computer Docks & Stands",
    parent: 279,
    level: 5,
  },
  499958: {
    id: 499958,
    name: "Safety & Crowd Control Barriers",
    parent: 128,
    level: 4,
  },
  499959: { id: 499959, name: "Saddle Covers & Cases", parent: 8107, level: 7 },
  499960: {
    id: 499960,
    name: "Lawn Mower Mulch Plugs & Plates",
    parent: 4566,
    level: 6,
  },
  499961: { id: 499961, name: "Safety Knee Pads", parent: 2047, level: 4 },
  499962: { id: 499962, name: "Pot & Planter Liners", parent: 2962, level: 5 },
  499963: { id: 499963, name: "Smoking Pipes", parent: 435, level: 4 },
  499964: { id: 499964, name: "Paddle Leashes", parent: 1120, level: 6 },
  499965: { id: 499965, name: "Playground Balls", parent: 1266, level: 5 },
  499966: { id: 499966, name: "Power Inlets", parent: 127, level: 4 },
  499969: { id: 499969, name: "Event Tickets", parent: 8, level: 3 },
  499970: { id: 499970, name: "Door Push Plates", parent: 6343, level: 5 },
  499971: {
    id: 499971,
    name: "Sewing Baskets & Kits",
    parent: 505369,
    level: 6,
  },
  499972: { id: 499972, name: "Sashes", parent: 167, level: 4 },
  499975: {
    id: 499975,
    name: "Hockey Suspenders & Belts",
    parent: 1105,
    level: 6,
  },
  499976: { id: 499976, name: "Borescopes", parent: 142, level: 4 },
  499978: {
    id: 499978,
    name: "Exercise Machine & Equipment Sets",
    parent: 990,
    level: 4,
  },
  499979: {
    id: 499979,
    name: "Dance Dresses, Skirts & Costumes",
    parent: 5322,
    level: 5,
  },
  499981: { id: 499981, name: "Casters", parent: 2878, level: 4 },
  499982: { id: 499982, name: "Small Engines", parent: 632, level: 3 },
  499985: { id: 499985, name: "Panel Saws", parent: 1235, level: 5 },
  499986: { id: 499986, name: "Lemon & Lime Juice", parent: 2660, level: 5 },
  499988: {
    id: 499988,
    name: "Prepared Meals & Entrées",
    parent: 5814,
    level: 5,
  },
  499989: {
    id: 499989,
    name: "Prepared Appetizers & Side Dishes",
    parent: 5814,
    level: 5,
  },
  499990: { id: 499990, name: "Bottle Caps", parent: 649, level: 5 },
  499991: {
    id: 499991,
    name: "Ice Cream & Frozen Yogurt",
    parent: 5788,
    level: 5,
  },
  499992: {
    id: 499992,
    name: "Hair Styling Tool Sets",
    parent: 6019,
    level: 6,
  },
  499993: { id: 499993, name: "Billiard Cue Cases", parent: 3222, level: 6 },
  499994: { id: 499994, name: "Billiard Cue Chalk", parent: 3222, level: 6 },
  499995: {
    id: 499995,
    name: "Carpentry & Woodworking Project Plans",
    parent: 783,
    level: 3,
  },
  499996: {
    id: 499996,
    name: "Waffle Iron Accessories",
    parent: 2901,
    level: 5,
  },
  499997: { id: 499997, name: "Egg Incubators", parent: 6991, level: 5 },
  499998: {
    id: 499998,
    name: "Camera Stabilizers & Supports",
    parent: 143,
    level: 5,
  },
  499999: { id: 499999, name: "Bathroom Suites", parent: 1673, level: 5 },
  500000: {
    id: 500000,
    name: "Bathroom Furniture Sets",
    parent: 6345,
    level: 4,
  },
  500001: {
    id: 500001,
    name: "Music Lyres & Flip Folders",
    parent: 55,
    level: 5,
  },
  500002: { id: 500002, name: "Smart Glasses", parent: 278, level: 4 },
  500003: {
    id: 500003,
    name: "Floating & Submersible Lights",
    parent: 594,
    level: 4,
  },
  500004: {
    id: 500004,
    name: "Cooktop, Oven & Range Accessories",
    parent: 2901,
    level: 5,
  },
  500005: {
    id: 500005,
    name: "Toy Weapon & Gadget Accessories",
    parent: 1253,
    level: 4,
  },
  500007: { id: 500007, name: "Cigarette Holders", parent: 600, level: 4 },
  500008: {
    id: 500008,
    name: "Costume Tobacco Products",
    parent: 5192,
    level: 5,
  },
  500009: { id: 500009, name: "Activity Monitors", parent: 494, level: 5 },
  500013: {
    id: 500013,
    name: "Tablet Computer Screens & Screen Digitizers",
    parent: 7349,
    level: 6,
  },
  500015: {
    id: 500015,
    name: "Educational Flash Cards",
    parent: 1262,
    level: 5,
  },
  500016: { id: 500016, name: "Power Sweepers", parent: 3798, level: 5 },
  500024: { id: 500024, name: "Ear Picks & Spoons", parent: 506, level: 5 },
  500025: {
    id: 500025,
    name: "Safety & Security Mirrors",
    parent: 359,
    level: 4,
  },
  500026: {
    id: 500026,
    name: "Pet Carrier & Crate Accessories",
    parent: 2,
    level: 4,
  },
  500027: {
    id: 500027,
    name: "Circuit Board Accessories",
    parent: 3702,
    level: 4,
  },
  500028: {
    id: 500028,
    name: "Bicycle Helmet Parts & Accessories",
    parent: 3982,
    level: 6,
  },
  500029: { id: 500029, name: "Roller Skis", parent: 499761, level: 5 },
  500030: {
    id: 500030,
    name: "Moving & Soundproofing Blankets & Covers",
    parent: 2878,
    level: 4,
  },
  500033: {
    id: 500033,
    name: "Garden Pot Saucers & Trays",
    parent: 2962,
    level: 5,
  },
  500034: {
    id: 500034,
    name: "Lawn Aerators & Dethatchers",
    parent: 3798,
    level: 5,
  },
  500035: {
    id: 500035,
    name: "Storage & Data Transfer Cables",
    parent: 259,
    level: 5,
  },
  500036: { id: 500036, name: "Cable Tie Guns", parent: 3328, level: 5 },
  500037: { id: 500037, name: "Camera Grips", parent: 143, level: 5 },
  500038: {
    id: 500038,
    name: "Aquarium Cleaning Supplies",
    parent: 6,
    level: 5,
  },
  500039: { id: 500039, name: "Dumpsters", parent: 4516, level: 5 },
  500040: {
    id: 500040,
    name: "Computer Accessory Sets",
    parent: 279,
    level: 5,
  },
  500042: {
    id: 500042,
    name: "Pool & Spa Chlorine Generators",
    parent: 2832,
    level: 5,
  },
  500043: { id: 500043, name: "Humidistats", parent: 1519, level: 5 },
  500044: {
    id: 500044,
    name: "Posters, Prints, & Visual Artwork",
    parent: 9,
    level: 5,
  },
  500045: { id: 500045, name: "Decorative Tapestries", parent: 9, level: 5 },
  500046: { id: 500046, name: "Digital Artwork", parent: 5032, level: 4 },
  500048: {
    id: 500048,
    name: "Gun Cases & Range Bags",
    parent: 2214,
    level: 5,
  },
  500049: {
    id: 500049,
    name: "Armatures, Rotors & Stators",
    parent: 127,
    level: 4,
  },
  500050: {
    id: 500050,
    name: "Pool Cover Accessories",
    parent: 2832,
    level: 5,
  },
  500051: { id: 500051, name: "Floor Chairs", parent: 443, level: 4 },
  500052: {
    id: 500052,
    name: "Mice & Trackball Accessories",
    parent: 6475,
    level: 6,
  },
  500053: { id: 500053, name: "Bicycle Hub Parts", parent: 4597, level: 7 },
  500054: {
    id: 500054,
    name: "Hardware Fasteners",
    children: [1508, 2408, 1739, 7062, 2230, 2251, 500055, 2195],
    parent: 2878,
    level: 4,
  },
  500055: { id: 500055, name: "Threaded Rods", parent: 500054, level: 5 },
  500056: { id: 500056, name: "Zipper Pulls", parent: 505380, level: 7 },
  500057: {
    id: 500057,
    name: "Freeze-Drying Machines",
    parent: 4335,
    level: 5,
  },
  500058: { id: 500058, name: "Anemometers", parent: 1305, level: 5 },
  500059: {
    id: 500059,
    name: "Cat Furniture Accessories",
    parent: 4,
    level: 5,
  },
  500060: { id: 500060, name: "Back Scratchers", parent: 5663, level: 5 },
  500061: { id: 500061, name: "Office Furniture Sets", parent: 6362, level: 4 },
  500062: {
    id: 500062,
    name: "Aquarium Temperature Controllers",
    parent: 6,
    level: 5,
  },
  500063: {
    id: 500063,
    name: "Water Heater Expansion Tanks",
    parent: 2751,
    level: 5,
  },
  500064: { id: 500064, name: "Sectional Sofa Units", parent: 7212, level: 4 },
  500065: {
    id: 500065,
    name: "Descalers & Decalcifiers",
    parent: 4973,
    level: 6,
  },
  500066: {
    id: 500066,
    name: "Electric Kettle Accessories",
    parent: 2901,
    level: 5,
  },
  500067: {
    id: 500067,
    name: "Bicycle Child Seat Accessories",
    parent: 3214,
    level: 6,
  },
  500074: {
    id: 500074,
    name: "Marinades & Grilling Sauces",
    parent: 427,
    level: 5,
  },
  500075: { id: 500075, name: "Relish & Chutney", parent: 427, level: 5 },
  500076: {
    id: 500076,
    name: "Pickled Fruits & Vegetables",
    parent: 427,
    level: 5,
  },
  500077: { id: 500077, name: "Vehicle Wheel Clamps", parent: 2768, level: 6 },
  500078: {
    id: 500078,
    name: "Bird & Wildlife Houses",
    children: [500079, 699, 500080],
    parent: 696,
    level: 4,
  },
  500079: { id: 500079, name: "Bat Houses", parent: 500078, level: 5 },
  500080: { id: 500080, name: "Butterfly Houses", parent: 500078, level: 5 },
  500081: { id: 500081, name: "Ultrasonic Cleaners", parent: 604, level: 4 },
  500082: {
    id: 500082,
    name: "Jewelry Cleaning Tools",
    parent: 5573,
    level: 4,
  },
  500083: {
    id: 500083,
    name: "Jewelry Steam Cleaners",
    parent: 5573,
    level: 4,
  },
  500084: { id: 500084, name: "Pet Bed Accessories", parent: 2, level: 4 },
  500085: {
    id: 500085,
    name: "Washer & Dryer Accessories",
    parent: 3456,
    level: 5,
  },
  500086: {
    id: 500086,
    name: "Janitorial Carts & Caddies",
    parent: 111,
    level: 3,
  },
  500087: { id: 500087, name: "Light Therapy Lamps", parent: 491, level: 4 },
  500088: { id: 500088, name: "Lighter Fluid", parent: 2277, level: 5 },
  500089: { id: 500089, name: "Sweet and Sour Sauces", parent: 427, level: 5 },
  500090: { id: 500090, name: "Air & Filter Dryers", parent: 499873, level: 4 },
  500091: { id: 500091, name: "Speed Radars", parent: 222, level: 3 },
  500092: {
    id: 500092,
    name: "Bicycle Handlebar Grips & Decor",
    parent: 3214,
    level: 6,
  },
  500093: { id: 500093, name: "Canned & Dry Milk", parent: 2660, level: 5 },
  500094: { id: 500094, name: "Art Fixatives", parent: 505378, level: 7 },
  500095: { id: 500095, name: "Water Parks & Slides", parent: 6464, level: 5 },
  500096: {
    id: 500096,
    name: "Hardware Pumps",
    children: [500099, 500098, 500097, 500102, 500101, 500100],
    parent: 632,
    level: 3,
  },
  500097: {
    id: 500097,
    name: "Sprinkler, Booster & Irrigation System Pumps",
    parent: 500096,
    level: 4,
  },
  500098: {
    id: 500098,
    name: "Pool, Fountain & Pond Pumps",
    parent: 500096,
    level: 4,
  },
  500099: {
    id: 500099,
    name: "Home Appliance Pumps",
    parent: 500096,
    level: 4,
  },
  500100: {
    id: 500100,
    name: "Well Pumps & Systems",
    parent: 500096,
    level: 4,
  },
  500101: { id: 500101, name: "Utility Pumps", parent: 500096, level: 4 },
  500102: {
    id: 500102,
    name: "Sump, Sewage & Effluent Pumps",
    parent: 500096,
    level: 4,
  },
  500103: {
    id: 500103,
    name: "Tool Organizer Liners & Inserts",
    parent: 3974,
    level: 5,
  },
  500104: { id: 500104, name: "Camera Focus Devices", parent: 143, level: 5 },
  500105: { id: 500105, name: "White & Cream Sauces", parent: 427, level: 5 },
  500106: {
    id: 500106,
    name: "Printers, Copiers & Fax Machines",
    parent: 345,
    level: 4,
  },
  500107: { id: 500107, name: "On-Camera Monitors", parent: 143, level: 5 },
  500109: {
    id: 500109,
    name: "Electric Bicycle Conversion Kits",
    parent: 3214,
    level: 6,
  },
  500110: {
    id: 500110,
    name: "Pet Heating Pad Accessories",
    parent: 2,
    level: 4,
  },
  500111: {
    id: 500111,
    name: "Outdoor Sectional Sofa Units",
    parent: 6368,
    level: 5,
  },
  500112: {
    id: 500112,
    name: "Speaker Bags, Covers & Cases",
    parent: 7163,
    level: 6,
  },
  500113: {
    id: 500113,
    name: "Fingerboards & Fingerboard Sets",
    parent: 1266,
    level: 5,
  },
  500114: { id: 500114, name: "Laboratory Freezers", parent: 4335, level: 5 },
  500115: {
    id: 500115,
    name: "Waste Container Labels & Signs",
    parent: 6757,
    level: 5,
  },
  500117: {
    id: 500117,
    name: "Video Game Console & Controller Batteries",
    parent: 276,
    level: 6,
  },
  500118: {
    id: 500118,
    name: "Costume Special Effects",
    parent: 5192,
    level: 5,
  },
  500119: { id: 500119, name: "Tactile Transducers", parent: 7163, level: 6 },
  500120: {
    id: 500120,
    name: "Speaker Components & Kits",
    parent: 7163,
    level: 6,
  },
  500121: {
    id: 500121,
    name: "Home Fragrance Accessories",
    children: [6336, 2784, 500122, 4741],
    parent: 696,
    level: 4,
  },
  500122: { id: 500122, name: "Candle Snuffers", parent: 500121, level: 5 },
  502966: {
    id: 502966,
    name: "Popcorn & French Fry Scoops",
    parent: 3175,
    level: 6,
  },
  502969: {
    id: 502969,
    name: "Transfer Boards & Sheets",
    parent: 520,
    level: 6,
  },
  502970: {
    id: 502970,
    name: "Field Hockey & Lacrosse Pads",
    parent: 499744,
    level: 6,
  },
  502972: { id: 502972, name: "Party Supply Kits", parent: 96, level: 5 },
  502973: { id: 502973, name: "Fence Panels", parent: 128, level: 4 },
  502974: { id: 502974, name: "Dry Boxes", parent: 5181, level: 3 },
  502975: {
    id: 502975,
    name: "Fuel Containers & Tanks",
    parent: 632,
    level: 3,
  },
  502976: {
    id: 502976,
    name: "Shochu & Soju",
    children: [543642, 543643],
    parent: 417,
    level: 6,
  },
  502977: {
    id: 502977,
    name: "Chain, Wire & Rope",
    children: [6298, 1492, 4469, 3053, 6297, 5119, 6904],
    parent: 2878,
    level: 4,
  },
  502978: { id: 502978, name: "Gear Ties", parent: 503773, level: 5 },
  502979: { id: 502979, name: "Jewelry Making Kits", parent: 505370, level: 6 },
  502980: { id: 502980, name: "Bottling Bottles", parent: 3577, level: 5 },
  502981: {
    id: 502981,
    name: "Party Streamers & Curtains",
    parent: 96,
    level: 5,
  },
  502982: {
    id: 502982,
    name: "Pet Waste Bag Dispensers & Holders",
    parent: 2,
    level: 4,
  },
  502983: {
    id: 502983,
    name: "Fence & Gate Accessories",
    parent: 128,
    level: 4,
  },
  502984: { id: 502984, name: "Lattice", parent: 128, level: 4 },
  502986: {
    id: 502986,
    name: "Garden Borders & Edging",
    parent: 128,
    level: 4,
  },
  502987: {
    id: 502987,
    name: "Maternity Belts & Support Bands",
    parent: 167,
    level: 4,
  },
  502988: {
    id: 502988,
    name: "Hair Pins, Claws & Clips",
    children: [543646, 543645, 543644],
    parent: 171,
    level: 5,
  },
  502989: {
    id: 502989,
    name: "Soda Maker Accessories",
    parent: 2901,
    level: 5,
  },
  502990: {
    id: 502990,
    name: "Printer, Copier & Fax Machine Accessories",
    children: [5258, 5265, 1683, 5459, 502991],
    parent: 345,
    level: 4,
  },
  502991: {
    id: 502991,
    name: "Printer, Copier & Fax Machine Replacement Parts",
    parent: 502990,
    level: 5,
  },
  502992: { id: 502992, name: "Utility Buckles", parent: 503773, level: 5 },
  502993: {
    id: 502993,
    name: "Portable Toilets & Showers",
    children: [502994, 503009],
    parent: 1013,
    level: 5,
  },
  502994: {
    id: 502994,
    name: "Portable Showers & Privacy Enclosures",
    parent: 502993,
    level: 6,
  },
  502995: { id: 502995, name: "Touch Table Computers", parent: 278, level: 4 },
  502996: {
    id: 502996,
    name: "False Eyelash Accessories",
    children: [7256, 7493, 502997],
    parent: 2548,
    level: 7,
  },
  502997: {
    id: 502997,
    name: "False Eyelash Remover",
    parent: 502996,
    level: 8,
  },
  502999: {
    id: 502999,
    name: "Changing Mat & Tray Covers",
    parent: 548,
    level: 4,
  },
  503000: { id: 503000, name: "Hourglasses", parent: 696, level: 4 },
  503001: { id: 503001, name: "Audio Converters", parent: 2182, level: 5 },
  503002: {
    id: 503002,
    name: "Tablet Computer Housings & Trim",
    parent: 7349,
    level: 6,
  },
  503003: { id: 503003, name: "Keyboard Keys & Caps", parent: 6475, level: 6 },
  503004: {
    id: 503004,
    name: "Headphone Cushions & Tips",
    parent: 505797,
    level: 6,
  },
  503005: { id: 503005, name: "Funnels", parent: 668, level: 5 },
  503006: { id: 503006, name: "Gait Belts", parent: 3477, level: 5 },
  503007: {
    id: 503007,
    name: "Measuring Scale Accessories",
    parent: 5526,
    level: 5,
  },
  503008: {
    id: 503008,
    name: "Audio & Video Receiver Accessories",
    parent: 1420,
    level: 5,
  },
  503009: {
    id: 503009,
    name: "Portable Toilets & Urination Devices",
    parent: 502993,
    level: 6,
  },
  503010: {
    id: 503010,
    name: "Flag & Windsock Pole Mounting Hardware & Kits",
    parent: 7419,
    level: 5,
  },
  503011: {
    id: 503011,
    name: "Pallets & Loading Platforms",
    parent: 6987,
    level: 4,
  },
  503014: {
    id: 503014,
    name: "Dry Box Liners & Inserts",
    parent: 110,
    level: 4,
  },
  503016: { id: 503016, name: "Tripod Spreaders", parent: 4638, level: 5 },
  503017: {
    id: 503017,
    name: "Studio Stand & Mount Accessories",
    parent: 42,
    level: 5,
  },
  503018: {
    id: 503018,
    name: "Studio Light & Flash Accessories",
    parent: 42,
    level: 5,
  },
  503019: {
    id: 503019,
    name: "Camera Sun Hoods & Viewfinder Attachments",
    parent: 143,
    level: 5,
  },
  503020: {
    id: 503020,
    name: "Camera Silencers & Sound Blimps",
    parent: 143,
    level: 5,
  },
  503021: {
    id: 503021,
    name: "Gun Cleaning",
    children: [499855, 499856, 499854],
    parent: 2214,
    level: 5,
  },
  503026: {
    id: 503026,
    name: "Reloading Supplies & Equipment",
    children: [499857],
    parent: 2214,
    level: 5,
  },
  503028: {
    id: 503028,
    name: "Special Effects Light Stands",
    parent: 408,
    level: 5,
  },
  503031: {
    id: 503031,
    name: "Off-Road and All-Terrain Vehicles",
    children: [3018, 2528],
    parent: 1267,
    level: 5,
  },
  503032: {
    id: 503032,
    name: "Guitar Fittings & Parts",
    parent: 3502,
    level: 7,
  },
  503033: {
    id: 503033,
    name: "Orchestral String Instrument Accessories",
    children: [8209, 503040, 503039, 503038, 503037, 503036, 503035, 503034],
    parent: 61,
    level: 6,
  },
  503034: {
    id: 503034,
    name: "Orchestral String Instrument Strings",
    parent: 503033,
    level: 7,
  },
  503035: {
    id: 503035,
    name: "Orchestral String Instrument Stands",
    parent: 503033,
    level: 7,
  },
  503036: {
    id: 503036,
    name: "Orchestral String Instrument Pickups",
    parent: 503033,
    level: 7,
  },
  503037: {
    id: 503037,
    name: "Orchestral String Instrument Mutes",
    parent: 503033,
    level: 7,
  },
  503038: {
    id: 503038,
    name: "Orchestral String Instrument Fittings & Parts",
    parent: 503033,
    level: 7,
  },
  503039: {
    id: 503039,
    name: "Orchestral String Instrument Cases",
    parent: 503033,
    level: 7,
  },
  503040: {
    id: 503040,
    name: "Orchestral String Instrument Bows",
    parent: 503033,
    level: 7,
  },
  503348: {
    id: 503348,
    name: "Sewing Machine Replacement Parts",
    parent: 504643,
    level: 6,
  },
  503721: { id: 503721, name: "Guitar String Winders", parent: 3502, level: 7 },
  503722: { id: 503722, name: "Laboratory Funnels", parent: 4335, level: 5 },
  503723: { id: 503723, name: "Aisle Runners", parent: 5455, level: 4 },
  503724: {
    id: 503724,
    name: "Protective Masks",
    children: [7407, 2349, 2473, 513],
    parent: 2047,
    level: 4,
  },
  503725: { id: 503725, name: "Cooking Gel Fuels", parent: 6944, level: 6 },
  503726: { id: 503726, name: "Tripod Handles", parent: 4638, level: 5 },
  503727: { id: 503727, name: "Door Frames", parent: 6343, level: 5 },
  503728: { id: 503728, name: "Window Frames", parent: 499772, level: 5 },
  503729: {
    id: 503729,
    name: "Wire Terminals & Connectors",
    parent: 127,
    level: 4,
  },
  503730: { id: 503730, name: "Locks & Latches", parent: 1974, level: 4 },
  503731: { id: 503731, name: "Metal Casting Molds", parent: 2878, level: 4 },
  503733: {
    id: 503733,
    name: "Pet Fragrances & Deodorizing Sprays",
    parent: 6383,
    level: 5,
  },
  503734: { id: 503734, name: "Sugar & Sweeteners", parent: 2660, level: 5 },
  503735: { id: 503735, name: "Photo Mounting Supplies", parent: 39, level: 4 },
  503736: {
    id: 503736,
    name: "Coffee Maker & Espresso Machine Replacement Parts",
    parent: 3988,
    level: 6,
  },
  503737: {
    id: 503737,
    name: "Electrical Testing Tools",
    parent: 1305,
    level: 5,
  },
  503738: {
    id: 503738,
    name: "Varnishes & Finishes",
    parent: 503740,
    level: 5,
  },
  503739: {
    id: 503739,
    name: "Building Consumables",
    children: [
      2277, 503742, 2212, 1753, 503743, 503740, 505305, 503744, 1995, 503741,
      505802,
    ],
    parent: 632,
    level: 3,
  },
  503740: {
    id: 503740,
    name: "Painting Consumables",
    children: [1361, 2474, 2058, 1648, 503738],
    parent: 503739,
    level: 4,
  },
  503741: {
    id: 503741,
    name: "Solvents, Strippers & Thinners",
    parent: 503739,
    level: 4,
  },
  503742: {
    id: 503742,
    name: "Hardware Glue & Adhesives",
    parent: 503739,
    level: 4,
  },
  503743: {
    id: 503743,
    name: "Masonry Consumables",
    children: [3031, 2282, 499876],
    parent: 503739,
    level: 4,
  },
  503744: {
    id: 503744,
    name: "Protective Coatings & Sealants",
    parent: 503739,
    level: 4,
  },
  503745: { id: 503745, name: "Craft & Office Glue", parent: 505376, level: 7 },
  503746: { id: 503746, name: "Tape Dispensers", parent: 2986, level: 4 },
  503747: {
    id: 503747,
    name: "Recorder Accessories",
    children: [503749, 503748, 503750],
    parent: 62,
    level: 6,
  },
  503748: { id: 503748, name: "Recorder Cases", parent: 503747, level: 7 },
  503749: {
    id: 503749,
    name: "Recorder Care & Cleaning",
    parent: 503747,
    level: 7,
  },
  503750: { id: 503750, name: "Recorder Parts", parent: 503747, level: 7 },
  503751: { id: 503751, name: "Pool Liners", parent: 2832, level: 5 },
  503752: {
    id: 503752,
    name: "Racquetball & Squash",
    children: [503753, 3119, 499783, 3714, 4002],
    parent: 499713,
    level: 4,
  },
  503753: {
    id: 503753,
    name: "Racquetball & Squash Balls",
    parent: 503752,
    level: 5,
  },
  503755: { id: 503755, name: "Riser Cards", parent: 287, level: 6 },
  503756: {
    id: 503756,
    name: "Gardening Scooters, Seats & Kneelers",
    parent: 5632,
    level: 6,
  },
  503757: { id: 503757, name: "Muddlers", parent: 651, level: 6 },
  503758: {
    id: 503758,
    name: "Scrapbooking & Stamping Kits",
    parent: 505370,
    level: 6,
  },
  503759: {
    id: 503759,
    name: "Citrus Fruits",
    children: [6621, 6632, 6636, 6641, 6642, 6658, 6697],
    parent: 5795,
    level: 6,
  },
  503760: {
    id: 503760,
    name: "Stone Fruits",
    children: [6567, 6601, 6646, 505301, 6675, 6674],
    parent: 5795,
    level: 6,
  },
  503761: { id: 503761, name: "Sprouts", parent: 5793, level: 6 },
  503762: { id: 503762, name: "Nursing Pads & Shields", parent: 561, level: 4 },
  503763: {
    id: 503763,
    name: "Gymnastics Bars & Balance Beams",
    parent: 1000,
    level: 5,
  },
  503764: {
    id: 503764,
    name: "Chain Connectors & Links",
    parent: 503773,
    level: 5,
  },
  503765: {
    id: 503765,
    name: "Office Furniture Accessories",
    children: [503766, 7559, 6909],
    parent: 436,
    level: 3,
  },
  503766: {
    id: 503766,
    name: "Desk Parts & Accessories",
    parent: 503765,
    level: 4,
  },
  503767: { id: 503767, name: "Personnel Lifts", parent: 131, level: 5 },
  503768: {
    id: 503768,
    name: "Hoists, Cranes & Trolleys",
    parent: 131,
    level: 5,
  },
  503769: {
    id: 503769,
    name: "Pulleys, Blocks & Sheaves",
    parent: 131,
    level: 5,
  },
  503770: {
    id: 503770,
    name: "Lifting Hooks, Clamps & Shackles",
    parent: 503773,
    level: 5,
  },
  503771: { id: 503771, name: "Jacks & Lift Trucks", parent: 131, level: 5 },
  503772: { id: 503772, name: "Winches", parent: 131, level: 5 },
  503773: {
    id: 503773,
    name: "Hooks, Buckles & Fasteners",
    children: [503764, 502978, 503770, 502992],
    parent: 2878,
    level: 4,
  },
  503774: { id: 503774, name: "Lighters & Matches", parent: 1167, level: 4 },
  503775: { id: 503775, name: "Siding", parent: 115, level: 4 },
  503776: { id: 503776, name: "Drywall", parent: 115, level: 4 },
  503777: { id: 503777, name: "Rebar & Remesh", parent: 115, level: 4 },
  504419: {
    id: 504419,
    name: "Wreath & Floral Frames",
    parent: 505381,
    level: 7,
  },
  504633: { id: 504633, name: "Rice Keepers", parent: 5103, level: 6 },
  504634: {
    id: 504634,
    name: "Bidet Faucets & Sprayers",
    parent: 2691,
    level: 6,
  },
  504635: {
    id: 504635,
    name: "Plumbing Fixture Hardware & Parts",
    children: [2996, 504637, 504636, 1489, 1458, 2206, 1963, 2691],
    parent: 133,
    level: 4,
  },
  504636: { id: 504636, name: "Drains", parent: 504635, level: 5 },
  504637: {
    id: 504637,
    name: "Drain Components",
    children: [2851, 1514, 2257, 1932, 1407, 1319, 2170],
    parent: 504635,
    level: 5,
  },
  504638: {
    id: 504638,
    name: "Shower Arms & Connectors",
    parent: 2206,
    level: 6,
  },
  504639: {
    id: 504639,
    name: "Art & Crafting Tools",
    children: [
      6152, 6151, 505391, 504640, 505386, 505392, 5137, 6150, 6133, 6158, 4073,
      5921, 505393, 6101, 6159, 505388, 6156, 505387,
    ],
    parent: 16,
    level: 5,
  },
  504640: {
    id: 504640,
    name: "Craft Cutting & Embossing Tools",
    children: [504641, 504642, 5136, 6119, 7340, 6122, 6161, 6447],
    parent: 504639,
    level: 6,
  },
  504641: {
    id: 504641,
    name: "Craft & Office Scissors",
    parent: 504640,
    level: 7,
  },
  504642: {
    id: 504642,
    name: "Craft Cutters & Embossers",
    parent: 504640,
    level: 7,
  },
  504643: {
    id: 504643,
    name: "Art & Crafting Tool Accessories",
    children: [232168, 4580, 505286, 5120, 503348, 6136, 499918],
    parent: 16,
    level: 5,
  },
  505284: { id: 505284, name: "Racquet Sport Toys", parent: 1266, level: 5 },
  505285: {
    id: 505285,
    name: "Plant & Herb Growing Kits",
    parent: 985,
    level: 4,
  },
  505286: {
    id: 505286,
    name: "Sewing Machine Extension Tables",
    parent: 504643,
    level: 6,
  },
  505287: { id: 505287, name: "Quilts & Comforters", parent: 569, level: 5 },
  505288: { id: 505288, name: "Conductor Batons", parent: 55, level: 5 },
  505291: { id: 505291, name: "Wakeboard Parts", parent: 3282, level: 7 },
  505292: {
    id: 505292,
    name: "Gardening Sickles & Machetes",
    parent: 3173,
    level: 6,
  },
  505293: {
    id: 505293,
    name: "Medical Tests",
    children: [499934, 7337, 2552, 7336, 1680, 505294],
    parent: 491,
    level: 4,
  },
  505294: {
    id: 505294,
    name: "Urinary Tract Infection Tests",
    parent: 505293,
    level: 5,
  },
  505295: {
    id: 505295,
    name: "Power Adapters & Chargers",
    parent: 275,
    level: 5,
  },
  505296: { id: 505296, name: "Ski Binding Parts", parent: 499845, level: 6 },
  505297: {
    id: 505297,
    name: "Pet Waste Disposal Systems & Tools",
    parent: 2,
    level: 4,
  },
  505298: {
    id: 505298,
    name: "Studio Recording Bundles",
    parent: 2165,
    level: 5,
  },
  505299: {
    id: 505299,
    name: "Computer Interface Cards & Adapters",
    parent: 287,
    level: 6,
  },
  505300: {
    id: 505300,
    name: "Weather Stripping & Weatherization Supplies",
    parent: 115,
    level: 4,
  },
  505301: {
    id: 505301,
    name: "Peaches & Nectarines",
    parent: 503760,
    level: 7,
  },
  505302: {
    id: 505302,
    name: "Sport Safety Lights & Reflectors",
    parent: 990,
    level: 4,
  },
  505303: { id: 505303, name: "Aquarium & Pond Tubing", parent: 6, level: 5 },
  505304: {
    id: 505304,
    name: "Pet Training Pad Holders",
    parent: 505314,
    level: 5,
  },
  505305: { id: 505305, name: "Plumbing Primer", parent: 503739, level: 4 },
  505306: { id: 505306, name: "Aquarium Fish Nets", parent: 6, level: 5 },
  505307: {
    id: 505307,
    name: "Aquarium Air Stones & Diffusers",
    parent: 6,
    level: 5,
  },
  505308: {
    id: 505308,
    name: "Brass Instrument Mouthpieces",
    parent: 57,
    level: 6,
  },
  505309: {
    id: 505309,
    name: "Brass Instrument Straps & Stands",
    parent: 57,
    level: 6,
  },
  505310: {
    id: 505310,
    name: "Brass Instrument Cases & Gigbags",
    parent: 57,
    level: 6,
  },
  505311: {
    id: 505311,
    name: "Pet Training Sprays & Solutions",
    parent: 505314,
    level: 5,
  },
  505312: { id: 505312, name: "Oil Lamp Fuel", parent: 2956, level: 4 },
  505313: {
    id: 505313,
    name: "Pet Training Clickers & Treat Dispensers",
    parent: 505314,
    level: 5,
  },
  505314: {
    id: 505314,
    name: "Pet Training Aids",
    children: [505313, 505304, 6846, 505311],
    parent: 2,
    level: 4,
  },
  505315: { id: 505315, name: "Punches & Awls", parent: 1167, level: 4 },
  505316: { id: 505316, name: "Food & Drink Stencils", parent: 668, level: 5 },
  505317: {
    id: 505317,
    name: "Kiteboard & Wakeboard Bindings",
    parent: 3282,
    level: 7,
  },
  505318: {
    id: 505318,
    name: "Voltage Transformers & Regulators",
    parent: 127,
    level: 4,
  },
  505319: {
    id: 505319,
    name: "Electrical Freeze Sprays",
    parent: 2277,
    level: 5,
  },
  505320: { id: 505320, name: "Wall Jacks & Braces", parent: 2878, level: 4 },
  505321: {
    id: 505321,
    name: "Gardening Tool Heads",
    parent: 505326,
    level: 6,
  },
  505322: {
    id: 505322,
    name: "Gardening Tool Handles",
    parent: 505326,
    level: 6,
  },
  505323: {
    id: 505323,
    name: "Mattock & Pickaxe Accessories",
    children: [505324],
    parent: 3650,
    level: 4,
  },
  505324: {
    id: 505324,
    name: "Mattock & Pickaxe Handles",
    parent: 505323,
    level: 5,
  },
  505325: { id: 505325, name: "Paint Strainers", parent: 2077, level: 5 },
  505326: {
    id: 505326,
    name: "Gardening Tool Accessories",
    children: [505322, 505321, 4972],
    parent: 2962,
    level: 5,
  },
  505327: { id: 505327, name: "Cocktail Strainers", parent: 651, level: 6 },
  505328: { id: 505328, name: "Music Benches & Stools", parent: 55, level: 5 },
  505329: { id: 505329, name: "Turnips & Rutabagas", parent: 5793, level: 6 },
  505330: { id: 505330, name: "Air Hockey Equipment", parent: 1002, level: 5 },
  505352: { id: 505352, name: "Therapeutic Swings", parent: 5870, level: 5 },
  505354: { id: 505354, name: "Squashes & Gourds", parent: 5793, level: 6 },
  505364: { id: 505364, name: "Powered Hammers", parent: 1186, level: 5 },
  505365: { id: 505365, name: "Metronomes", parent: 55, level: 5 },
  505366: {
    id: 505366,
    name: "Breast Pump Accessories",
    parent: 561,
    level: 4,
  },
  505367: {
    id: 505367,
    name: "Orthodontic Appliance Cases",
    parent: 526,
    level: 5,
  },
  505368: { id: 505368, name: "Bathtub Bases & Feet", parent: 2996, level: 6 },
  505369: {
    id: 505369,
    name: "Craft Organization",
    children: [505394, 499971, 505395],
    parent: 16,
    level: 5,
  },
  505370: {
    id: 505370,
    name: "Art & Craft Kits",
    children: [505374, 4778, 6382, 6989, 502979, 6829, 7096, 503758, 4986],
    parent: 16,
    level: 5,
  },
  505371: {
    id: 505371,
    name: "Crafting Patterns & Molds",
    children: [6999, 8007, 6135, 505373, 3697],
    parent: 16,
    level: 5,
  },
  505372: {
    id: 505372,
    name: "Art & Crafting Materials",
    children: [
      24, 505380, 505378, 505381, 505376, 505382, 505377, 505379, 6121, 6142,
      505383, 44, 505375, 505384, 7403, 7402,
    ],
    parent: 16,
    level: 5,
  },
  505373: {
    id: 505373,
    name: "Needlecraft Patterns",
    parent: 505371,
    level: 6,
  },
  505374: { id: 505374, name: "Candle Making Kits", parent: 505370, level: 6 },
  505375: { id: 505375, name: "Raw Candle Wax", parent: 505372, level: 6 },
  505376: {
    id: 505376,
    name: "Crafting Adhesives & Magnets",
    children: [503745, 36, 505419, 7192, 6418],
    parent: 505372,
    level: 6,
  },
  505377: {
    id: 505377,
    name: "Crafting Wire",
    children: [5062, 505418, 6102],
    parent: 505372,
    level: 6,
  },
  505378: {
    id: 505378,
    name: "Craft Paint, Ink & Glaze",
    children: [505417, 500094, 505416, 499879, 505415, 505414, 6558],
    parent: 505372,
    level: 6,
  },
  505379: {
    id: 505379,
    name: "Embellishments & Trims",
    children: [
      6955, 32, 505413, 4054, 6146, 505411, 5996, 198, 5982, 505412, 505410,
      1927,
    ],
    parent: 505372,
    level: 6,
  },
  505380: {
    id: 505380,
    name: "Craft Fasteners & Closures",
    children: [4226, 505408, 505409, 6145, 500056, 4174],
    parent: 505372,
    level: 6,
  },
  505381: {
    id: 505381,
    name: "Craft Shapes & Bases",
    children: [6117, 505404, 505403, 504419],
    parent: 505372,
    level: 6,
  },
  505382: {
    id: 505382,
    name: "Crafting Fibers",
    children: [6540, 49, 6140, 2669],
    parent: 505372,
    level: 6,
  },
  505383: { id: 505383, name: "Leather & Vinyl", parent: 505372, level: 6 },
  505384: {
    id: 505384,
    name: "Textiles",
    children: [505397, 47, 7076, 505396],
    parent: 505372,
    level: 6,
  },
  505386: {
    id: 505386,
    name: "Craft Decoration Makers",
    parent: 504639,
    level: 6,
  },
  505387: {
    id: 505387,
    name: "Thread & Yarn Tools",
    children: [6164, 6138, 6163, 6155, 6154, 6153, 6167, 6166],
    parent: 504639,
    level: 6,
  },
  505388: {
    id: 505388,
    name: "Textile Craft Machines",
    children: [6134, 505422, 505421, 615, 6137],
    parent: 504639,
    level: 6,
  },
  505391: {
    id: 505391,
    name: "Color Mixing Tools",
    children: [1653, 1719],
    parent: 504639,
    level: 6,
  },
  505392: {
    id: 505392,
    name: "Craft Measuring & Marking Tools",
    children: [18, 6126, 4032, 3083, 6125, 5883, 2671, 6160, 6157, 505420],
    parent: 504639,
    level: 6,
  },
  505393: {
    id: 505393,
    name: "Needles & Hooks",
    children: [6127, 5992, 6139, 6168, 4579],
    parent: 504639,
    level: 6,
  },
  505394: {
    id: 505394,
    name: "Needle, Pin & Hook Organizers",
    parent: 505369,
    level: 6,
  },
  505395: {
    id: 505395,
    name: "Thread & Yarn Organizers",
    parent: 505369,
    level: 6,
  },
  505396: { id: 505396, name: "Printable Fabric", parent: 505384, level: 7 },
  505397: {
    id: 505397,
    name: "Crafting Canvas",
    children: [505398, 19, 6144],
    parent: 505384,
    level: 7,
  },
  505398: { id: 505398, name: "Needlecraft Canvas", parent: 505397, level: 8 },
  505399: {
    id: 505399,
    name: "Cardstock & Scrapbooking Paper",
    children: [543510, 543511],
    parent: 24,
    level: 7,
  },
  505400: {
    id: 505400,
    name: "Drawing & Painting Paper",
    parent: 24,
    level: 7,
  },
  505401: { id: 505401, name: "Papier Mache Mixes", parent: 44, level: 7 },
  505402: { id: 505402, name: "Pottery Slips", parent: 44, level: 7 },
  505403: { id: 505403, name: "Papier Mache Shapes", parent: 505381, level: 7 },
  505404: { id: 505404, name: "Craft Wood & Shapes", parent: 505381, level: 7 },
  505405: { id: 505405, name: "Pillow Forms", parent: 6142, level: 7 },
  505406: { id: 505406, name: "Filling Pellets", parent: 6142, level: 7 },
  505407: { id: 505407, name: "Batting & Stuffing", parent: 6142, level: 7 },
  505408: { id: 505408, name: "Clasps & Hooks", parent: 505380, level: 7 },
  505409: { id: 505409, name: "Eyelets & Grommets", parent: 505380, level: 7 },
  505410: { id: 505410, name: "Sequins & Glitter", parent: 505379, level: 7 },
  505411: { id: 505411, name: "Feathers", parent: 505379, level: 7 },
  505412: { id: 505412, name: "Ribbons & Trim", parent: 505379, level: 7 },
  505413: { id: 505413, name: "Bows & Yo-Yos", parent: 505379, level: 7 },
  505414: { id: 505414, name: "Ink Pads", parent: 505378, level: 7 },
  505415: { id: 505415, name: "Craft Dyes", parent: 505378, level: 7 },
  505416: { id: 505416, name: "Art Ink", parent: 505378, level: 7 },
  505417: { id: 505417, name: "Art & Craft Paint", parent: 505378, level: 7 },
  505418: { id: 505418, name: "Floral Wire", parent: 505377, level: 7 },
  505419: { id: 505419, name: "Decorative Tape", parent: 505376, level: 7 },
  505420: { id: 505420, name: "Wood Burning Tools", parent: 505392, level: 7 },
  505421: { id: 505421, name: "Mechanical Looms", parent: 505388, level: 7 },
  505422: { id: 505422, name: "Hand Looms", parent: 505388, level: 7 },
  505666: { id: 505666, name: "Food Mixers & Blenders", parent: 730, level: 5 },
  505667: {
    id: 505667,
    name: "Outdoor Grill Replacement Parts",
    parent: 3684,
    level: 6,
  },
  505668: { id: 505668, name: "Bicycle Tools", parent: 3214, level: 6 },
  505669: { id: 505669, name: "Steam Inhalers", parent: 4551, level: 5 },
  505670: {
    id: 505670,
    name: "Hairdressing Capes & Neck Covers",
    parent: 7240,
    level: 4,
  },
  505761: { id: 505761, name: "Absinthe", parent: 417, level: 6 },
  505762: {
    id: 505762,
    name: "Ammunition Cases & Holders",
    parent: 2214,
    level: 5,
  },
  505763: { id: 505763, name: "Chair Sashes", parent: 96, level: 5 },
  505764: { id: 505764, name: "Beds & Bed Frames", parent: 6433, level: 4 },
  505765: {
    id: 505765,
    name: "Food Mixer & Blender Accessories",
    parent: 2901,
    level: 5,
  },
  505766: {
    id: 505766,
    name: "Motor Vehicle A/V Players & In-Dash Systems",
    parent: 8526,
    level: 5,
  },
  505767: {
    id: 505767,
    name: "Hard Drive Enclosures & Mounts",
    parent: 1623,
    level: 7,
  },
  505768: { id: 505768, name: "Brass Instrument Mutes", parent: 57, level: 6 },
  505769: { id: 505769, name: "Alto & Baritone Horns", parent: 63, level: 6 },
  505770: { id: 505770, name: "Trumpets & Cornets", parent: 63, level: 6 },
  505771: {
    id: 505771,
    name: "Headphones & Headsets",
    children: [543626, 543627],
    parent: 2165,
    level: 5,
  },
  505772: {
    id: 505772,
    name: "Ski & Snowboard Tuning Tools",
    parent: 499845,
    level: 6,
  },
  505797: {
    id: 505797,
    name: "Headphone & Headset Accessories",
    children: [503004],
    parent: 1420,
    level: 5,
  },
  505801: {
    id: 505801,
    name: "Electronic Card Readers",
    parent: 1928,
    level: 6,
  },
  505802: {
    id: 505802,
    name: "Wall Patching Compounds & Plaster",
    parent: 503739,
    level: 4,
  },
  505803: { id: 505803, name: "Bed Canopies", parent: 569, level: 5 },
  505804: { id: 505804, name: "Plaster Gauze", parent: 44, level: 7 },
  505805: { id: 505805, name: "Mounting Putty", parent: 932, level: 4 },
  505806: {
    id: 505806,
    name: "Beverage Tubs & Chillers",
    parent: 649,
    level: 5,
  },
  505808: {
    id: 505808,
    name: "Credit Card Terminals",
    parent: 505825,
    level: 6,
  },
  505809: {
    id: 505809,
    name: "Seasonal Village Sets & Accessories",
    parent: 596,
    level: 5,
  },
  505810: { id: 505810, name: "Tool Handle Wedges", parent: 3650, level: 4 },
  505811: { id: 505811, name: "Pet Door Accessories", parent: 2, level: 4 },
  505812: { id: 505812, name: "Wedge Tools", parent: 3650, level: 4 },
  505813: {
    id: 505813,
    name: "Field & Court Boundary Markers",
    parent: 6739,
    level: 5,
  },
  505814: {
    id: 505814,
    name: "Watering Can Accesssories",
    parent: 3568,
    level: 5,
  },
  505815: {
    id: 505815,
    name: "Pool & Spa Maintenance Kits",
    parent: 2832,
    level: 5,
  },
  505817: {
    id: 505817,
    name: "Skateboard Small Parts",
    parent: 3670,
    level: 6,
  },
  505818: { id: 505818, name: "Play Dough & Putty", parent: 3731, level: 5 },
  505819: {
    id: 505819,
    name: "Activity Monitor Accessories",
    parent: 5071,
    level: 5,
  },
  505820: {
    id: 505820,
    name: "Conductivity Gels & Lotions",
    parent: 491,
    level: 4,
  },
  505821: {
    id: 505821,
    name: "Livestock Feed",
    children: [543545, 543544, 543547, 543548, 543546],
    parent: 6991,
    level: 5,
  },
  505822: {
    id: 505822,
    name: "Fertility Monitors and Ovulation Tests",
    children: [543679, 543680],
    parent: 494,
    level: 5,
  },
  505824: {
    id: 505824,
    name: "Cash Registers & POS Terminals",
    children: [543647, 543648],
    parent: 4181,
    level: 5,
  },
  505825: {
    id: 505825,
    name: "Cash Register & POS Terminal Accessories",
    children: [4283, 505808, 5310],
    parent: 4181,
    level: 5,
  },
  505826: {
    id: 505826,
    name: "Night Lights & Ambient Lighting",
    parent: 594,
    level: 4,
  },
  505827: { id: 505827, name: "Clock Parts", parent: 696, level: 4 },
  505828: { id: 505828, name: "Ostomy Supplies", parent: 2907, level: 5 },
  505830: { id: 505830, name: "Letter Openers", parent: 2986, level: 4 },
  505831: {
    id: 505831,
    name: "Cutter & Scraper Blades",
    parent: 2174,
    level: 5,
  },
  505832: { id: 505832, name: "Kitchen Linens Sets", parent: 4171, level: 4 },
  543510: { id: 543510, name: "Cardstock", parent: 505399, level: 8 },
  543511: { id: 543511, name: "Scrapbooking Paper", parent: 505399, level: 8 },
  543512: { id: 543512, name: "Pre-paid Mobile Phones", parent: 267, level: 6 },
  543513: { id: 543513, name: "Contract Mobile Phones", parent: 267, level: 6 },
  543514: { id: 543514, name: "Unlocked Mobile Phones", parent: 267, level: 6 },
  543515: {
    id: 543515,
    name: "Mobile Phone Pre-Paid Cards",
    parent: 6030,
    level: 7,
  },
  543516: { id: 543516, name: "SIM Cards", parent: 6030, level: 7 },
  543517: { id: 543517, name: "Graphing Calculators", parent: 333, level: 5 },
  543518: { id: 543518, name: "Basic Calculators", parent: 333, level: 5 },
  543519: { id: 543519, name: "Financial Calculators", parent: 333, level: 5 },
  543520: { id: 543520, name: "Scientific Calculators", parent: 333, level: 5 },
  543521: {
    id: 543521,
    name: "Construction Calculators",
    parent: 333,
    level: 5,
  },
  543522: { id: 543522, name: "Music CDs", parent: 855, level: 4 },
  543523: { id: 543523, name: "Records & LPs", parent: 855, level: 4 },
  543524: { id: 543524, name: "Music Cassette Tapes", parent: 855, level: 4 },
  543525: {
    id: 543525,
    name: "Spoken Word & Field Recordings",
    parent: 855,
    level: 4,
  },
  543526: {
    id: 543526,
    name: "Digital Music Downloads",
    parent: 855,
    level: 4,
  },
  543527: { id: 543527, name: "Film & Television DVDs", parent: 839, level: 4 },
  543528: {
    id: 543528,
    name: "Film & Television VHS Tapes",
    parent: 839,
    level: 4,
  },
  543529: {
    id: 543529,
    name: "Film & Television Digital Downloads",
    parent: 839,
    level: 4,
  },
  543530: { id: 543530, name: "Distilled Water", parent: 420, level: 5 },
  543531: {
    id: 543531,
    name: "Carbonated Water",
    children: [543534, 543535],
    parent: 420,
    level: 5,
  },
  543532: { id: 543532, name: "Spring Water", parent: 420, level: 5 },
  543533: { id: 543533, name: "Flat Mineral Water", parent: 420, level: 5 },
  543534: {
    id: 543534,
    name: "Flavored Carbonated Water",
    parent: 543531,
    level: 6,
  },
  543535: {
    id: 543535,
    name: "Unflavored Carbonated Water",
    parent: 543531,
    level: 6,
  },
  543536: {
    id: 543536,
    name: "Shelf-stable Cocktail Mixes",
    parent: 5725,
    level: 6,
  },
  543537: { id: 543537, name: "Frozen Cocktail Mixes", parent: 5725, level: 6 },
  543538: {
    id: 543538,
    name: "Eyewear Replacement Parts",
    parent: 2521,
    level: 6,
  },
  543539: { id: 543539, name: "Magazines", parent: 886, level: 4 },
  543540: { id: 543540, name: "Newspapers", parent: 886, level: 4 },
  543541: { id: 543541, name: "Audiobooks", parent: 784, level: 4 },
  543542: { id: 543542, name: "E-books", parent: 784, level: 4 },
  543543: { id: 543543, name: "Print Books", parent: 784, level: 4 },
  543544: { id: 543544, name: "Chicken Feed", parent: 505821, level: 6 },
  543545: { id: 543545, name: "Cattle Feed", parent: 505821, level: 6 },
  543546: { id: 543546, name: "Pig Feed", parent: 505821, level: 6 },
  543547: { id: 543547, name: "Goat & Sheep Feed", parent: 505821, level: 6 },
  543548: { id: 543548, name: "Mixed Herd Feed", parent: 505821, level: 6 },
  543549: { id: 543549, name: "Egg Replacers", parent: 2660, level: 5 },
  543554: { id: 543554, name: "Egg Whites", parent: 4627, level: 6 },
  543555: { id: 543555, name: "Liquid & Frozen Eggs", parent: 4627, level: 6 },
  543556: { id: 543556, name: "Prepared Eggs", parent: 4627, level: 6 },
  543557: { id: 543557, name: "Whole Eggs", parent: 4627, level: 6 },
  543558: { id: 543558, name: "Potted Houseplants", parent: 6762, level: 5 },
  543559: { id: 543559, name: "Bushes & Shrubs", parent: 6762, level: 5 },
  543560: {
    id: 543560,
    name: "Landscaping & Garden Plants",
    parent: 6762,
    level: 5,
  },
  543561: { id: 543561, name: "Plant & Flower Bulbs", parent: 2802, level: 5 },
  543562: { id: 543562, name: "Seeds & Seed Tape", parent: 2802, level: 5 },
  543573: { id: 543573, name: "Lip Balms", parent: 482, level: 7 },
  543574: {
    id: 543574,
    name: "Medicated Lip Treatments",
    parent: 482,
    level: 7,
  },
  543575: {
    id: 543575,
    name: "Fuel",
    children: [543703, 543576, 543577],
    parent: 632,
    level: 3,
  },
  543576: {
    id: 543576,
    name: "Kerosene",
    children: [543579, 543578],
    parent: 543575,
    level: 4,
  },
  543577: { id: 543577, name: "Propane", parent: 543575, level: 4 },
  543578: { id: 543578, name: "Dyed Kerosene", parent: 543576, level: 5 },
  543579: { id: 543579, name: "Clear Kerosene", parent: 543576, level: 5 },
  543586: { id: 543586, name: "Bandanas", parent: 168, level: 5 },
  543587: { id: 543587, name: "Hair Care Wraps", parent: 168, level: 5 },
  543588: { id: 543588, name: "Joystick Controllers", parent: 301, level: 7 },
  543589: { id: 543589, name: "Gaming Pads", parent: 301, level: 7 },
  543590: { id: 543590, name: "Game Remotes", parent: 301, level: 7 },
  543591: { id: 543591, name: "Game Racing Wheels", parent: 301, level: 7 },
  543593: {
    id: 543593,
    name: "Musical Instrument Game Controllers",
    parent: 301,
    level: 7,
  },
  543595: { id: 543595, name: "Massage Cushions", parent: 471, level: 6 },
  543596: { id: 543596, name: "Electric Massagers", parent: 471, level: 6 },
  543597: { id: 543597, name: "Manual Massage Tools", parent: 471, level: 6 },
  543598: { id: 543598, name: "Deodorant", parent: 484, level: 5 },
  543599: { id: 543599, name: "Anti-Perspirant", parent: 484, level: 5 },
  543600: { id: 543600, name: "Carpet Steamers", parent: 616, level: 5 },
  543601: { id: 543601, name: "Carpet Shampooers", parent: 616, level: 5 },
  543602: { id: 543602, name: "Floor Scrubbers", parent: 616, level: 5 },
  543603: { id: 543603, name: "Steam Mops", parent: 616, level: 5 },
  543606: { id: 543606, name: "Collectible Coins", parent: 217, level: 6 },
  543607: { id: 543607, name: "Collectible Banknotes", parent: 217, level: 6 },
  543608: { id: 543608, name: "Markers", parent: 980, level: 6 },
  543609: { id: 543609, name: "Highlighters", parent: 980, level: 6 },
  543610: { id: 543610, name: "Stair Climbers", parent: 996, level: 7 },
  543611: { id: 543611, name: "Stair Steppers", parent: 996, level: 7 },
  543612: { id: 543612, name: "Play Mats", parent: 1243, level: 5 },
  543613: { id: 543613, name: "Play Gyms", parent: 1243, level: 5 },
  543615: { id: 543615, name: "Shampoo", parent: 2441, level: 6 },
  543616: { id: 543616, name: "Conditioners", parent: 2441, level: 6 },
  543617: {
    id: 543617,
    name: "Shampoo & Conditioner Sets",
    parent: 2441,
    level: 6,
  },
  543618: { id: 543618, name: "Correction Fluids", parent: 2591, level: 5 },
  543619: { id: 543619, name: "Correction Tapes", parent: 2591, level: 5 },
  543620: { id: 543620, name: "Correction Pens", parent: 2591, level: 5 },
  543622: { id: 543622, name: "Toy Kitchens", parent: 3298, level: 6 },
  543623: { id: 543623, name: "Toy Tableware", parent: 3298, level: 6 },
  543624: { id: 543624, name: "Play Food", parent: 3298, level: 6 },
  543626: { id: 543626, name: "Headphones", parent: 505771, level: 6 },
  543627: { id: 543627, name: "Headsets", parent: 505771, level: 6 },
  543628: { id: 543628, name: "Clay", parent: 3692, level: 8 },
  543629: { id: 543629, name: "Modeling Dough", parent: 3692, level: 8 },
  543631: { id: 543631, name: "Sauna Kits", parent: 543687, level: 5 },
  543632: { id: 543632, name: "Sauna Heaters", parent: 543687, level: 5 },
  543633: {
    id: 543633,
    name: "Sauna Buckets & Ladles",
    parent: 543687,
    level: 5,
  },
  543634: { id: 543634, name: "Vaporizers", parent: 4091, level: 5 },
  543635: { id: 543635, name: "Electronic Cigarettes", parent: 4091, level: 5 },
  543636: { id: 543636, name: "Baby Bottle Nipples", parent: 5630, level: 5 },
  543637: { id: 543637, name: "Baby Bottle Liners", parent: 5630, level: 5 },
  543638: { id: 543638, name: "Thimbles", parent: 6156, level: 7 },
  543639: { id: 543639, name: "Sewing Palms", parent: 6156, level: 7 },
  543640: { id: 543640, name: "Portfolios", parent: 6885, level: 5 },
  543641: { id: 543641, name: "Padfolios", parent: 6885, level: 5 },
  543642: { id: 543642, name: "Shochu", parent: 502976, level: 7 },
  543643: { id: 543643, name: "Soju", parent: 502976, level: 7 },
  543644: { id: 543644, name: "Hair Pins", parent: 502988, level: 6 },
  543645: { id: 543645, name: "Hair Claws & Clips", parent: 502988, level: 6 },
  543646: { id: 543646, name: "Barrettes", parent: 502988, level: 6 },
  543647: { id: 543647, name: "Cash Registers", parent: 505824, level: 6 },
  543648: { id: 543648, name: "POS Terminals", parent: 505824, level: 6 },
  543649: { id: 543649, name: "Glass Cleaners", parent: 4976, level: 7 },
  543650: { id: 543650, name: "Muti-surface Cleaners", parent: 4976, level: 7 },
  543651: { id: 543651, name: "Cereal Bars", parent: 5747, level: 6 },
  543652: { id: 543652, name: "Granola Bars", parent: 5747, level: 6 },
  543653: { id: 543653, name: "Trophies", parent: 5868, level: 5 },
  543654: { id: 543654, name: "Award Ribbons", parent: 5868, level: 5 },
  543655: { id: 543655, name: "Award Pins & Medals", parent: 5868, level: 5 },
  543656: { id: 543656, name: "Award Certificates", parent: 5868, level: 5 },
  543657: { id: 543657, name: "Award Plaques", parent: 5868, level: 5 },
  543658: { id: 543658, name: "Toners", parent: 5976, level: 7 },
  543659: { id: 543659, name: "Astringents", parent: 5976, level: 7 },
  543660: { id: 543660, name: "Mechanical Pencils", parent: 981, level: 8 },
  543661: { id: 543661, name: "Wooden Pencils", parent: 981, level: 8 },
  543662: { id: 543662, name: "Report Covers", parent: 6884, level: 5 },
  543663: { id: 543663, name: "Pocket Folders", parent: 6884, level: 5 },
  543664: { id: 543664, name: "Receiving Blankets", parent: 6899, level: 4 },
  543665: { id: 543665, name: "Swaddling Blankets", parent: 6899, level: 4 },
  543666: { id: 543666, name: "Marker Refills", parent: 7117, level: 6 },
  543667: { id: 543667, name: "Highlighter Refills", parent: 7117, level: 6 },
  543668: { id: 543668, name: "Thin Client Computers", parent: 8539, level: 5 },
  543669: { id: 543669, name: "Zero Client Computers", parent: 8539, level: 5 },
  543670: { id: 543670, name: "Medical Needles", parent: 499696, level: 6 },
  543671: { id: 543671, name: "Medical Syringes", parent: 499696, level: 6 },
  543672: {
    id: 543672,
    name: "Medical Needle & Syringe Sets",
    parent: 499696,
    level: 6,
  },
  543673: { id: 543673, name: "Scarves", parent: 177, level: 5 },
  543674: { id: 543674, name: "Shawls", parent: 177, level: 5 },
  543675: { id: 543675, name: "Paper Clips", parent: 936, level: 5 },
  543676: { id: 543676, name: "Binder Clips", parent: 936, level: 5 },
  543677: { id: 543677, name: "Sand", parent: 1794, level: 6 },
  543678: { id: 543678, name: "Soil", parent: 1794, level: 6 },
  543679: {
    id: 543679,
    name: "Fertility Tests & Monitors",
    parent: 505822,
    level: 6,
  },
  543680: { id: 543680, name: "Ovulation Tests", parent: 505822, level: 6 },
  543681: { id: 543681, name: "Prescription Dog Food", parent: 3530, level: 6 },
  543682: {
    id: 543682,
    name: "Non-prescription Dog Food",
    parent: 3530,
    level: 6,
  },
  543683: { id: 543683, name: "Prescription Cat Food", parent: 3367, level: 6 },
  543684: {
    id: 543684,
    name: "Non-prescription Cat Food",
    parent: 3367,
    level: 6,
  },
  543687: {
    id: 543687,
    name: "Sauna Accessories",
    children: [543633, 543632, 543631],
    parent: 729,
    level: 4,
  },
  543688: {
    id: 543688,
    name: "Bulletin Board Trim Sets",
    parent: 7525,
    level: 6,
  },
  543689: { id: 543689, name: "Flashlights", parent: 338, level: 5 },
  543690: { id: 543690, name: "Toy Cookware", parent: 3298, level: 6 },
  543703: { id: 543703, name: "Home Heating Oil", parent: 543575, level: 4 },
};

//function that transforms categoryIds to category objects using map function
export const getCategoriesByIds = (categoryIds: number[] = []) => {
  return categoryIds
    .map((categoryId) => raw_categories[categoryId])
    .filter((c) => !!c);
};

const categories = raw_categories;

export function findCategoryParents(
  category: number,
  withAll = false
): number[] {
  let categoriesResult: number[] = [category];

  do {
    category = categories[category].parent as number;
    if (category) categoriesResult.unshift(category);
  } while (
    categories[category] &&
    (!!categories[category].parent || categories[category].parent === 0)
  );

  if (withAll) {
    if (categoriesResult[0] !== 0) categoriesResult.unshift(0);
  } else {
    categoriesResult = categoriesResult.filter((el) => el !== 0);
  }

  return categoriesResult;
}

export const getProductCategoryNameById = (categoryId: number) => {
  return categories[categoryId]?.name;
};
