import { memo } from 'react';

function Software() {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.3999 2.5H3.3999C3.13469 2.5 2.88033 2.60536 2.6928 2.79289C2.50526 2.98043 2.3999 3.23478 2.3999 3.5V12.5C2.3999 12.7652 2.50526 13.0196 2.6928 13.2071C2.88033 13.3946 3.13469 13.5 3.3999 13.5H14.3999C14.6651 13.5 14.9195 13.3946 15.107 13.2071C15.2945 13.0196 15.3999 12.7652 15.3999 12.5V3.5C15.3999 3.23478 15.2945 2.98043 15.107 2.79289C14.9195 2.60536 14.6651 2.5 14.3999 2.5ZM14.5666 12.6667H3.23324V3.33333H14.5666V12.6667ZM5.8999 5.25C5.8999 5.39834 5.85592 5.54334 5.7735 5.66668C5.69109 5.79001 5.57396 5.88614 5.43692 5.94291C5.29987 5.99968 5.14907 6.01453 5.00358 5.98559C4.8581 5.95665 4.72446 5.88522 4.61957 5.78033C4.51468 5.67544 4.44325 5.5418 4.41431 5.39632C4.38537 5.25083 4.40023 5.10003 4.45699 4.96299C4.51376 4.82594 4.60989 4.70881 4.73322 4.6264C4.85656 4.54399 5.00157 4.5 5.1499 4.5C5.34881 4.5 5.53958 4.57902 5.68023 4.71967C5.82088 4.86032 5.8999 5.05109 5.8999 5.25ZM8.3999 5.25C8.3999 5.39834 8.35592 5.54334 8.27351 5.66668C8.19109 5.79001 8.07396 5.88614 7.93692 5.94291C7.79987 5.99968 7.64907 6.01453 7.50358 5.98559C7.3581 5.95665 7.22446 5.88522 7.11957 5.78033C7.01468 5.67544 6.94325 5.5418 6.91431 5.39632C6.88537 5.25083 6.90023 5.10003 6.95699 4.96299C7.01376 4.82594 7.10989 4.70881 7.23322 4.6264C7.35656 4.54399 7.50157 4.5 7.6499 4.5C7.84881 4.5 8.03958 4.57902 8.18023 4.71967C8.32088 4.86032 8.3999 5.05109 8.3999 5.25Z"
        fill="#1B1D2E"
      />
    </svg>
  );
}

export default memo(Software);
