import { memo } from 'react';

function ArtsEntertainment() {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.39475 14.2187V1.77866M11.8054 14.2187V1.77866M11.8054 4.88799H14.8181M11.8054 11.1073H14.8181M2.38208 4.88799H5.39475M2.38208 7.99799H14.8181M2.38208 11.1073H5.39475M14.8181 3.78066V12.2167C14.8181 13.3213 13.9227 14.2167 12.8181 14.2167H4.38208C3.27741 14.2167 2.38208 13.3213 2.38208 12.2167V3.78066C2.38208 2.67599 3.27741 1.78066 4.38208 1.78066H12.8181C13.9227 1.78066 14.8181 2.67599 14.8181 3.78066Z"
        stroke="#1B1D2E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default memo(ArtsEntertainment);
