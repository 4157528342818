import { memo } from 'react';

function ApparelAccessories() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.5234 6.74481V14.073H4.47922V6.74481M6.10682 2.37762L3.77367 3.38008C3.16796 3.64033 2.72941 4.18235 2.60132 4.82904L1.76953 9.02828H4.47866M9.89846 2.37762L12.2316 3.38008C12.8373 3.64033 13.2759 4.18235 13.404 4.82904L14.2358 9.02828H11.5266M9.89845 2.37424C9.89845 3.42199 9.04908 4.27136 8.00133 4.27136C6.95358 4.27136 6.10421 3.42199 6.10421 2.37424H9.89845Z"
        stroke="#1B1D2E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default memo(ApparelAccessories);
