import { Box } from "@mui/system";
import styled from "styled-components";
import { Breadcrumbs, Typography } from "@mui/material";

import React, { useState } from "react";
import { ArrowBack } from "@mui/icons-material";
import {
  findCategoryParents,
  getProductCategoryNameById,
} from "../../commons/categories";
import { SquareButton } from "../SquareButton";

export const StyledArrowBack = styled(ArrowBack)<{ disabled?: boolean }>`
  font-size: 15px;
`;

const Container = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  gap: ${({ theme: { spacing } }) => spacing(2)};
`;
const StyledA = styled.a`
  text-decoration: none;
`;

const CatsalBreadcrumbs = ({
  category,
  onClickBack,
  isSelector,
  onCategorySelect,
}: {
  category: number;
  onClickBack?: (previousCategory: number) => void;
  isSelector?: boolean;
  onCategorySelect?: (category: number) => void;
}) => {
  const [, setOpen] = useState(false);

  const breadcrumbs = findCategoryParents(+category, true);

  const isBackButtonDisabled = isSelector
    ? breadcrumbs.length === 2
    : breadcrumbs.length === 1;

  const onClickHandler = () => {
    const previousCategory = breadcrumbs[breadcrumbs.length - 2];
    if (previousCategory !== 0 && !previousCategory) return;
    if (onClickBack) onClickBack(previousCategory);
  };

  return (
    <Container>
      <SquareButton
        disabled={isBackButtonDisabled}
        width={32}
        borderRadius={6}
        onClick={onClickHandler}
      >
        <StyledArrowBack />
      </SquareButton>

      <Breadcrumbs maxItems={4} itemsAfterCollapse={3} aria-label="breadcrumb">
        {breadcrumbs
          .slice(0, breadcrumbs.length - 1)
          .map((item: number, i: number) => {
            const isFirst = i === 0;

            const label = (
              <Typography
                key={i}
                variant="interSH6"
                color="gray.GrayDark02"
                sx={isFirst && isSelector ? {} : { cursor: "pointer" }}
              >
                {getProductCategoryNameById(item)}
              </Typography>
            );

            if (isFirst && isSelector) return label;

            return (
              <StyledA
                // href={PATH_CATSAL.category(item)}
                onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (onCategorySelect) onCategorySelect(item);
                  setOpen(false);
                }}
                key={i}
              >
                {label}
              </StyledA>
            );
          })}
      </Breadcrumbs>
    </Container>
  );
};

export default CatsalBreadcrumbs;
