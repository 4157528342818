import { memo } from 'react';

function Furniture() {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.06673 11.5553V13.3327M11.7334 11.5553V13.3327M5.06673 6.22199V4.66733C5.06673 3.56266 5.96207 2.66733 7.06673 2.66733H9.7334C10.8381 2.66733 11.7334 3.56266 11.7334 4.66733V6.22199M13.0667 7.55533C13.0667 6.74533 12.3447 6.10466 11.5094 6.23999C10.8541 6.34733 10.4001 6.96266 10.4001 7.62666V8.88866H6.40007V7.62666C6.40007 6.96266 5.94607 6.34666 5.29073 6.23999C4.4554 6.10466 3.7334 6.746 3.7334 7.55533V9.55466C3.7334 10.6593 4.62873 11.5547 5.7334 11.5547L10.8527 11.5553L11.0667 11.5547C12.1714 11.5547 13.0667 10.6593 13.0667 9.55466V7.55533Z"
        stroke="#1B1D2E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default memo(Furniture);
