import { memo } from 'react';

function HealthBeauty() {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.62218 4.95681H7.57785V6.97785H5.55681V9.02217H7.57785V11.0432H9.62218V9.02217H11.6432V6.97785H9.62218V4.95681Z"
        stroke="#1B1D2E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.8213 14.2213H4.37866C3.274 14.2213 2.37866 13.326 2.37866 12.2213V3.77866C2.37866 2.67399 3.274 1.77866 4.37866 1.77866H12.8213C13.926 1.77866 14.8213 2.67399 14.8213 3.77866V12.2213C14.8213 13.326 13.926 14.2213 12.8213 14.2213Z"
        stroke="#1B1D2E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default memo(HealthBeauty);
