import { memo } from 'react';

function FoodBeveragesTobacco() {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.95093 2.05344V4.60532C3.95093 5.69473 4.83407 6.57787 5.92348 6.57787M7.89603 2.05344V4.60532C7.89603 5.69473 7.01289 6.57787 5.92348 6.57787M5.90397 2.05344V4.7163M5.92348 6.57787V13.9466M13.6488 13.9466L13.6488 2.05344C13.6488 2.05344 10.5627 2.05344 10.5627 7.87196V10.6654H11.9783"
        stroke="#1B1D2E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default memo(FoodBeveragesTobacco);
